import { columnsBancos } from "./column/bancos.columns";
import { modifyInformationBancos } from "./bancos-modify-informations";
import { IConstructTable, ConstructTable } from "../../../../../../components/construct/construct-table/construct-table";
import { endpoint } from "../../../../../../config/environment/endpoint.config";
import { ID } from "../../../../../../config/environment/id.config";
import { useState } from "react";


export const Bancos = () => {
  const [isLoading,setIsLoading] = useState(false);

  const Informations: IConstructTable = {
    endpoint: endpoint.financeiro,
    idNameTable: ID.financeiro.banco,
    nameTable: "Bancos",
    columnsTable: columnsBancos,
    nameMenu: "configuracoes",
    nameSubmenu: "banco",
    modifyData: (data: any) => modifyInformationBancos(data),
    isLoading,setIsLoading
  };
  return <ConstructTable {...Informations} />;
};
