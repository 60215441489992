
export const preloadedValues = {
  opIARecStatus:false,
  opIADescricao :"",
  opIAAliquota:"",
  opIADeduzTotalDFe:false,
  opIADadosAdicionaisGerar :false,
  opIADadosAdicionaisTexto :"",

};
