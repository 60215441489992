import { FormEvent } from "react";

export const masksApp = {
  CPF: "***.***.***-**",
  CNPJ: "**.***.***/****-**",
  celular: "(**)*****-****",
  telefone: "(**)****-*****",
  NCM: "****.**.**",
  codigoEX: "***",
  CEP:"*****-***"
};

const maskTyping = (e:FormEvent<HTMLInputElement | HTMLTextAreaElement>, name: string) => {
  let valueCurrent = e.currentTarget.value;
  valueCurrent = valueCurrent.replace(/\D/g, "");

  switch (name) {
    case "ncm":
      valueCurrent = valueCurrent.replace(/^(\d{4})(\d{2})(\d)/, "$1.$2.$3");
      break;

    case "aliquota":
      valueCurrent = valueCurrent.replace(/(\d)(\d{2})$/, "$1,$2");
      break;
      
    case "unidade":
      valueCurrent = valueCurrent.replace(/(\d)(\d{2})$/, "$1,$2");
      valueCurrent = valueCurrent.replace(/(?=(\d{3})+(\D))\B/g, ".");
      break;
    default:
      break;
  }

  return (e.currentTarget.value = valueCurrent);
};
export const maskTypingNcm = (e:FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  return maskTyping(e, "ncm");
};

export const maskTypingAliquota = (e:FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  return maskTyping(e, "aliquota");
};

export const maskTypingUnidade = (e:FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  return maskTyping(e, "unidade");
};
