import { createContext, ReactNode, useState } from "react";

type AuthContextProps = {
  children: ReactNode;
};

type AuthContextType = {
  isAuth: boolean;
  setIsAuth: (newState: boolean) => void;
};

const initialValue = {
  isAuth: false,
  setIsAuth: () => {},
};

export const AuthContext = createContext<AuthContextType>(initialValue);

export const AuthContextProvider = ({ children }: AuthContextProps) => {
  const [isAuth, setIsAuth] = useState(initialValue.isAuth);

  return (
    <AuthContext.Provider value={{ isAuth, setIsAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
