
import style from "./style.module.scss";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ControllerCheckBox } from "../../../../../../../../components/controllers/checkbox-controller";
import { FooterFormButtons } from "../../../../../../../../components/footer-form/buttons-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { OutrosImpostosFormProps, schemaOutrosImpostos } from "./form.config";
import { preloadedValues } from "./default-values-form";
import { Loading } from "../../../../../../../../components/loading/loading";
import { uuid } from "../../../../../cadastro/pessoas/forms/create.informations";
import { idItemSelectedContext } from "../../../../../../../../context/idItemSelected";
import { ID } from "../../../../../../../../config/environment/id.config";
import { ActionButtonTable } from "../../../../../../../../context/actionButtonTable";
import { recStatusModify } from "../../../../../../../../utils/rec-status.modify";
import { ControllerTextField } from "../../../../../../../../components/controllers/ControllerTextField";

type Form = {
  arrayForm: any
  setArrayForm: any
  setShowForm: any
  valuesForm: any
}
export const OutrosImpostosForm = ({ arrayForm, setArrayForm, setShowForm, valuesForm }: Form) => {
  const { actionButton } = useContext(ActionButtonTable);
  const { idItemSelected } = useContext(idItemSelectedContext);
  const [loading, setLoading] = useState(false)
  const actionForm = actionButton === "edit" || actionButton === "copy"

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<OutrosImpostosFormProps>({
    mode: "onChange",
    resolver: zodResolver(schemaOutrosImpostos),
    defaultValues: preloadedValues
  });

  let searchId: OutrosImpostosFormProps = arrayForm.find(
    (value: any) => value[ID.fiscal.operacaoFiscalImpostoAdicional] === idItemSelected.id
  );

  const editValues = (value: OutrosImpostosFormProps) => {
    setValue("opIARecStatus", value.opIARecStatus)
    setValue("opIADescricao", value.opIADescricao)
    setValue("opIAAliquota", value.opIAAliquota)
    setValue("opIADeduzTotalDFe", value.opIADeduzTotalDFe)
    setValue("opIADadosAdicionaisGerar", value.opIADadosAdicionaisGerar)
    setValue("opIADadosAdicionaisTexto", value.opIADadosAdicionaisTexto)
    setLoading(false)
  }

  const submit = (data: OutrosImpostosFormProps) => {
    if  (actionForm) {
      searchId.opIAAliquota = data.opIAAliquota

      searchId.opIADadosAdicionaisGerar = data.opIADadosAdicionaisGerar
      searchId.opIADadosAdicionaisTexto = data.opIADadosAdicionaisTexto
      searchId.opIADeduzTotalDFe = data.opIADeduzTotalDFe
      searchId.opIADescricao = data.opIADescricao
      searchId.opIARecStatus = recStatusModify.form(data.opIARecStatus)
      setShowForm(false)
      return
    }
    setArrayForm([{ "id": uuid(),...data },...arrayForm])
    setShowForm(false)
  }

  useEffect(() => {
    setLoading(true)
    if (actionForm) {
      editValues(searchId)
      return
    }
    setLoading(false)
  }, [])
  return (
    <>
      {loading && <Loading />}
      <form className={style["form"]}>
        <div className={style["form_content"]}>
          <ControllerCheckBox name={"opIARecStatus"} control={control} label="Desativado" recStatus={true} />
          <ControllerTextField name={"opIADescricao"} placeholder="Insira a descrição" control={control} label="Descrição" errors={errors.opIADescricao?.message} />
          <div className={style["form_row"]}>
            <ControllerTextField name={"opIAAliquota"} placeholder="Insira a alíquota" control={control} label="Alíquota" errors={errors.opIAAliquota?.message}/>
            <div className={style["form_row-checkbox"]}>
              <ControllerCheckBox name={"opIADeduzTotalDFe"} control={control} label="Deduzir do total da nota fiscal" />
              <ControllerCheckBox name={"opIADadosAdicionaisGerar"} control={control} label="Gerar dados adicionais" />
            </div>
          </div>
          <ControllerTextField name={"opIADadosAdicionaisTexto"} placeholder="Insira os dados adicionais" control={control} label="Dados adicionais" multiline={true} errors={errors.opIADadosAdicionaisTexto?.message} />
          <FooterFormButtons min={true} btn_salvar={handleSubmit(submit)} btn_cancelar={() => setShowForm(false)}

          />
        </div>
      </form>
    </>
  )
}