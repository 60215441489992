
import { zodResolver } from "@hookform/resolvers/zod"
import { ControllerCheckBox } from "../../../../../../../../components/controllers/checkbox-controller"
import { ControllerComboBox } from "../../../../../../../../components/controllers/combobox-controller"
import { FooterFormButtons } from "../../../../../../../../components/footer-form/buttons-form"
import { LIpiCst } from "../../options/options.form"
import style from "./style.module.scss"
import { useForm } from "react-hook-form"
import { IPIFormProps, schemaIPI } from "./form.config"
import { IOperacoesFiscais } from "../../interface/form.interface"
import { preloadedValues } from "./default-values-form"
import { useEffect, useState } from "react"
import { Loading } from "../../../../../../../../components/loading/loading"
import { ControllerTextField } from "../../../../../../../../components/controllers/ControllerTextField"
export const IPI = ({ getValuesForm, setValuesForm, closeExpander, setCloseExpander }: IOperacoesFiscais) => {

  const [loading, setLoading] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<IPIFormProps>({
    mode: "onChange",
    resolver: zodResolver(schemaIPI),
    defaultValues: preloadedValues
  });


  const setFormValues = () => {
    setLoading(true)
    setValue("operIPICST", getValuesForm("operIPICST"))
    setValue("operIPIEnquandramento", getValuesForm("operIPIEnquandramento"))
    setValue("operIPIAcumulaBCICMSProprio", getValuesForm("operIPIAcumulaBCICMSProprio"))
    setValue("operIPIAcumulaBCICMSDestino", getValuesForm("operIPIAcumulaBCICMSDestino"))
    setLoading(false)
  }

  const submit = async (data: IPIFormProps) => {
    setValuesForm("operIPICST", data.operIPICST)
    setValuesForm("operIPIEnquandramento", data.operIPIEnquandramento)
    setValuesForm("operIPIAcumulaBCICMSProprio", data.operIPIAcumulaBCICMSProprio)
    setValuesForm("operIPIAcumulaBCICMSDestino", data.operIPIAcumulaBCICMSDestino)
    setCloseExpander({ state: true, open: false })
  }


  useEffect(() => {
    if (closeExpander.state) setFormValues()

  }, [])


  return (
    <>
      {loading ? <Loading /> :
        <form>
          <ControllerComboBox options={LIpiCst} name={"operIPICST"} control={control} label="CST" errors={errors.operIPICST?.message} />
          <ControllerTextField name={"operIPIEnquandramento"} control={control} label="Enquadramento" placeholder="Enquadramanto do IPI" errors={errors.operIPIEnquandramento?.message} />
          <div className={style["form_footer"]}>
            <ControllerCheckBox name={"operIPIAcumulaBCICMSProprio"} control={control} label="Acumular na BC do ICMS Próprio" />
            <ControllerCheckBox name={"operIPIAcumulaBCICMSDestino"} control={control} label="Acumular na BC do ICMS Destino" />
          </div>
          <FooterFormButtons min={true}
            btn_salvar={handleSubmit(submit)}
            btn_cancelar={() => closeExpander({ state: false, open: false })} />

        </form>
      }
    </>
  )
}

