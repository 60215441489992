import { useState } from "react";

import { modifyInformation } from "./modify-information";
import { columnsPrincipal } from "./columns/principal.column";
import { endpoint } from "../../../../../config/environment/endpoint.config";
import { ConstructTable, IConstructTable } from "../../../../../components/construct/construct-table/construct-table";
import { ID } from "../../../../../config/environment/id.config";


export const CadastroPessoas = () => {
  const [isLoading,setIsLoading] = useState(false);
  
  const Informations: IConstructTable = {
    endpoint: endpoint.cadastro,
    idNameTable: ID.cadastro.pessoa,
    nameTable: "Pessoas",
    columnsTable: columnsPrincipal,
    nameMenu: "cadastro",
    nameSubmenu: "pessoa",
    modifyData: modifyInformation,
    isLoading,setIsLoading
  };
  return <ConstructTable {...Informations} />;
}