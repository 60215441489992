import style from "./endereco.module.scss";

import { useContext, useEffect, useState } from "react";
import { ActionButtonTable } from "../../../../../../../context/actionButtonTable";
import { idItemSelectedContext } from "../../../../../../../context/idItemSelected";
import { uuid } from "../create.informations";
import { modifyInformationEnderecos } from "../../modify-information";
import { getLocation } from "../../../../../../../config/location/location.search";
import { IconModify } from "../../../../../../../utils/icon-table.modify";
import { FooterFormButtons } from "../../../../../../../components/footer-form/buttons-form";
import { ControllerCheckBox } from "../../../../../../../components/controllers/checkbox-controller";
import { CadastroPessoaEnderecoFormProps, schemaCadastroPessoaEndereco } from "./form.config";
import { useForm } from "react-hook-form";
import { preloadedValuesEndereco } from "./default-values-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ID } from "../../../../../../../config/environment/id.config";
import { ControllerTextFieldRequest } from "../../../../../../../components/controllers/request/request-controller";
import { ControllerComboBox } from "../../../../../../../components/controllers/combobox-controller";
import { getCep } from "../services/cep";
import {  ValuesPessoaForm } from "../types/types-cadastro-pessoas";
import { Loading } from "../../../../../../../components/loading/loading";
import { CenterValuesContext } from "../../../../../../../context/center-values";
import { convertForComboBox } from "../../../../../../../utils/convert-for-combobox";
import { masksApp } from "../../../../../../../config/masks/masks";
import { recStatusModify } from "../../../../../../../utils/rec-status.modify";
import { ControllerTextField } from "../../../../../../../components/controllers/ControllerTextField";

export const EnderecosForm = ({
  arrayForm, setArrayForm, setShowForm,setValuesForm,getValuesForm
}: ValuesPessoaForm) => {

  const { actionButton } = useContext(ActionButtonTable);
  const { idItemSelected } = useContext(idItemSelectedContext);
  const [loading, setLoading] = useState(false)
  const actionForm = actionButton === "edit" || actionButton === "copy"

  const { centerValues } = useContext(CenterValuesContext)
  const [countryOptions, setCountryOptions] = useState([])
  const [ufOptions, setUfOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<CadastroPessoaEnderecoFormProps>({
    mode: "all",
    resolver: zodResolver(schemaCadastroPessoaEndereco),
    defaultValues: preloadedValuesEndereco,
  });

  let searchId: CadastroPessoaEnderecoFormProps = arrayForm.find(
    (value: any) => value[ID.cadastro.pessoaEndereco] === idItemSelected.id
  );

  const watchUF = watch("psEnUfdeId")
  useEffect(() => {
    let citys = centerValues.cidade.filter(
      (value: any) => value.cidaUfdeId === watchUF
    )
    convertForComboBox({ id: ID.cadastro.city, list: citys, prop: 'cidaNome', items: cityOptions, setItems: setCityOptions });
  }, [watchUF])

  const editValues = () => {
    const EnderecoPadrao_modify: any = IconModify.toForm(
      searchId.psEnEnderecoPadrao
    );
    const EnderecoCobranca_modify: any = IconModify.toForm(
      searchId.psEnEnderecoCobranca
    );
    const EnderecoEntrega_modify: any = IconModify.toForm(
      searchId.psEnEnderecoEntrega
    );

    const countryKey: any = getLocation.getCountryByName(searchId.psEnPaisId);
    const ufKey: any = getLocation.getUFBySigla(searchId.psEnUfdeId);
    const cityKey: any = getLocation.getCityByName(searchId.psEnCidaId);

    setValue("psEnRecStatus", recStatusModify.form(searchId.psEnRecStatus))
    setValue("psEnEnderecoPadrao", EnderecoPadrao_modify);
    setValue("psEnEnderecoCobranca", EnderecoCobranca_modify);
    setValue("psEnEnderecoEntrega", EnderecoEntrega_modify);
    setValue("psEnCEP", searchId.psEnCEP);
    setValue("psEnPaisId", countryKey[ID.cadastro.country]);
    setValue("psEnUfdeId", ufKey[ID.cadastro.uf]);
    setValue("psEnCidaId", cityKey[ID.cadastro.city]);
    setValue("psEnBairro", searchId.psEnBairro);
    setValue("psEnLogradouro", searchId.psEnLogradouro);
    setValue("psEnLogradouroNumero", searchId.psEnLogradouroNumero);
    setValue("psEnComplemento", searchId.psEnComplemento);
  }

  const changeValuesArray = async (data: CadastroPessoaEnderecoFormProps) => {
    searchId.psEnRecStatus = recStatusModify.form(searchId.psEnRecStatus)
    searchId.psEnEnderecoPadrao = data.psEnEnderecoPadrao;
    searchId.psEnEnderecoCobranca = data.psEnEnderecoCobranca;
    searchId.psEnEnderecoEntrega = data.psEnEnderecoEntrega;
    searchId.psEnCEP = data.psEnCEP;
    searchId.psEnPaisId = data.psEnPaisId;
    searchId.psEnUfdeId = data.psEnUfdeId;
    searchId.psEnCidaId = data.psEnCidaId;
    searchId.psEnBairro = data.psEnBairro;
    searchId.psEnLogradouro = data.psEnLogradouro;
    searchId.psEnLogradouroNumero = data.psEnLogradouroNumero;
    searchId.psEnComplemento = data.psEnComplemento;

    await modifyInformationEnderecos(data);
    setShowForm(false);
  };

  const submit = async (data: CadastroPessoaEnderecoFormProps) => {
    if (actionButton === "edit") {
      changeValuesArray(data);
      setValuesForm("pessEnderecos",arrayForm)
      setArrayForm([...arrayForm])
      setShowForm(false)
      return;
    }
    setValuesForm("pessEnderecos",arrayForm)
    setArrayForm([...arrayForm, { id: uuid(), ...data }]);
    setShowForm(false);
  }

  useEffect(() => {
    setLoading(true)
    convertForComboBox({ id: ID.cadastro.country, list: centerValues.pais, prop: 'paisNome', items: countryOptions, setItems: setCountryOptions });
    convertForComboBox({ id: ID.cadastro.uf, list: centerValues.uf, prop: 'ufdeNome', items: ufOptions, setItems: setUfOptions, isUF: true });

    if (actionForm) {
      editValues()
      setLoading(false)
      return
    }
    setLoading(false)
  }, [])

  return (
    <>
      {loading && <Loading />}
      <main className={style["content"]}>
        <form>
          <ControllerCheckBox
            control={control}
            name="psEnRecStatus"
            label="Desativado"
            recStatus={true}
          />
          <div className={style["form-row"]}>

            <ControllerTextFieldRequest
              functionRequest={() => getCep({ cepNumber: getValues("psEnCEP"), setValue })}
              control={control}
              name={"psEnCEP"}
              className={style.inputMiddle}
              label={"CEP"}
              errors={errors.psEnCEP?.message}
              placeholder="Informe seu Cep"
              mask={masksApp.CEP}
            />
            <ControllerComboBox
              control={control}
              options={countryOptions}
              name={"psEnPaisId"}
              label="Pais"
              placeholder="Informe o pais"

            />
            <ControllerComboBox
              control={control}
              options={ufOptions}
              name={"psEnUfdeId"}
              label="UF"
              placeholder="Informe a unidade federativa"
            />
            <ControllerComboBox
              control={control}
              options={cityOptions}
              name={"psEnCidaId"}
              label="Cidade"
              placeholder="Informe a cidade"
            />
          </div>

          <div className={style["form-row"]}>

            <ControllerTextField
              name={"psEnBairro"}
              control={control}
              errors={errors.psEnBairro?.message}
              label={"Bairro"}
              placeholder="Bairro"
            />
            <ControllerTextField
              name={"psEnLogradouro"}
              control={control}
              errors={errors.psEnLogradouro?.message}
              label={"Logradouro"}
              placeholder="Logradouro"
            />
            <ControllerTextField
              name={"psEnLogradouroNumero"}
              control={control}
              errors={errors.psEnLogradouroNumero?.message}
              label={"Número"}
              placeholder="Número"
            />

          </div>

          <ControllerTextField
            name={"psEnComplemento"}
            control={control}
            errors={errors.psEnComplemento?.message}
            label={"Complemento"}
            placeholder="Complemento do endereço"
          />

          <div className={style["form-row-checkbox"]}>

            <ControllerCheckBox
              control={control}
              name="psEnEnderecoPadrao"
              label="Padrão"
            />
            <ControllerCheckBox
              control={control}
              name="psEnEnderecoCobranca"
              label="Cobrança"
            />
            <ControllerCheckBox
              control={control}
              name="psEnEnderecoEntrega"
              label="Entrega"
            />
          </div>
          <FooterFormButtons min={true} btn_cancelar={() => setShowForm(false)} btn_salvar={handleSubmit(submit)} />
        </form>
      </main>
    </>
  );
};
