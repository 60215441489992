
import { ButtonsNav } from "../nav/buttons/buttons-nav"
import { HeaderNav } from "../nav/header-nav/header-nav"
import style from "./style.module.scss"

type menuProps = {
    isCloseMenu: boolean

    setIsCloseMenu: (value:boolean) => void
}
export const Menu = ({isCloseMenu,setIsCloseMenu}:menuProps) => {

    return(
        <aside className={style["aside"]}>
          
            <HeaderNav isClosed={isCloseMenu} setIsClosed={setIsCloseMenu}/>
            <ButtonsNav isClosed={isCloseMenu}/> 
        </aside>
    )
}