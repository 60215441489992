import { IProgressIndicatorStyles, ProgressIndicator } from "@fluentui/react/lib/ProgressIndicator";

export const Loading = () => {
  const customStyles: Partial<IProgressIndicatorStyles> = {
    root: {zIndex: "1", position: "fixed", width: "100%", top: "-100", marginLeft: "2px"},
    itemProgress: {padding: "0px", height: "4px"},
    progressBar: {height: "4px"},
    progressTrack: {height: "4px"},
  }
  
  return <ProgressIndicator styles={customStyles} label="" description="" />
};
