import { ControllerCheckBox } from "../../../../../../../components/controllers/checkbox-controller";
import { FooterFormButtons } from "../../../../../../../components/footer-form/buttons-form";
import { TitleForm } from "../../../../../../../components/text/form/title/title";

import style from "./style.module.scss";
import { ControllerTextFieldRequest } from "../../../../../../../components/controllers/request/request-controller";
import { ControllerComboBox } from "../../../../../../../components/controllers/combobox-controller";
import { EProdTipoItem, LProdOrigem } from "../options.input";

import { Loading } from "../../../../../../../components/loading/loading";
import { useContext, useEffect, useState } from "react";
import { CadastroProdutoFormProps } from "../../form.config";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { schemaBancos } from "../../../../financeiro/config/bancos-contas/form.config";
import { preloadedValues } from "../../default-values-form";
import { useNavigate, useLocation } from "react-router-dom";
import { idItemSelectedContext } from "../../../../../../../context/idItemSelected";
import { currentURlName } from "../../../../../../../utils/url.name";
import { create, getValuesItem } from "../../../../../../../utils/actions/form.actions";
import { ID } from "../../../../../../../config/environment/id.config";
import { endpoint } from "../../../../../../../config/environment/endpoint.config";
import { editItemVerification } from "../../../../../../../utils/actions/editItemPost";
import { FormsExpanderCadastroProduto } from "../forms-expander";
import { getValue } from "@testing-library/user-event/dist/utils";
import { convertForComboBox } from "../../../../../../../utils/convert-for-combobox";
import { Store } from "../../../../../local";
import { recStatusModify } from "../../../../../../../utils/rec-status.modify";
import { ControllerTextField } from "../../../../../../../components/controllers/ControllerTextField";

export const FormCadastroProduto = () => {
 // const [arrayWithValuesForm, setArrayWithValuesForm] = useState<any>([]);
  //const [isOpenContasBancos, setIsOpenContasBancos] = useState(false);
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { idItemSelected } = useContext(idItemSelectedContext);
  const currentPage = currentURlName(pathname);

  const pageReturn = "/linko/Cadastros/produtosServicos/";

 
  const[expanderCodigosAlternativos,setExpanderCodigosAlternativos]=useState({open:false,state:false})
  const[expanderCustoVenda,setExpanderCustoVenda]=useState({open:false,state:false})
  const[expanderInformacoesEspecificas,setExpanderInformacoesEspecificas]=useState({open:false,state:false})
  const[expanderOperacaoFiscais,setExpanderOperacaoFiscais]=useState({open:false,state:false})
  const[expanderPautasFiscais,setExpanderPautasFiscais]=useState({open:false,state:false})
  const[expanderTributacao,setExpanderTributacao]=useState({open:false,state:false})

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,register,
    formState: { errors },
  } = useForm<CadastroProdutoFormProps>({
    mode: "all",
    resolver: zodResolver(schemaBancos),
    defaultValues: preloadedValues,
  });
  const [optionsUnidadeMedida,setOptionsUnidadeMedida] = useState<any>([])
  const getOptions = async() => {
    const unidademedida: any = await Store(endpoint.cadastro, "unidademedida")
    convertForComboBox({ id: ID.cadastro.unidadeMedida, list: unidademedida, prop: 'unidUnidade', items: optionsUnidadeMedida, setItems: setOptionsUnidadeMedida });

  }


  const valuesEdit = (itemValue: CadastroProdutoFormProps) => {
  //  setArrayWithValuesForm(itemValue.bcCtBanco);
    setValue(
      "prodRecStatus",recStatusModify.table(itemValue.prodRecStatus)
    );
    setValue("prodCodigo", itemValue.prodCodigo);
    setValue("prodGTIN", itemValue.prodGTIN);
    setValue("prodDescricao", itemValue.prodDescricao);
    setValue("prodOrigem", itemValue.prodOrigem);
    setValue("prodTipoItem", itemValue.prodTipoItem);
    setValue("prodIndMovFisica", itemValue.prodIndMovFisica);
    setValue("prodUnidId", itemValue.prodUnidId);
    setValue("prodUnidMultiplaPermitida", itemValue.prodUnidMultiplaPermitida);
    setValue("prodPesoB", itemValue.prodPesoB);
    setValue("prodPesoL", itemValue.prodPesoL);
 
    
    setIsLoading(false);
    return;
  };
  useEffect(() => {
    getValuesItem({
      currentPage,
      idSelected: idItemSelected.id,
      nameMenuCurrent: "produto",
      setIsLoading,
      url: endpoint.cadastro,
      valuesEdit,
    });
  }, []);

  const createItem = async (data: CadastroProdutoFormProps) => {
    const idNumber = idItemSelected.id;
    const id = ID.cadastro.produto + ":" + idNumber;
    await create({
      currentPage,
      nameMenuCurrent: "produto",
      url: endpoint.financeiro,
      data: editItemVerification({ currentPage, data, id }),
      setIsLoading,
      idItem: idNumber,
    });
    navigate(pageReturn);
  };

  /*useEffect(() => {
    setValue("bcCtBanco", [...arrayWithValuesForm]);
  }, [arrayWithValuesForm.length]);*/
 useEffect(()=> {
  getOptions()
 },[])
  return (
    <>
   { isLoading && <Loading/>}
    <main className={style["content"]}>
      <section className={style["section"]}>
        <TitleForm title={"Cadastro: Produtos e serviços"} />
        <form className={style["section_form"]}>
          <ControllerCheckBox
            name={"prodRecStatus"}
            label="Desativado"
            control={control}
            recStatus={true}
          />
          <div className={style["section_form-row-sm"]}>
            <ControllerTextFieldRequest
              name="prodGTIN"
              label="GTIN"
              placeholder="GTIN (Global Trade Item Number) do produto"
              functionRequest={() =>{}}
              control={control}
             // errors={errors.prodGTIN?.message}
            />

            <ControllerTextField
              name="prodCodigo"
              label="Código"
              control={control}
              disabled={true}
              errors={errors.prodCodigo?.message}
            />
          </div>
          <ControllerTextField
            name="prodDescricao"
            label="Descrição"
            placeholder="Digite a descrição"
            control={control}
            errors={errors.prodDescricao?.message}
          />
          <div className={style["section_form-row-full"]}>
            <ControllerComboBox
              name="prodTipoItem"
              label="Tipo de item"
              placeholder="Selecione o tipo de item"
              control={control}
              options={EProdTipoItem}
              errors={errors.prodTipoItem?.message}
            />
            <ControllerCheckBox
              name="prodIndMovFisica"
              label="Indicador de movimentação física (controlar estoque)"
              control={control}
              className={style["section_form-row-checkbox"]}
            />
          </div>
          <ControllerComboBox
            name="prodOrigem"
            label="Origem"
            placeholder="Selecione a origem"
            control={control}
            options={LProdOrigem}
            errors={errors.prodOrigem?.message}
          />
          <div className={style["section_form-row-footer"]}>
            <ControllerComboBox
              name="prodUnidId"
              label="Unidade de medida"
              placeholder="Selecione a origem"
              className={style["input-sm"]}
              control={control}
              options={optionsUnidadeMedida}
              errors={errors.prodUnidId?.message}
            />

            <ControllerTextField
              name="prodUnidMultiplaPermitida"
              label="Unidade multipla permitida"
              className={style["input-sm"]}
              placeholder={
                "Múltiplo permitido para as quantidades deste produto"
              }
              control={control}
              disabled={false}
              errors={errors.prodUnidMultiplaPermitida?.message}
            />
            <ControllerTextField
              name="prodPesoL"
              label="Peso liquído"
              className={style["input-sm"]}
              placeholder="Insira o peso liquído"
              control={control}
              errors={errors.prodPesoL?.message}
            />
            <ControllerTextField
              name="prodPesoB"
              label="Peso bruto"
              className={style["input-sm"]}
              placeholder="Insira o peso bruto"
              control={control}
              errors={errors.prodPesoB?.message}
            />
          </div>

          <FormsExpanderCadastroProduto
         getValuesForm={getValues}
         setValuesForm={setValue}
     
          expanderCodigosAlternativos={expanderCodigosAlternativos}
          expanderCustoVenda={expanderCustoVenda}
          expanderInformacoesEspecificas={expanderInformacoesEspecificas}
          expanderOperacaoFiscais={expanderOperacaoFiscais}
          expanderPautasFiscais={expanderPautasFiscais}
          expanderTributacao={expanderTributacao}
          setExpanderCodigosAlternativos={setExpanderCodigosAlternativos}
          setExpanderCustoVenda={setExpanderCustoVenda}
          setExpanderInformacoesEspecificas={setExpanderInformacoesEspecificas}
          setExpanderOperacaoFiscais={setExpanderOperacaoFiscais}
          setExpanderPautasFiscais={setExpanderPautasFiscais}
          setExpanderTributacao={setExpanderTributacao}
          />

          <FooterFormButtons
            min={false}
            btn_salvar={handleSubmit(createItem)}
            btn_cancelar={() => {
              navigate(pageReturn);
            }}

          />
        </form>
      </section>
    </main>
    </>
  );
};
