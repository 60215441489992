import style from "./style.module.scss";

import { TextField } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { NameItemContext } from "../../../context/nameItemSearch";

type ISearch = {
 onChange?: any
 value?:string;
}

let timerSearch: any;

export const Search = ({onChange}:ISearch) => {
  const [showButtonClean, setShowButtonClean] = useState(false);

  const onChangeEvent = (e: any, value: any) => {
    clearTimeout(timerSearch);
    timerSearch = setTimeout(() => {
      onChange(e, value)
    }, 600)
  }

  return (
    <div className={style["header"]}>
      <div className={style["header_search"]}>
        <TextField
          className={style["header_search-input"]}
          placeholder="Procurar..."
          onChange={onChangeEvent}
        />
        {showButtonClean && (
          <div
            className={style["header_search-clean"]}
            onClick={(e) => {
              onChangeEvent(e, "");
            }}
          />
        )}
        <div className={style["header_search-icon"]} />
      </div>
   
    </div>
  );
};/*   <div className={style["header_filter"]}>
<button className={style["header_filter-button"]}>
  <div className={style["header_filter-icon"]}></div>
</button>
</div>*/