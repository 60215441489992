import style from "./style.module.scss";
import { useContext, useEffect, useState } from "react";
import { FooterFormButtons } from "../../../../../../../components/footer-form/buttons-form";
import { SubtitleForm } from "../../../../../../../components/text/form/subtitle/subtitle";
import { useForm } from "react-hook-form";
import { IPropsFormProduto } from "../../interface/props.interface-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { RegEx, moneyFormatEvent, percentFormatEvent} from "../../../../../../../config/RegEx/regex";
import { PrecoVendaAjustado } from "./preco-venda-ajustado/preco-venda-ajustado.form";
import { OutrasDespesas } from "./despesas/despesas-extras.table";
import { ActionButtonTable } from "../../../../../../../context/actionButtonTable";
import { idItemSelectedContext } from "../../../../../../../context/idItemSelected";
import { preloadedValues } from "./default-values";
import { calcCustoFinal, calcValue, calcPercent } from "./calc/calc.custo-final";

import { ControllerTextFieldMoney } from "./component/textfield";
import { ControllerTextFieldPercent } from "../../component/controller-textfield-percent";
import { ControllerTextField } from "../../../../../../../components/controllers/ControllerTextField";
import { CenterValuesContext } from "../../../../../../../context/center-values";
import { apiApp } from "../../../../../../../services/api/app.api";
import { endpoint } from "../../../../../../../config/environment/endpoint.config";
import { schemaPrecoVenda } from "./preco-venda-ajustado/form.config";
import { CadastroPrecoVendaProps, schemaCustoPrecoVenda } from "./form.config";

export const CustaPrecoVendaProduto = ({
  getValuesForm, setValuesForm, setCloseExpander
}: IPropsFormProduto) => {
  const {centerValues} = useContext(CenterValuesContext)
  const [showFormDespesas, setShowFormDespesas] = useState(false);
  const [showFormPrecoDeVendas, setShowFormPrecoDeVendas] = useState(false);
  const { actionButton } = useContext(ActionButtonTable);
  const { idItemSelected } = useContext(idItemSelectedContext);
  const [loading, setLoading] = useState(false)
  const actionForm = actionButton === "edit" || actionButton === "copy"
  const [expanderPrecoVendaAjustado, setExpanderPrecoVendaAjustado] = useState({ open: false, state: false });
  const [expanderOutrasDespesas, setExpanderOutrasDespesas] = useState({ open: false, state: false });
  const [colorCalc, setColorCalc] = useState<any>()

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<CadastroPrecoVendaProps>({
    mode: "onChange",
    resolver: zodResolver(schemaCustoPrecoVenda),
    defaultValues: preloadedValues
  });

  const custoInicial = watch("prPBCustoInicial")
  const watchprPBCustoVIPI = watch("prPBCustoVIPI");
  const watchprPBCustoVFrete = watch("prPBCustoVFrete");
  const watchprPBCustoVFreteCTe = watch("prPBCustoVFreteCTe");
  const watchprPBCustoVST = watch("prPBCustoVST");

  const watchprPBCustoVDespesasTotais = watch("prPBCustoVDespesasTotais");
  const watchprPBCustoVICMSFreteCTe = watch("prPBCustoVICMSFreteCTe");
  const watchprPBCustoVICMS = watch("prPBCustoVICMS");
  const watchprPBCustoVPIS = watch("prPBCustoVPIS");
  const watchprPBCustoVCOFINS = watch("prPBCustoVCOFINS");

  const regimeTributacao = centerValues.empresa.regimeTributacao
  if(regimeTributacao < 5){
    setValue("prPBCustoVICMS","0")
    setValue("prPBCustoVPIS","0")
    setValue("prPBCustoVCOFINS","0")

  }
  ///TODO: Testar valores com , e maior que 100 com ,
  const valueWithPercent = (value:string,name:any) => {
    if(Number(value.replace(RegEx.onlyNumber,"")) >= 100){
      setValue(name, custoInicial)  
      return "100"
    }
    setValue(name, calcValue(custoInicial, value))
    return value
  }

  const percentWithValue = (value:string,name:any) => {
   
    setValue(name, calcPercent(custoInicial, value))
    return value
  }
  
  useEffect(() => {
    calcCustoFinal(getValues, setValue, setColorCalc)
    return
  }, [custoInicial, watchprPBCustoVIPI, watchprPBCustoVFrete, watchprPBCustoVFreteCTe, watchprPBCustoVST, watchprPBCustoVDespesasTotais, watchprPBCustoVICMSFreteCTe,
    watchprPBCustoVICMS, watchprPBCustoVPIS, watchprPBCustoVCOFINS])

  useEffect(() => {
  
    setValue("prPBCustoVIPI",calcValue(custoInicial, getValues("prPBCustoPIPI")));
    setValue("prPBCustoVFrete",calcValue(custoInicial,  getValues("prPBCustoPFrete")));
    setValue("prPBCustoVFreteCTe",calcValue(custoInicial,  getValues("prPBCustoPFreteCTe")));
    setValue("prPBCustoVST",calcValue(custoInicial,  getValues("prPBCustoPST")));
  }, [custoInicial]);

 
  return (
    <form>
      <SubtitleForm subtitle={"Preço de custo"} />
      <div
        className={
          !showFormDespesas
            ? style["inputs-values-products"]
            : style["inputs-values-products-form"]
        }
      >
        {!showFormDespesas ? (
          <>
            <div className={style["form-row"]}>
              <span className={style["span-label"]}>
                Custo unitário inicial
              </span>

              <ControllerTextField
                name={"prPBCustoInicial"}
                control={control}
                className={style["input-middle"]}
                prefix={"$"}
                format={true}
                formatFunction={moneyFormatEvent}
              />
            </div>
            <div className={style["form-row"]}>
              <span className={style["span-label"]}>IPI</span>
              <ControllerTextFieldPercent
                name={"prPBCustoPIPI"}
                control={control}
                className={style["input-sm"]}
                onChange={(e: any) => {
                  setValue("prPBCustoPIPI", valueWithPercent(e.currentTarget.value,"prPBCustoVIPI"))
                }}
              />
              <ControllerTextFieldMoney
                name={"prPBCustoVIPI"}
                control={control}
                className={style["input-middle"]}
                prefix="$"
                onChange={(e: any) => {
                  setValue("prPBCustoVIPI", percentWithValue(e.currentTarget.value,"prPBCustoPIPI"))
                }}              
              />
            </div>
            <div className={style["form-row"]}>
              <span className={style["span-label"]}>Frete Fornecedor</span>

              <ControllerTextFieldPercent
                name={"prPBCustoPFrete"}
                control={control}
                className={style["input-sm"]}
                onChange={(e: any) => {
                  setValue("prPBCustoPFrete", valueWithPercent(e.currentTarget.value,"prPBCustoVFrete"))
                }}
              />

              <ControllerTextField
                name={"prPBCustoVFrete"}
                control={control}
                className={style["input-middle"]}
                prefix={"$"}
                format={true}
                formatFunction={moneyFormatEvent}
              />

            </div>
            <div className={style["form-row"]}>
              <span className={style["span-label"]}>Frete Transportadora</span>

              <ControllerTextFieldPercent
                name={"prPBCustoPFreteCTe"}
                control={control}
                className={style["input-sm"]}
                onChange={(e: any) => {
                  setValue("prPBCustoPFreteCTe",valueWithPercent(e.currentTarget.value,"prPBCustoVFreteCTe"))
                }}
              />

              <ControllerTextField
                name={"prPBCustoVFreteCTe"}
                control={control}
                className={style["input-middle"]}
                prefix={"$"}
                format={true}
                formatFunction={moneyFormatEvent}
              />

            </div>
            <div className={style["form-row"]}>
              <span className={style["span-label"]}>ICMS ST</span>

              <ControllerTextFieldPercent
                name={"prPBCustoPST"}
                control={control}
                className={style["input-sm"]}
                onChange={(e: any) => {
                  setValue("prPBCustoPST",valueWithPercent(e.currentTarget.value,"prPBCustoVST"))
                }}
              />

              <ControllerTextField
                name={"prPBCustoVST"}
                control={control}
                className={style["input-middle"]}
                prefix={"$"}
                format={true}
                formatFunction={moneyFormatEvent}
              />

            </div>
            <div className={style["form-row"]}>
              <span className={style["span-label"]}>Outras despesas</span>
              <button
                className={style["button-form-edit"]}
                onClick={() => setShowFormDespesas(true)}
              >
                Editar
              </button>
              <ControllerTextField
                name={"prPBCustoVDespesasTotais"}
                control={control}
                className={style["input-middle"]}
                prefix={"$"}
                disabled={true}
                format={true}
                formatFunction={moneyFormatEvent}
              />

            </div>
            <div className={style["form-row"]}>
              <span className={style["span-label"]}>
                Crédito de ICMS S/Frete
              </span>
              <ControllerTextField
                name="prPBCustoVICMSFreteCTe"
                control={control}
                errors={errors.prPBCustoVICMSFreteCTe?.message}
                className={style["input-middle"]}
                prefix={"$"}
                format={true}
                formatFunction={moneyFormatEvent}
              />
            </div>
            <div className={style["form-row"]}>
              <span className={style["span-label"]}>Crédito de ICMS</span>

              <ControllerTextField
                name="prPBCustoVICMS"
                control={control}
                errors={errors.prPBCustoVICMS?.message}
                className={style["input-middle"]}
                prefix={"%"}
                format={true}
                formatFunction={moneyFormatEvent}
              />
            </div>
            <div className={style["form-row"]}>
              <span className={style["span-label"]}>Crédito de PIS</span>
              <ControllerTextField
                name="prPBCustoVPIS"
                control={control}
                errors={errors.prPBCustoVPIS?.message}
                className={style["input-middle"]}
                prefix={"%"}
                format={true}
                formatFunction={moneyFormatEvent}
              />
            </div>
            <div className={style["form-row"]}>
              <span className={style["span-label"]}>Crédito de COFINS</span>
              <ControllerTextField
                name="prPBCustoVCOFINS"
                control={control}
                errors={errors.prPBCustoVCOFINS?.message}
                className={style["input-middle"]}
                prefix={"%"}
                format={true}
                formatFunction={moneyFormatEvent}
              />
            </div>
            <div className={style["form-row"]}>
              <span className={style["span-label"]}>Custo total</span>

              <div className={style["result"]}>
                <span className={style["result_calc-prefix"]}> $ </span>
                <span className={style["result_calc"]}>
                {colorCalc ? "- ": ""}{watch("prPBCustoFinal")}</span>

              </div>
            </div>
          </>
        ) : (
          <OutrasDespesas
            closeExpander={expanderOutrasDespesas}
            getValuesForm={getValues}
            setCloseExpander={setExpanderOutrasDespesas}
            setValuesForm={setValue}
            showFormOutrasDespesas={setShowFormDespesas}
          />
        )}
      </div>

      <SubtitleForm subtitle={"Preço de venda"} />

      <div className={style["inputs-values-products"]}>
        {!showFormPrecoDeVendas ? (
          <>
            <div className={style["form-row"]}>
              <span className={style["span-label"]}>Comissão base</span>

              <ControllerTextField
                name="prPBFormacaoPComissao"
                control={control}
                errors={errors.prPBFormacaoPComissao?.message}
                className={style["input-middle"]}
                prefix={"%"}
                format={true}
                formatFunction={percentFormatEvent}
                
              />
            </div>
            <div className={style["form-row"]}>
              <span className={style["span-label"]}>Margem de lucro</span>
              <ControllerTextField
                name="prPBFormacaoPMargem"
                control={control}
                errors={errors.prPBFormacaoPMargem?.message}
                className={style["input-middle"]}
                prefix={"%"}
                format={true}
                formatFunction={percentFormatEvent}
              />
            </div>
            <div className={style["form-row"]}>
              <span className={style["span-label"]}>Preço de venda mínimo</span>
              <ControllerTextField
                name="prLPMkPVMinimo"
                placeholder="Markup"
                control={control}
                errors={""}
                className={style["input-sm"]}
                disabled={true}
              />
              <ControllerTextField
                name="prLPPVMinimo"
                control={control}
                errors={errors.prLPPVMinimo?.message}
                className={style["input-middle"]}
                disabled={true}

              />
            </div>
            <div className={style["form-row"]}>
              <span className={style["span-label"]}>
                Preço de venda calculado
              </span>
              <ControllerTextField
                name="prLPMkPVFinal"
                placeholder="Markup"
                control={control}
                errors={""}
                className={style["input-sm"]}
                disabled={true}
              />
              <ControllerTextField
                name="prLPPVFinal"
                control={control}
                errors={errors.prPBCustoInicial?.message}
                className={style["input-middle"]}
                disabled={true}

              />
            </div>
            <div className={style["form-row"]}>
              <span className={style["span-label"]}>
                Preço de venda ajustado
              </span>
              <button
                className={style["button-form-edit"]}
                onClick={() => setShowFormPrecoDeVendas(true)}
              >
                Editar
              </button>
              <ControllerTextField
                name="prLPPV"
                control={control}
                errors={""}
                className={style["input-middle"]}
                prefix={"%"}
              />
            </div>
            <div className={style["form-row"]}>
              <span className={style["span-label"]}>
                Desconto máximo permitido
              </span>
              <ControllerTextField
                name=""
                control={control}
                errors={""}
                className={style["input-middle"]}
                prefix={"%"}
              />
            </div>
          </>
        ) : (
          <PrecoVendaAjustado
            getValuesForm={getValues}
            setValuesForm={setValue}
            closeExpander={setExpanderPrecoVendaAjustado}
            setCloseExpander={expanderPrecoVendaAjustado}
            setShowFormPrecoVenda={setShowFormPrecoDeVendas}
            
          />
        )}
      </div>
      {!showFormPrecoDeVendas ? (
        <FooterFormButtons
          min={true}
          btn_cancelar={() => { }}
          btn_salvar={() => { }}
        />
      ) : (
        <></>
      )}
    </form>
  );
};

