export const LIpiCst = [
  { key: "0", text: "0 - Entrada com recuperação de crédito" },
  { key: "1", text: "1 - Entrada tributada com alíquota zero" },
  { key: "2", text: "2 - Entrada isenta" },
  { key: "3", text: "3 - Entrada não tributada" },
  { key: "4", text: "4 - Entrada imune" },
  { key: "5", text: "5 - Entrada com suspensão" },
  { key: "49", text: "49 - Outras entradas" },
  { key: "50", text: "50 - Saída tributada" },
  { key: "51", text: "51 - Saída tributada com alíquota zero" },
  { key: "52", text: "52 - Saída isenta" },
  { key: "53", text: "53 - Saída não tributada" },
  { key: "54", text: "54 - Saída imune" },
  { key: "55", text: "55 - Saída com suspensão" },
  { key: "99", text: "99 - Outras saídas" },
];

export const LIpiModalidadeBc = [
  { key: "0", text: "0 - Alíquota" },
  { key: "1", text: "1 - Unidade" },
];

export const LIcmsCst = [
  { key: "00", text: "0 - Trib integralmente" },
  { key: "01", text: "1 - Trib p/Simples Nacional s/permissão de crédito" },
  {
    key: "10",
    text: "10 - Trib c/cobrança de ICMS p/ST",
  },
  {
    key: "11",
    text: "11 - Trib p/Simples Nacional s/permissão de crédito c/ICMS devido p/ST relativo às operações e prestações subsequentes",
  },
  {
    key: "12",
    text: "12 - Trib c/ICMS devido p/ST relativo às operações e prestações antecedentes",
  },
  {
    key: "13",
    text: "13 - Trib c/ICMS devido p/ST relativo às operações e prestações concomitantes",
  },
  {
    key: "14",
    text: "14 - Trib p/Simples Nacional s/permissão de crédito c/ICMS devido p/ST relativo às operações e prestações concomitantes",
  },
  {
    key: "15",
    text: "15 - Trib monofásica própria c/responsabilidade pela retenção s/combustíveis",
  },
  { key: "20", text: "20 - Trib c/redução de base de cálculo" },
  { key: "21", text: "21 - Trib p/Simples Nacional c/redução do imposto e s/permissão de crédito" },

  {
    key: "30",
    text:
      "30 - Isento ou Não Trib c/cobrança de ICMS p/ST",
  },
  { key: "40", text: "40 - Isento" },
  { key: "41", text: "41 - Não Trib" },
  { key: "50", text: "50 - Suspensão" },
  { key: "51", text: "51 - Diferimento" },
  { key: "52", text: "52 - Diferimento c/ICMS devido p/ST relativo às operações e prestações subsequentes" },
  { key: "53", text: "53 - Trib monofásica s/combustíveis c/recolhimento diferido" },

  {
    key: "60",
    text: "60 - ICMS cobrado anteriormente p/ST ou p/antecipação c/encerramento de tributação",
  },
  {
    key: "61",
    text: "61 - Trib monofásica s/combustíveis cobrada anteriormente",
  },

  {
    key: "70",
    text: "70 - Trib c/redução de base de cálculo e cobrança de ICMS p/ST",
  },
  {
    key: "71",
    text: "71 - Trib p/Simples Nacional c/redução do imposto s/permissão de crédito c/ICMS devido p/ST relativo às operações e prestações subsequentes",
  },
  {
    key: "72",
    text: "72 - Trib c/redução de base de cálculo ou c/redução do imposto c/ICMS devido p/ST relativo às operações e prestações antecedentes",
  },
  {
    key: "73",
    text: "73 - Trib p/Simples Nacional c/redução do imposto, s/permissão de crédito c/ICMS devido p/ST relativo às operações e prestações antecedentes",
  },
  {
    key: "74",
    text: "74 - Trib c/redução de base de cálculo ou c/redução do imposto c/ICMS devido p/ST relativo às operações e prestações concomitantes",
  },
   {
    key: "75",
    text: "75 - Trib p/Simples Nacional c/redução do imposto, s/permissão de crédito c/ICMS devido p/ST relativo às operações e prestações concomitantes",
  },
  { key: "90", text: "90 - Outras operações" },
];
export const LIcmsCsosn = [
  {
    key: "101",
    text: "101 - Tributada pelo Simples Nacional com permissão de crédito",
  },
  {
    key: "102",
    text: "102 - Tributada pelo Simples Nacional sem permissão de crédito",
  },
  {
    key: "103",
    text: "103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta",
  },
  {
    key: "201",
    text:
      "201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária",
  },
  {
    key: "202",
    text:
      "202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária",
  },
  {
    key: "203",
    text:
      "203 - Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária",
  },
  { key: "300", text: "300 - Imune" },
  { key: "400", text: "400 - Não tributada pelo Simples Nacional" },
  {
    key: "500",
    text:
      "500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação",
  },
  { key: "900", text: "900 - Outros" },
];

export const LIcmsModalidadeBc = [
  { key: "0", text: "MVA (Margem de Valor Agregado)" },
  { key: "1", text: "Valor de Pauta" },
  { key: "2", text: "Preço Tabelado Máximo (valor)" },
  { key: "3", text: "Valor da operação" },
];

export const LIcmsDesoneracaoMotivo = [
  { key: "0", text: "0 - Sem desoneração" },
  { key: "1", text: "1 - Táxi" },
  { key: "3", text: "3 - Produtor agropecuário" },
  { key: "4", text: "4 - Frotista/Locadora" },
  { key: "5", text: "5 - Diplomático/Consultar" },
  { key: "6", text: "6 - Utilitários e motocicletas da Amazônia Ocidental e áreas de livre comércio"},
  { key: "7", text: "7 - SUFRAMA" },
  { key: "8", text: "8 - Venda a órgão público" },
  { key: "9", text: "9 - Outros (NT 2011/004)" },
  { key: "10", text: "10 - Deficiente condutor (Convênio ICMS 38/12)" },
  { key: "11", text: "11 - Deficiente não condutor (Convênio ICMS 38/12)" },
  { key: "12", text: "12 - Órgão de fomento e desenvolvimento agropecuário" },
  { key: "90", text: "90 - Solicitado pelo Fisco (NT 2016/002)" },
];

export const LIcmsDesoneracaoFormulaBc = [
  { key: "0", text: "0 - Base de cálculo simples" },
  { key: "1", text: "1 - Base de calculo por dentro" },
];

export const LIcmsModalidadeBcSt = [
  { key: "0", text: "0 - Preço tabelado ou máximo sugerido" },
  { key: "1", text: "1 - Lista Negativa (valor)" },
  { key: "2", text: "2 - Lista Positiva (valor)" },
  { key: "3", text: "3 - Lista Neutra (valor)" },
  { key: "4", text: "4 - Margem de Valor Agregado (%)" },
  { key: "5", text: "5 - Pauta (valor" },
  { key: "6", text: "6 - Valor da operação" },
];


export const LOperIndicadorPresenca = [
  {
    key: "0",
    text: "0 - Não se aplica (Notas complementares, ajuste e outras operações)",
  },
  { key: "1", text: "1 - Operação presencial" },
  { key: "2", text: "2 - Não presencial: Internet" },
  { key: "3", text: "3 - Não presencial: Teleatendimento" },
  { key: "4", text: "4 - NFC-e entrega em domicílio" },
  { key: "5", text: "5 - Operação presencial, fora do estabelecimento" },
  { key: "9", text: "9 - Não presencial: Outros" },
];

export const LDfIndIntermed = [
  { key: "0", text: "0 - Operação sem intermediador (em site ou plataforma própria)" },
  { key: "1", text: "1 - OPeração em site ou plataforma de terceiros (intermediadores/marketplace)" },
]

export const LIndFinal = [
  { key: "0", text: "0 - Não" },
  { key: "1", text: "1 - Consumidor Final" },
]

export const LContribuicaoCst = [ 
  {key:"01",text:"01 - Operação Tributável com Alíquota Básica"},
  {key:"02",text:"02 - Operação Tributável com Alíquota Diferenciada"},
  {key:"03",text:"03 - Operação Tributável com Alíquota por Unidade de Medida de Produto"},
  {key:"04",text:"04 - Operação Tributável Monofásica - Revenda a Alíquota Zero"},
  {key:"05",text:"05 - Operação Tributável por Substituição Tributária"},
  {key:"06",text:"06 - Operação Tributável a Alíquota Zero"},
  {key:"07",text:"07 - Operação Isenta da Contribuição"},
  {key:"08",text:"08 - Operação sem Incidência da Contribuição"},
  {key:"09",text:"09 - Operação com Suspensão da Contribuição"},
  {key:"49",text:"49 - Outras Operações de Saída"},
  {key:"50",text:"50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno"},
  {key:"51",text:"51 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno"},
  {key:"52",text:"52 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação"},
  {key:"53",text:"53 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno"},
  {key:"54",text:"54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação"},
  {key:"55",text:"55 - Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação"},
  {key:"56",text:"56 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação"},
  {key:"60",text:"60 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno"},
  {key:"61",text:"61 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno"},
  {key:"62",text:"62 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação"},
  {key:"63",text:"63 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno"},
  {key:"64",text:"64 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação"},
  {key:"65",text:"65 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação"},
  {key:"66",text:"66 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de exportação"},
  {key:"67",text:"67 - Crédito Presumido - Outras Operações"},
  {key:"70",text:"70 - Operação de Aquisição sem Direito a Crédito"},
  {key:"71",text:"71 - Operação de Aquisição com Isenção"},
  {key:"72",text:"72 - Operação de Aquisição com Suspensão"},
  {key:"73",text:"73 - Operação de Aquisição a Alíquota Zero"},
  {key:"74",text:"74 - Operação de Aquisição sem Incidência da Contribuição"},
  {key:"75",text:"75 - Operação de Aquisição por Substituição Tributária"},
  {key:"98",text:"98 - Outras Operações de Entrada"},
  {key:"99",text:"99 - Outras Operações"},
]

