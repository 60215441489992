
export const preloadedValues = {
  prLPPDescontoMaximo:"0",
  prLPPVCalculado:"0",
  prLPPV:"0",
  prLPPCustoFixo:"0",
  prLPPComissao:"0",
  prLPPFaixaSN:"0",
  prLPPICMS:"0",
  prLPPReducaoICMS:"0",
  prLPPDiferimentoICMS:"0",
  prLPPPIS:"0",
  prLPPCOFINS:"0",
  };