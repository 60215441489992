import { z } from "zod";
import { formatDate } from "../../../../../../../components/controllers/calendar-controller/format-date.config";

export const schemaContasBancarias = z.object({
    bcCtRecStatus: z.any(),
    bcCtDataConciliacao:z.any().transform(value => formatDate(value)),
    bcCtAgencia:z.string().length(4, "Deve conter 4 Números"),
    bcCtAgenciaDV: z.string().length(1, "Deve conter apenas 1 Número"),
    bcCtConta: z.string().length(30, "Deve conter 30 Números"),
    bcCtContaDV: z.string().length(1,"Deve conter apenas 1 Número"),

});

export type ContasBancariasFormProps = z.infer<
  typeof schemaContasBancarias
>;
