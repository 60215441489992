import { BrowserRouter } from "react-router-dom";
import { ActionButtonTableProvider } from "./context/actionButtonTable";
import { AllItemsProvider } from "./context/allItemsTable";
import { TabsContextProvider } from "./context/allTabs";
import { FilterItemsContextProvider } from "./context/filterItems";
import { IdItemSelectedContextProvider } from "./context/idItemSelected";
import { AuthContextProvider } from "./context/isAuth";
import { LoadingContextProvider } from "./context/loading";
import { MenuContextProvider } from "./context/menuSize";
import { ModalDeleteProvider } from "./context/modalDelete";
import { NameItemContextProvider } from "./context/nameItemSearch";
import { TabSelectedContextProvider } from "./context/tabSelected";
import { NamePageLoginContextProvider } from "./context/name-page-login";
import { CenterValuesContextProvider } from "./context/center-values";

export const ContextsApp = ({ children }: any) => {

  return (
    <BrowserRouter>
    <CenterValuesContextProvider>
   
      
        <MenuContextProvider>
          <TabsContextProvider>
            <TabSelectedContextProvider>
              <NameItemContextProvider>
                <LoadingContextProvider>
                  <IdItemSelectedContextProvider>
                    <ActionButtonTableProvider>
                      <ModalDeleteProvider>
                        <AllItemsProvider>
                          <FilterItemsContextProvider>
                            <AuthContextProvider>
                              <NamePageLoginContextProvider>
                                {children}
                              </NamePageLoginContextProvider>
                            </AuthContextProvider>
                          </FilterItemsContextProvider>
                        </AllItemsProvider>
                      </ModalDeleteProvider>
                    </ActionButtonTableProvider>
                  </IdItemSelectedContextProvider>
                </LoadingContextProvider>
              </NameItemContextProvider>
            </TabSelectedContextProvider>
          </TabsContextProvider>
        </MenuContextProvider>
    
      </CenterValuesContextProvider>
    </BrowserRouter>
  );
};
