import axios from "axios";

export const api = axios.create({
  timeout: 30000,
});

export const TokenUpdate = () => {
  api.interceptors.request.use((config) => {
    let token = localStorage.getItem("linko_token");
  
    if (token) {
      config.headers!.Token = token;
      return config;
    }
  
    return config;
  });
  
}

