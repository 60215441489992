import { useNavigate } from "react-router-dom";
import style from "./style.module.scss";
import React from "react";
import { TabsContext } from "../../../context/allTabs";
import { TabSelectedContext } from "../../../context/tabSelected";
import { ITab } from "./button-nav-options";

interface IButtonNavNotOptionsProps {
  isClose: boolean;
  icon: any;
  props: ITab;
}

export const ButtonNotOptions = ({
  icon,
  isClose,
  props,
}: IButtonNavNotOptionsProps) => {
  const navigate = useNavigate();

  const { newTab, setNewTab } = React.useContext(TabsContext);
  const { setTabSelected } = React.useContext(TabSelectedContext);

  const openPage = (props: ITab) => {
    if (newTab.length === 0) {
      navigate(`/linko/${props.category}/${props.key}`);
      setTabSelected(props.key);
      return setNewTab([...newTab, props]);
    }
    let tab = newTab.filter((value) => value.key === props.key);

    if (tab.length === 0) {
      navigate(`/linko/${props.category}/${props.key}`);
      setTabSelected(props.key);

      return setNewTab([...newTab, props]);
    }
    setTabSelected(props.key);

    navigate(`/linko/${props.category}/${props.key}`);
  };
  return (
    <div
      className={
        style[isClose ? "button_not-options-close" : "button_not-options"]
      }
      aria-label={props.name}
      onClick={() => openPage(props)}
    >
      <div
        className={
          style[
            isClose
              ? "button_not-options-close-content"
              : "button_not-options-content"
          ]
        }
      >
        <img className={style["button_options-icon"]} src={icon} alt="" />
        {isClose ? (
          <></>
        ) : (
          <span className={style["button_options-name"]}>{props.name}</span>
        )}
      </div>
    </div>
  );
};
