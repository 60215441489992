export const configFiscal = [
  {
    category: "configuracoes",
    key: "NCMNVE",
    name: "NCM/NVE",
  },
  {
    category: "configuracoes",
    key: "CFOP",
    name: "CFOP",
  },
  {
    category: "configuracoes",
    key: "aliquotasInterestaduaisICMS",
    name: "Alíquotas interestaduais de ICMS",
  },
  {
    category: "configuracoes",
    key: "aliquotasFCP",
    name: "Alíquotas de FCP",
  },
  {
    category: "configuracoes",
    key: "operacoesFiscais",
    name: "Operações fiscais",
  },
];
