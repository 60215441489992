import { z } from "zod";

export const schemaPrecoVenda = z
  .object({
    prLPPDescontoMaximo:z.string(),
    prLPPVMinimo:z.string(),
    prLPPVCalculado:z.string(),
    prLPPV:z.string(),
    prLPPCustoFixo:z.string(),
    prLPPComissao:z.string(),
    prLPPFaixaSN:z.string(),
    prLPPICMS:z.string(),
    prLPPReducaoICMS:z.string(),
    prLPPDiferimentoICMS:z.string(),
    prLPPPIS:z.string(),
    prLPPCOFINS:z.string(),
    
});

export type CustoPrecoVenda = z.infer<typeof schemaPrecoVenda>;
