export const columnsProdutos = [
    {
      key: "column1",
      name: "Código",
      fieldName: "prodCodigo",
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column2",
      name: "GTIN",
      fieldName: "prodGTIN",
      minWidth: 160,
      maxWidth: 240,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Descrição",
      fieldName: "prodDescricao",
   
      isResizable: true,
    },
    {
      key: "column4",
      name: "Unidade",
      fieldName: "prodUnidId",
      minWidth: 160,
      maxWidth: 240,
      isResizable: true,
    },
    {
      key: "column5",
      name: "Status",
      fieldName: "prodRecStatus",
      minWidth: 120,
      maxWidth: 160,
      isResizable: true,
    },

  ];
  
