import { columnsCondicoesPagamento } from "./column/condicoes-pagamento.columns";
import { endpoint } from "../../../../../../config/environment/endpoint.config";
import { ID } from "../../../../../../config/environment/id.config";
import { ConstructTable, IConstructTable } from "../../../../../../components/construct/construct-table/construct-table";
import { useState } from "react";
import { modifyInformationCondicoesPagamento } from "./condicoes-pagamento-modify-informations";

export const CondicoesPagamento = () => {
  const [isLoading,setIsLoading] = useState(false)
  const Informations: IConstructTable = {
    endpoint: endpoint.financeiro,
    idNameTable: ID.financeiro.condicaoPagamento,
    nameTable: "Condições de pagamento",
    columnsTable: columnsCondicoesPagamento,
    nameMenu: "configuracoes",
    nameSubmenu: "condicaoPagamento",
    modifyData: (data: any) => {modifyInformationCondicoesPagamento(data)},
    isLoading,setIsLoading
  };
  return <ConstructTable {...Informations} />;
};

