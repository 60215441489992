import { z } from "zod";

export const schemaListaPrecos = z
  .object({
  
      lsPrRecStatus:z.any(),
      lsPrDescricao:z.string().min(1,"Insira uma descrição"),
      lsPrPadrao:z.boolean(),
      lsPrAtualizacaoAutomatica:z.boolean(),
      lsPrUfdeId:z.string(),
      lsPrPCustoFixo:z.string(),
      lsPrIndComissao:z.string(),
      lsPrPFatorComissao:z.string(),
      lsPrPDescontoMaximo:z.string(),
      lsPrValidAt:z.any(),
      lsPrExpireAt:z.any()
      });

export type ListaPrecosFormProps = z.infer<typeof schemaListaPrecos>;
