import style from "./style.module.scss";
import * as React from "react";

import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  DetailsRow,
  IDetailsListProps,
  IDetailsRowStyles,
  SelectionMode,
  CheckboxVisibility,

} from "@fluentui/react/lib/DetailsList";

import { Checkbox, MarqueeSelection, TextField } from "@fluentui/react";

import { Pagination } from "./pagination/pagination";
import { MyProps, ITableItems } from "./types/interfaces.table";
import { AllItemsContext } from "../../../context/allItemsTable";
import { FilterItemsContext } from "../../../context/filterItems";
import { idItemSelectedContext } from "../../../context/idItemSelected";
import { RegEx, moneyFormatValue } from "../../../config/RegEx/regex";

export const Table = ({ columns, listItems, FilterTablesValue,propIdName,setGetItemSelectedTable, pageIndex, pageCount, totalItens, onSetPage}: MyProps) => {
  const [selectionDetails, setSelectionDetails] = React.useState();
  const [allSelected, setAllSelected] = React.useState("");
  const { allItems } = React.useContext(AllItemsContext);
  const { setFilterItems, filterItems } = React.useContext(FilterItemsContext);
  
  const { setIdItemSelected } = React.useContext(
    idItemSelectedContext
    );

    
  const getSelectionDetails = (): any => {
    const selectionCount = selection.getSelectedCount();

    switch (selectionCount) {
      case 0:
        setAllSelected(" Nenhum item foi selecionado");
        return;
      case 1:
        setAllSelected("1 item selecionado");
        return;
      default:
        setAllSelected(`${selectionCount} itens selecionados`);
        return;
    }
  };
  
  const selection = new Selection({
    onSelectionChanged: () => {
      setIdItemSelected({
        id: (selection.getSelection()[0] as any)?.[propIdName],
        count: selection.getSelectedCount(),
      });
      setSelectionDetails(getSelectionDetails);
      let Array = selection.getSelection()
      
      //TODO Jeff: sem sentido isso ser aqui dessa forma, já que é global
      //let sumWithInitial = Array.reduce((accumulator:any, currentValue:any) =>  Number(accumulator) + Number(currentValue.prPDValor.replace(/\D/g, '')), 0)
      //setGetItemSelectedTable(moneyFormatValue(sumWithInitial))  
    },
  });

  const onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.itemIndex % 2 === 0) {
        customStyles.root = { backgroundColor: "#eee" };
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  return (
    <section className={style.section}>      
      <div className={style.table}>
        <div className={style.sectionTable}>
          <MarqueeSelection selection={selection}>
            <DetailsList
              className={style.tableLayout}
              items={listItems}
              columns={columns}
              onRenderRow={onRenderRow}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              selection={selection}
              selectionPreservedOnEmptyClick={false}
              checkboxVisibility={CheckboxVisibility.always}
         
            />
          </MarqueeSelection>
        </div>
      </div>
      <div className={style.sectionTableDetails}>
        {totalItens} registros | {allSelected}
      </div>
      <Pagination
        pageIndex={pageIndex}
        pageCount={pageCount}
        onSetPage={onSetPage}
      />
    </section>
  );
};

//TODO: deixar a table com tamanho fixo