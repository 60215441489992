import { createContext, ReactNode, useState } from "react";


type TabContextProps = {
    children: ReactNode;
};

type Empresa = {
    name: string;
    regimeTributacao:number;
}


type ITab = {
    cfop:any;
    centroDeCustos:any;
    planoContas:any;
    pais:any;
    uf:any;
    cidade:any;
    empresa:Empresa;
}

type TabContextType = {
    centerValues: ITab;
    setCenterValues: (value:ITab) => void;
};

const initialValue = {
    centerValues: {cfop:[], centroDeCustos:[], planoContas:[],uf:[], cidade:[], pais:[],empresa:{name:"",regimeTributacao:0}},
    setCenterValues: () => { },
};

export const CenterValuesContext = createContext<TabContextType>(initialValue);

export const CenterValuesContextProvider = ({ children }: TabContextProps) => {
    const [centerValues, setCenterValues] = useState(initialValue.centerValues);


    return (
        <CenterValuesContext.Provider value={{ centerValues, setCenterValues }}>
            {children}
        </CenterValuesContext.Provider>
    );
};
