import { z } from "zod";

export const schemaBancos = z.object({
  bcosRecStatus: z.any(),
  bcosCodigoFebraban: z.string().length(3),
  bcosCodigoFebrabanDV: z.string().length(1),
  bcosNomeFebraban: z.string(),
  bcosNome:z.string(),
  bcosContas:z.any()
});

export type BancosFormProps = z.infer<
  typeof schemaBancos
>;
