import { FeriadosFormProps, schemaFeriados } from "./form.config";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { preloadedValues } from "./default-values-form";
import { ControllerCalendar } from "../../../../../../components/controllers/calendar-controller/calendar";
import { ControllerCheckBox } from "../../../../../../components/controllers/checkbox-controller";
import { FooterFormButtons } from "../../../../../../components/footer-form/buttons-form";
import { useLocation, useNavigate } from "react-router-dom";
import { TitleForm } from "../../../../../../components/text/form/title/title";
import style from "./style.module.scss";
import { useContext, useEffect, useState } from "react";
import { ID } from "../../../../../../config/environment/id.config";
import { endpoint } from "../../../../../../config/environment/endpoint.config";
import { idItemSelectedContext } from "../../../../../../context/idItemSelected";
import { editItemVerification } from "../../../../../../utils/actions/editItemPost";
import {
  getValuesItem,
  create,
} from "../../../../../../utils/actions/form.actions";
import { currentURlName } from "../../../../../../utils/url.name";
import { Loading } from "../../../../../../components/loading/loading";
import { recStatusModify } from "../../../../../../utils/rec-status.modify";
import { ControllerTextField } from "../../../../../../components/controllers/ControllerTextField";
export const FeriadosForm = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { idItemSelected } = useContext(idItemSelectedContext);
  const currentPage = currentURlName(pathname);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FeriadosFormProps>({
    mode: "all",
    resolver: zodResolver(schemaFeriados),
    defaultValues: preloadedValues,
  });

  const pageReturn = "/linko/configuracoes/feriados";

  const valuesEdit = (itemValue: FeriadosFormProps) => {
    setValue(
      "hdayRecStatus",
      recStatusModify.table(itemValue.hdayRecStatus)
    );
    setValue("hdayDescricao", itemValue.hdayDescricao);
    setValue("hdayPermanente", itemValue.hdayPermanente);
    setValue("hdayData", itemValue.hdayData);

    setIsLoading(false);
    return;
  };
  useEffect(() => {
    getValuesItem({
      currentPage,
      idSelected: idItemSelected.id,
      nameMenuCurrent: "feriado",
      setIsLoading,
      url: endpoint.financeiro,
      valuesEdit,
    });
  }, []);

  const submit = async (data: any) => {
    const idNumber = idItemSelected.id;
    const id = ID.financeiro.feriado + ":" + idNumber;
    await create({
      currentPage,
      nameMenuCurrent: "feriado",
      url: endpoint.financeiro,
      data: editItemVerification({ currentPage, data, id }),
      setIsLoading,
      idItem: idNumber,
    });
    navigate(pageReturn);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className={style["content"]}>
            <TitleForm title={"Feriados"} />

            <form>
              <div className={style["content_form"]}>
                <ControllerCheckBox
                  name={"hdayRecStatus"}
                  label={"Desativado"}
                  control={control}
                  recStatus={true}
                />
                <div className={style["content_form-section"]}>
                  <ControllerCalendar
                    name={"hdayData"}
                    label="Data"
                    control={control}
                    errorMessage={errors.hdayData?.message}
                  />
                  <ControllerCheckBox
                    name={"hdayPermanente"}
                    label="Feriado permanente"
                    control={control}
                    className={style["content_form-section-checkbox"]}
                  />
                </div>
                <ControllerTextField
                  name={"hdayDescricao"}
                  label="Descrição"
                  control={control}
                  errors={errors.hdayDescricao?.message}
                />
              </div>
              <FooterFormButtons
                min={false}
                btn_cancelar={() => {
                  navigate(`/linko/configuracoes/feriados`);
                }}
                btn_salvar={handleSubmit(submit)}
              />
            </form>
          </div>
        </>
      )}
    </>
  );
};
