import { useContext, useEffect, useState } from "react";
import { columnsContaBancaria } from "./column/bancos.columns";
import { ContaBancariaForm } from "./conta-bancaria.form";
import { modifyInformationContasBancos } from "../bancos-modify-informations";
import { idItemSelectedContext } from "../../../../../../../context/idItemSelected";
import { ModalDelete } from "../../../../../../../context/modalDelete";
import { TableForm } from "../../../../../../../components/tables/form/table-form";
import { ID } from "../../../../../../../config/environment/id.config";
import { ModalDeleteItem } from "../../../../../../../components/modal-delete/modalDelete";
import { Loading } from "../../../../../../../components/loading/loading";
import style from "./style.module.scss";
type props = {
  setArrayWithValuesForm: any;
  arrayWithValuesForm: any;
};

export const ContaBancaria = ({
  setArrayWithValuesForm,
  arrayWithValuesForm,
}: props) => {
  const [loading, setLoading] = useState(false);

  const [showForm, setShowForm] = useState(false);
  const { isOpenModal, setIsOpenModal } = useContext(ModalDelete);
  const { idItemSelected, setCategoryId } = useContext(idItemSelectedContext);
  const [showModal, setShowModal] = useState(false);
  const [listRemovedValues, setListRemovedValues] = useState<any>([]);

  const getItems = async () => {
    let result = await modifyInformationContasBancos(arrayWithValuesForm);
    if (!result) {
      setArrayWithValuesForm([]);
      setLoading(false);

      return;
    }

    setArrayWithValuesForm(result);
    setLoading(false);

    return;
  };

  const deleteItem = async () => {
    let index = arrayWithValuesForm.findIndex((value: any) => {
      return value[ID.financeiro.bancoConta] === idItemSelected.id;
    });

    setListRemovedValues([...listRemovedValues, arrayWithValuesForm[index]]);
    arrayWithValuesForm.splice(index, 1);

    getItems();
    setIsOpenModal(false);
    return;
  };

  const restoreList = () => {
    let result = listRemovedValues[listRemovedValues.length - 1];
    if (listRemovedValues.length > 0) {
      setArrayWithValuesForm([...arrayWithValuesForm, result]);
      listRemovedValues.pop();
      return;
    }
    return;
  };

  useEffect(() => {
    setLoading(true);
    setCategoryId("configuracoes");
    getItems();
  }, [arrayWithValuesForm.length]);

  return (
    <section className={style.section}>
      {loading ? (
        <Loading />
      ) : (
        <div className={style.sectionContent}>
          {isOpenModal ? (
            <ModalDeleteItem
              functionDelete={deleteItem}
              setShowModal={setShowModal}
            />
          ) : (
            <>
              <TableForm
                columns={columnsContaBancaria}
                listItems={arrayWithValuesForm}
                propIdName={ID.financeiro.bancoConta}
                Form={
                  <ContaBancariaForm
                    setArrayWithValuesForm={setArrayWithValuesForm}
                    setShowForm={setShowForm}
                    arrayWithValuesForm={arrayWithValuesForm}
                  />
                }
                showForm={showForm}
                setShowForm={setShowForm}
                restoreList={restoreList}
                showModal={showModal}
                setShowModal={setShowModal}
              />
            </>
          )}
        </div>
      )}
    </section>
  );
};
