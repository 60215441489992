import style from "./style.module.scss";
import { useContext } from "react";
import { ActionButtonTable } from "../../context/actionButtonTable";
import { ModalDelete } from "../../context/modalDelete";
import { idItemSelectedContext } from "../../context/idItemSelected";
import { ButtonActionTable } from "../buttons/actions-tables/buttons-action-table";

interface INavigatePage {
  navigateAdd: () => void;
  navigateCopy: () => void;
  navigateEdit: () => void;
  setShowModal: (state: any) => void;
  children?: any;
}
export const ButtonsActionTable = ({
  navigateAdd,
  navigateCopy,
  navigateEdit,
  setShowModal,
  children,
}: INavigatePage) => {
  const { setActionButton } = useContext(ActionButtonTable);
  const { setIsOpenModal, setIdItem } = useContext(ModalDelete);

  const { idItemSelected } = useContext(idItemSelectedContext);

  const verification = () => {
    if (idItemSelected.count === 0) {
      alert("Precisa selecionar um item");
      setIsOpenModal(false);

      return;
    }
    if (idItemSelected.count > 1) {
      alert("Selecione apenas um item");
      setIsOpenModal(false);

      return;
    }
  };
  return (
    <div className={style["buttonsActionForm"]}>
      <ButtonActionTable
        type={"add"}
        onClick={() => {
          setActionButton("add");
          navigateAdd();
        }}
      />
      <ButtonActionTable
        type={"edit"}
        onClick={() => {
          verification();
          setActionButton("edit");
          setIdItem(idItemSelected.id);
          navigateEdit();
        }}
      />
      <ButtonActionTable
        type={"copy"}
        onClick={() => {
          verification();
          setActionButton("copy");
          setIdItem(idItemSelected.id);
          navigateCopy();
        }}
      />

      <ButtonActionTable
        type={"remove"}
        onClick={() => {
          verification();
          setShowModal(true);
          setIdItem(idItemSelected.id);
          return;
        }}
      />

      {children}
    </div>
  );
};
