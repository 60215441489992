import cancel from "../assets/icons/gerais/cancel.svg";
import checkCircle from "../assets/icons/gerais/checkCircle.svg";

class Icon {
  typeOf(value: any) {
    let typeOfValue = typeof(value);
    if (typeOfValue === "boolean") {
      return value ? true : false;
    }
    if (typeOfValue === "object") {
      return value.props.className === "true" ? true : false;
    }
  }

  valueToIcon(value: any) {
    return value ? <img src={checkCircle} alt="sim" className="true" /> :
    <img src={cancel} alt="nao" className="false" />
  }

  toApi(value: any) {
    let result = this.typeOf(value);
    return result;
  }

  toTable(value: any) {
    return this.valueToIcon(value);
  }

  toForm(value: any) {
    let result = this.typeOf(value);
    return result;
  }
}

export const IconModify = new Icon();
