import { ID } from "../../../../../../../config/environment/id.config";

import { CodigoAlternativoForm } from "./form/codigo-alternativo.form";
import { columnsCodigosAlternativos } from "../../columns/codigos-alternativos.column";
import {
  ConstructTableForm,
  IConstructTableForm,
} from "../../../../../../../components/construct/construct-table-form/construct-table-form";
import { useState } from "react";
import { IPropsFormProduto } from "../../interface/props.interface-form";
import { modifyForm } from "./modify.form";


export const CodigosAlternativosProduto = ({
  getValuesForm, setValuesForm, setCloseExpander, closeExpander

}: IPropsFormProduto) => {
  const [showForm, setShowForm] = useState(false)
  const [arrayForm, setArrayForm] = useState([]);

  const Informations: IConstructTableForm = {
    columns: columnsCodigosAlternativos,
    propIdName: ID.cadastro.produto,
    modifyValuesApi: modifyForm,
    arrayWithValuesForm: arrayForm,
    setArrayWithValuesForm: setArrayForm,
    categoryId: "cadastro",
    setShowForm: setShowForm,
    showForm: showForm,
    navigatePage: "", 
    setCloseExpander, closeExpander,
    form: <CodigoAlternativoForm arrayForm={arrayForm} setArrayForm={setArrayForm} setShowForm={setShowForm} valuesForm={undefined} />,
  };
  return <ConstructTableForm {...Informations} />;
};
