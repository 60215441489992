import style from "../style.module.scss";
import { ControllerCalendar } from "../../../../../../../components/controllers/calendar-controller/calendar";
import { ControllerCheckBox } from "../../../../../../../components/controllers/checkbox-controller";
import { FooterFormButtons } from "../../../../../../../components/footer-form/buttons-form";
import { ContasBancariasFormProps, schemaContasBancarias } from "./form.config";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { preloadedValues } from "./default-values-form";
import { useContext, useEffect, useState } from "react";
import { ActionButtonTable } from "../../../../../../../context/actionButtonTable";
import { idItemSelectedContext } from "../../../../../../../context/idItemSelected";
import { Loading } from "../../../../../../../components/loading/loading";
import { uuid } from "../../../../cadastro/pessoas/forms/create.informations";
import { recStatusModify } from "../../../../../../../utils/rec-status.modify";
import { ControllerTextField } from "../../../../../../../components/controllers/ControllerTextField";

type props = {
  setShowForm:(value:boolean) => void;
  arrayWithValuesForm:any;
  setArrayWithValuesForm:any;

}

export const ContaBancariaForm = ({arrayWithValuesForm,setArrayWithValuesForm,setShowForm}:props) => {
  const { actionButton } = useContext(ActionButtonTable);
  const { idItemSelected } = useContext(idItemSelectedContext);
  const [loading,setIsLoading] = useState(false)
  const actionForm = actionButton === "edit" || actionButton === "copy"
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ContasBancariasFormProps>({
    mode: "all",
    resolver: zodResolver(schemaContasBancarias),
    defaultValues: preloadedValues,
  });

  let searchId:ContasBancariasFormProps = arrayWithValuesForm.find(
    (value: any) => value.id === idItemSelected.id
  );

  const editValues = (value:ContasBancariasFormProps) => {
    setValue("bcCtRecStatus",recStatusModify.form(value.bcCtRecStatus))
    setValue("bcCtAgencia",value.bcCtAgencia)
    setValue("bcCtAgenciaDV",value.bcCtAgenciaDV)
    setValue("bcCtConta",value.bcCtConta)
    setValue("bcCtContaDV",value.bcCtContaDV)
    setValue("bcCtDataConciliacao",value.bcCtDataConciliacao)
    setIsLoading(false)

  }

  const submit = (data: ContasBancariasFormProps) => {
    if(actionForm){
      searchId.bcCtAgencia = getValues("bcCtAgencia")
      searchId.bcCtAgenciaDV = getValues("bcCtAgenciaDV")
      searchId.bcCtConta = getValues("bcCtConta")
      searchId.bcCtContaDV = getValues("bcCtContaDV")
      searchId.bcCtDataConciliacao = getValues("bcCtDataConciliacao")
      searchId.bcCtRecStatus = recStatusModify.api(getValues("bcCtRecStatus"))
      setShowForm(false)
      return
      
    }

    setArrayWithValuesForm([{"id":uuid(),...data}, ...arrayWithValuesForm])
    setShowForm(false)
  }

  useEffect(() => {
    setIsLoading(true)
    if(actionForm){
      editValues(searchId)
      return
    }
    setIsLoading(false)
  },[])


  return (<>
    {loading && <Loading/>}

    <form className={style["content"]}>
      <ControllerCheckBox
        name={"bcCtRecStatus"}
        label="Desativado"
        control={control}
        recStatus={true}
      />
      <ControllerCalendar
        name={"bcCtDataConciliacao"}
        label={"Data de conciliação ou fechamento"}
        control={control}
       errorMessage={errors.bcCtDataConciliacao?.message }
      />

      <div className={style["content_form-row"]}>
        <div className={style["content_form-row-group"]}>
          <ControllerTextField
            name={"bcCtAgencia"}
            label={"Agência"}
            control={control}
            placeholder="Insira a agência"
            errors={errors.bcCtAgencia?.message }
          />
          <ControllerTextField
            name={"bcCtAgenciaDV"}
            label={"DV"}
            control={control}
            className={style["input-md"]}
            placeholder="Insira DV"
           errors={errors.bcCtAgenciaDV?.message}
          />
        </div>
        <div className={style["content_form-row-group"]}>
          <ControllerTextField
            name={"bcCtConta"}
            label={"Conta"}
            control={control}
            placeholder="Insira Conta"
           errors={errors.bcCtConta?.message}
          />
          <ControllerTextField
            name={"bcCtContaDV"}
            label={"DV"}
            className={style["input-md"]}
            placeholder="Insira DV"
            control={control}
           errors={errors.bcCtContaDV?.message}
          />
        </div>
      </div>

      <FooterFormButtons
        min={true}
        btn_salvar={handleSubmit(submit)}
        btn_cancelar={() => {setShowForm(false)}}
      />
    </form>
    </>
  );
};
