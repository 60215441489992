import { DatePicker } from "@fluentui/react";
import style from "./style.module.scss";
import { DayPickerStrings } from "./calendar.config";
import {
  convertDateInString,
  convertStringInDate,
  formatDate,
} from "./format-date.config";
import { Controller } from "react-hook-form";

interface ICalendarInput {
  name: string;
  label: string;
  className?: string;
  control: any;
  disabled?: boolean;
  errorMessage: any;
}

export const ControllerCalendar = ({
  className = "",
  control,
  disabled = false,
  label,
  name,
  errorMessage,
}: ICalendarInput) => {
  const showDateValue = (value: string) => {
    if (typeof value === "string") {
      return convertStringInDate(value);
    }
    return convertDateInString(value);
  };

  return (
    <div className={style["content"]}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            className={style["calendar"]}
            placeholder="Selecione uma data"
            label={label}
            value={showDateValue(value)}
            ariaLabel="Selecione a data"
            strings={DayPickerStrings}
            formatDate={(date) => formatDate(date)}
            onSelectDate={onChange}
            disabled={disabled}

          />
        )}
      />
      <span className={style["calendar_error"]}>{errorMessage}</span>
    </div>
  );
};
