export const columnsUnidadeMedida = [
    {
      key: "column1",
      name: "Unidade de medida",
      fieldName: "unidUnidade",
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column2",
      name: "Descrição",
      fieldName: "unidDescricao",
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
    },
  
    {
      key: "column3",
      name: "Status",
      fieldName: "unidRecStatus",
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
    },
  ];
  