import style from "./principal.module.scss";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { idItemSelectedContext } from "../../../../../../../context/idItemSelected";
import { EventosPessoa } from "../evento/eventos.table";
import { EnderecosPessoa } from "../endereco/endereco.table";
import { LleTipo, LRegimeTributacao } from "../../optionsInput/options.input";
import { apiApp } from "../../../../../../../services/api/app.api";
import { currentURlName } from "../../../../../../../utils/url.name";
import { FooterFormButtons } from "../../../../../../../components/footer-form/buttons-form";
import { TitleForm } from "../../../../../../../components/text/form/title/title";
import { Expander } from "../../../../../../../components/expander/expander";
import { endpoint } from "../../../../../../../config/environment/endpoint.config";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CadastroPessoaFormProps, schemaCadastroPessoa } from "./form.config";
import { ControllerCheckBox } from "../../../../../../../components/controllers/checkbox-controller";
import { ControllerComboBox } from "../../../../../../../components/controllers/combobox-controller";
import { preloadedValues } from "./default-values-form";
import { create, getValuesItem } from "../../../../../../../utils/actions/form.actions";
import { editItemVerification } from "../../../../../../../utils/actions/editItemPost";
import { ID } from "../../../../../../../config/environment/id.config";
import { ControllerMaskTextField } from "../../../../../../../components/controllers/mask-textfield-controller";
import { IETipo, regimeDeTributacaoRule } from "./rules";
import { ControllerTextFieldRequest } from "../../../../../../../components/controllers/request/request-controller";
import { getValuesCNPJ } from "../services/cnpj";
import { Loading } from "../../../../../../../components/loading/loading";
import { ContatosPessoa } from "../contato/contatos.table";
import { recStatusModify } from "../../../../../../../utils/rec-status.modify";
import { ControllerTextField } from "../../../../../../../components/controllers/ControllerTextField";
interface IExpander {
  open: boolean,
  state: boolean
}
export const FormCadastroPessoas = () => {
  const navigate = useNavigate();
  const { idItemSelected } = useContext(idItemSelectedContext);
  const { pathname } = useLocation();
  const currentPage = currentURlName(pathname);
  const pageReturn = "/linko/cadastros/pessoas";

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<CadastroPessoaFormProps>({
    mode: "all",
    resolver: zodResolver(schemaCadastroPessoa),
    defaultValues: preloadedValues,
  });


  const watchRegimeTributacao = watch("pessRegimeTributacao")
  const watchIETipo = watch("pessIETipo")

  const [readOnlyPessIE, setReadOnlyPessIE] = useState(false)
  const [loading, setIsLoading] = useState(false);
  const [expanderEnderecos, setExpanderEnderecos] = useState<IExpander>({ open: false, state: false });
  const [expanderContatos, setExpanderContatos] = useState<IExpander>({ open: false, state: false });
  const [expanderEventos, setExpanderEventos] = useState<IExpander>({ open: false, state: false });
  const [maskDocumento, setMaskDocumento] = useState("");

  const [arrayFormContatos, setArrayFormContatos] = useState<any>(getValues("pessContatos") || []);
  const [arrayFormEnderecos, setArrayFormEnderecos] = useState<any>(getValues("pessEnderecos") || []);
  const [arrayFormEventos, setArrayFormEventos] = useState<any>(getValues("pessEventos") || []);


  useEffect(() => {
    regimeDeTributacaoRule({ watchRegimeTributacao, setMaskDocumento })
  }, [watchRegimeTributacao])

  useEffect(() => {
    IETipo({ setValue, watchIETipo, setReadOnlyPessIE })
  }, [watchIETipo])

  const valuesEdit = (values: any) => {
    setValue("pessRecStatus", recStatusModify.form(values.pessRecStatus));
    setValue("pessRegimeTributacao", values.pessRegimeTributacao);
    setValue("pessDocumento", values.pessDocumento);
    setValue("pessRazao", values.pessRazao);
    setValue("pessFantasia", values.pessFantasia);
    setValue("pessIETipo", values.pessIETipo);
    setValue("pessIE", values.pessIE);
    setValue("pessIM", values.pessIM);
    setValue("pessSUFRAMA", values.pessSUFRAMA);
    setArrayFormContatos(values.pessContatos)
    setArrayFormEnderecos(values.pessEnderecos)
    setArrayFormEventos(values.pessEventos)
    setIsLoading(true);

  }

  useEffect(() => {
    getValuesItem({
      currentPage,
      idSelected: idItemSelected.id,
      nameMenuCurrent: "pessoa",
      setIsLoading,
      url: endpoint.cadastro,
      valuesEdit,
    });
    setIsLoading(false);

    return;
  }, []);

  useEffect(() => {
    setValue("pessContatos", [...arrayFormContatos])
    setValue("pessEnderecos", [...arrayFormEnderecos])
    setValue("pessEventos", [...arrayFormEventos])
  }, [arrayFormContatos, arrayFormEnderecos, arrayFormEnderecos])

  const submit = async (data: any) => {
    const getFantasiaValue = getValues("pessFantasia")

    if (!getFantasiaValue) setValue("pessFantasia", getValues("pessRazao"))

    const idNumber = idItemSelected.id;
    const id = ID.cadastro.pessoa + ":" + idNumber;
    await create({
      currentPage,
      nameMenuCurrent: "pessoa",
      url: endpoint.cadastro,
      data: editItemVerification({ currentPage, data, id }),
      setIsLoading,
      idItem: idNumber,
    });
    navigate(pageReturn);
  };

  return (
    <main className={style["content"]}>
      {loading ? <Loading /> :
        <form>

          <TitleForm title={"Pessoa"} />
          <ControllerCheckBox
            name={"pessRecStatus"}
            control={control}
            label="Desativado"
            recStatus={true}
          />

          <div className={style["form-row"]}>
            <ControllerComboBox
              options={LRegimeTributacao}
              name={"pessRegimeTributacao"}
              control={control}
              label="Regime de tributação"
              errors={errors.pessRegimeTributacao?.message}
            />
            {watchRegimeTributacao > 1 ?

              <ControllerTextFieldRequest
                functionRequest={() => getValuesCNPJ({ cnpjNumber: getValues("pessDocumento"), setValue })}
                name={"pessDocumento"}
                control={control}
                errors={errors.pessDocumento?.message}
                placeholder="Informe seu documento"
                label="Documento(CPF/CNPJ/Outros)"
                mask={maskDocumento}
              /> : <ControllerMaskTextField
                name={"pessDocumento"}
                control={control}
                errors={errors.pessDocumento?.message}
                placeholder="Informe seu documento"
                label="Documento(CPF/CNPJ/Outros)"
                mask={maskDocumento}
              />}


            <ControllerTextField
              name={"pessFantasia"}
              control={control}
              errors={errors.pessFantasia?.message}
              placeholder="Informe o nome fantasia da empresa"
              label={"Nome Fantasia"}
            />
          </div>
          <div className={style["form-row"]}>
            <ControllerTextField
              name={"pessIM"}
              control={control}
              errors={errors.pessIM?.message}
              placeholder="Inscrição Municipal"
              label={"Inscrição Municipal"}
            />
            <ControllerTextField
              name={"pessSUFRAMA"}
              control={control}
              errors={errors.pessSUFRAMA?.message}
              label={"Inscrição SUFRAMA"}
              placeholder="Inscrição SUFRAMA se integrante"
            />


            <ControllerTextField
              name={"pessRazao"}
              control={control}
              errors={errors.pessRazao?.message}
              label={"Nome/Razão Social"}
              placeholder="Informe a razão social ou nome"
            />
          </div><div className={style["form-row"]}>
            <ControllerComboBox
              options={LleTipo}
              name={"pessIETipo"}
              control={control}
              errors={errors.pessIETipo?.message}
              label="Tipo de contribuinte"
              placeholder="Tipo de contribuinte"
            />
            <ControllerTextField
              name={"pessIE"}
              control={control}
              errors={errors.pessIE?.message}
              label={"Inscrição Estadual"}
              placeholder="Inscrição Estadual"
              readOnly={readOnlyPessIE}
            />
          </div>

          <Expander
            name={"Endereços"}
            value={expanderEnderecos}
            setValue={setExpanderEnderecos}
            statusForm={expanderEnderecos.state}
            withIcon={true}

            content={
              <EnderecosPessoa
                closeExpander={expanderEnderecos}
                setCloseExpander={setExpanderEnderecos}
                arrayForm={arrayFormEnderecos}
                setArrayForm={setArrayFormEnderecos}
                setShowForm={setExpanderEnderecos}
                getValuesForm={getValues}
                setValuesForm={setValue}
              />
            }
          />

          <Expander
            name={"Contatos"}
            value={expanderContatos}
            setValue={setExpanderContatos}
            statusForm={expanderContatos.state}
            withIcon={true}
            content={
              <ContatosPessoa
                closeExpander={expanderContatos}
                setCloseExpander={setExpanderContatos}
                arrayForm={arrayFormContatos}
                setArrayForm={setArrayFormContatos}
                setShowForm={setExpanderContatos}
                getValuesForm={getValues}
                setValuesForm={setValue}
              />
            }
          />
          <Expander
            name={"Eventos"}
            value={expanderEventos}
            setValue={setExpanderEventos}
            statusForm={expanderEventos.state}
            withIcon={true}
            content={
              <EventosPessoa
                closeExpander={expanderEventos}
                setCloseExpander={setExpanderEventos}
                arrayForm={arrayFormEventos}
                setArrayForm={setArrayFormEventos}
                setShowForm={setExpanderEventos}
                getValuesForm={getValues}
                setValuesForm={setValue}
              />
            }
          />

          <FooterFormButtons
            btn_cancelar={() => navigate(pageReturn)}
            btn_salvar={handleSubmit(submit)}
            min={false}
          />
        </form>
      }
    </main>
  );
};

