export const columns = [
  {
    key: "column1",
    name: "Origem",
    fieldName: "alIeOrigemUfdeId",
    minWidth: 160 ,
    maxWidth: 210,
    isResizable: true,
  },
  {
    key: "column2",
    name: "Destino",
    fieldName: "alIeDestinoUfdeId",
    minWidth: 160 ,
    maxWidth: 210,
    isResizable: true,
  },
  {
    key: "column3",
    name: "Aliquota Nacionais",
    fieldName: "alIeicmsAliquota",
    minWidth: 160 ,
    maxWidth: 210,
    isResizable: true,
  },
  {
    key: "column3",
    name: "Aliquota Internacionais",
    fieldName: "alIeICMSAliquotaImportados",
    minWidth: 160 ,
    maxWidth: 210,
    isResizable: true,
  },
  {
    key: "column4",
    name: "Status",
    fieldName: "alleRecStatus",
    minWidth: 60,
    maxWidth: 80,
    isResizable: true,
   
  },
];
