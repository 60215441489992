export const columnsFeriados = [
  {
    key: "column1",
    name: "Data",
    fieldName: "hdayData",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "column2",
    name: "Descrição",
    fieldName: "hdayDescricao",
    minWidth: 80,
    maxWidth: 100,
    isResizable: true,
  },

  {
    key: "column3",
    name: "Permanente",
    fieldName: "hdayPermanente",
    minWidth: 120,
    maxWidth: 140,
    isResizable: true,
  },
  {
    key: "column4",
    name: "Status",
    fieldName: "hdayRecStatus",
    minWidth: 140,
    maxWidth: 190,
    isResizable: true,
  },
]