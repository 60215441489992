import { useState } from "react";
import visibility from "../../../assets/icons/gerais/visibility.svg";
import visibilityOff from "../../../assets/icons/gerais/visibility_off.svg";
import style from "./style.module.scss";
type value = {
  label: string;
  inline: boolean;
  variavel?: string;
  register:any;
};

export const PasswordInput = ({ inline, label, register}: value) => {
  const [visiblePassword, setVisiblePassword] = useState(false);

  document.addEventListener("keydown", (e: KeyboardEvent) => {

    if (e.key === "Enter") {
      setVisiblePassword(false)
      return
    }
    return
  })
  const showPassword = (e: Event & { target: HTMLInputElement }) => {

    setVisiblePassword(!visiblePassword);
  };
  return (
    <div className={style[inline ? "section_inline-content" : "section"]}>
      <label className={style[inline ? "section_content-label-inline" : "section_content-label"]}>{label}</label>
      <div className={style["section_content"]}>
        <input
          className={style[inline ? "section_content-input-inline" : "section_content-input"]}
          placeholder="Digite sua senha"
          aria-label="Digite sua senha"
          type={visiblePassword ? "text" : "password"}
          {...register("usroSenha")}
        />

        <div
          className={style["section_content-visibility"]}
          onClick={(e: any) => showPassword(e)}
        >
          {visiblePassword ? (
            <img src={visibility} alt="" />
          ) : (
            <img src={visibilityOff} alt="" />
          )}
        </div>
      </div>
    </div>
  );
};
