import { recStatusModify } from "../../../../../../utils/rec-status.modify";

export const modifyInformationBancos = async (dados: any) => {
  await dados.forEach((value: any) => {
    value.bcosRecStatus = recStatusModify.table(value.bcosRecStatus)
 });
  return dados;
};

export const modifyInformationContasBancos = async (dados: any) => {
  await dados.forEach((value: any) => {
    value.bcCtRecStatus = recStatusModify.table(value.bcCtRecStatus)
  });
  return dados;
};

