import style from "../style.module.scss";
import { FooterFormButtons } from "../../../../../../../../components/footer-form/buttons-form";
import { TextField } from "@fluentui/react";
import { preloadedValues } from "./default-values";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { IPropsFormProduto } from "../../../interface/props.interface-form";
import { ControllerTextField } from "../../../../../../../../components/controllers/ControllerTextField";
import { CustoPrecoVenda, schemaPrecoVenda } from "./form.config";
import { useContext, useEffect, useState } from "react";
import { ListaPrecosFormProps } from "../../../../../faturamento/config/lista-precos/form/form.config";
import { CenterValuesContext } from "../../../../../../../../context/center-values";
import { ICMSFormProps } from "../../../../../fiscal/config/operacoes-fiscais/form/icms/form.config";
import { AliquotaInterestadualFormProps } from "../../../../../fiscal/config/aliquotas-interestaduais-icms/form/form.config";
import { PISCofinsFormProps } from "../../../../../fiscal/config/operacoes-fiscais/form/pis-cofins/form.config";
import { apiApp } from "../../../../../../../../services/api/app.api";
import { endpoint } from "../../../../../../../../config/environment/endpoint.config";
import { getInformations } from "./calc";

interface propsForm extends IPropsFormProduto {
  setShowFormPrecoVenda: any
}

export const PrecoVendaAjustado = ({
  getValuesForm, setValuesForm, closeExpander, setCloseExpander, setShowFormPrecoVenda
}: propsForm) => {
  const [listaPrecoValues, setListaPrecoValues] = useState<any>()
  const [operacaoFiscal, setOperacaoFiscal] = useState<any>()
  const [aliquotasInterestaduais, setAliquotasInterestaduais] = useState<AliquotaInterestadualFormProps>()
  const { centerValues } = useContext(CenterValuesContext)
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<CustoPrecoVenda>({
    mode: "all",
    resolver: zodResolver(schemaPrecoVenda),
    defaultValues: preloadedValues,
  });

  useEffect(() => {
    getInformations({aliquotasInterestaduais,centerValues,
      getValues,getValuesForm,listaPrecoValues,operacaoFiscal,
      setAliquotasInterestaduais,setValue,setListaPrecoValues,
      setOperacaoFiscal,setValuesForm})
  },[])
 
  return (
    <form>
      <div className={style["form-row-header"]}>
        <span className={style["form-row-header-label"]}>Outras despesas</span>
        <ControllerTextField
          name="prPBCustoVDespesasTotais"
          control={control}
          disabled={true}
          className={style["form-row-header-input"]}
          prefix="$"

        />
      </div>
      <div className={style["form-row"]}>
        <span className={style["span-label"]}>Custo total</span>
        <ControllerTextField
          name="prPBCustoFinal"
          className={style["input-middle"]}
          control={control}
          disabled={true}
          prefix="$"

        />
      </div>
      <div className={style["form-row"]}>
        <span className={style["span-label"]}>PIS</span>

        <TextField suffix={"%"} className={style["input-sm"]} />
        <ControllerTextField
          prefix="$"
          className={style["input-middle"]}
          name=""
          control={control}
          disabled={true} />

      </div>
      <div className={style["form-row"]}>
        <span className={style["span-label"]}>COFINS</span>
        <TextField suffix={"%"} className={style["input-sm"]} />
        <ControllerTextField
          prefix="$"
          className={style["input-middle"]}
          name="prLPPCOFINS"
          control={control}
          disabled={true}
        />
      </div>
      <div className={style["form-row"]}>
        <span className={style["span-label"]}>Simples Nacional</span>
        <TextField suffix={"%"} className={style["input-sm"]} />
        <ControllerTextField
          prefix="$"
          className={style["input-middle"]}
          name="prLPPFaixaSN"
          control={control}
          disabled={true}
        />
      </div>
      <div className={style["form-row"]}>
        <span className={style["span-label"]}>ICMS</span>
        <TextField suffix={"%"} className={style["input-sm"]} />
        <ControllerTextField
          prefix="$"
          className={style["input-middle"]}
          name="prLPPICMS"
          control={control}
          disabled={true}
        />
      </div>
      <div className={style["form-row"]}>
        <span className={style["span-label"]}>Comissão</span>
        <TextField suffix={"%"} className={style["input-sm"]} />
        <ControllerTextField
          prefix="$"
          className={style["input-middle"]}
          name="prLPComissao"
          control={control}
          disabled={true}
        />
      </div>
      <div className={style["form-row"]}>
        <span className={style["span-label"]}>Custo fixo</span>
        <TextField suffix={"%"} className={style["input-sm"]} />
        <ControllerTextField
          prefix="$"
          className={style["input-middle"]}
          name="prLPPCustoFixo"
          control={control}
          disabled={true}
        />
      </div>
      <div className={style["form-row"]}>
        <span className={style["span-label"]}>Margem de lucro</span>
        <TextField suffix={"%"} className={style["input-sm"]} />
        <ControllerTextField
          prefix="$"
          className={style["input-middle"]}
          name="prPBFormacaoPMargem"
          control={control}
          disabled={true}
        />
      </div>

      <FooterFormButtons
        min={true}
        btn_cancelar={() => {
          setShowFormPrecoVenda(false);
        }}
        btn_salvar={() => setShowFormPrecoVenda(false)}
      />
    </form>
  );
};
