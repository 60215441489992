export const LContribuicaoCst=[
    {key:'01',text:'Operação Tributável com Alíquota Básica'},
    {key:'02',text:'Operação Tributável com Alíquota Diferenciada'},
    {key:'03',text:'Operação Tributável com Alíquota por Unidade de Medida de Produto'},
    {key:'04',text:'Operação Tributável Monofásica - Revenda a Alíquota Zero'},
    {key:'05',text:'Operação Tributável por Substituição Tributária'},
    {key:'06',text:'Operação Tributável a Aliquota Zero'},
    {key:'07',text:'Operação Isenta da Contribuição'},
    {key:'08',text:'Operação sem Incidência da Contribuição'},
    {key:'09',text:'Operação com Suspensão da Contribuição'},

    {key:'49',text:'Outras Operações de Saída'},

    {key:'50',text:'Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno'},
    {key:'51',text:'Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno'},
    {key:'52',text:'Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação'},
    {key:'53',text:'Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno'},
    {key:'54',text:'Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação '},
    {key:'55',text:'Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação'},
    {key:'56',text:'Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação'},
   
    {key:'60',text:'Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno'},
    {key:'61',text:'Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno'},
    {key:'62',text:'Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receta de Exportação'},
    {key:'63',text:'Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno'},
    {key:'64',text:'Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação'},
    {key:'65',text:'Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação'},
    {key:'66',text:'Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação'},
    {key:'67',text:'Crédito Presumido - Outras Operações'},

    {key:'70',text:'Operação de Aquisição sem Direito a Crédito'},
    {key:'71',text:'Operação de Aquisição com Isenção'},
    {key:'72',text:'Operação de Aquisição com Suspensão'},
    {key:'73',text:'Operação de Aquisição a Alíquota Zero'},
    {key:'74',text:'Operação de Aquisição sem Incidência da Contribuição'},
    {key:'75',text:'Operação de Aquisição por Substituição Tributária'},
    
    {key:'98',text:'Outras Operação de Entrada'},
    {key:'99',text:'Outras Operação'},


]