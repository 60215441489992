import { ID } from "../../../../../../../../config/environment/id.config";

type props = {
  centerValues: any;
  setCfopListAjuste: (value: any) => void;
  setCfopListDevolucao: (value: any) => void;
};
const ajuste: any = [];
const devolucao: any = [];

export const filter = async ({
  centerValues,
  setCfopListAjuste,
  setCfopListDevolucao,
}: props) => {
  await centerValues.cfop.map((value: any) => {
    if (value.cfopPermiteAjusteEstorno) {
      ajuste.push({
        key: value[ID.fiscal.cfop],
        text: value["cfopNaturezaOperacao"],
      });
    }

    if (value.cfopPermiteDevolucao) {
      devolucao.push({
        key: value[ID.fiscal.cfop],
        text: value["cfopNaturezaOperacao"],
      });
    }
    setCfopListAjuste(ajuste);
    setCfopListDevolucao(devolucao);
  });
};
