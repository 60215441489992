import { z } from "zod";

export const schemaOutrosImpostos = z.object({
  opIARecStatus:z.any(),
  opIADescricao :z.string({ required_error: "Insira a descrição" }).trim().min(1),
  opIAAliquota:z.string().refine((val) => !Number.isNaN(parseInt(val, 10)), {
    message: "Expected number, received a string"
  }),
  opIADeduzTotalDFe:z.boolean(),
  opIADadosAdicionaisGerar :z.boolean(),
  opIADadosAdicionaisTexto :z.string({ required_error: "Insira dados adicionais" }).trim().min(1,"Insira os dados adicionais"),
});

export type OutrosImpostosFormProps = z.infer<
  typeof schemaOutrosImpostos
>;
