import style from "./style.module.scss";
import { FooterFormButtons } from "../../../../../../../../components/footer-form/buttons-form";
import { TitleForm } from "../../../../../../../../components/text/form/title/title";
import { ControllerCheckBox } from "../../../../../../../../components/controllers/checkbox-controller";
import { ControllerComboBox } from "../../../../../../../../components/controllers/combobox-controller";
import { zodResolver } from "@hookform/resolvers/zod";
import { CodigoAlternativoFormProps, schemaCodigoAlternativo } from "./form.config";
import { useForm } from "react-hook-form";
import { useContext, useState } from "react";
import { ActionButtonTable } from "../../../../../../../../context/actionButtonTable";
import { idItemSelectedContext } from "../../../../../../../../context/idItemSelected";
import { ID } from "../../../../../../../../config/environment/id.config";
import { EProdTipoCodigoAlternativo } from "../../options.input";
import { ControllerTextField } from "../../../../../../../../components/controllers/ControllerTextField";

type Form = {
  arrayForm: any
  setArrayForm: any
  setShowForm: any
  valuesForm: any
}

export const CodigoAlternativoForm = ({ arrayForm,setArrayForm,setShowForm,valuesForm }: Form) => {
  const { actionButton } = useContext(ActionButtonTable);
  const { idItemSelected } = useContext(idItemSelectedContext);
  const [loading, setLoading] = useState(false)
  const actionForm = actionButton === "edit" || actionButton === "copy"

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CodigoAlternativoFormProps>({
    mode: "all",
    resolver: zodResolver(schemaCodigoAlternativo),
  });
  
  let searchId: CodigoAlternativoFormProps = arrayForm.find(
    (value: any) => value[ID.cadastro.produto] === idItemSelected.id
  );
  const submit = () => {
    setShowForm(false)  }
  return (
    <div className={style["form"]}>
      <TitleForm title={"Produtos: Código alternativo"} />
      <ControllerCheckBox
        control={control}
        name="prCARecStatus"
        label="Desativado"
        recStatus={true}
      /> 
      <ControllerComboBox
        name="prCATipo"
        label="Tipo"
        options={EProdTipoCodigoAlternativo}
        control={control}
      />
      <ControllerTextField
        name="prCACodigo"
        label="Código"
        control={control}
      />

      <ControllerCheckBox
        control={control}
        name="prCAXML"
        label="Utilizar este código no XML/DANFE"
      />

<FooterFormButtons min={true} btn_salvar={handleSubmit(submit)} btn_cancelar={() => setShowForm(false)}/>

    </div>
  );
};
