export const LRegimeTributacao = [
  { key: 0, value: "PSRT_PESSOA_FISICA", text: "0 - Pessoa Física" },
  { key: 1, value: "PSRT_PESSOA", text: "1 - Pessoa Física - Estrangeiro" },
  { key: 2, value: "PSRT_MEI  ", text: "2 - MEI" },
  { key: 3, value: "PSRT_SIMPLES_NACIONAL ", text: "3 - Simples Nacional" },
  {
    key: 4,
    value: "PSRT_SIMPLES_NACIONAL_EXCESSO ",
    text: "4 - Simples Nacional - Excesso de sublimite de receita bruta",
  },
  { key: 5, value: "PSRT_LUCRO_REAL", text: "5 - Lucro Real" },
  { key: 6, value: "PSRT_LUCRO_PRESUMIDO ", text: "6 - Lucro Presumido" },
  { key: 7, value: "PSRT_LUCRO_ARBITRADO", text: "7 - Lucro Arbitrado" },
];

export const LleTipo = [
  { key: 0, value: "IETP_CONTRIBUINTE", text: "0 - Contribuinte" },
  { key: 1, value: "ETP_NAO_CONTRIBUINTE", text: "1 - Não contribuinte" },
  { key: 2, value: "ETP_ISENTO", text: "2 - Isento" },
];

