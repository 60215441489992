import style from "../style.module.scss";
import { ModeloDocumento } from "../options/modelo-documento.options";
import { PropositoOperacao } from "../options/proposito-operacao";
import { FooterFormButtons } from "../../../../../../../../components/footer-form/buttons-form";
import { TitleForm } from "../../../../../../../../components/text/form/title/title";
import { ControllerComboBox } from "../../../../../../../../components/controllers/combobox-controller";
import { OperacoesFiscaisFormProps, schemaOperacoesFiscais } from "./form.config";
import { preloadedValues } from "./default-values-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { apiApp } from "../../../../../../../../services/api/app.api";
import { endpoint } from "../../../../../../../../config/environment/endpoint.config";
import { convertForComboBox } from "../../../../../../../../utils/convert-for-combobox";
import { ID } from "../../../../../../../../config/environment/id.config";
import { IPropsFormProduto } from "../../../interface/props.interface-form";

interface IProps {
  setShowForm: (show: boolean) => void
  arrayOperacoesFiscais: any
}
export const OperacaoFiscalForm = ({ getValuesForm, setValuesForm, setCloseExpander, closeExpander }: IPropsFormProduto) => {
  const [listOperacoesFiscais, setListOperacoesFiscais] = useState([])
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<OperacoesFiscaisFormProps>({
    mode: "all",
    resolver: zodResolver(schemaOperacoesFiscais),
    defaultValues: preloadedValues,
  });

  const submit = () => {
    setCloseExpander({ state: true, open: false })
  }

  const getOperacoesFiscais = async () => {
    const respost: any = await apiApp.getAll(endpoint.fiscal, "operacoesFiscais")
    await convertForComboBox({ id: ID.fiscal.operacoesFiscais, list: respost.data, prop: 'operDescricao', items: listOperacoesFiscais, setItems: setListOperacoesFiscais });
  }
  useEffect(() => {
    getOperacoesFiscais()
  }, [!listOperacoesFiscais])

  return (
    <section className={style["form"]}>
      <TitleForm title="Produto: Operação fiscal" />
      <ControllerComboBox
        options={ModeloDocumento}
        label="Modelo do documento"
        name={"prOFMod"}
        control={control}
        errors={errors.prOFMod?.message}
      />
      <ControllerComboBox
        options={PropositoOperacao}
        label="Propósito da operação"
        name={"prOFProposito"}
        control={control}
        errors={errors.prOFProposito?.message}
      />
      <ControllerComboBox
        options={listOperacoesFiscais}
        label="Operação fiscal"
        name={"prOFOperId"}
        control={control}
        errors={errors.prOFOperId?.message}
      />


      <FooterFormButtons
        min={true}
        btn_salvar={handleSubmit(submit)}
        btn_cancelar={() => setCloseExpander({ state: false, open: false })}
      />
    </section>
  );
};
