import "./style.scss";
import olhoLinkoContent from "../../../assets/olho_linko.svg";

export const ContentApp = () => {
  return (
    <div className="contentApp">
      <img className="contentApp--img" src={olhoLinkoContent} alt="" />
    </div>
  );
};
