export const columnsBancos = [
  {
    key: "column1",
    name: "Código",
    fieldName: "bcosCodigoFebraban",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "column2",
    name: "Nome",
    fieldName: "bcosNome",
    minWidth: 80,
    maxWidth: 100,
    isResizable: true,
  },

  {
    key: "column3",
    name: "Status",
    fieldName: "bcosRecStatus",
    minWidth: 120,
    maxWidth: 140,
    isResizable: true,
  },
 
]