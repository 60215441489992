import { ButtonForm } from "../buttons/button-form/button-form";
import style from "./style.module.scss";

type form = {
  min: boolean;
  btn_salvar: () => void;
  btn_cancelar: () => void; 
  btn_salvar_type?: "submit" | "button" ;
};

export const FooterFormButtons = ({ ...props }: form) => {
  return (
    <footer
      className={props.min ? style["footerForm-min"] : style["footerForm"]}
    >
      <div className={props.min ? style["buttons-min"] : style["buttons"]}>
        <ButtonForm
          name="Cancelar"
          styleButton={props.min ? "cancel-sm" : "cancel"}
          onClick={props.btn_cancelar}
          type="button"
        />
        <ButtonForm
          name="Salvar"
          styleButton={props.min ? "save-sm" : "save"}
          onClick={props.btn_salvar}
          type={props.btn_salvar_type}
        />
      </div>
    </footer>
  );
};
