import { endpoint } from "../../../../../../../../config/environment/endpoint.config"
import { apiApp } from "../../../../../../../../services/api/app.api"

type PropsCalc = {
  setListaPrecoValues: any
  setOperacaoFiscal: any
  setAliquotasInterestaduais: any
  setValue: any
  setValuesForm: any
  getValues: any
  getValuesForm: any
  listaPrecoValues: any
  aliquotasInterestaduais: any
  operacaoFiscal: any
  centerValues: any
}
export const getInformations = async ({ getValues, getValuesForm, setAliquotasInterestaduais,
  setListaPrecoValues, setOperacaoFiscal, setValue,
  setValuesForm, aliquotasInterestaduais, centerValues, listaPrecoValues, operacaoFiscal }: PropsCalc) => {
  const listPreco: any = await apiApp.getAll(endpoint.cadastro, "listaPrecos")
  const operFiscal: any = await apiApp.getAll(endpoint.fiscal, "operacoesFiscais")
  const aliqInterestaduais: any = await apiApp.getAll(endpoint.fiscal, "aliquotaInterestadual")

  setListaPrecoValues(listPreco.data)
  setOperacaoFiscal(operFiscal.data)
  setAliquotasInterestaduais(aliqInterestaduais.data)



  setValue("prLPPDescontoMaximo", listaPrecoValues!.lsPrPDescontoMaximo)
  setValue("prLPPCustoFixo", listaPrecoValues!.lsPrPCustoFixo)

  listaPrecoValues?.lsPrIndComissao === "0" ?
    setValue("prLPPComissao", "0") :
    setValue("prLPPComissao", String(Number(getValuesForm("prPBFormacaoPComissa")) + Number(listaPrecoValues!.lsPrPFatorComissao)))

  if (centerValues.empresa.regimeTributacao === 3) {
    setValue("prLPPFaixaSN", "0")
    setValue("prLPPCOFINS", "1%") //psEmPFaixaSN
    setValue("prLPPPIS", "0")
    return
  }

  if (!getValuesForm("prTrOperId")) setValue("prLPPICMS", "0")

  if (operacaoFiscal?.operICMSCST === "00" || operacaoFiscal?.operICMSCST === "10" || operacaoFiscal?.operICMSCST === "20" ||
    operacaoFiscal?.operICMSCST === "51" || operacaoFiscal?.operICMSCST === "70" || operacaoFiscal?.operICMSCST === "90") {

  }

  getValuesForm("prodOrigem") === 3 || getValuesForm("prodOrigem") === 8 ?
    setValue("prLPPICMS", String(aliquotasInterestaduais?.alIeICMSAliquotaImportados)) :
    setValue("prLPPICMS", String(aliquotasInterestaduais?.alIeICMSAliquota))

  setValue("prLPPReducaoICMS", String(operacaoFiscal?.operICMSReducaoBC))
  setValuesForm("prLPDiferimentoICMS", String(operacaoFiscal?.operICMSDiferimentoBC))
  const CONFINSCST = operacaoFiscal?.operCOFINSCST === "01" || operacaoFiscal?.operCOFINSCST === "02"
  const PISCST = operacaoFiscal?.operPISSCST === "01" || operacaoFiscal?.operPISSCST === "02"

  if (CONFINSCST && PISCST) {
    setValue("prLPPCOFINS", "1%") //psEmPCOFINS
    setValue("prLPPPIS", "1%") //psEmPPIS
  } else {
    setValue("prLPPCOFINS", "0")
    setValue("prLPPPIS", "0")
  }

  let ICMS_EFETIVO: number = 0
  if (Number(getValues("prLPPReducaoICMS")) > 0) {
    ICMS_EFETIVO = Number(getValues("prLPPICMS")) - Number(getValues("prLPPReducaoICMS"))
  } else {
    ICMS_EFETIVO = Number(getValues("prLPPICMS"))
  }

  let SOMA = ICMS_EFETIVO + Number(getValuesForm("prLPFaixaSN")) + Number(getValues("prLPPPIS")) + Number(getValues("prLPPCOFINS")) + Number(getValues("prLPPComissao")) + Number(getValues("prLPPCustoFixo"))

  if (SOMA < 100) {
    setValuesForm("prLPMkPVMinimo", String(100 / (100 - SOMA)));
    setValue("prLPPVMinimo", `${Number(getValuesForm("prPBCustoFinal")) * Number(getValuesForm("prLPMkPVMinimo"))}`)
  } else {
    setValuesForm("prLPMkPVMinimo", "0");
    setValue("prLPPVMinimo", "0")
  }

  SOMA = SOMA + Number(getValuesForm("prPBFormacaoPMargem"))

  if (SOMA < 100) {
    setValuesForm("prLPMkPVFinal", String(100 / (100 - SOMA)));
    setValue("prLPPVCalculado", `${Number(getValuesForm("prPBCustoFinal")) * Number(getValuesForm("prLPMkPVFinal"))}`)
  } else {
    setValuesForm("prLPMkPVFinal", "0");
    setValue("prLPPVCalculado", "0")
  }
}