import { createContext, ReactNode, useState } from "react";

type InformationsItem = {
  id: string;
  count: number;
};

type IdItemSelectedContextProps = {
  children: ReactNode;
};

type IdItemSelectedContextType = {
  idItemSelected: { id: string; count: number};
  categoryId: string;
  setIdItemSelected: (newState: InformationsItem) => void;
  setCategoryId: (categoryId:string) => void;
};

const initialValue = {
  idItemSelected: { id: "", count: 0},
  categoryId:"",
  setCategoryId: () => {},
  setIdItemSelected: () => {},
};

export const idItemSelectedContext =
  createContext<IdItemSelectedContextType>(initialValue);

export const IdItemSelectedContextProvider = ({
  children,
}: IdItemSelectedContextProps) => {
  const [idItemSelected, setIdItemSelected] = useState(initialValue.idItemSelected);
  const [categoryId,setCategoryId] = useState(initialValue.categoryId)

  return (
    <idItemSelectedContext.Provider
      value={{ idItemSelected, setIdItemSelected,categoryId,setCategoryId }}
    >
      {children}
    </idItemSelectedContext.Provider>
  );
};
