import { api } from "../../services/api/api";
import { apiApp } from "../../services/api/app.api";
import { ICreateItem, IEdit, IGetValues } from "./types/actions.types";

const getValuesItemEdit = async ({
  nameMenuCurrent,
  setIsLoading,
  url,
  idSelected,
  valuesEdit,
}: IEdit) => {
  setIsLoading(true);
  let result: any = await apiApp.getItem(url, nameMenuCurrent, idSelected);
  valuesEdit(result.data);
  setIsLoading(false);
  return;
};

export const getValuesItem = async ({
  currentPage,
  setIsLoading,
  idSelected,
  nameMenuCurrent,
  url,
  valuesEdit,
}: IGetValues) => {
  setIsLoading(true);
  if (currentPage === "edit" || currentPage === "copy") {
    getValuesItemEdit({
      nameMenuCurrent,
      setIsLoading,
      url,
      idSelected,
      valuesEdit,
    });
    return;
  }
  return setIsLoading(false);
};

export const create = async ({
  currentPage,
  nameMenuCurrent,
  setIsLoading,
  url,
  data,
  idItem,
}: ICreateItem) => {
  setIsLoading(true);
  if (currentPage === "add" && process.env.REACT_APP_LOCAL === "true") {
    await apiApp.post(url, nameMenuCurrent, {
      ...data,
      "id": `${Math.floor(Math.random() * 100)}`,
    });
    setIsLoading(false);
    return;
  } else if (currentPage === "add") {
    await apiApp.post(url, nameMenuCurrent, {
      ...data,
    });
    setIsLoading(false);
    return;
  }

  if (currentPage === "edit" && process.env.REACT_APP_LOCAL === "true") {
    const urlEdit = `${url}/${nameMenuCurrent}/${idItem}`;
    api.put(urlEdit, {
      ...data,
    });
    setIsLoading(false);
    return;
  } else if (currentPage === "edit") {
    await apiApp.post(url, nameMenuCurrent, {
      ...data,
      idItem,
    });
  }
  if (currentPage === "copy" && process.env.REACT_APP_LOCAL === "true") {
    
    await apiApp.post(url, nameMenuCurrent, {
      ...data,
      idItem,
    });
    setIsLoading(false);
    return;
  } else if (currentPage === "copy") {
    await apiApp.post(url, nameMenuCurrent, {
      ...data,
    });
    setIsLoading(false);
    return;
  }
};

//TODO: verificar se é local ou não. Local, utiliza PUT para editar a api utiliza POST 