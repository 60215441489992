import style from "../style.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { LProdIndicador } from "../options/lista-precos.options";
import { useContext, useEffect, useState } from "react";
import info from "../../../../../../../assets/icons/gerais/info.svg";
import { FooterFormButtons } from "../../../../../../../components/footer-form/buttons-form";
import { endpoint } from "../../../../../../../config/environment/endpoint.config";
import { idItemSelectedContext } from "../../../../../../../context/idItemSelected";
import { ListaPrecosFormProps, schemaListaPrecos } from "./form.config";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { preloadedValues } from "./default-values-form";
import { create, getValuesItem } from "../../../../../../../utils/actions/form.actions";
import { ID } from "../../../../../../../config/environment/id.config";
import { editItemVerification } from "../../../../../../../utils/actions/editItemPost";
import { currentURlName } from "../../../../../../../utils/url.name";
import { ControllerCheckBox } from "../../../../../../../components/controllers/checkbox-controller";
import { ControllerTextField } from "../../../../../../../components/controllers/ControllerTextField";
import { CenterValuesContext } from "../../../../../../../context/center-values";
import { convertForComboBox } from "../../../../../../../utils/convert-for-combobox";
import { ControllerComboBox } from "../../../../../../../components/controllers/combobox-controller";
import { recStatusModify } from "../../../../../../../utils/rec-status.modify";
import { percentFormatEvent } from "../../../../../../../config/RegEx/regex";
import { ControllerCalendar } from "../../../../../../../components/controllers/calendar-controller/calendar";

export const ListaDePrecosForm = () => {
  let navigate = useNavigate();
  const { centerValues } = useContext(CenterValuesContext)
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { idItemSelected } = useContext(idItemSelectedContext);
  const currentPage = currentURlName(pathname);
  const pageReturn = "/linko/configuracoes/listaPrecos";
  const [ufOptions, setUfOptions] = useState([])
  const [readOnlyComissao, setRedOnlyFatorComissao] = useState(false)
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ListaPrecosFormProps>({
    mode: "onChange",
    resolver: zodResolver(schemaListaPrecos),
    defaultValues: preloadedValues,
  });


  const valuesEdit = (itemValue: ListaPrecosFormProps) => {
    setValue(
      "lsPrRecStatus", recStatusModify.form(itemValue.lsPrRecStatus)
    );
    setValue("lsPrDescricao", itemValue.lsPrDescricao);
    setValue("lsPrIndComissao", itemValue.lsPrIndComissao);
    setValue("lsPrPCustoFixo", itemValue.lsPrPCustoFixo);
    setValue("lsPrPDescontoMaximo", itemValue.lsPrPDescontoMaximo);
    setValue("lsPrPFatorComissao", itemValue.lsPrPFatorComissao);

    setValue("lsPrPadrao", itemValue.lsPrPadrao);
    setValue("lsPrUfdeId", itemValue.lsPrUfdeId);
    setIsLoading(false);
    return;
  };
  useEffect(() => {
    convertForComboBox({ id: ID.cadastro.uf, list: centerValues.uf, prop: 'ufdeNome', items: ufOptions, setItems: setUfOptions, isUF: true });

    getValuesItem({
      currentPage,
      idSelected: idItemSelected.id,
      nameMenuCurrent: "listaPrecos",
      setIsLoading,
      url: endpoint.cadastro,
      valuesEdit,
    });
  }, []);

  const submit = async (data: any) => {
    const idNumber = idItemSelected.id;
    const id = ID.faturamento.listaPreco + ":" + idNumber;

    await create({
      currentPage,
      nameMenuCurrent: "listaPrecos",
      url: endpoint.cadastro,
      data: editItemVerification({ currentPage, data, id }),
      setIsLoading,
      idItem: idNumber,
    });
    navigate(pageReturn);
  };

  useEffect(() => {
    if (watch("lsPrIndComissao") === "0") {

      setRedOnlyFatorComissao(true)
      setValue("lsPrPFatorComissao", "0")
      return
    }
    setRedOnlyFatorComissao(false)
    return
  }, [watch("lsPrIndComissao")])

  return (
    <div className={style["sectionContent"]}>
      <form className={style["sectionContent-form"]}>
        <h1 className={style["title-form"]}>Lista de preços</h1>

        <div className={style["form-row"]}>
          <div className={style["group-checkbox"]}>
            <ControllerCheckBox
              control={control}
              name="lsPrRecStatus"
              label="Desativado"
              recStatus={true}
            />
            <ControllerCheckBox
              control={control}
              name="lsPrAtualizacaoAutomatica"
              label="Atualização automática"
            />
          </div>

          {watch("lsPrPadrao") ? (
            <div className={style["info-form"]}>
              <img className={style["info-form-img"]} src={info} />

              <span className={style["info-form-text"]}>
                Lista de preços padrão
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>

        <ControllerTextField
          control={control}
          name={"lsPrDescricao"}
          label="Descrição"
          className={style["inputFullWidth"]}
          placeholder="Descrição ou nome da lista de preços"
        />

        <ControllerComboBox
          control={control}
          name={"lsPrUfdeId"}
          className={style["inputFullWidth"]}
          label="UF de destino"
          options={ufOptions}
          disabled={watch("lsPrPadrao") ? true : false}
          placeholder="UF de destino para cálculo
          do ICMS sobre o preço de venda"
        />

        <div className={style["form-row-product"]}>
          <ControllerTextField
            name="lsPrPCustoFixo"
            label="Custo fixo"
            placeholder="Percentual de custo fixo
                para cálculo do preço de venda"
            control={control}
            errors={errors.lsPrPCustoFixo?.message}
            className={style["input-middle"]}
            prefix={"%"}
            format={true}
            formatFunction={percentFormatEvent}
          />

          <ControllerComboBox
            control={control}
            name="lsPrIndComissao"
            label="Indicador de comissão"
            placeholder="Indicador de pagamento de
            comissões"
            options={LProdIndicador}
            className={style["inputFullWidth"]}
          />
        </div>

        <div className={style["fator_options"]}>
          <ControllerTextField
            name="lsPrPFatorComissao"
            label="Fator de acrésimo/decrécimo da comissão"
            placeholder="Fator de acréscimo ou
                decréscimo da comissão"
            control={control}
            errors={errors.lsPrPFatorComissao?.message}
            readOnly={readOnlyComissao}
            className={style["input-middle"]}
            prefix={"%"}
            format={true}
            formatFunction={percentFormatEvent}
          />

        </div>
        <ControllerTextField
          name="lsPrPDescontoMaximo"
          label="Desconto máximo"
          placeholder="Percentual de desconto máximo que poderá 
                ser concedido nas vendas"
          control={control}
          errors={errors.lsPrPFatorComissao?.message}
          readOnly={readOnlyComissao}
          className={style["inputFullWidth"]}
          prefix={"%"}
          format={true}
          formatFunction={percentFormatEvent}
        />


        <ControllerCalendar
          name={"lsPrValidAt"}
          label={"Início da vigência"}
          control={control}
          errorMessage={""}
        />
        <ControllerCalendar
          name={"lsPrExpireAt"}
          label={"Fim da vigência"}
          control={control}
          errorMessage={""}
        />

        <FooterFormButtons
          min={false}
          btn_cancelar={() => navigate("/linko/configuracoes/listaPrecos")}
          btn_salvar={handleSubmit(submit)}
        />
      </form>
    </div>
  );
};
