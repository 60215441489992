import { z } from "zod";


export const schemaAliquotaInterestadual = z
  .object({
    alleRecStatus:z.any(),
    alIeOrigemUfdeId:z.string().min(1,"Insira um valor"),
    alIeDestinoUfdeId:z.string().min(1,"Insira um valor"),
    alIeICMSAliquota:z.string().min(1,"Insira um valor"),
    alIeICMSAliquotaImportados:z.string().min(1,"Insira um valor"),
  });

export type AliquotaInterestadualFormProps = z.infer<typeof schemaAliquotaInterestadual>;
