import { Expander } from "../../../../../../components/expander/expander";
import { CodigosAlternativosProduto } from "./codigos-alternativos/codigos-alternativos";
import { CustaPrecoVendaProduto } from "./custo-preco-de-venda/custo-preco-de-venda";
import { InformacoesEspecificaProduto } from "./informacoes-especificas/informacoes-especificas.form";
import { OperacaoFiscalProduto } from "./operacao-fiscal/operacao-fiscal";
import { PaustasFiscaisProduto } from "./pautas-fiscais/pautas-fiscais.form";
import { TributacaoProduto } from "./tributacao/tributacao.form";

type values = {
  getValuesForm: any;
  setValuesForm: any;
  expanderTributacao: { open: boolean, state: boolean };
  expanderCustoVenda: { open: boolean, state: boolean };
  expanderOperacaoFiscais: { open: boolean, state: boolean };
  expanderCodigosAlternativos: { open: boolean, state: boolean };
  expanderInformacoesEspecificas: { open: boolean, state: boolean };
  expanderPautasFiscais: { open: boolean, state: boolean };

  setExpanderTributacao: (value: any) => void;
  setExpanderCustoVenda: (value: any) => void;
  setExpanderOperacaoFiscais: (value: any) => void;
  setExpanderCodigosAlternativos: (value: any) => void;
  setExpanderInformacoesEspecificas: (value: any) => void;
  setExpanderPautasFiscais: (value: any) => void;
};
export const FormsExpanderCadastroProduto = ({ ...props }: values) => {
  return (
    <>
      <Expander
        name="Tributação"
        statusForm={props.expanderTributacao.state}
        value={props.expanderTributacao}
        setValue={props.setExpanderTributacao}
        withIcon={true}
        content={
          <TributacaoProduto
            getValuesForm={props.getValuesForm}
            setValuesForm={props.setValuesForm}
            setCloseExpander={props.setExpanderTributacao}
            closeExpander={props.expanderTributacao.state}
          />
        }

      />
      <Expander
        name="Custo e preço de venda"
        withIcon={true}
        statusForm={props.expanderCustoVenda.state}
        value={props.expanderCustoVenda}
        setValue={props.setExpanderCustoVenda}
        content={
          <CustaPrecoVendaProduto
            getValuesForm={props.getValuesForm}
            setValuesForm={props.setValuesForm}
            setCloseExpander={props.setExpanderCustoVenda}
            closeExpander={props.expanderCustoVenda.state}

          />
        }
      />

      <Expander
        name="Operação fiscais"
        withIcon={true}
        statusForm={props.expanderOperacaoFiscais.state}
        value={props.expanderOperacaoFiscais}
        setValue={props.setExpanderOperacaoFiscais}
        content={<OperacaoFiscalProduto 
          getValuesForm={props.getValuesForm}
          setValuesForm={props.setValuesForm} 
          setCloseExpander={props.setExpanderOperacaoFiscais} 
          closeExpander={props.expanderOperacaoFiscais.state}
        />}
      />

      <Expander
        name="Códigos alternativos"
        withIcon={true}
        statusForm={props.expanderCodigosAlternativos.state}
        value={props.expanderCodigosAlternativos}
        setValue={props.setExpanderCodigosAlternativos}
        content={<CodigosAlternativosProduto 
          getValuesForm={props.getValuesForm}
          setValuesForm={props.setValuesForm} 
          setCloseExpander={props.setExpanderCodigosAlternativos} 
          closeExpander={props.expanderCodigosAlternativos.state}
        />}
      />

      <Expander
        name="Informações especificas"
        withIcon={true}
        statusForm={props.expanderInformacoesEspecificas.state}
        value={props.expanderInformacoesEspecificas}
        setValue={props.setExpanderInformacoesEspecificas}
        content={
          <InformacoesEspecificaProduto
            getValuesForm={props.getValuesForm}
            setValuesForm={props.setValuesForm}
            setCloseExpander={props.setExpanderInformacoesEspecificas}
          />
        }
      />

      <Expander
        name="Pautas fiscais"
        withIcon={true}
        statusForm={props.expanderPautasFiscais.state}
        value={props.expanderPautasFiscais}
        setValue={props.setExpanderPautasFiscais}
        content={
          <PaustasFiscaisProduto
            getValuesForm={props.getValuesForm}
            setValuesForm={props.setValuesForm}
            setCloseExpander={props.setExpanderPautasFiscais}
          />
        }
      />
    </>
  );
};
