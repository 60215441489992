import style from "./style.module.scss";

type titleForm = {
  title: string;
};
export const TitleForm = ({ title }: titleForm) => {
  return (
    <div className={style["title"]} data-testid="test-title-form">
      <span className={style["title-form"]}>{title}</span>
    </div>
  );
};
