import { columnsFeriados } from "./column/feriados.columns";
import { endpoint } from "../../../../../../config/environment/endpoint.config";
import { ID } from "../../../../../../config/environment/id.config";
import { ConstructTable, IConstructTable} from "../../../../../../components/construct/construct-table/construct-table";
import { modifyInformationFeriados } from "./modify-values";
import { useState } from "react";

export const Feriado = () => {
  const [isLoading,setIsLoading] = useState(false)
  const Informations: IConstructTable = {
    endpoint: endpoint.financeiro,
    idNameTable: ID.financeiro.feriado,
    nameTable: "Feriados",
    columnsTable: columnsFeriados,
    nameMenu: "configuracoes",
    nameSubmenu: "feriado",
    modifyData: (data: any) => {modifyInformationFeriados(data)},
    isLoading,setIsLoading
  };
  return <ConstructTable {...Informations} />;
};
