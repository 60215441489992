export const configEstoque = [
    {
      category: "configuracoes",
      key: "gradesEstoque",
      name: "Grades de estoque",
    },
    {
      category: "configuracoes",
      key: "unidadesMedida",
      name: "Unidades de medida",
    },
  ];