import { TextField } from "@fluentui/react"
import { Controller } from "react-hook-form"

type PercentProps = {
    name: string;
    control: any;
    label?: string;
    className?: string;
    disabled?: boolean;
    errors?: string;
    onChange: any
    readOnly?: boolean;
    placeholder?: string;
}
export const ControllerTextFieldPercent = ({ control,placeholder="",readOnly=false,errors = "", label = "", name, className = "", disabled = false, onChange }: PercentProps) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value } }) => (
                <TextField
                    value={value}
                    label={label}
                    className={className}
                    disabled={disabled}
                    prefix="%"
                    errorMessage={errors}
                    onChange={onChange}
                    readOnly={readOnly}
                    placeholder={placeholder}
                />
            )} />)
}





