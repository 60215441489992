import style from "./style.module.scss";
import expandLess from "../../assets/icons/gerais/expand_less.svg";
import expandMore from "../../assets/icons/gerais/expand_more.svg";

import success from '../../assets/icons/messages/success.svg';
import error from '../../assets/icons/messages/error.svg';


interface IProps {
  name: string;
  content: any;
  statusForm: boolean
  value: any;
  setValue: any;
  withIcon?: boolean
}

export const Expander = ({ content, name, setValue, statusForm, value, withIcon = true }: IProps) => {

  return (
    <div className={style["content"]}>
      <header
        className={style["content_header"]}
        onClick={() => setValue({ open: !value.open, state:statusForm ? true : false})}
      >
        <div className={value.open ? style["content_header-border"] : style["content_header-border-close"]}>
          <h4 className={style["content_header-name"]}>{name}
            {withIcon && <img
              className={statusForm ? style["icon-success"] : style["icon-error"]}
              src={statusForm ? success : error} alt="" />}
          </h4>

          <div
            className={value.open ? style["content_header-button-open"] : style["content_header-button"]}
            onClick={() => setValue({ open: !value.open, state:value.state ? true : !value.state })}
          >
            <span>Editar

            </span>
            <img
              className={style["content_header-icon"]}
              src={value.open ? expandLess : expandMore}
              alt={value.open
                ? "Mostrar formulário" : "Ocultar formulário"
              }
            />
          </div>
        </div>
      </header>
      {value.open && (
        <section className={style["content_section"]}>{content}</section>
      )}
    </div>
  );
};

