export const configCadastros = [
  {
    category: "configuracoes",
    key: "unidadesFederativasEstados",
    name: "Unidades Federativas / Estados",
  },
  {
    category: "configuracoes",
    key: "cidades",
    name: "Cidades",
  },
  {
    category: "configuracoes",
    key: "paises",
    name: "Paises",
  },
];
