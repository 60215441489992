import { z } from "zod";
import { formatDate } from "../../../../../../components/controllers/calendar-controller/format-date.config";

export const schemaFeriados = z.object({
  hdayRecStatus: z.any(),
  hdayData: z.any().transform(value => formatDate(value)),
  hdayPermanente: z.boolean(),
  hdayDescricao: z
    .string()
    .nonempty("Informe a descrição ou motivo do feriado."),
});

export type FeriadosFormProps = z.infer<
  typeof schemaFeriados
>;
