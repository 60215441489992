import { useState } from "react";
import { IConstructTable, ConstructTable } from "../../../../../../components/construct/construct-table/construct-table";
import { endpoint } from "../../../../../../config/environment/endpoint.config";
import { ID } from "../../../../../../config/environment/id.config";
import { columnsListaPrecos } from "./column/lista-preco.column";
import { modifyListaPrecosTable } from "./form/lista-precos.modify";

export const ListaDePrecos = () => {
  const [isLoading, setIsLoading] = useState(false);

  const Informations: IConstructTable = {
    endpoint: endpoint.cadastro,
    idNameTable: ID.faturamento.listaPreco,
    nameTable: "Configurações: Lista de preços",
    columnsTable: columnsListaPrecos,
    nameMenu: "faturamento",
    nameSubmenu: "listaPrecos",
    modifyData: modifyListaPrecosTable,
    isLoading, setIsLoading
  };
  return <ConstructTable {...Informations} />;
}
