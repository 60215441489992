import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { preloadedValues } from "./default-values-form";
import { LFincCriterioDiaUtil } from "./option";
import {
  CondicoesDePagamentoFormProps,
  schemaCondicoesDePagamento,
} from "./form.config";
import { ControllerCheckBox } from "../../../../../../components/controllers/checkbox-controller";
import { ControllerComboBox } from "../../../../../../components/controllers/combobox-controller";
import { FooterFormButtons } from "../../../../../../components/footer-form/buttons-form";
import { TitleForm } from "../../../../../../components/text/form/title/title";
import style from "./style.module.scss";
import iconCalc from "../../../../../../assets/icons/gerais/calc.svg";
import { useContext, useEffect, useState } from "react";
import iconEdit from "../../../../../../assets/icons/geraisCadastros/edit.svg";
import { Modal } from "../../../../../../components/modal/modal";
import { CondicoesDePagamentoParcelas } from "./parcelas/parcelas-form";
import { uuid } from "../../../cadastro/pessoas/forms/create.informations";
import { idItemSelectedContext } from "../../../../../../context/idItemSelected";
import { ActionButtonTable } from "../../../../../../context/actionButtonTable";
import {
  create,
  getValuesItem,
} from "../../../../../../utils/actions/form.actions";
import { endpoint } from "../../../../../../config/environment/endpoint.config";
import { currentURlName } from "../../../../../../utils/url.name";
import { useLocation, useNavigate } from "react-router-dom";
import { editItemVerification } from "../../../../../../utils/actions/editItemPost";
import { ID } from "../../../../../../config/environment/id.config";
import { recStatusModify } from "../../../../../../utils/rec-status.modify";
import { ControllerTextField } from "../../../../../../components/controllers/ControllerTextField";
export const CondicoesDePagamentoForm = () => {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CondicoesDePagamentoFormProps>({
    mode: "all",
    resolver: zodResolver(schemaCondicoesDePagamento),
    defaultValues: preloadedValues,
  });
  const { idItemSelected } = useContext(idItemSelectedContext);
  const { actionButton } = useContext(ActionButtonTable);
  const { pathname } = useLocation();
  let navigate = useNavigate();

  const currentPage = currentURlName(pathname);

  const [openModal, setOpenModal] = useState(false);
  const [parcelaSelected,setParcelaSelected] = useState("")
  const [showParcelas, setShowParcelas] = useState(false);
  const [rowTableParcelas, setRowTablesParcelas] = useState<any>();
  const actionForm = actionButton === "edit" || actionButton === "copy";
  const pageReturn = "/linko/configuracoes/condicoesPagamento";
  const [isLoading, setIsLoading] = useState(false);

  const generateParcelas = async() => {
    const list = [];
    const valueInterval = getValues("cnPgIntervaloParcelas");
    const numberOfParcelas = getValues("cnPgParcelas");
    for (let i = 2; i <= +numberOfParcelas; i++) {
      const informationParcela = {
        id: uuid(),
        parcela: i,
        interval: valueInterval,
      };

      list.push(informationParcela);
    }
    setValue("CPPcCnPg",list);
    setRowTablesParcelas(list);
    setShowParcelas(true);
    return
  }
  const parcelas = (e?: any ) => {
    e.preventDefault();
    generateParcelas()
  };

  const valuesEdit = (itemValue: CondicoesDePagamentoFormProps) => {
    setRowTablesParcelas(itemValue.CPPcCnPg)
    setValue(
      "cnPgRecStatus", recStatusModify.form(itemValue.cnPgRecStatus)
    );
    setValue("cnPgCriterioDiaUtil", itemValue.cnPgCriterioDiaUtil);
    setValue("cnPgDescricao", itemValue.cnPgDescricao);
    setValue("cnPgDiaFixo", itemValue.cnPgDiaFixo);
    setValue("cnPgDiasPrimeiraParcela", itemValue.cnPgDiasPrimeiraParcela);
    setValue("cnPgIntervaloParcelas", itemValue.cnPgIntervaloParcelas);
    setValue("cnPgParcelas", itemValue.cnPgParcelas);

    setValue("cnPgPercPrimeiraParcela", itemValue.cnPgPercPrimeiraParcela);
    setValue("cnPgValorMinimoVenda", itemValue.cnPgValorMinimoVenda);
    setValue("cnPgValorParcelaMinima", itemValue.cnPgValorParcelaMinima);
    setShowParcelas(true)

    setIsLoading(false);
    return;
  };
  useEffect(() => {
    getValuesItem({
      currentPage,
      idSelected: idItemSelected.id,
      nameMenuCurrent: "condicaoPagamento",
      setIsLoading,
      url: endpoint.financeiro,
      valuesEdit,
    });
  }, []);


  const createItem = async (data: any) => {
    const idNumber = idItemSelected.id;
    const id = ID.financeiro.condicaoPagamento + ":" + idNumber;
    if(!rowTableParcelas){
      await generateParcelas()
    }  
    await create({
      currentPage,
      nameMenuCurrent: "condicaoPagamento",
      url: endpoint.financeiro,
      data: editItemVerification({ currentPage, data, id }),
      setIsLoading,
      idItem: idNumber,
    });
    navigate(pageReturn);
  };

  return (
    <>
      {openModal && (
        <Modal
          content={
            <CondicoesDePagamentoParcelas
              setOpenModal={setOpenModal}
              setRowTablesParcelas={setRowTablesParcelas}
              rowTableParcelas={rowTableParcelas}
              parcelaSelected={parcelaSelected}
              updateParcelas={setValue}
            />
          }
          title="Condições de pagamento: parcelas"
        />
      )}
      <section className={style["content"]}>
        <TitleForm title={"Condições de pagamento"} />
        <form>
          <ControllerCheckBox
            name={"cnPgRecStatus"}
            label={"Desativado"}
            control={control}
            recStatus={true}
          />
          <ControllerTextField
            name={"cnPgDescricao"}
            control={control}
            label="Descrição"
            placeholder="Descrição da condição de pagamento"
            errors={errors.cnPgDescricao?.message}
          />
          <div className={style["section"]}>
            <div className={style["section_form"]}>
              <div className={style["form-row"]}>
                <ControllerTextField
                  name={"cnPgValorParcelaMinima"}
                  control={control}
                  label="Valor da parcela mínima"
                  placeholder="Valor da parcela mínima"
                  errors={errors.cnPgValorParcelaMinima?.message}
                />
                <ControllerTextField
                  name={"cnPgValorMinimoVenda"}
                  control={control}
                  label="Valor mínimo para autorizar uma venda"
                  placeholder="Valor mínimo para autorizar uma venda"
                  errors={errors.cnPgValorMinimoVenda?.message}
                />
              </div>

              <ControllerTextField
                name={"cnPgIntervaloParcelas"}
                control={control}
                label="Intervalo entre as parcelas"
                placeholder="Intervalo entre as parcelas"
                errors={errors.cnPgIntervaloParcelas?.message}
              />
              <ControllerTextField
                name={"cnPgDiaFixo"}
                control={control}
                label="Dia fixo para os vencimentos"
                placeholder="Dia fixo para os vencimentos"
                errors={errors.cnPgDiaFixo?.message}
              />
              <ControllerComboBox
                options={LFincCriterioDiaUtil}
                name={"cnPgCriterioDiaUtil"}
                control={control}
                label="Critério para dias úteis"
                placeholder="Critério para cálculo de vencimentos em dias úteis"
                errors={errors.cnPgCriterioDiaUtil?.message}
              />
              <div className={style["form-row"]}>
                <ControllerTextField
                  name={"cnPgParcelas"}
                  control={control}
                  label="Número de parcelas"
                  placeholder="Descrição da condição de pagamento"
                  errors={errors.cnPgParcelas?.message}
                />
                <ControllerTextField
                  name={"cnPgDiasPrimeiraParcela"}
                  control={control}
                  label="Prazo da primeira parcela"
                  placeholder="Dias para cálculo da primeira parcela"
                  errors={errors.cnPgDiasPrimeiraParcela?.message}
                />
                <ControllerTextField
                  name={"cnPgPercPrimeiraParcela"}
                  control={control}
                  label="Percentual da primeira parcela"
                  placeholder="Percentual da primeira parcela"
                  errors={errors.cnPgPercPrimeiraParcela?.message}
                />
              </div>
            </div>
            <div className={style["section_parcelas"]}>
              <header className={style["section_parcelas-header"]}>
                <span>Parcelas</span>
                <button
                  className={style["section_parcelas-button"]}
                  onClick={(e: any) => {
                    parcelas(e);
                  }}
                >
                  Calcular parcelas <img src={iconCalc} alt="icon calculo" />
                </button>
              </header>
              <div className={style["section_parcelas-list"]}>
                {showParcelas && (
                  <table className={style["table"]}>
                    <thead className={style["table-head"]}>
                      <tr>
                        <th>Parcela</th>
                        <th>Dias</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className={style["row-color"]}>
                        <td>1</td>
                        <td>{watch("cnPgDiasPrimeiraParcela")}</td>
                        <td>
                          <img
                            src={iconEdit}
                            onClick={() => {
                              setOpenModal(true)
                            }} 
                            alt="icon edit"
                          />
                        </td>
                      </tr>

                      {rowTableParcelas.map((value: any, index: number) => (
                        <tr
                          key={index}
                          className={!!(index % 2) ? style["row-color"] : ""}
                        >
                          <td>{value.parcela}</td>
                          <td>{value.interval}</td>
                          <td>
                            <img
                              src={iconEdit}
                              onClick={() => {
                                setOpenModal(true)
                                setParcelaSelected(value.id)
                              }}
                              alt="icon edit"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
          <FooterFormButtons
            min={false}
            btn_cancelar={() => navigate(pageReturn)}
            btn_salvar={handleSubmit(createItem)}
          />
        </form>
      </section>
    </>
  );
};
