import style from "./style.module.scss";

import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { idItemSelectedContext } from "../../context/idItemSelected";

interface IModalDelete {
  functionDelete: () => void;
  setShowModal: (state: any) => void;
}
export const  ModalDeleteItem = ({
  functionDelete,
  setShowModal,
}: IModalDelete) => {
  const { idItemSelected } = useContext(idItemSelectedContext);
  const [showMessageError, setShowMessageError] = useState(false);

  const btnCancel = () => {
    setShowModal(false);
  };

  const btnExcluir =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    functionDelete();
    setShowModal(false);
  };
  
  useEffect(() => {
    if (!idItemSelected) {
      setShowMessageError(true);
      setTimeout(() => {
        setShowMessageError(false);
      }, 2000);
      return
    }
    
  }, []);

  return (
    <div className={style["background-modal"]}>
      {showMessageError && <></>}
      <section className={style["section"]}>
        <div className={style["section_content"]}>
          <span className={style["section_content-title"]}>
            Excluir Registro
          </span>
          <p className={style["section_content-message"]}>
            Selecione excluir para remover o registro
          </p>

          <footer className={style["section_footer"]}>
            <button
              className={style["section_footer-btn-cancel"]}
              onClick={() => {
                btnCancel();
              }}
            >
              Cancelar
            </button>

            <button
              className={style["section_footer-btn-delete"]}
              onClick={(e) => {
                btnExcluir(e);
              }}
            >
              Excluir
            </button>
          </footer>
        </div>
      </section>
    </div>
  );
};