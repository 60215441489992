import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { IPI } from "../ipi/ipi.form";
import { ICMS } from "../icms/icms.form";
import { PISCOFINS } from "../pis-cofins/pis-cofins.form";
import { OutrosImpostos } from "../outros-impostos/outros-impostos.table";
import { XML } from "../xml/xml.form";
import style from "./style.module.scss";
import { idItemSelectedContext } from "../../../../../../../../context/idItemSelected";
import { ControllerCheckBox } from "../../../../../../../../components/controllers/checkbox-controller";
import { ControllerComboBox } from "../../../../../../../../components/controllers/combobox-controller";
import { Expander } from "../../../../../../../../components/expander/expander";
import { FooterFormButtons } from "../../../../../../../../components/footer-form/buttons-form";
import { TitleForm } from "../../../../../../../../components/text/form/title/title";
import { currentURlName } from "../../../../../../../../utils/url.name";
import { OperacoesFiscaisProps, schemaOperacoesFiscais } from "./form.config";
import { Loading } from "../../../../../../../../components/loading/loading";
import { ID } from "../../../../../../../../config/environment/id.config";
import { CenterValuesContext } from "../../../../../../../../context/center-values";
import { convertForComboBox } from "../../../../../../../../utils/convert-for-combobox";
import { filter } from "./convert-options";
import { create, getValuesItem } from "../../../../../../../../utils/actions/form.actions";
import { endpoint } from "../../../../../../../../config/environment/endpoint.config";
import { editItemVerification } from "../../../../../../../../utils/actions/editItemPost";
import { localStorageGet } from "../../../../../../local";
import { preloadedValues } from "./default-values-form";
import { recStatusModify } from "../../../../../../../../utils/rec-status.modify";
import { ControllerTextField } from "../../../../../../../../components/controllers/ControllerTextField";

interface IExpander{
  open:boolean,
  state:boolean
}
export const OperacoesFiscaisForm = () => {
  let navigate = useNavigate();
  const pageReturn = "/linko/configuracoes/operacoesFiscais/";

  const { pathname } = useLocation();
  const { centerValues } = useContext(CenterValuesContext)
  const [isLoading, setIsLoading] = useState(true);
  const { idItemSelected } = useContext(idItemSelectedContext);
  const currentPage = currentURlName(pathname);

  const [expanderIPI, setExpanderIPI] = useState<IExpander>({open:false,state:false});
  const [expanderICMS, setExpanderICMS] = useState<IExpander>({open:false,state:false});
  const [expanderPISCOFINS, setExpanderPISCOFINS] = useState<IExpander>({open:false,state:false});
  const [expanderOutrosImpostos, setExpanderOutrosImpostos] = useState<IExpander>({open:false,state:false});
  const [expanderXML, setExpanderXML] = useState<IExpander>({open:false,state:false});

  const [cfopList, setCfopList] = useState([]);
  const [cfopListAjuste, setCfopListAjuste] = useState([]);
  const [cfopListDevolucao, setCfopListDevolucao] = useState([]);
  const [listPlanoContas, setListPlanoContas] = useState([])
  const [listCentroCusto, setListCentroCusto] = useState([])
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<OperacoesFiscaisProps>({
    mode: "onChange",
    resolver: zodResolver(schemaOperacoesFiscais),
    defaultValues:preloadedValues
  });

  const valuesEdit = (itemValue: OperacoesFiscaisProps) => {
    setValue("operRecStatus", recStatusModify.form(itemValue.operRecStatus))
    setValue("operDescricao", itemValue.operDescricao)
    setValue("operCfopId", itemValue.operCfopId)
    setValue("operAjusteCfopId", itemValue.operAjusteCfopId)
    setValue("operDevolucaoCfopId", itemValue.operDevolucaoCfopId)
    setValue("operCnCtId", itemValue.operCnCtId)
    setValue("operPlCtId", itemValue.operPlCtId)
    setIsLoading(false);
    return;
  }

  const submit = async (data: OperacoesFiscaisProps) => {
    const idNumber = idItemSelected.id;
    const id = ID.fiscal.operacoesFiscais + ":" + idNumber;
    await create({
      currentPage,
      nameMenuCurrent: "operacoesFiscais",
      url: endpoint.fiscal,
      data: editItemVerification({ currentPage, data, id }),
      setIsLoading,
      idItem: idNumber,
    });
    navigate(pageReturn);
  }

  const getOptions = () => {
    convertForComboBox({ id: ID.fiscal.cfop, list: centerValues.cfop, prop: 'cfopNaturezaOperacao', items: cfopList, setItems: setCfopList })
    convertForComboBox({ id: ID.cadastro.centroDeCusto, list: centerValues.centroDeCustos, prop: 'cnCtDescricao', items: listCentroCusto, setItems: setListCentroCusto })
    convertForComboBox({ id: ID.cadastro.planoContas, list: centerValues.planoContas, prop: 'plCtDescricao', items: listPlanoContas, setItems: setListPlanoContas })
    filter({ centerValues, setCfopListAjuste, setCfopListDevolucao })
  }
  useEffect(() => {
    getOptions()
    getValuesItem({
      currentPage,
      idSelected: idItemSelected.id,
      nameMenuCurrent: "operacoesFiscais",
      setIsLoading,
      url: endpoint.fiscal,
      valuesEdit,
    });
  }, [])

  return (
    <>
      {isLoading ? <Loading /> :
        <section className={style["content"]}>
          <TitleForm title="Operações Fiscais" />
          <form>
            <div className={style["form_content"]}>
              <ControllerCheckBox
                name="operRecStatus"
                label="Desativado"
                control={control}
                recStatus={true}
              />
              <ControllerTextField
                name="operDescricao"
                label="Descrição"
                control={control}
                placeholder="Descrição da operação fiscal"
                errors={errors.operDescricao?.message}
              />
              <div className={style["form-row"]}>
                <ControllerComboBox
                  name="operCfopId"
                  options={cfopList}
                  control={control}
                  label="CFOP"
                  placeholder="CFOP"
                  errors={errors.operCfopId?.message}
                />
                <ControllerComboBox
                  name="operAjusteCfopId"
                  options={cfopListAjuste}
                  control={control}
                  label="CFOP de Ajuste"
                  placeholder="CFOP de Ajuste/Estorno"
                  errors={errors.operAjusteCfopId?.message}
                />
                <ControllerComboBox
                  name="operDevolucaoCfopId"
                  options={cfopListDevolucao}
                  control={control}
                  label="CFOP de Devolução"
                  placeholder="CFOP de Devolução"
                  errors={errors.operDevolucaoCfopId?.message}
                />
              </div><div className={style["form-row"]}>
                <ControllerComboBox
                  name="operCnCtId"
                  options={listCentroCusto}
                  control={control}
                  label="Centro de custos"
                  placeholder="Centro de custos"
                  errors={errors.operCnCtId?.message}
                />

                <ControllerComboBox
                  name="operPlCtId"
                  options={listPlanoContas}
                  control={control}
                  label="Plano de contas"
                  placeholder="Plano de contas"
                  errors={errors.operPlCtId?.message}
                />
              </div>
            </div>
            <Expander
              name="IPI"
              content={<IPI getValuesForm={getValues} setValuesForm={setValue} closeExpander={expanderIPI} setCloseExpander={setExpanderIPI}/>}
              withIcon={true}
              statusForm={expanderIPI?.state}
              value={expanderIPI.open}
              setValue={setExpanderIPI}

            />
            <Expander
              name="ICMS"
              content={<ICMS getValuesForm={getValues} setValuesForm={setValue} states={localStorageGet("UF")} closeExpander={setExpanderICMS} />}
              withIcon={true}
              statusForm={expanderICMS?.state}
              value={expanderICMS.open}
              setValue={setExpanderICMS}
            />

            <Expander
              name="PIS e COFINS"
              content={<PISCOFINS getValuesForm={getValues} setValuesForm={setValue} closeExpander={setExpanderPISCOFINS} />}
              withIcon={true}
              statusForm={expanderPISCOFINS?.state}
              value={expanderPISCOFINS.open}
              setValue={setExpanderPISCOFINS}
            />
            <Expander
              name="Outros Impostos"
              content={<OutrosImpostos  getValuesForm={getValues} setValuesForm={setValue} closeExpander={setExpanderOutrosImpostos} /> }
              withIcon={true}
              statusForm={expanderOutrosImpostos?.state}
              value={expanderOutrosImpostos.open}
              setValue={setExpanderOutrosImpostos}
            />
            <Expander
              name="XML"
              content={<XML getValuesForm={getValues} setValuesForm={setValue} closeExpander={setExpanderXML} />}
              withIcon={true}
              statusForm={expanderXML?.state}
              value={expanderXML.open}
              setValue={setExpanderXML}
            />

            <FooterFormButtons
              min={false}
              btn_cancelar={() => navigate(pageReturn)}
              btn_salvar={handleSubmit(submit)}
            />

          </form>
        </section>
      }</>
  );
};



