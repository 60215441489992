import { apiApp } from "../../services/api/app.api"

const localStorageSave = (resp: any, key: string) => {
    if (resp.status === 200) localStorage.setItem(key, JSON.stringify(resp.data))
    return false
}

export const localStorageGet = (key: string) => {
    const search = localStorage.getItem(key)
    return !search ? false : JSON.parse(search)
}

export const Store = async (endpoint: string, key: string) => {
    const verify = localStorageGet(key)
    if (!verify) {

        const result = await apiApp.getAll(endpoint, key)
        localStorageSave(result, key)
        return result
    }
    return verify
}

export const GetAllLocalStart = (setCenterValues:any) => {
    setCenterValues({
        cfop:localStorageGet("cfop"),
        centroDeCustos:localStorageGet("centroDeCustos"),
        planoContas:localStorageGet("planoContas"),
        uf:localStorageGet("uf"),
        pais:localStorageGet("pais"),
        cidade:localStorageGet("cidade"),
        unidademedida:localStorageGet("unidademedida"),
        empresa:localStorageGet("empresa"),
    })
}