export const columnsOperacoesFiscais = [
    {
      key: "column1",
      name: "Modelo do documento",
      fieldName: "prodCodigo",
      minWidth: 200,
      maxWidth: 240,
      isResizable: true,
    },
    {
      key: "column2",
      name: "Propósito da operação",
      fieldName: "prodCodigo",
      minWidth: 360,
      maxWidth: 500,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Operação",
      fieldName: "prodCodigo",
      minWidth: 360,
      maxWidth: 500,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Status",
      fieldName: "prodCodigo",
      minWidth: 160,
      maxWidth: 200,
      isResizable: true,
    },
  ]