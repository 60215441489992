import iconSucess from "../../assets/icons/messages/success.svg";
import iconError from "../../assets/icons/messages/error.svg";
import iconInfo from "../../assets/icons/messages/info.svg";
import iconWarning from "../../assets/icons/messages/warning.svg";

type ICard = {
  title: string;
  class: string;
  icon: any;
};

let cardValue: ICard = { title: "", class: "", icon: "" };
export const Card = (type: string) => {
  switch (type) {
    case "success":
      cardValue = {
        title: "Concluido!",
        class: "card-success",
        icon: iconSucess,
      };
      return cardValue;

    case "error":
      cardValue = {
        title: "Erro!",
        class: "card-error",
        icon: iconError,
      };
      return cardValue;

    case "info":
      cardValue = {
        title: "Informação!",
        class: "card-info",
        icon: iconInfo,
      };
      return cardValue;

    case "warning":
      cardValue = {
        title: "Atenção!",
        class: "card-warning",
        icon: iconWarning,
      };
      return cardValue;

    default:
      break;
  }
};
