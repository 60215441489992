import { recStatusModify } from "../../../../../../../utils/rec-status.modify";

export const modifyUnidadeMedidaTable = async (dados: any) => {
  await dados.map((value: any) => {
    value.unidRecStatus = recStatusModify.table(value.unidRecStatus);
  });
  return dados;
};

export const modifyUnidadeMedidaApi = async (dados: any) => {
  await dados.map((value: any) => {
    value.unidRecStatus = recStatusModify.api(value.unidRecStatus);
  });
  return dados;
};
