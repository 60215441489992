import { z } from "zod";

export const schemaFormaCondicao = z.object({
  cnFmRecStatus: z.any(),
  cnFmCnPgId: z.string(),
});

export type FormaCondicaoFormProps = z.infer<
  typeof schemaFormaCondicao
>;
