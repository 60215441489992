import AMEX from "../../../../../../assets/icons/bandeiras/AMEX.png"
import AURA from "../../../../../../assets/icons/bandeiras/AURA.png"
import CABAL from "../../../../../../assets/icons/bandeiras/CABAL.png"
import DINERSCLUB from "../../../../../../assets/icons/bandeiras/DINERSCLUB.png"
import ELO from "../../../../../../assets/icons/bandeiras/ELO.png"
import HIPERCARD from "../../../../../../assets/icons/bandeiras/HIPERCARD.png"
import MASTERCARD from "../../../../../../assets/icons/bandeiras/MASTERCARD.png"
import SOROCRED from "../../../../../../assets/icons/bandeiras/SOROCRED.png"
import VISA from "../../../../../../assets/icons/bandeiras/VISA.png"

import TPAG01 from "../../../../../../assets/icons/formas-de-pagamento/TPAG01.svg";
import TPAG02 from "../../../../../../assets/icons/formas-de-pagamento/TPAG02.svg";
import TPAG0304_TBAND09 from "../../../../../../assets/icons/formas-de-pagamento/TPAG0304_TBAND09.svg";
import TPAG05 from "../../../../../../assets/icons/formas-de-pagamento/TPAG05.svg";
import TPAG1011 from "../../../../../../assets/icons/formas-de-pagamento/TPAG1011.svg";
import TPAG12 from "../../../../../../assets/icons/formas-de-pagamento/TPAG12.svg";
import TPAG13 from "../../../../../../assets/icons/formas-de-pagamento/TPAG13.svg";
import TPAG15 from "../../../../../../assets/icons/formas-de-pagamento/TPAG15.svg";
import TPAG16 from "../../../../../../assets/icons/formas-de-pagamento/TPAG16.svg";
import TPAG17 from "../../../../../../assets/icons/formas-de-pagamento/TPAG17.svg";
import TPAG18 from "../../../../../../assets/icons/formas-de-pagamento/TPAG18.svg";
import TPAG19 from "../../../../../../assets/icons/formas-de-pagamento/TPAG19.svg";
import TPAG99 from "../../../../../../assets/icons/formas-de-pagamento/TPAG99.svg";


export const EDfFincTPag = [
    {key:"01",text:"01 - Dinheiro",icon:TPAG01},
    {key:"02",text:"02 - Cheque",icon:TPAG02},
    {key:"03",text:"03 - Cartão de crédito",icon:""},
    {key:"04",text:"04 - Cartão de débito",icon:""},
    {key:"05",text:"05 - Crédito loja",icon:TPAG05},
    {key:"10",text:"10 - Vale alimentação",icon:""},
    {key:"11",text:"11 - Vale refeição",icon:""},
    {key:"12",text:"12 - Vale presente",icon:TPAG12},
    {key:"13",text:"13 - Vale combustível",icon:""},
    {key:"15",text:"15 - Boleto bancário",icon:TPAG15},
    {key:"16",text:"16 - Depósito bancário",icon:TPAG16},
    {key:"17",text:"17 - Pagamento instantâneo (Pix)",icon:TPAG17},
    {key:"18",text:"18 - Transferência bancária, Carteira digital",icon:TPAG18},
    {key:"19",text:"19 - Programa de fidelidade, Cashback, Crédito virtual",icon:TPAG19},
    {key:"90",text:"90 - Sem pagamento",icon:""},
    {key:"99",text:"99 - Outros",icon:TPAG99},
]

export const EDfFincTBand = [
    {key:"00",text:"00 - Visa",icon:VISA},
    {key:"01",text:"01 - Mastercard",icon:MASTERCARD},
    {key:"02",text:"02 - American Express",icon:AMEX},
    {key:"03",text:"03 - Sorocred",icon:SOROCRED},
    {key:"04",text:"04 - Diners Club",icon:DINERSCLUB},
    {key:"05",text:"05 - Elo",icon:ELO},
    {key:"06",text:"06 - Hipercard",icon:HIPERCARD},
    {key:"07",text:"07 - Aura",icon:AURA},
    {key:"08",text:"08 - Cabal",icon:CABAL},
    {key:"09",text:"09 - Outros",icon:""},
 
]
export const EDfFincTpIntegra = [
    {key:"1",text:"1 - Pagamento integrado com o sistema de automação da empresa"},
    {key:"2",text:"2 - Pagamento não integrado com o sistema de automação da empresa"},

 
]
