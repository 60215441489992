import { Card } from "./type-message";
import style from "./style.module.scss";
import { forwardRef, useState, Ref, useImperativeHandle } from "react";

export interface IMessage {
  message: string;
  type: "success" | "error" | "info" | "warning";
}

let toMessageError: NodeJS.Timeout

export interface RetNotification {
  setMessage: (message: IMessage) => void
}

export const Notification = forwardRef((props, ref: Ref<RetNotification>) => {
  useImperativeHandle(ref, () => ({ setMessage }));

  const [state, setState] = useState<IMessage>({ message: "", type: "error" })
  const [visible, setvible] = useState(false)
  const { type, message } = state;
  const value = Card(type);

  function setMessage(message: IMessage) {
    clearTimeout(toMessageError)
    toMessageError = setTimeout(() => {setvible(false)}, 3000)

    if (visible) setvible(false)
    setState(message)
    setvible(true)
  }

  return (
    (visible) ?
      <div className={style[`${value?.class}`]} role="alert" onClick={()=>setvible(false)}>
        <img className={style["card_icon"]} src={value?.icon} alt="" />
        <div className={style["card_text"]}>
          <span className={style["card_text-title"]}>{value?.title}</span>
          <span className={style["card_text-message"]}>{message}</span>
        </div>
      </div>
    :
      <></>
  );
});
