export const messagesCFOPForm = {
  NFeAndNFCeNull: "Selecione NF-e ou NFC-e",
  tipo:"Selecione um tipo",
  codigo:"O CFOP deve ter 4 numeros" , 
  cfopEntrada:
    "Deve iniciar com 1, 2 ou 3 para operações de entrada",
  cfopSaida:
    "Deve iniciar com 5, 6 ou 7 para operações de saída.",
  naturezaDaOperacao: "Informe a natureza da operação para este CFOP",
  inputEmpty: "Complete o campo corretamente",
};

