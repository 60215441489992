import { createContext, ReactNode, useState } from "react";

type ActionButtonTableProps = {
  children: ReactNode;
};

type ActionButtonTableType = {
  actionButton: string;
  setActionButton: (newState: string) => void;
};

const initialValue = {
  actionButton: "",
  setActionButton: () => {},
};

export const ActionButtonTable =
  createContext<ActionButtonTableType>(initialValue);

export const ActionButtonTableProvider = ({
  children,
}: ActionButtonTableProps) => {
  const [actionButton, setActionButton] = useState(initialValue.actionButton);

  return (
    <ActionButtonTable.Provider value={{ actionButton, setActionButton }}>
      {children}
    </ActionButtonTable.Provider>
  );
};
