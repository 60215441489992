export const configFinanceiro = [
    {
      category: "configuracoes",
      key: "centroCustos",
      name: "Centros de custos",
    },
    {
      category: "configuracoes",
      key: "planoContas",
      name: "Plano de contas",
    },
    {
      category: "configuracoes",
      key: "formasPagamento",
      name: "Formas de pagamento",
    },
    {
      category: "configuracoes",
      key: "condicoesPagamento",
      name: "Condições de pagamento",
    },
    {
      category: "configuracoes",
      key: "feriados",
      name: "Feriados",
    },
    {
      category: "configuracoes",
      key: "bancosContas",
      name: "Bancos / Contas",
    },
  ];