export const preloadedValues = {
  operRecStatus: 0,
  operDescricao: "",
  operCfopId: "",
  operAjusteCfopId: "",
  operDevolucaoCfopId: "",
  operCnCtId: "",
  operPlCtId: "",
  operUfdeId: "",
  operIPICST: "0",
  operIPIEnquandramento: "",
  operIPIAcumulaBCICMSProprio: false,
  operIPIAcumulaBCICMSDestino: false,
  operICMSCST: "",
  operICMSCSOSN: "",
  operICMSCodigoBeneficioFiscal: "",
  operICMSModalidadeBC: "",
  operICMSModalidadeBCST: "",
  operICMSDesoneracaoMotivo: "",
  operICMSDesoneracaoFormulaBC: "",
  operICMSAliquotaInternaUF: "",
  operICMSReducaoBC: "0",
  operICMSDiferimentoBC: "0",
  operICMSSTMVA: "0",
  operPISSCST: "01",
  operPISDeduzICMS: false,
  operCOFINSCST: "01",
  operCOFINSDeduzICMS: false,
  operIndicadorPresenca: "",
  operConsumidorFinal: "",
  operIntermediador: "",
  operIntermediadorCNPJ: "",
  operIntermediadorID: "",
  opIA: [{
    opIARecStatus:0,
    opIAId: "",
    opIAOperId: "",
    opIADescricao: "",
    opIAAliquota: "0",
    opIADeduzTotalDFe: false,
    opIADadosAdicionaisGerar: false,
    opIADadosAdicionaisTexto: "",

  }]

}

