import { idItemSelectedContext } from "../../../context/idItemSelected";
import { Loading } from "../../loading/loading";
import { ModalDeleteItem } from "../../modal-delete/modalDelete";
import { TableForm } from "../../tables/form/table-form";
import style from "./style.module.scss";
import { ReactComponentElement, useContext, useEffect, useState } from "react";

export interface IConstructTableForm {
  columns: any;
  propIdName: string;
  form: ReactComponentElement<any>;
  modifyValuesApi: any;
  arrayWithValuesForm: any[];
  setArrayWithValuesForm: any;
  categoryId: string,
  showForm: boolean,
  navigatePage?:string,
  setShowForm: (value: boolean) => void;
  setGetItemSelectedTable?: any;

  setCloseExpander:any,
  closeExpander:any
}
export const ConstructTableForm = ({
  arrayWithValuesForm,categoryId,columns,form,modifyValuesApi,setArrayWithValuesForm,propIdName,
  showForm,navigatePage,closeExpander,setShowForm,setCloseExpander,setGetItemSelectedTable = ""
}: IConstructTableForm) => {
  const { idItemSelected, setCategoryId } = useContext(idItemSelectedContext);
  const [showModal, setShowModal] = useState(false);
  const [listRemovedValues, setListRemovedValues] = useState<any>([]);
  const [loading,setLoading] = useState(false);

  const getItems = () => {
    modifyValuesApi(arrayWithValuesForm);
    setArrayWithValuesForm(arrayWithValuesForm);
    setLoading(false)
    return;
  };

  const deleteValueArray: any = async() => {
    let index = arrayWithValuesForm.findIndex((value: any) => {
      return value[propIdName] === idItemSelected.id;
    });
    setListRemovedValues([...listRemovedValues, arrayWithValuesForm[index]]);
    arrayWithValuesForm.splice(index, 1);
    getItems();
    setShowModal(false);
    return;
  };

  const restoreList = () => {
    let result = listRemovedValues[listRemovedValues.length - 1];
    if (listRemovedValues.length > 0) {
      setArrayWithValuesForm([...arrayWithValuesForm, result]);
      listRemovedValues.pop();
      return;
    }
    alert("history vazio");
    return
  };

  useEffect(() => {
    getItems();
    setLoading(true)
    setCategoryId(categoryId) 
    arrayWithValuesForm.length > 0 ?
      setCloseExpander({state:true,open:true}):
      setCloseExpander({state:false,open:true})
      setLoading(false)

  }, [arrayWithValuesForm.length]);


  return (
    <>
    {loading ? <Loading /> :
    <section className={style.section}>
 
        <div className={style.sectionContent}>
          {showModal  ? (
            <ModalDeleteItem
              functionDelete={deleteValueArray}
              setShowModal={setShowModal}
            />
          ):(
          <>
          <TableForm
            columns={columns}
            listItems={arrayWithValuesForm}
            propIdName={propIdName}
            Form={form} 
            showForm={showForm}
            setShowForm={setShowForm}
            restoreList={restoreList}
            showModal={showModal}
            setShowModal={setShowModal}
            setGetItemSelectedTable={setGetItemSelectedTable}
            />
            
            </>)}
        </div>
   
    </section>
 } </>
  );
};
