
import style from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ModalDelete } from "../../context/modalDelete";
import { ButtonsActionTable } from "../buttons-action-table/buttons-action-table";
import { TitleForm } from "../text/form/title/title";
import { Search } from "../input/search/search";
import { Checkbox } from "@fluentui/react";

interface IHeaderContent {
  namePage: string;
  onSearch: (text: string) => void
  onFilterStatus: (value: boolean) => void
}

export const HeaderTable = ({ namePage, onSearch, onFilterStatus }: IHeaderContent) => {
  const navigate = useNavigate() 
  const {setIsOpenModal} = useContext(ModalDelete)
  return (
    <header className={style["header"]}>
      <div className={style["header_content"]}>
        <div className={style["header_content-search"]}>
          
          <div className={style["header_content-left"]}>
            <Search onChange={(e: any, value: string) => { onSearch(value) }}/>            
            <Checkbox
              className={style.checkBoxListTable}
              label="Listar Desativados"
              onChange={(e, value: any) => onFilterStatus(value)}
            />
          </div>

          <ButtonsActionTable navigateAdd={() => navigate('./add')} navigateCopy={() => navigate('./copy')} navigateEdit={() => navigate('./edit')} setShowModal={() => setIsOpenModal(true)} />
        </div>
        <TitleForm title={namePage}/>
      </div>
    </header>
  );
};
