import { createContext, ReactNode, useState } from "react";

type TabContextProps = {
  children: ReactNode;
};

type ITab = {
  key: string;
  name: string;
  category: string;
};

type TabContextType = {
  newTab: Array<ITab>;
  setNewTab: (data: any) => void;
};

const initialValue = {
  newTab: [],
  setNewTab: () => {},
};

export const TabsContext = createContext<TabContextType>(initialValue);

export const TabsContextProvider = ({ children }: TabContextProps) => {
  const [newTab, setNewTab] = useState(initialValue.newTab);

  return (
    <TabsContext.Provider value={{ newTab, setNewTab }}>
      {children}
    </TabsContext.Provider>
  );
};
