import { useState } from "react";
import { columnsUnidadeMedida } from "./column/unidade-medida.columns";
import { modifyUnidadeMedidaTable } from "./form/unidade-medida.modify";
import { endpoint } from "../../../../../../config/environment/endpoint.config";
import { ID } from "../../../../../../config/environment/id.config";
import { ConstructTable, IConstructTable } from "../../../../../../components/construct/construct-table/construct-table";


export const UnidadeMedida = (data: any) => {
  const [isLoading,setIsLoading] = useState(false);

  const Informations: IConstructTable = {
    endpoint: endpoint.cadastro,
    idNameTable:ID.cadastro.unidadeMedida,
    nameTable: "Configurações: Unidades de medida",
    columnsTable: columnsUnidadeMedida,
    nameMenu: "configuracoes",
    nameSubmenu: "unidademedida",
    modifyData: (data: any) => modifyUnidadeMedidaTable(data),
    isLoading,setIsLoading
  };
  return <ConstructTable {...Informations} />;
}
