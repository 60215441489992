import { createContext, useState } from "react";

export const TabSelectedContext = createContext<any>("");

export const TabSelectedContextProvider = ({ children }: any) => {
  const [tabSelected, setTabSelected] = useState("");

  return (
    <TabSelectedContext.Provider value={{ tabSelected, setTabSelected }}>
      {children}
    </TabSelectedContext.Provider>
  );
};
