import { z } from "zod";

export const schemaInformacoesEspecificas = z
  .object({
    prIEmedANVISARegistro: z.string(),
      prIEmedANVISAMotivoIsencao: z.string(),
      prIEmedPrecoMaximoConsumidor: z.number(),
      prIEcombANPCodigo: z.string(),
      prIEcombANPDescricao: z.number(),
      prIEcombPercGLPPetroleo: z.number(),
      prIEcombPercGasNaturalNacional: z.number(),
      prIEcombPercGasNaturalImportado: z.number(),
      prIEcombValorPartida: z.number(),
      prIERECOPI: z.string(),
  });

export type InformacoesEspecificasFormProps = z.infer<typeof schemaInformacoesEspecificas>;
