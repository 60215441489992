import style from "./style.module.scss";

import restoreIcon from "../../../assets/icons/geraisCadastros/restart.svg";
import { Table } from "../component/table";
import { ButtonsActionTable } from "../../buttons-action-table/buttons-action-table";
import { ITable } from "./interface";

export const TableForm = ({
  columns,
  listItems,
  FilterTablesValue,
  propIdName,
  Form,
  showForm,
  setShowForm,
  restoreList,
  showModal,
  setShowModal,
  setGetItemSelectedTable
}: ITable) => {
  

  const ButtonRestart = () => {
    return (
      <button className={style.icon} onClick={restoreList}>
        <img className={style.iconRestore} src={restoreIcon} alt="" />
      </button>
    );
  };

  const onSetPage = (pageIndex: number) => {

  }

  return (
    <section className={style.section}>
      {!showForm ? (
        <>
          <header className={style.headerForm}>
            <ButtonsActionTable
              navigateAdd={() => setShowForm(!showForm)}
              navigateCopy={() => setShowForm(!showForm)}
              navigateEdit={() => setShowForm(!showForm)}
              setShowModal={setShowModal}
              children={<ButtonRestart />}
            />
          </header>
          <Table
            columns={columns}
            listItems={listItems}
            propIdName={propIdName}
            FilterTablesValue={FilterTablesValue}
            setGetItemSelectedTable={setGetItemSelectedTable}
            pageCount={0}
            pageIndex={1}
            totalItens={listItems.length}
            onSetPage={onSetPage}
          />
        </>
      ) : (
        <>{Form}</>
      )}
    </section>
  );
};
