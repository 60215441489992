import { coerce, z } from "zod";
import { messagesNCMForm, messagesValidationNcm } from "../../../../messages/messages";
import { RegEx } from "../../../../../../../config/RegEx/regex";

export const schemaNCM = z
  .object({
    ncmsRecStatus: z.any(),
    ncmsNCM: z
      .string()
      .refine((fields) => fields.replace(RegEx.onlyNumber, "").length === 8, {
        message: messagesNCMForm.ncm,
      })

      .transform((value) => value.replace(RegEx.onlyNumber, "")),
    ncmsCodigoEX: z
      .string()
      .min(2, messagesNCMForm.codigoEx)
      .max(3, messagesNCMForm.codigoEx)
      .refine((fields) => fields.replace(RegEx.onlyNumber, "").length !== 2 || fields.replace(RegEx.onlyNumber, "").length !== 3, {
        message: messagesNCMForm.codigoEx,
      })

      .transform((value) => value.replace(RegEx.onlyNumber, "")),
    ncmsDescricao: z.string().nonempty(messagesValidationNcm.inputEmpty),
    ncmsIPIModalidadeBC: z.number().nonnegative(messagesValidationNcm.inputEmpty),
    ncmsIPIAliquota: z.number( {invalid_type_error: messagesValidationNcm.inputEmpty} ).nullable()
  })
  .superRefine((val, ctx) => {
    if (
      val.ncmsIPIModalidadeBC === 0 &&
      (val.ncmsIPIAliquota == null || val.ncmsIPIAliquota < 0 || val.ncmsIPIAliquota > 100)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["ncmsIPIAliquota"],
        message: messagesValidationNcm.aliquotaInformation,
      });
    }
    
    if (
      val.ncmsIPIModalidadeBC === 1 &&
      (val.ncmsIPIAliquota == null || val.ncmsIPIAliquota < 0)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["ncmsIPIAliquota"],
        message: messagesValidationNcm.unidadeValidation,
      });
    }
  });

export type NCMFormProps = z.infer<typeof schemaNCM>;
