export const preloadedValues = {
    prPBCustoInicial:"0",
    prPBCustoPIPI:"0",
    prPBCustoVIPI:"0",
    prPBCustoPFrete:"0",
    prPBCustoVFrete:"0",
    prPBCustoPFreteCTe:"0",
    prPBCustoVFreteCTe:"0",
    prPBCustoVICMSFreteCTe:"0",
    prPBCustoPST:"0",
    prPBCustoVST:"0",
    prPBCustoVICMS:"0",
    prPBCustoVPIS:"0",
    prPBCustoVCOFINS:"0",
    prPBCustoVDespesasTotais:"0",
    prPBFormacaoPComissao:"0",
    prPBFormacaoPMargem:"0",
    prPBCustoFinal:"0",
    prLPMkPVMinimo:"0",
    prLPMkPVFinal:"0",
    prLPPVMinimo:"0",

  };