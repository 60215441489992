import { zodResolver } from "@hookform/resolvers/zod";
import { Loading } from "../../../../../../../components/loading/loading"
import { TitleForm } from "../../../../../../../components/text/form/title/title"
import { AliquotaInterestadualFormProps, schemaAliquotaInterestadual } from "./form.config";
import { preloadedValues } from "./default-values-form";
import { useForm } from "react-hook-form";
import { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { idItemSelectedContext } from "../../../../../../../context/idItemSelected";
import { currentURlName } from "../../../../../../../utils/url.name";
import { FooterFormButtons } from "../../../../../../../components/footer-form/buttons-form";
import { ControllerCheckBox } from "../../../../../../../components/controllers/checkbox-controller";
import { ControllerComboBox } from "../../../../../../../components/controllers/combobox-controller";
import { ControllerTextField } from "../../../../../../../components/controllers/ControllerTextField";
import { recStatusModify } from "../../../../../../../utils/rec-status.modify";
import { create, getValuesItem } from "../../../../../../../utils/actions/form.actions";
import { endpoint } from "../../../../../../../config/environment/endpoint.config";
import { editItemVerification } from "../../../../../../../utils/actions/editItemPost";
import { ID } from "../../../../../../../config/environment/id.config";
import style from "./style.module.scss"
import { convertForComboBox } from "../../../../../../../utils/convert-for-combobox";
import { CenterValuesContext } from "../../../../../../../context/center-values";
import { ControllerTextFieldPercent } from "../../../../cadastro/produtos-servicos/component/controller-textfield-percent";
import { percentFormatEvent } from "../../../../../../../config/RegEx/regex";

export const AliquotaInterestadualForm = () => {
    let navigate = useNavigate();
    const { pathname } = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const { idItemSelected } = useContext(idItemSelectedContext);
    const currentPage = currentURlName(pathname);
    const [ufOptions, setUfOptions] = useState([])
    const [countryOptions, setCountryOptions] = useState([])
    const { centerValues } = useContext(CenterValuesContext)
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<AliquotaInterestadualFormProps>({
        mode: "all",
        resolver: zodResolver(schemaAliquotaInterestadual),
        defaultValues: preloadedValues,
    });


    const pageReturn = "/linko/configuracoes/aliquotasInterestaduaisICMS";

    const valuesEdit = (itemValue: AliquotaInterestadualFormProps) => {
        setValue(
            "alleRecStatus", recStatusModify.form(itemValue.alleRecStatus)
        );
        setValue("alIeOrigemUfdeId", itemValue.alIeOrigemUfdeId);
        setValue("alIeDestinoUfdeId", itemValue.alIeDestinoUfdeId);
        setValue("alIeICMSAliquotaImportados", itemValue.alIeICMSAliquotaImportados);
        setValue("alIeICMSAliquota", itemValue.alIeICMSAliquota);
        setIsLoading(false);
        return;
    };
    useEffect(() => {
        setIsLoading(true)

        convertForComboBox({ id: ID.cadastro.uf, list: centerValues.uf, prop: 'ufdeNome', items: ufOptions, setItems: setUfOptions, isUF: true });

        getValuesItem({
            currentPage,
            idSelected: idItemSelected.id,
            nameMenuCurrent: "aliquotaInterestadual",
            setIsLoading,
            url: endpoint.fiscal,
            valuesEdit,
        });
        setIsLoading(false)
    }, []);

    const submit = async (data: any) => {
        const idNumber = idItemSelected.id;
        const id = ID.fiscal.aliquotaInterestadual + ":" + idNumber;

        await create({
            currentPage,
            nameMenuCurrent: "aliquotaInterestadual",
            url: endpoint.fiscal,
            data: editItemVerification({ currentPage, data, id }),
            setIsLoading,
            idItem: idNumber,
        });
        navigate(pageReturn);
    };
    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <section className={style["section"]}>

                    <TitleForm title={"Alíquota interestadual de ICMS"} />
                    <form>
                        <ControllerCheckBox
                            label="Desativado"
                            name={"alleRecStatus"}
                            control={control}
                            recStatus={true} />
                        <ControllerComboBox
                            options={ufOptions}
                            label="UF de origem"
                            name={"alIeOrigemUfdeId"}
                            control={control}
                            errors={errors.alIeOrigemUfdeId?.message}
                            placeholder="Selecione a UF de origem" />
                        <ControllerComboBox
                            options={ufOptions}
                            label="UF de destino"
                            name={"alIeDestinoUfdeId"}
                            control={control}
                            errors={errors.alIeDestinoUfdeId?.message}
                            placeholder="Selecione a UF de destino" />
                        <ControllerTextFieldPercent
                            label="Alíquota de produtos Nacionais"
                            name={"alIeICMSAliquota"}
                            control={control}
                            errors={errors.alIeICMSAliquota?.message}
                            placeholder="Alíquota de produtos nacionais"
                            onChange={(e: InputEvent) => setValue("alIeICMSAliquota", percentFormatEvent(e))}
                        />
                        <ControllerTextFieldPercent
                            label="Alíquota de produtos Importados"
                            name={"alIeICMSAliquotaImportados"}
                            control={control}
                            errors={errors.alIeICMSAliquotaImportados?.message}
                            placeholder="Alíquota de produtos importados"
                            onChange={(e: InputEvent) => setValue("alIeICMSAliquotaImportados", percentFormatEvent(e))}
                        />
                        <FooterFormButtons
                            min={false}
                            btn_salvar={handleSubmit(submit)}
                            btn_cancelar={() => {
                                navigate(pageReturn);
                            }}
                        />
                    </form>

                </section>

            )}
        </>
    )
}