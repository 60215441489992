import style from "../ncm.module.scss";
import {  TextField } from "@fluentui/react";
import { IIBPT } from "../../interfaces/interface.ncm";
import { SubtitleForm } from "../../../../../../../../components/text/form/subtitle/subtitle";
import { Calendar } from "../../../../../../../../components/input/calendar/calendar";

export const IBPT = ({
  federais,
  estaduais,
  municipais,
  importacao,
  vigencia,
}: IIBPT) => {
  return (
    <>
      <SubtitleForm subtitle=" IBPT" />

      <div className={style["section_content-box"]}>
        <div className={style["section_content-box-form"]}>
          <TextField
            id={"federal"}
            label="Impostos Federais"
            placeholder="Digite o código EX"
            value={String(federais)}
            disabled
          />
          <TextField
            id={"estadual"}
            label="Impostos Estaduais"
            placeholder="Digite o valor"
            value={String(estaduais)}
            disabled
          />

          <TextField
            id={"municipal"}
            label="Impostos Municipais"
            placeholder="Digite o valor"
            value={String(municipais)}
            disabled
          />
        </div>

        <div className={style["section_content-box-form"]}>
          <TextField
            id={"importacao"}
            label="Impostos de importação"
            placeholder="Digite a importação"
            value={String(importacao)}
            disabled
          />

          <Calendar
            label="Vigência da Tabela do IBPT"
            value={vigencia}
            disabled={true}
          />
        </div>
      </div>
    </>
  );
};
