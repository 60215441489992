import { RegEx } from "../../../../../../../config/RegEx/regex";
import { validationCNPJ } from "../../../../../../../utils/validation-document";

type props = {
    setValue: any;
    cnpjNumber: any
}

export const getValuesCNPJ = async ({ cnpjNumber, setValue }: props) => {
    let CNPJ: any = validationCNPJ(cnpjNumber);

    if (!CNPJ) return false
    cnpjNumber = cnpjNumber.replace(RegEx.onlyNumber, "")
    await fetch(`https://api-publica.speedio.com.br/buscarcnpj?cnpj=${cnpjNumber}`).then((res) => {
        res.json().then((resp) => {
            setValue("pessDocumento", cnpjNumber)
            setValue("pessFantasia", resp["NOME FANTASIA"])
            setValue("pessRazao", resp["RAZAO SOCIAL"])
            return;
        });
    })
        .catch((err) => {
            return false;
        });

};
