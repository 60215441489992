import { useEffect, useState } from "react";
import { columnsContatos } from "../../columns/contato.column";
import { ContatosForm } from "./contato.form";
import { ID } from "../../../../../../../config/environment/id.config";
import { ConstructTableForm, IConstructTableForm } from "../../../../../../../components/construct/construct-table-form/construct-table-form";
import {  ValuesPessoaTable } from "../types/types-cadastro-pessoas";
import { modifyInformationContatos } from "../../modify-information";

export const ContatosPessoa = ({
  arrayForm,setArrayForm,setCloseExpander,getValuesForm,setValuesForm,closeExpander
}: ValuesPessoaTable) => {
  const [showForm, setShowForm] = useState(false);

  const Informations: IConstructTableForm = {
    columns: columnsContatos,
    propIdName: ID.cadastro.pessoaContato,
    modifyValuesApi: modifyInformationContatos,
    arrayWithValuesForm: arrayForm,
    setArrayWithValuesForm: setArrayForm,
    categoryId: "cadastro",
    showForm: showForm,
    setShowForm: setShowForm,
    navigatePage: "",
    setCloseExpander,closeExpander,
    form: <ContatosForm arrayForm={arrayForm} setArrayForm={setArrayForm} setShowForm={setShowForm} getValuesForm={getValuesForm} setValuesForm={setValuesForm} />,
  };
  return <ConstructTableForm {...Informations} />;
};

