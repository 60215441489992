import { recStatusModify } from "../../../../../../utils/rec-status.modify";
import { EDfFincTBand, EDfFincTPag } from "./options";

export const modifyInformationFormaPagamento = async (dados: any) => {
  await dados.forEach((value: any) => {
    const bandeira = EDfFincTBand.find(
      (item: any) => item.key === value.fmPgTBand
    );
    const tipo = EDfFincTPag.find((item: any) => item.key === value.fmPgTPag);
    value.fmPgRecStatus = recStatusModify.table(value.fmPgRecStatus);
    value.fmPgTBand = bandeira?.text;
    value.fmPgTPag = tipo?.text;
  });
  return dados;
};

export const modifyInformationFormaCondicao = async (dados: any) => {
  await dados.forEach((value: any) => {
    value.cnPgRecStatus = recStatusModify.table(value.cnPgRecStatus);
  });
  return dados;
};
