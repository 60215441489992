export const columnsCodigosAlternativos = [
    {
      key: "column1",
      name: "Tipo",
      fieldName: "prodCodigo",
      minWidth: 200,
      maxWidth: 240,
      isResizable: true,
    },
    {
      key: "column2",
      name: "Código",
      fieldName: "prodCodigo",
      minWidth: 360,
      maxWidth: 500,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Usar no XML/DANFE",
      fieldName: "prodCodigo",
      minWidth: 360,
      maxWidth: 500,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Status",
      fieldName: "prodCodigo",
      minWidth: 160,
      maxWidth: 200,
      isResizable: true,
    },
  ]