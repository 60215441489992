import { z } from "zod";

export const schemaCondicoesDePagamento = z.object({
  cnPgRecStatus: z.any(),
  cnPgDescricao: z.string().nonempty("Complete o campo"),
  cnPgParcelas: z.string().nonempty("Complete o campo"),
  cnPgDiasPrimeiraParcela: z.string().nonempty("Complete o campo"),
  cnPgPercPrimeiraParcela:z.string().nonempty("Complete o campo"),
  cnPgIntervaloParcelas:z.string().nonempty("Complete o campo"),
  cnPgDiaFixo: z.string().nonempty("Complete o campo"),
  cnPgCriterioDiaUtil: z.number(),
  cnPgValorParcelaMinima: z.string().nonempty("Complete o campo "),
  cnPgValorMinimoVenda: z.string().nonempty("Complete o campo"),
  CPPcCnPg: z.any()
});

export type CondicoesDePagamentoFormProps = z.infer<typeof schemaCondicoesDePagamento>;
