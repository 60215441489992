import style from "./style.module.scss";
import { MaskedTextField, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import searchIcon from "../../../assets/icons/gerais/search.svg";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { IControllerTextFieldRequest } from "../types/types";

//TODO: tempo global
export const ControllerTextFieldRequest = ({
  name,
  label,
  placeholder,
  functionRequest,
  className = "",
  control,
  errors,
  mask = "",
  disabled = false,
}: IControllerTextFieldRequest) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchBlock, setSearchBlock] = useState(false);

  const handleClick = () => {
    
    setTimeout(() => {
      setIsLoading(false);
      setSearchBlock(false);
    }, 2000);
    setSearchBlock(true);
    setIsLoading(true);
  };

  return (
    <div className={style["content"]}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <MaskedTextField
            value={value}
            label={label}
            className={style["content-input"]}
            disabled={disabled}
            placeholder={placeholder}
            errorMessage={errors}
            mask={mask}
            onChange={(_, value: any) => {
              onChange(value);
            }}
          />
        )}
      />

      <button
        className={
          searchBlock
            ? style["button_request-disabled"]
            : style["button_request"]
        }
        disabled={searchBlock ? true : false}
        onClick={(e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.preventDefault()
          handleClick();
          functionRequest();
        }}
      >
        {!isLoading ? (
          <img
            className={style["button_request-icon"]}
            src={searchIcon}
            alt=""
          />
        ) : (
          <Spinner size={SpinnerSize.medium} />
        )}
      </button>
    </div>
  );
};
