import "./style.scss";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TabsContext } from "../../../../context/allTabs";
import { TabSelectedContext } from "../../../../context/tabSelected";
import iconClose from "../../../../assets/icons/gerais/cancel.svg";
import iconPerson from "../../../../assets/icons/gerais/person.svg";
import { UserInformations } from "./userInformation/userInformation";

export const HeaderContentApp = () => {
  const navigate = useNavigate();

  const [showInformationUser, setShowInformationUser] = useState(false);
  const { tabSelected, setTabSelected } = useContext(TabSelectedContext);
  const { newTab, setNewTab } = useContext(TabsContext);
  const [showMinimizedTabs, setShowMinimizedTabs] = useState(false);
  const listMinimizedTab: { key: string; name: string; category: string }[] =
    [];

  const actionRemove = (tab: any) => {
    newTab.splice(newTab.indexOf(tab), 1);
    setNewTab([...newTab]);
  };
  const removeTab = (tab: any, index: number) => {
    if (newTab.length === 1) {
      actionRemove(tab);
      navigate(`/linko`);
      return;
    }

    if (tabSelected !== tab.key) {
      actionRemove(tab);
      return;
    }

    if (index > 0 && index <= newTab.length - 1) {
      actionRemove(tab);

      setTabSelected(newTab[index - 1].key);
      navigate(`/linko/${newTab[index - 1].category}/${newTab[index - 1].key}`);

      return;
    }

    if (!index && tabSelected === tab.key && newTab.length > 1) {
      actionRemove(tab);

      setTabSelected(newTab[index].key);
      navigate(`/linko/${newTab[index].category}/${newTab[index].key}`);
      return;
    }
  };

  const nameCurrentPage = (value: string) => {
    setTabSelected(value);
  };

  useEffect(() => {
    if (newTab.length < 1) {
      navigate("/linko");
    }
  }, []);

  return (
    <header className="header__contentApp">
      <ul className="header__contentApp--tabs">
        {newTab.map((value, index) => {
          if (index < 5) {
            return (
              <li
                className={
                  tabSelected === value.key
                    ? "header__contentApp--tab header__tab--selected"
                    : "header__contentApp--tab"
                }
                aria-label={value.name}
                key={index}
              >
                <Link
                  key={value.key}
                  to={`/linko/${value.category}/${value.key}`}
                  className="header__tab--link"
                  onClick={() => nameCurrentPage(value.key)}
                >
                  <span className="header__tab--name">{value.name}</span>
                </Link>
                <img
                  className="header__tab--iconClose"
                  src={iconClose}
                  alt="icone fechar"
                  onClick={() => removeTab(value, index)}
                />
              </li>
            );
          } else {
            listMinimizedTab.push(value);
          }
        })}
      </ul>

      <div className="header__buttons">
        <button
          data-testid="show_minimized_tabs"
          className={
            newTab.length > 5 ? "header__buttons--tabs" : "header__buttons--off"
          }
          onClick={() => {
            setShowInformationUser(false);
            setShowMinimizedTabs(!showMinimizedTabs);
          }}
        >
          {newTab.length > 5 ? `+ ${newTab.length - 5}` : 0}
        </button>
        <ul
          className={
            showMinimizedTabs ? "tabs__minimized" : "tabs__minimized--hide"
          }
        >
          {listMinimizedTab.map((value, index) => {
            return (
              <li
                className={
                  tabSelected === value.key ? "header__tab--selected" : ""
                }
                aria-label={value.name}
                key={index}
              >
                <Link
                  key={value.key}
                  to={`/linko/${value.category}/${value.key}`}
                  className="tab__minimized--link"
                  onClick={() => nameCurrentPage(value.key)}
                >
                  {value.name}
                </Link>
                <img
                  className="header__tab--iconClose"
                  src={iconClose}
                  alt="icone fechar"
                  onClick={() => removeTab(value, index)}
                />
              </li>
            );
          })}
        </ul>
        <button
          data-testid="button_informationUser"
          className="header__buttons--userInformation"
          onClick={() => {
            setShowMinimizedTabs(false);
            setShowInformationUser(!showInformationUser);
          }}
        >
          <img src={iconPerson} alt="" />
        </button>
      </div>
      {showInformationUser && <UserInformations />}
    </header>
  );
};
