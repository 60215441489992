export const resultFaturamento = [
  {
    category: "resultados",
    key: "graficoVendas",
    name: "Gráfico de vendas",
  },
  {
    category: "resultados",
    key: "analiseReceitaLiquota",
    name: "Análise de receita líquota",
  },
  {
    category: "resultados",
    key: "relatorioVendas",
    name: "Relatório de vendas",
  },
];
