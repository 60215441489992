import { createContext, ReactNode, useState } from "react";

type AllItemsContextProps = {
  children: ReactNode;
};

type AllItemsContextType = {
  allItems: Array<any>;
  setAllItems: (data: any) => void;
};

const initialValue = {
  allItems: [],
  setAllItems: () => {},
};

export const AllItemsContext = createContext<AllItemsContextType>(initialValue);

export const AllItemsProvider = ({ children }: AllItemsContextProps) => {
  const [allItems, setAllItems] = useState(initialValue.allItems);

  return (
    <AllItemsContext.Provider value={{ allItems, setAllItems }}>
      {children}
    </AllItemsContext.Provider>
  );
};
