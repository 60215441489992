import style from "../../style.module.scss";
import { FooterFormButtons } from "../../../../../../../components/footer-form/buttons-form";
import { ControllerComboBox } from "../../../../../../../components/controllers/combobox-controller";
import { PautasFiscaisFormProps, schemaPautasFiscais } from "./form.config";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { preloadedValues } from "./default-values-form";
import { IPropsFormProduto } from "../../interface/props.interface-form";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { convertForComboBox } from "../../../../../../../utils/convert-for-combobox";
import { ID } from "../../../../../../../config/environment/id.config";
import { CenterValuesContext } from "../../../../../../../context/center-values";
import { ControllerTextField } from "../../../../../../../components/controllers/ControllerTextField";


export const PaustasFiscaisProduto = ({ getValuesForm, setValuesForm, setCloseExpander, closeExpander }: IPropsFormProduto) => {
  const [ufOptions, setUfOptions] = useState([])
  const [loading, setLoading] = useState(false);
  const { centerValues } = useContext(CenterValuesContext)

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PautasFiscaisFormProps>({
    mode: "all",
    resolver: zodResolver(schemaPautasFiscais),
    defaultValues: preloadedValues,
  });
  const submit = (data: any) => {
    setCloseExpander({ state: true, open: false })

  }

  useEffect(() => {
    setLoading(true);

    convertForComboBox({ id: ID.cadastro.uf, list: centerValues.uf, prop: 'ufdeNome', items: ufOptions, setItems: setUfOptions, isUF: true });
    setLoading(false);
  }, []);

  return (
    <div className={style["form-content"]}>
      <ControllerComboBox
        options={ufOptions}
        name={"prPFUfdeId"}
        label="UF de origem:"
        placeholder="Selecione a UF"
        control={control}
        errors={errors.prPFUfdeId?.message}

      />
      <div className={style["form-row"]}>
        <ControllerTextField
          name={"prPFPrecoPauta"}
          label="Preço de pauta"
          control={control}
          errors={errors.prPFPrecoPauta?.message}
          prefix="$"

        />
        <ControllerTextField
          name={"prPFPrecoTabeladoMaximo"}
          label="Preço tabelado máximo"
          errors={errors.prPFPrecoTabeladoMaximo?.message}
          control={control}
          prefix="$"

        />
        <ControllerTextField
          name={"prPFPrecoListaNegativa"}
          errors={errors.prPFPrecoListaNegativa?.message}
          label="Preço lista negativa"
          control={control}
          prefix="$"

        />
        <ControllerTextField
          name={"prPFPrecoListaPositiva"}
          errors={errors.prPFPrecoListaNegativa?.message}
          label="Preço lista positiva"
          control={control}
          prefix="$"

        />
        <ControllerTextField
          name={"prPFPrecoListaNeutral"}
          errors={errors.prPFPrecoListaNegativa?.message}
          label="Preço lista neutra"
          control={control}

          prefix="$"
        />
      </div>
      <FooterFormButtons
        min={true}
        btn_salvar={handleSubmit(submit)}
        btn_cancelar={() => setCloseExpander({ state: false, open: false })}
      />
    </div>
  );
};
