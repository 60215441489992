
export const preloadedValuesContato = {
  psCtRecStatus: false,
  psCtPrincipal: false,
  psCtXMLNFCe: false,
  psCtXMLNFe: false,
  psCtXMLAutorizado: false,
  psCtNome: "",
  psCtCPF: "",
  psCtCNPJ: "",
  psCtEmail: "",
  psCtCelular: "",
  psCtTelefone: "",
};