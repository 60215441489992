import { z } from "zod";

import { RegEx } from "../../../../../../../config/RegEx/regex";
import { schemaCadastroPessoaEvento } from "../evento/form.config";
import { schemaCadastroPessoaEndereco } from "../endereco/form.config";
import { schemaCadastroPessoaContato } from "../contato/form.config";
import {
  validationCNPJ,
  validationCPF,
} from "../../../../../../../utils/validation-document";

export const schemaPrincipal = z
  .object({
    pessRecStatus: z.any(),
    pessRegimeTributacao: z.number(),
    pessDocumento: z.string(),
    pessRazao: z.string(),
    pessFantasia: z.string(),
    pessIETipo: z.number(),
    pessIE: z.string(),
    pessIM: z.string(),
    pessSUFRAMA: z.string(),
  }).superRefine((val,ctx) => {

    if(val.pessRegimeTributacao > 1){
      if(!validationCNPJ(val.pessDocumento)){
        ctx.addIssue({
          code:z.ZodIssueCode.custom,
          message:"CNPJ Incorreto",
          path:["pessDocumento"]
        })}
      }
    })
  
  const schemaForms = z.object({
    pessEnderecos: z.array(schemaCadastroPessoaEndereco),
    pessContatos: z.array(schemaCadastroPessoaContato),
    pessEventos: z.array(schemaCadastroPessoaEvento)
  })
    
 export const schemaCadastroPessoa = z.intersection(schemaPrincipal,schemaForms)

export type CadastroPessoaFormProps = z.infer<typeof schemaCadastroPessoa>;
