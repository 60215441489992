import { endpoint } from "../../../../../../../config/environment/endpoint.config"
import { ID } from "../../../../../../../config/environment/id.config"
import { convertForComboBox } from "../../../../../../../utils/convert-for-combobox"
import { Store } from "../../../../../local"

type getInformationsProps = {
    optionsNCM: any
    setOptionsNCM: any
    optionsOperacoesFiscais: any
    setOptionsOperacoesFiscais: any
}

export const getInformations = async ({ setOptionsNCM, setOptionsOperacoesFiscais, optionsNCM, optionsOperacoesFiscais }: getInformationsProps) => {
    const ncms: any = await Store(endpoint.fiscal, "ncm")
    const operacoesFiscais: any = await Store(endpoint.fiscal, "operacoesFiscais")
    convertForComboBox({ id: ID.fiscal.ncm, list: ncms, prop: 'ncmsDescricao', items: optionsNCM, setItems: setOptionsNCM });
    convertForComboBox({ id: ID.fiscal.operacoesFiscais, list: operacoesFiscais, prop: 'operDescricao', items: optionsOperacoesFiscais, setItems: setOptionsOperacoesFiscais });
    return
}