
import { useState } from "react";
import { IConstructTable, ConstructTable } from "../../../../../../components/construct/construct-table/construct-table";
import { endpoint } from "../../../../../../config/environment/endpoint.config";
import { ID } from "../../../../../../config/environment/id.config";
import { columnsCentroDeCusto } from "./columns/columns";

export const CentroDeCustoTable = () => {
  const [isLoading,setIsLoading] = useState(false);

  const Informations: IConstructTable = {
    endpoint: endpoint.cadastro,
    idNameTable: ID.cadastro.centroDeCusto,
    nameTable: "Centros de custos",
    columnsTable: columnsCentroDeCusto,
    nameMenu: "cadastros",
    nameSubmenu: "centroDeCusto",
    modifyData: (data: any) => {},
    isLoading,setIsLoading
  };
  return <ConstructTable {...Informations} />;
}

