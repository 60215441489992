class Status {
  table(value: string | boolean | number) {
    const typeValue = typeof value;
    if (typeValue === "boolean") {
      value = value ? "Desativado" : "Ativo";
      return value;
    }
    if (typeValue === "number") {
      return value === 0 ? "Ativo" : "Desativado";
    }
  }
  form(value: string | boolean | number) {
    const typeValue = typeof value;

    if (typeValue === "boolean") {
      value = !value ? false : true;

      return value;
    }

    if (typeValue === "string") {
      value = value === "Ativo" ? false : true;

      return value;
    }
    if (typeValue === "number") {
      value = !value ? false : true;

      return value;
    }
  }
  api(value: string | boolean | number) {
    const typeValue = typeof value;
    if (typeValue === "string") {
      value = value ? 0 : 1;
      return;
    }
    if (typeValue === "boolean") {
      value = !value ? 0 : 1;
      return;
    }
  }
}

export const recStatusModify = new Status();
