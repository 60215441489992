export const columnsCondicoesPagamento = [
  {
    key: "column1",
    name: "Descricao",
    fieldName: "cnPgDescricao",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
  
  {
    key: "column2",
    name: "Status",
    fieldName: "cnPgRecStatus",
    minWidth: 140,
    maxWidth: 190,
    isResizable: true,
  },
]