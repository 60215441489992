import { useContext, useEffect, useRef, useState } from "react";
import { idItemSelectedContext } from "../../../context/idItemSelected";
import { NameItemContext } from "../../../context/nameItemSearch";
import { ModalDelete } from "../../../context/modalDelete";
import {
  ItensData,
  deleteItem,
  detectKeyDown,
  getAllItems,
} from "../../../utils/actions/table.actions";
import { TablePrincipal } from "../../tables/principal/table-principal";
import { Loading } from "../../loading/loading";
import { XPagination } from "../../../utils/actions/types/actions.types";
import { Notification, RetNotification } from "../../../components/notification/notification";

export interface IConstructTable {
  nameMenu: string;
  nameSubmenu: string;
  endpoint: string;
  nameTable: string;
  idNameTable: string;
  modifyData: (data: any) => void;
  columnsTable: any[];
  setIsLoading: (value: boolean) => void;
  isLoading: boolean;
}

type Filtros = {
  xPagination: XPagination;
  filtro: string;
  desativados: boolean;
}

const defaultXPagination = {PageSize: 11, PageIndex: 1, PageTotalItens: -1}

export const ConstructTable = ({
  endpoint,
  idNameTable,
  nameTable,
  columnsTable,
  nameMenu,
  nameSubmenu,
  modifyData,
  setIsLoading,
  isLoading,
}: IConstructTable) => {
  const [items, setItems] = useState([]);
  const [filtros, setFiltros] = useState<Filtros>({xPagination: defaultXPagination, filtro: "", desativados: false});
  const { setCategoryId } = useContext(idItemSelectedContext);
  const { nameItem } = useContext(NameItemContext);
  const { isOpenModal, setIsOpenModal } = useContext(ModalDelete);
  const { idItemSelected } = useContext(idItemSelectedContext);
  const notification = useRef<RetNotification>(null);
  const nameMenuCurrent = nameSubmenu;
  const url = endpoint;

  useEffect(() => {
    document.addEventListener("keydown", keyDown, true);
  }, [nameItem]);

  const keyDown = async (e: any) => {
    detectKeyDown(e, {
      nameItem,
      url,
      nameMenuCurrent,
      setIsLoading,
      setItems,
    });
  };

  const getItems = async (search: string = '', xPagination: XPagination, desativados: boolean = false) => {
    if (isLoading) {
      notification.current?.setMessage({ message: "Aguarde a consulta anterior", type: "warning" })

      return;
    }

    setIsLoading(true);
    try {
      try {
        let itensData: ItensData = await getAllItems({
          url,
          nameMenuCurrent,
          setIsLoading,
          modifyInformation: modifyData,
          search,
          xPagination,
          desativados
        })
        setItems(itensData.data)
      
        let f = {...filtros}
        f.filtro = search
        f.xPagination = itensData.xPagination
        f.desativados = desativados
        setFiltros(f)
      } catch (e) {
        notification.current?.setMessage({ message: "Erro ao executar a consulta", type: "error" })
      }
    } finally {
      setIsLoading(false);
    }
  };

  const remove: any = async (e: any) => {
    await deleteItem({
      url,
      nameMenuCurrent,
      idSelected: idItemSelected.id,
      setIsOpenModal,
      setIsLoading,
    });

    getItems(filtros.filtro, filtros.xPagination, filtros.desativados);
    setIsLoading(false);
  };

  useEffect(() => {
    setCategoryId(nameMenu);
    if (!nameItem) {
     getItems(filtros.filtro, filtros.xPagination, filtros.desativados);
    }
  }, [nameItem.length]);

  const onSearch = (text: string) => {
    getItems(text, defaultXPagination, filtros.desativados);
  }

  const onSetPage = (pageIndex: number) => {
    getItems(filtros.filtro, {...filtros.xPagination, PageIndex: pageIndex + 1}, filtros.desativados);
  }

  const onFilterStatus = (value: boolean) => {
    getItems(filtros.filtro, defaultXPagination, value);
  }

  return (
    <>
      <Notification ref={notification} />
      {isLoading && <Loading />}
      <TablePrincipal
        nameForm={nameTable}
        loading={isLoading}
        isOpenModal={isOpenModal}
        idName={idNameTable}
        columns={columnsTable}
        items={items}
        deleteItem={remove}
        setIsOpenModal={setIsOpenModal}
        onSearch={onSearch}
        onFilterStatus={onFilterStatus}
        pageIndex={(filtros.xPagination.PageIndex ?? 1) - 1}
        pageCount={filtros.xPagination.PageCount ?? 1}
        totalItens={(filtros.xPagination.PageTotalItens ?? 0) < 0 ? 0 : (filtros.xPagination.PageTotalItens ?? 0)}
        onSetPage={onSetPage}
      />
    </>
  );
};
