import { z } from "zod";
import { RegEx } from "../../../../../../../config/RegEx/regex";
import { validationCNPJ } from "../../../../../../../utils/validation-document";

export const schemaTributacao = z
  .object({
    PrTrNcmsId: z.string().min(1,"NCM inválida"),
    prTrCOFINSCSTCredito: z
      .string()
      .nonempty(
        "Informe a CST da COFINS para fins de aproveitamento de crédito"
      ),
    prTrOperId: z.string().min(1,"Informe a operação padrão de vendas"),
    prTrCEST: z
      .string()
      .refine(
        (value) => value.replace(RegEx.onlyNumber, "").length > 0,
        {
          message: "Deve conter apenas números",
        }
      ),
    prTrPISCSTCredito: z.string().min(1,"Informe a CST do PIS"),
    prTrIndEscala: z.boolean(),
    prTrIndEscalaCNPJFabricante: z.string().refine(value => validationCNPJ(value),{message:"CNPJ inválido"}).transform((value:any) => value.replace(RegEx.removeMask,"")),
  });

export type TributacaoFormProps = z.infer<typeof schemaTributacao>;
