

export const LProdOrigem = [
  { key: '00', text: "Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8" },
  {
    key: '01',
    text: "Estrangeira - Importação direta, exceto a indicada no código 6",
  },
  {
    key: '02',
    text: "Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7",
  },
  {
    key: '03',
    text: "Nacional, mercadoria ou bem com conteúdo de importação superior a 40% e inferior ou igual a 70%",
  },
  {
    key: '04',
    text: "Cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes",
  },
  {
    key: '05',
    text: "Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%",
  },
  {
    key: '06',
    text: "Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural",
  },
  {
    key: '07',
    text: "Estrangeira - Adquirida no mercado interno, sem similar nacional, constante lista CAMEX e gás natural",
  },
  {
    key: '08',
    text: "Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%",
  },
];

export const EProdTipoItem = [
  { key: '00', text: "00 Mercadoria para revenda" },
  { key: '01', text: "01 Matéria-prima" },
  { key: '02', text: "02 Embalagem" },
  { key: '03', text: "03 Produto em processo" },
  { key: '04', text: "04 Produto acabado" },
  { key: '05', text: "05 Subproduto" },
  { key: '06', text: "06 Produto intermediário" },
  { key: '07', text: "07 Material de uso e consumo" },
  { key: '08', text: "08 Ativo imobilizado" },
  { key: '09', text: "09 Serviços" },
  { key: '10', text: "10 Outros insumos" },
  { key: '99', text: "99 Outras" },
];

export const EProdTipoCodigoAlternativo = [
  { key: '00', text: "Código alternativo" },
  { key: '01', text: "Código de fabricante" },
  { key: '02', text: "Código do fornecedor" },
  { key: '03', text: "Referência" },
]