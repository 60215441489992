import { TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";

type props = {
  name: string;
  label?: string;
  className?: string;
  control: any;
  disabled?: boolean;
  prefix?: string;
  suffix?: string;
  readOnly?: boolean;
  multiline?: boolean;
  onChange?: any;
  mask?:any;
  maxLength?:any;
  value?:any;
  placeholder?: string;
  errors?: any;
}


export const ControllerTextFieldMoney = ({
  name, label, className = "",errors,placeholder ,control, prefix, suffix, readOnly = false, 
  disabled = false, multiline = false, maxLength = "",onChange}: props) => {
  

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value } }) => (
        <TextField
          value={value}
          label={label}
          className={className}
          disabled={disabled}
          prefix={prefix}
          suffix={suffix}
          placeholder={placeholder}
          multiline={multiline}
          errorMessage={errors}
          maxLength={maxLength}
          readOnly={readOnly}
          onChange={onChange} />
      )}/>)}

