export const faturamentoItems = [
  { category: "Faturamento", key: "nfeNFCeCFe", name: "NF-e / NFC-e / CF-e" },
  { category: "Faturamento", key: "NFSe", name: "NFS-e" },
  { category: "Faturamento", key: "MDFe", name: "MDF-e" },
  { category: "Faturamento", key: "devolucoes", name: "Devoluções" },
  {
    category: "Faturamento",
    key: "valePresenteValeTroca",
    name: "Vale presente e Vale troca",
  },

  {
    category: "Faturamento",
    key: "etiquetasRotulos",
    name: "Etiquetas e rótulos",
  },
];
