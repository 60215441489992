export const columnsOperacoesFiscais = [
  {
    key: "column1",
    name: "Descrição",
    fieldName: "operDescricao",
    minWidth: 100 ,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "column2",
    name: "CFOP",
    fieldName: "operCfopId",
    minWidth: 100 ,
    maxWidth: 120,
    isResizable: true,
  },

  {
    key: "column3",
    name: "Status",
    fieldName: "operRecStatus",
    minWidth: 140,
    maxWidth: 160,
    isResizable: true,
   
  },
];
