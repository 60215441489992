import { useEffect, useState } from "react";
import { resultEstoque } from "./list/estoque";
import { resultFaturamento } from "./list/faturamento";
import { resultFinanceiro } from "./list/financeiro";

import style from "../style.module.scss";
import { ButtonNavList } from "../../../../../components/buttons/buttons-create-tabs/button-nav-list";
import { Search } from "../../../../../components/input/search/search";
export const Resultados = () => {
  const [searchValue, setSearchValue] = useState("");
  const [listItems, setListItems] = useState<any>([]);

  let valuesListSearch = resultEstoque
    .concat(resultFaturamento)
    .concat(resultFinanceiro);
  const searchList = () => {
    const filter = valuesListSearch.filter((item) => {
      return item.name
        .toLocaleUpperCase()
        .includes(searchValue.toLocaleUpperCase());
    });

    return filter;
  };

  useEffect(() => {
    setListItems(searchList());
  }, [searchValue]);
  return (
    <section className={style["config_section"]}>
      <div className={style["config_search"]}>
        <Search onChange={(e: any, value: any) => setSearchValue(value)} />
      </div>

      <div className={style["config_content"]}>
        <div className={style["config_content-list"]}>
          {!searchValue ? (
            <>
              <ul className={style["config_group"]}>
                <li className={style["config_group-title"]}>Compras</li>
              </ul>
              <ul className={style["config_group"]}>
                <li className={style["config_group-title"]}>Pré-vendas</li>
              </ul>
              <ul className={style["config_group"]}>
                <li className={style["config_group-title"]}>Faturamento</li>
                {resultFaturamento.map((value, index) => (
                  <ButtonNavList
                    title={value.name}
                    props={value}
                    key={index}
                    className={style["config_group-item"]}
                  />
                ))}
              </ul>
              <ul className={style["config_group"]}>
                <li className={style["config_group-title"]}>PDV</li>
              </ul>
              <ul className={style["config_group"]}>
                <li className={style["config_group-title"]}>Financeiro</li>
                {resultFinanceiro.map((value, index) => (
                  <ButtonNavList
                    title={value.name}
                    props={value}
                    key={index}
                    className={style["config_group-item"]}
                  />
                ))}
              </ul>
              <ul className={style["config_group"]}>
                <li className={style["config_group-title"]}>Estoque</li>
                {resultEstoque.map((value, index) => (
                  <ButtonNavList
                    title={value.name}
                    props={value}
                    key={index}
                    className={style["config_group-item"]}
                  />
                ))}
              </ul>
              <ul className={style["config_group"]}>
                <li className={style["config_group-title"]}>Fiscal</li>
              </ul>
              <ul className={style["config_group"]}>
                <li className={style["config_group-title"]}>Cadastros</li>
              </ul>
              <ul className={style["config_group"]}>
                <li className={style["config_group-title"]}>Gerais</li>
              </ul>
            </>
          ) : (
            <>
              {
                <ul>
                  {listItems.map((value: any, index: number) => (
                    <ButtonNavList
                      title={value.name}
                      props={value}
                      key={index}
                      className={style["config_group-item"]}
                    />
                  ))}
                </ul>
              }
            </>
          )}
        </div>
      </div>
    </section>
  );
};
