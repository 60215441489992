export const columnsEventos = [
    {
      key: "column1",
      name: "Usuário",
      fieldName: "psEvPessId",
      minWidth: 320,
      maxWidth: 340,
      isResizable: true,
    },
    {
      key: "column2",
      name: "Observação",
      fieldName: "psEvObservacao",
      minWidth: 200,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Data/Hora",
      fieldName: "psEvCreatedAt",
      minWidth: 200,
      maxWidth: 250,
      isResizable: true,
    },
  
    {
      key: "column4",
      name: "Status",
      fieldName: "psEvRecStatus",
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
    },
  ];
  