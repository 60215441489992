import { z } from "zod";

export const schemaCadastroPessoaEndereco = z.object({
  psEnRecStatus: z.any(),
  psEnEnderecoPadrao: z.boolean(),
  psEnEnderecoCobranca: z.boolean(),
  psEnEnderecoEntrega: z.boolean(),
  psEnCEP: z.string().min(1, { message: "Complete o campo corretamente" }),
  psEnPaisId: z.string().min(1, { message: "Complete o campo corretamente" }),
  psEnUfdeId: z.string().min(1, { message: "Complete o campo corretamente" }),
  psEnCidaId: z.string().min(1, { message: "Complete o campo corretamente" }),
  psEnBairro: z.string().min(1, { message: "Complete o campo corretamente" }),
  psEnLogradouro: z
    .string()
    .min(1, { message: "Complete o campo corretamente" }),
  psEnLogradouroNumero: z
    .string()
    .min(1, { message: "Complete o campo corretamente" }),
  psEnComplemento: z.string(),
});

export type CadastroPessoaEnderecoFormProps = z.infer<
  typeof schemaCadastroPessoaEndereco
>;
