import { useEffect, useState } from "react";
import { columnsOperacoesFiscais } from "../../columns/operacoes-fiscais.column";
import { OperacaoFiscalForm } from "./form/operacao-fiscal.form";
import { IPropsFormProduto } from "../../interface/props.interface-form";
import { ID } from "../../../../../../../config/environment/id.config";
import { ConstructTableForm, IConstructTableForm } from "../../../../../../../components/construct/construct-table-form/construct-table-form";


export const OperacaoFiscalProduto = ({ getValuesForm, setValuesForm, setCloseExpander,closeExpander }: IPropsFormProduto) => {
  const [showForm, setShowForm] = useState(false)
  const [arrayForm, setArrayForm] = useState([])
  useEffect(() => {
    arrayForm.length > 0 ? setCloseExpander({ state: true, open: true }) : setCloseExpander({ state: false, open: true })
  }, [arrayForm.length])

  const Informations: IConstructTableForm = {
    columns: columnsOperacoesFiscais,
    propIdName: ID.cadastro.operacoesFiscas,
    modifyValuesApi: () => { },
    arrayWithValuesForm: arrayForm,
    setArrayWithValuesForm: setArrayForm,
    categoryId: "cadastro",
    showForm: showForm,
    setShowForm: setShowForm,
    navigatePage: "",
    setCloseExpander,closeExpander,
    form: <OperacaoFiscalForm getValuesForm={getValuesForm} setValuesForm={setValuesForm} setCloseExpander={setCloseExpander} />
  };
  return <ConstructTableForm {...Informations} />;
};
