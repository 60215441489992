export const columnsEnderecos = [
    {
      key: "column1",
      name: "Padrão",
      fieldName: "psEnEnderecoPadrao",
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column2",
      name: "Cobrança",
      fieldName: "psEnEnderecoCobranca",
      minWidth: 80,
      maxWidth: 100,
    },
    {
      key: "column3",
      name: "Entrega",
      fieldName: "psEnEnderecoEntrega",
      minWidth: 80,
      maxWidth: 100,
    },
    {
      key: "column4",
      name: "CEP",
      fieldName: "psEnCEP",
      minWidth: 100,
      maxWidth: 120,
    },
    {
      key: "column5",
      name: "UF",
      fieldName: "psEnUfdeId",
      minWidth: 80,
      maxWidth: 100,
    },
    {
      key: "column6",
      name: "Cidade",
      fieldName: "psEnCidaId",
      minWidth: 180,
      maxWidth: 200,
    },
    {
      key: "column7",
      name: "Bairro",
      fieldName: "psEnBairro",
      minWidth: 160,
      maxWidth: 180,
    },
    {
      key: "column8",
      name: "Logradouro",
      fieldName: "psEnLogradouro",
      minWidth: 160,
      maxWidth: 180,
    },
    {
      key: "column9",
      name: "Complemento",
      fieldName: "psEnComplemento",
      isResizable: true,
    },
    {
      key: "column10",
      name: "Status",
      fieldName: "psEnRecStatus",
      isResizable: true,
    },
  ];
  