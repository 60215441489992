import { ComboBox } from "@fluentui/react";
import { Controller } from "react-hook-form";
import { IControllerCombobox } from "./types/types";
import style from "./style.module.scss";
export const ControllerComboBox = ({
  name,
  label,
  placeholder,
  className = style["fields"],
  control,
  errors,
  options,
  valueDefault = "",
  disabled = false,
  
}: IControllerCombobox) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <ComboBox
      
          selectedKey={!valueDefault ? value : valueDefault}
          className={className}
          errorMessage={errors}
          label={label}
          placeholder={placeholder}
          options={options}
          disabled={disabled}
          onChange={(_, value: any) => {
            onChange(value.key);
          }}
        />
      )}
    />
  );
};
