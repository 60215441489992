import { localStorageGet } from "../../pages/content/local";
import { RegEx } from "../RegEx/regex";
import { ID } from "../environment/id.config";

export class Location {
  getCityById(id: string) {
    const list = localStorageGet("cidade");
    const result = list.find(
      (value: any) => value[ID.cadastro.city] === id
    );
    return result;
  }

  getCityByName(name: string) {
    const list = localStorageGet("cidade");
    const result = list.find(
      (value: any) =>
        value.cidaNome.replace(RegEx.removeWordAccents, "").toUpperCase() ===
        name.replace(RegEx.removeWordAccents, "").toUpperCase()
    );
    return result;
  }
  getUFBySigla(Sigla: string) {
    const list = localStorageGet("uf");
    const result = list.find((value: any) => value.ufdeSigla === Sigla);
    return result;
  }

  getUFById(id: string) {
    const list = localStorageGet("uf");
    const result = list.find((value: any) => value[ID.cadastro.uf] === id);
    return result;
  }

  getUFByCity(name: string) {
    const result = localStorageGet("cidade").find(
      (value: any) =>
        value.text.replace(RegEx.removeWordAccents, "").toUpperCase() ===
        name.replace(RegEx.removeWordAccents, "").toUpperCase()
    );
    return result;
  }

  getUFByName(UF: string) {
    const result = localStorageGet("uf").find(
      (value: any) => value.ufdeNome === UF
    );
    return result;
  }

  getCityByUfId(UfId: string) {
    const result = localStorageGet("cidade").filter(
      (value: any) => value.cidaUfdeId === UfId
    );
    return result;
  }

  getCountryById(id: string) {
    const result = localStorageGet("pais").find(
      (value: any) => value[ID.cadastro.country] === id
    );
    return result;
  }
  getCountryByName(name: string) {
    const result = localStorageGet("pais").find(
      (value: any) =>
        value.paisNome.replace(RegEx.removeWordAccents, "").toUpperCase() ===
        name.replace(RegEx.removeWordAccents, "").toUpperCase()
    );
    return result;
  }
}

export const getLocation = new Location();
