import { createContext, ReactNode, useState } from "react";

type MenuContextProps = {
  children: ReactNode;
};

type MenuContextType = {
  isCloseMenu: boolean;
  setIsCloseMenu: (newState: boolean) => void;
};

const initialValue = {
  isCloseMenu: true,
  setIsCloseMenu: () => {},
};

export const MenuContext = createContext<MenuContextType>(initialValue);

export const MenuContextProvider = ({ children }: MenuContextProps) => {
  const [isCloseMenu, setIsCloseMenu] = useState(initialValue.isCloseMenu);

  return (
    <MenuContext.Provider value={{ isCloseMenu, setIsCloseMenu }}>
      {children}
    </MenuContext.Provider>
  );
};
