export const columnsOutrosImpostos = [
  {
    key: "column1",
    name: "Descrição",
    fieldName: "opIADescricao",
    isResizable: true,
    minWidth: 300,
    maxWidth: 320,
  },
  {
    key: "column2",
    name: "Alíquota",
    fieldName: "opIAAliquota",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },

  {
    key: "column3",
    name: "Status",
    fieldName: "opIARecStatus",
    minWidth: 140,
    maxWidth: 160,
    isResizable: true,

  },
];
  