import { createContext, ReactNode, useState } from "react";

type LoadingContextProps = {
  children: ReactNode;
};

type LoadingContextType = {
  isLoading: boolean;
  setIsLoading: (newState: boolean) => void;
};

const initialValue = {
  isLoading: true,
  setIsLoading: () => {},
};

export const LoadingContext = createContext<LoadingContextType>(initialValue);

export const LoadingContextProvider = ({ children }: LoadingContextProps) => {
  const [isLoading, setIsLoading] = useState(initialValue.isLoading);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};
