import style from "../style.module.scss";
import { LValorPercentual } from "./options/curso-despesas.options";
import { FooterFormButtons } from "../../../../../../../../components/footer-form/buttons-form";
import { ControllerComboBox } from "../../../../../../../../components/controllers/combobox-controller";
import { CustoDespesasExtrasProps, schemaCustoDespesasExtras } from "./form.config";
import { zodResolver } from "@hookform/resolvers/zod";
import { set, useForm } from "react-hook-form";
import { preloadedValues } from "./default-values";
import { useContext, useEffect, useState } from "react";
import { ActionButtonTable } from "../../../../../../../../context/actionButtonTable";
import { idItemSelectedContext } from "../../../../../../../../context/idItemSelected";
import { ControllerCheckBox } from "../../../../../../../../components/controllers/checkbox-controller";
import { Loading } from "../../../../../../../../components/loading/loading";
import { ID } from "../../../../../../../../config/environment/id.config";
import { uuid } from "../../../../pessoas/forms/create.informations";
import { recStatusModify } from "../../../../../../../../utils/rec-status.modify";
import { moneyFormatEvent, percentFormatEvent } from "../../../../../../../../config/RegEx/regex";
import { ControllerTextField } from "../../../../../../../../components/controllers/ControllerTextField";

type Form = {
  arrayForm: any
  setArrayForm: any
  setShowForm: any
}
export const DespesasExtras = ({
  arrayForm, setArrayForm, setShowForm
}: Form) => {
  const { actionButton } = useContext(ActionButtonTable);
  const { idItemSelected } = useContext(idItemSelectedContext);
  const [loading, setLoading] = useState(false)
  const actionForm = actionButton === "edit" || actionButton === "copy"
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<CustoDespesasExtrasProps>({
    mode: "all",
    resolver: zodResolver(schemaCustoDespesasExtras),
    defaultValues: preloadedValues,
  });

  const watchTipoDespesa = watch("prPDTipo")

  let searchId: CustoDespesasExtrasProps = arrayForm.find(
    (value: any) => value[ID.cadastro.despesasExtras] === idItemSelected.id
  );

  const editValues = (value: CustoDespesasExtrasProps) => {
    setValue("prPDDescricao", value.prPDDescricao)
    setValue("prPDRecStatus", value.prPDRecStatus)
    setValue("prPDTipo", value.prPDTipo)
    setValue("prPDValor", value.prPDValor)
  }

  const submit = (data: CustoDespesasExtrasProps) => {
    if (actionForm) {
      searchId.prPDDescricao = data.prPDDescricao
      searchId.prPDTipo = data.prPDTipo
      searchId.prPDValor = data.prPDValor
      searchId.prPDRecStatus = recStatusModify.form(data.prPDRecStatus)
      setShowForm(false)
      return
    }
    setArrayForm([...arrayForm, {id:uuid(),...data}])
    setShowForm(false)
  }

  useEffect(() => {
    setLoading(true)
    if (actionForm) {
      editValues(searchId)
      return
    }
    setLoading(false)
  }, [])
  return (
    <>
      {loading && <Loading />}
      <form className={style["inputs-values-products-form"]}>

        <ControllerCheckBox
          name="prPDRecStatus"
          label="Desativado"
          control={control}
          recStatus={true}
        />

        <ControllerTextField
          name="prPDDescricao"
          label="Descrição"
          placeholder="Digite a descrição"
          control={control}
          errors={errors.prPDDescricao?.message}
          className={style["inputSmall"]}
        />
        <ControllerComboBox
          name="prPDTipo"
          label="Tipo"
          placeholder="Selecione a origem"
          control={control}
          options={LValorPercentual}
          errors={errors.prPDTipo?.message}
        />
        <ControllerTextField
          name="prPDValor"
          label="Valor/Percentual"
          control={control}
          errors={errors.prPDValor?.message}
          placeholder="Insira um valor"
          className={style["inputSmall"]}
          prefix={watchTipoDespesa === 0 ? "$" : "%"}
          format={true}
          formatFunction={watchTipoDespesa === 0 ? moneyFormatEvent : percentFormatEvent}
        />

        <FooterFormButtons
          min={true}
          btn_cancelar={() => { setShowForm(false) }}
          btn_salvar={handleSubmit(submit)}
        />
      </form>
    </>
  );
};
