import { createContext, ReactNode, useState } from "react";

type ModalDeleteProps = {
  children: ReactNode;
};

type ModalDeleteType = {
  isOpenModal: boolean;
  setIsOpenModal: (newState: boolean) => void;
  idItem: string;
  setIdItem: (newState: string) => void;
};

const initialValue = {
  isOpenModal: false,
  setIsOpenModal: () => {},
  idItem: "",
  setIdItem: () => {},
};

export const ModalDelete = createContext<ModalDeleteType>(initialValue);

export const ModalDeleteProvider = ({ children }: ModalDeleteProps) => {
  const [isOpenModal, setIsOpenModal] = useState(initialValue.isOpenModal);
  const [idItem, setIdItem] = useState(initialValue.idItem);

  return (
    <ModalDelete.Provider
      value={{ isOpenModal, setIsOpenModal, idItem, setIdItem }}
    >
      {children}
    </ModalDelete.Provider>
  );
};
