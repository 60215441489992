import { z } from "zod";
import { RegEx } from "../../../../../../../config/RegEx/regex";

export const schemaPautasFiscais = z
  .object({

    prPFUfdeId: z.string().min(1, "UF Inválida. Informe UF previamente cadastrada"),
    prPFPrecoPauta: z.string().transform((value:string) => transformNumber(value)),
    prPFPrecoTabeladoMaximo: z.string().transform((value:string) => transformNumber(value)),
    prPFPrecoListaNegativa:z.string().transform((value:string) => transformNumber(value)),
    prPFPrecoListaPositiva:z.string().transform((value:string) => transformNumber(value)),
    prPFPrecoListaNeutral:z.string().transform((value:string) => transformNumber(value)),
  })

  const transformNumber = (value:string) => {
    let result = value.replace(RegEx.onlyNumber,"") 
    return Number(result)
  }

export type PautasFiscaisFormProps = z.infer<typeof schemaPautasFiscais>;

