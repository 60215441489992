import * as React from "react";
import { DatePicker, IDatePickerStrings } from "@fluentui/react";

import style from "./style.module.scss";

interface ICalendarInput {
  label: string;
  componentRef?: any;
  dataSelected?: any;
  disabled?: boolean;
  onSelectDate?: (date: any) => any;
  value: any;
}

const DayPickerStrings: IDatePickerStrings = {
  months: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  shortMonths: [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ],
  days: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
  shortDays: ["D", "S", "T", "Q", "Q", "S", "S"],
  goToToday: "Data atual",
  prevMonthAriaLabel: "Go to previous month",
  nextMonthAriaLabel: "Go to next month",
  prevYearAriaLabel: "Go to previous year",
  nextYearAriaLabel: "Go to next year",
  isRequiredErrorMessage: "Start date is required.",
  invalidInputErrorMessage: "Invalid date format.",
};

export const Calendar = ({
  label,
  onSelectDate,
  componentRef,
  dataSelected,
  disabled,
  value,
}: ICalendarInput) => {
  const [date, setDate] = React.useState(onSelectDate);
  const formatDate = (props: any) => {
    if (!props) return "";
    const day = props.getDate();
    const month = props.getMonth() + 1;
    const year = props.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <DatePicker
      className={style["middle"]}
      label={label}
      componentRef={componentRef}
      placeholder="Selecione a data"
      ariaLabel="Selecione a data"
      strings={DayPickerStrings}
      formatDate={(date) => formatDate(date)}
      onSelectDate={onSelectDate}
      disabled={disabled}
      value={value}
    />
  );
};
