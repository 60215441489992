import { columnsOutrosImpostos } from "../../columns/outros-impostos.column";
import { IOperacoesFiscais } from "../../interface/form.interface";
import { ConstructTableForm, IConstructTableForm } from "../../../../../../../../components/construct/construct-table-form/construct-table-form";
import { ID } from "../../../../../../../../config/environment/id.config";
import { OutrosImpostosForm } from "./outros-impostos.form";
import { useEffect, useState } from "react";
import { modifyForm } from "./modify.form";

export const OutrosImpostos = ({ getValuesForm, setValuesForm, closeExpander,setCloseExpander }: IOperacoesFiscais) => {
  const [showForm, setShowForm] = useState(false);
  const values = getValuesForm("opIA")
  const [arrayForm, setArrayForm] = useState([]);

  useEffect(() => {
    if (arrayForm.length > 0) closeExpander({ state: true, open: true });

    setValuesForm("opIA", [...arrayForm])
  }, [arrayForm])
  
  const Informations: IConstructTableForm = {
    columns: columnsOutrosImpostos,
    propIdName: ID.fiscal.operacaoFiscalImpostoAdicional,
    modifyValuesApi: (data: any) => modifyForm(data),
    arrayWithValuesForm: arrayForm,
    setArrayWithValuesForm: setArrayForm,
    categoryId: "fiscal",
    showForm: showForm,
    setShowForm: setShowForm,
    navigatePage: "",
    closeExpander,setCloseExpander,
    form: <OutrosImpostosForm arrayForm={arrayForm} setArrayForm={setArrayForm} setShowForm={setShowForm} valuesForm={values} />,
  };
  return <ConstructTableForm {...Informations} />;
};
