
export const RegEx = {
    location: /[\u0300-\u036f]/g,
    onlyNumber: /[^0-9]/g,
    onlyLetter: /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]+$/,
    removeWordAccents:/[\u0300-\u036f]/g,
    removeMask:/_|-|\./g,
    cnpjCpfFormat:  /(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/
}

export const moneyFormatEvent = (e: any) => {
    let value = e.currentTarget.value.replace(/\D/g, '');
    value = (value / 100).toFixed(2) + '';
    value = value.replace(".", ",");
    value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    return value;
}

export const percentFormatEvent = (e: any) => {
    let value = e.currentTarget.value.replace(/\D/g, '');
    Number(value) > 10000 ? value = "10000" : String(value)
    
    value = value.replace(/(\d)(\d{2})$/, "$1,$2")
    return value;
}

export const moneyFormatValue = (value: any) => {
    let result = String(value).replace(/\D/g, '');
    result = (Number(result) / 100).toFixed(2) + '';
    result = result.replace(".", ",");
    result = result.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    return result;
}

export const percentFormatValue = (value: any) => {
    let result = String(value).replace(/\D/g, '');
    Number(result) > 10000 ? result = "10000" : String(result)
    
    result = result.replace(/(\d)(\d{2})$/, "$1,$2")
    return result;
}

export const cnpjCpfFormat = (value:string | number) => {
    let result = String(value).replace(RegEx.cnpjCpfFormat,"")
    return result
}
