import { z } from "zod";

export const schemaFormasDePagamento = z.object({
  fmPgRecStatus: z.any(),
  fmPgDescricao: z.string().nonempty("Descrição inválida."),
  fmPgBcCtId: z.string().nonempty("É obrigatório associar uma conta bancária com a forma de pagamento."),
  fmPgTPag: z.string().nonempty("Escolha o tipo de pagamento ao qual esta forma deve ser associada"),
  fmPgTpIntegra: z.string().nonempty("Escolha o tipo de integração ao qual esta forma deve ser associada"),
  fmPgCNPJCredenciadora: z.string(),
  fmPgCodCredenciadora: z.string(),
  fmPgAutorizadorTEF: z.string(),
  fmPgTBand: z.string(),
  fmPgTpIntegraCNPJ: z.string(),
  fmFc: z.any()
}).superRefine((val, ctx) => {
  if (val.fmPgTpIntegra === "1" && !val.fmPgCNPJCredenciadora) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["fmPgCNPJCredenciadora"],
      message: `Para o tipo de integração escolhido será necessário informar o CNPJ da credenciadora`,
    })
  }
}).superRefine((val, ctx) => {
  if (val.fmPgTpIntegra === "1" && !val.fmPgCodCredenciadora) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["fmPgCodCredenciadora"],
      message: `Para o tipo de integração escolhido será necessário informar o código da credenciadora`,
    })
  }
}).superRefine((val, ctx) => {
  if (val.fmPgTpIntegra === "1" && !val.fmPgAutorizadorTEF) {

    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["fmPgAutorizadorTEF"],
      message: `Para o tipo de integração escolhido será necessário informar o autorizador TEF`,
    })
  }
}).superRefine((val, ctx) => {
  const bandeira = val.fmPgTBand === "03" || val.fmPgTBand === "04" || val.fmPgTBand === "10" || val.fmPgTBand === "11" || val.fmPgTBand === "13";
  if (bandeira) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["fmPgTBand"],
      message: `Para o tipo de integração escolhido será necessário informar o autorizador TEF`,
    })
  }
});

export type FormasDePagamentoFormProps = z.infer<
  typeof schemaFormasDePagamento
>;
