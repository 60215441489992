
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ControllerComboBox } from "../../../../../../../../components/controllers/combobox-controller";
import { FooterFormButtons } from "../../../../../../../../components/footer-form/buttons-form";
import {
  LDfIndIntermed,
  LIndFinal,
  LOperIndicadorPresenca,
} from "../../options/options.form";
import { XMLFormProps, schemaXML } from "./form.config";
import { IOperacoesFiscais } from "../../interface/form.interface";
import { masksApp } from "../../../../../../../../config/masks/masks";
import { ControllerMaskTextField } from "../../../../../../../../components/controllers/mask-textfield-controller";
import { Loading } from "../../../../../../../../components/loading/loading";
import { preloadedValues } from "./default-values-form";
import style from "./style.module.scss"
import { useEffect, useState } from "react";
import { ControllerTextField } from "../../../../../../../../components/controllers/ControllerTextField";

export const XML = ({ getValuesForm, setValuesForm, closeExpander }: IOperacoesFiscais) => {

  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<XMLFormProps>({
    mode: "onChange",
    resolver: zodResolver(schemaXML),
  });

  const setFormValues = () => {
    setLoading(true)
    setValue("operConsumidorFinal", getValuesForm("operConsumidorFinal"))
    setValue("operIndicadorPresenca", getValuesForm("operIndicadorPresenca"))
    setValue("operIntermediador", getValuesForm("operIntermediador"))
    setValue("operIntermediadorCNPJ", getValuesForm("operIntermediadorCNPJ"))
    setValue("operIntermediadorID", getValuesForm("operIntermediadorID"))

    setLoading(false)
  }

  const submit = async (data: XMLFormProps) => {
    setValuesForm("operConsumidorFinal", data.operConsumidorFinal)
    setValuesForm("operIndicadorPresenca", data.operIndicadorPresenca)
    setValuesForm("operIntermediador", data.operIntermediador)
    setValuesForm("operIntermediadorCNPJ", data.operIntermediadorCNPJ)
    setValuesForm("operIntermediadorID", data.operIntermediadorID)

    closeExpander({ state: true, open: false })
  }

  useEffect(() => {
    if (closeExpander.state) setFormValues()

  }, [])


  return (
    <>
      {loading && <Loading />}
      <form className={style["form-content"]}>
        <div className={style["form-row"]}>
          <ControllerComboBox
            options={LIndFinal}
            name={"operConsumidorFinal"}
            control={control}
            label="Indicador de operação com consumidor final"
            placeholder="Indicador de operação com consumidor final"
            errors={errors.operConsumidorFinal?.message}
          />
          <ControllerComboBox
            options={LOperIndicadorPresenca}
            name={"operIndicadorPresenca"}
            control={control}
            label="Indicador de presença do comprador no estabelecimento comercial no momento da operação"
            placeholder="Indicador de presença"
            errors={errors.operIndicadorPresenca?.message}

          />

        </div>
        <div className={style["form-row"]}>
          <ControllerComboBox
            options={LDfIndIntermed}
            name={"operIntermediador"}
            control={control}
            label="Indicador de intermediador/marketplace"
            placeholder="Indicador de intermediador/marketplace"
            errors={errors.operIntermediador?.message}

          />
          <ControllerMaskTextField
            name={"operIntermediadorCNPJ"}
            control={control}
            label="CNPJ do Intermediador"
            placeholder="CNPJ do intermediador"
            errors={errors.operIntermediadorCNPJ?.message}
            mask={masksApp.CNPJ}
          />
          <ControllerTextField
            name={"operIntermediadorID"}
            control={control}
            label="Identificador cadastrado no Intermediador"
            placeholder="Identificador cadastrado no Intermediador"
            errors={errors.operIntermediadorID?.message}

          />
        </div>
        <FooterFormButtons
          min={true}
          btn_salvar={handleSubmit(submit)}
          btn_cancelar={() => closeExpander({ state: false, open: false })} />

      </form>
    </>
  );
};
