import { MaskedTextField, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import { IControllerTextField } from "./types/types";
import style from "./style.module.scss"
import { maskFormat } from "../../config/masks/mask.format";

export const ControllerMaskTextField = ({
  name,
  label,
  placeholder,
  className = style["fields"],
  control,
  errors,
  prefix,
  suffix,
  readOnly = false,
  disabled = false,
  multiline = false,
  maxLength = "",
  mask
}: IControllerTextField) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <MaskedTextField
          value={value}
          label={label}
          className={multiline ? style["textarea"]:className}
          disabled={disabled}
          prefix={prefix}
          suffix={suffix}
          placeholder={placeholder}
          multiline={multiline}
          errorMessage={errors}
          maskFormat={maskFormat}
          maskChar="_"
          maxLength={maxLength}
          readOnly={readOnly}
          mask={mask}
          onChange={onChange}
        />
      )}
    />
  );
};
