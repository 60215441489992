import { useForm } from "react-hook-form";
import { PlanoDeContasFormProps, schemaPlanoDeContas } from "./form.config";
import { zodResolver } from "@hookform/resolvers/zod";
import { preloadedValues } from "./default-values-form";
import style from "./style.module.scss";
import { useContext, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { ControllerCheckBox } from "../../../../../../components/controllers/checkbox-controller";
import { FooterFormButtons } from "../../../../../../components/footer-form/buttons-form";
import { TitleForm } from "../../../../../../components/text/form/title/title";
import { endpoint } from "../../../../../../config/environment/endpoint.config";
import { ID } from "../../../../../../config/environment/id.config";
import { idItemSelectedContext } from "../../../../../../context/idItemSelected";
import { editItemVerification } from "../../../../../../utils/actions/editItemPost";
import { getValuesItem, create } from "../../../../../../utils/actions/form.actions";
import { currentURlName } from "../../../../../../utils/url.name";
import { recStatusModify } from "../../../../../../utils/rec-status.modify";
import { ControllerTextField } from "../../../../../../components/controllers/ControllerTextField";

export const PlanoDeContasForm = () => {
  let navigate = useNavigate();
  const pageReturn = "/linko/configuracoes/planoContas";

  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { idItemSelected } = useContext(idItemSelectedContext);
  const currentPage = currentURlName(pathname);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PlanoDeContasFormProps>({
    mode: "all",
    resolver: zodResolver(schemaPlanoDeContas),
    defaultValues: preloadedValues,
  });

  const valuesEdit = (itemValue: PlanoDeContasFormProps) => {
    setValue(
      "plCtRecStatus", recStatusModify.form(itemValue.plCtRecStatus)
    );
    setValue("plCtDescricao", itemValue.plCtDescricao);

    setIsLoading(false);
    return;
  };
  useEffect(() => {
    getValuesItem({
      currentPage,
      idSelected: idItemSelected.id,
      nameMenuCurrent: "planoContas",
      setIsLoading,
      url: endpoint.fiscal,
      valuesEdit,
    });
  }, []);

  const createItem = async (data: any) => {
    const idNumber = idItemSelected.id;
    const id = ID.cadastro.planoContas + ":" + idNumber;

    await create({
      currentPage,
      nameMenuCurrent: "planoContas",
      url: endpoint.cadastro,
      data: editItemVerification({ currentPage, data, id }),
      setIsLoading,
      idItem: idNumber,
    });
    navigate(pageReturn);
  };

  return (
    <div className={style["form"]}>
      <TitleForm title={"Plano de contas"} />
      <form className={style["form_content"]}>
        <ControllerCheckBox
          name={"plCtRecStatus"}
          control={control}
          label="Desativado"
          recStatus={true}
        />
        <ControllerTextField
          name={"plCtDescricao"}
          control={control}
          label="Descrição"
          errors={errors.plCtDescricao?.message}
        />
        <FooterFormButtons
          min={false}
          btn_salvar={handleSubmit(createItem)}
          btn_cancelar={() => {
            navigate(pageReturn);
          }}
        />
      </form>
    </div>
  );
};
