import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { preloadedValues } from "./default-values-form";
import {
  CondicoesDePagamentoParcelasFormProps,
  schemaCondicoesDePagamentoParcelas,
} from "./form.config";
import { FooterFormButtons } from "../../../../../../../components/footer-form/buttons-form";
import { useEffect } from "react";
import style from "../style.module.scss";
import { ControllerTextField } from "../../../../../../../components/controllers/ControllerTextField";
interface IParcelas {
  setOpenModal: (value: boolean) => void;
  rowTableParcelas: any;
  setRowTablesParcelas: any;
  parcelaSelected: any;
  updateParcelas: any;
}

export const CondicoesDePagamentoParcelas = ({
  setOpenModal,
  parcelaSelected,
  rowTableParcelas,
  setRowTablesParcelas,
  updateParcelas,
}: IParcelas) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CondicoesDePagamentoParcelasFormProps>({
    mode: "all",
    resolver: zodResolver(schemaCondicoesDePagamentoParcelas),
    defaultValues: preloadedValues,
  });

  useEffect(() => {
    edit();
  }, []);

  let valuesEdit: any = rowTableParcelas.find(
    (value: any) => value.id === parcelaSelected
  );
  const edit = () => {
    setValue("CPPcDias", valuesEdit.interval);
    setValue("CPPcParcela", valuesEdit.parcela);
  };

  const submit = async (data: any) => {
    valuesEdit.interval = data.CPPcDias;
    setRowTablesParcelas(rowTableParcelas);
    await updateParcelas("CPPcCnPg", rowTableParcelas);
    setOpenModal(false);
  };
  return (
    <form className={style["form_content-parcelas"]}>
      <span className={style["form_content-parcelas-title"]}>{`Parcela: ${valuesEdit.parcela}`}</span>
      <ControllerTextField
        control={control}
        name="CPPcDias"
        errors={errors.CPPcDias}
        placeholder=""
        label="Dias de prazo"
      />
      <FooterFormButtons
        min={true}
        btn_cancelar={() => setOpenModal(false)}
        btn_salvar={handleSubmit(submit)}
      />
    </form>
  );
};
