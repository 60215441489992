import { endpoint } from "../../../../../../config/environment/endpoint.config";
import { ID } from "../../../../../../config/environment/id.config";
import { ConstructTable, IConstructTable} from "../../../../../../components/construct/construct-table/construct-table";
import { useState } from "react";
import { columnsFormasPagamento } from "./column/formas-pagamento.columns";
import { modifyInformationFormaPagamento } from "./modify-values";

export const FormasPagamento = () => {
  const [isLoading,setIsLoading] = useState(false)
  const Informations: IConstructTable = {
    endpoint: endpoint.financeiro,
    idNameTable: ID.financeiro.formasPagamento,
    nameTable: "Formas de pagamento",
    columnsTable: columnsFormasPagamento,
    nameMenu: "configuracoes",
    nameSubmenu: "formasPagamento",
    modifyData: (data: any) => {modifyInformationFormaPagamento(data)},
    isLoading,setIsLoading
  };
  return <ConstructTable {...Informations} />;
};
