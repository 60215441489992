
export const preloadedValues = {
  lsPrRecStatus:false,
  lsPrDescricao:"",
  lsPrPadrao:false,
  lsPrAtualizacaoAutomatica:false,
  lsPrUfdeId:"39c9629c-453c-421e-90c7-c92f1c7dcc1a",
  lsPrPCustoFixo:"0",
  lsPrIndComissao:"0",
  lsPrPFatorComissao:"0",
  lsPrPDescontoMaximo:"0",
  lsPrValidAt: new Date(),
  lsPrExpireAt: new Date()
  };