import { preloadedValuesContato } from "../contato/default-values-form";
import { preloadedValuesEndereco } from "../endereco/default-values-form";
import { preloadedValuesEvento } from "../evento/default-values-form";

export const preloadedValues = {
  pessRecStatus: false,
  pessRegimeTributacao: 0,
  pessDocumento: "",
  pessRazao: "",
  pessFantasia: "",
  pessIETipo: 1,
  pessIE: "",
  pessIM: "",
  pessSUFRAMA: "",
  pessContato: preloadedValuesContato,
  pessEvento:preloadedValuesEvento,
  pessEndereco:preloadedValuesEndereco
  };