import style from "./ncm.module.scss";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IBPT } from "./ibpt/ibpt";
import { MaskedTextField } from "@fluentui/react";
import { NCMInformation } from "./ncmInformation/ncmInformation";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { IPIForm } from "./ipi/ipi.form";
import { NCMFormProps, schemaNCM } from "./form.config";
import { preloadedValues } from "./default-values-form";
import { idItemSelectedContext } from "../../../../../../../context/idItemSelected";
import { currentURlName } from "../../../../../../../utils/url.name";
import { ControllerCheckBox } from "../../../../../../../components/controllers/checkbox-controller";
import { FooterFormButtons } from "../../../../../../../components/footer-form/buttons-form";
import { Loading } from "../../../../../../../components/loading/loading";
import { TitleForm } from "../../../../../../../components/text/form/title/title";
import { endpoint } from "../../../../../../../config/environment/endpoint.config";
import { ID } from "../../../../../../../config/environment/id.config";
import { masksApp } from "../../../../../../../config/masks/masks";
import { editItemVerification } from "../../../../../../../utils/actions/editItemPost";
import { getValuesItem, create } from "../../../../../../../utils/actions/form.actions";
import { maskFormat } from "../../../../../../../config/masks/mask.format";
import { recStatusModify } from "../../../../../../../utils/rec-status.modify";
import { ControllerTextField } from "../../../../../../../components/controllers/ControllerTextField";

export const NCMForm = () => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { idItemSelected } = useContext(idItemSelectedContext);
  const currentPage = currentURlName(pathname);

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<NCMFormProps>({
    mode: "onChange",
    resolver: zodResolver(schemaNCM),
    defaultValues: preloadedValues,
  });

  const watchIPIModalidadeBC = watch("ncmsIPIModalidadeBC");
  const watchIPIAliquota = watch("ncmsIPIAliquota");
  const pageReturn = "/linko/configuracoes/NCMNVE";

  const valuesEdit = (itemValue: NCMFormProps) => {
    setValue(
      "ncmsRecStatus", recStatusModify.form(itemValue.ncmsRecStatus)
    );
    setValue("ncmsNCM", itemValue.ncmsNCM);
    setValue("ncmsCodigoEX", itemValue.ncmsCodigoEX);
    setValue("ncmsDescricao", itemValue.ncmsDescricao);
    setValue("ncmsIPIModalidadeBC", itemValue.ncmsIPIModalidadeBC);
    setValue("ncmsIPIAliquota", itemValue.ncmsIPIAliquota);

    setIsLoading(false);
    return;
  };
  useEffect(() => {
    getValuesItem({
      currentPage,
      idSelected: idItemSelected.id,
      nameMenuCurrent: "ncm",
      setIsLoading,
      url: endpoint.fiscal,
      valuesEdit,
    });
  }, []);

  const createItem = async (data: any) => {
    const idNumber = idItemSelected.id;
    const id = ID.fiscal.ncm + ":" + idNumber;

    await create({
      currentPage,
      nameMenuCurrent: "ncm",
      url: endpoint.fiscal,
      data: editItemVerification({ currentPage, data, id }),
      setIsLoading,
      idItem: idNumber,
    });
    navigate(pageReturn);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <section className={style["section"]}>
          <TitleForm title={"NCM: Nomenclatura comum do mercosul"} />

          <form className={style["section_form"]}>
            <div className={style["section_content"]}>
              <ControllerCheckBox
                name={"ncmsRecStatus"}
                control={control}
                label="Desativado"
                className={style["section_content-checkbox"]}
                recStatus={true}
              />

              <div className={style["section_content-box"]}>
                <div className={style["section_content-box-form"]}>
                  <Controller
                    name="ncmsNCM"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <MaskedTextField
                        value={value}
                        label="NCM"
                        placeholder="NCM"
                        errorMessage={errors.ncmsNCM?.message}
                        mask={masksApp.NCM}
                        maskFormat={maskFormat}
                        maskChar="_"
                        onChange={(_, value) => {
                          onChange(value);
                        }}
                      />
                    )}
                  />
                  <ControllerTextField
                    name="ncmsCodigoEX"
                    control={control}
                    label={"Código EX"}
                    placeholder="Código de Exceção da TIPI"
                    errors={errors.ncmsCodigoEX?.message}
                  />
                </div>

                <div className={style["section_content-box-form"]}>
                  <NCMInformation validoAPartir={""} expiraEm={""} />
                </div>
              </div>
              <div>
                <ControllerTextField
                  name="ncmsDescricao"
                  control={control}
                  label={"Descrição"}
                  placeholder="Descrição do NCM"
                  errors={errors.ncmsDescricao?.message}
                />
              </div>
            </div>
            <IPIForm
              control={control}
              name={"ncmsIPIAliquota"}
              register={register}
              watchIPIAliquota={watchIPIAliquota}
              watchIPIModalidadeBC={watchIPIModalidadeBC}
              errorMessage={errors}
              trigger={trigger}
            />
            <IBPT
              federais={0}
              estaduais={0}
              municipais={0}
              importacao={0}
              vigencia={""}
            />

            <FooterFormButtons
              min={false}
              btn_salvar={handleSubmit(createItem)}
              btn_cancelar={() => {
                navigate(pageReturn);
              }}
            />
          </form>
        </section>
      )}
    </>
  );
};
