import style from "../style.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { LUnidadeMedidaTempo } from "../options/options";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  UnidadesDeMedidasFormProps,
  schemaUnidadesDeMedidas,
} from "./form.config";
import { preloadedValues } from "./default-values-form";
import { ControllerCheckBox } from "../../../../../../../components/controllers/checkbox-controller";
import { ControllerComboBox } from "../../../../../../../components/controllers/combobox-controller";
import { FooterFormButtons } from "../../../../../../../components/footer-form/buttons-form";
import { Loading } from "../../../../../../../components/loading/loading";
import { TitleForm } from "../../../../../../../components/text/form/title/title";
import { endpoint } from "../../../../../../../config/environment/endpoint.config";
import { ID } from "../../../../../../../config/environment/id.config";
import { idItemSelectedContext } from "../../../../../../../context/idItemSelected";
import { editItemVerification } from "../../../../../../../utils/actions/editItemPost";
import { create, getValuesItem } from "../../../../../../../utils/actions/form.actions";
import { currentURlName } from "../../../../../../../utils/url.name";
import { recStatusModify } from "../../../../../../../utils/rec-status.modify";
import { ControllerTextField } from "../../../../../../../components/controllers/ControllerTextField";

export const UnidadeMedidaForm = () => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { idItemSelected } = useContext(idItemSelectedContext);
  const currentPage = currentURlName(pathname);
  const pageReturn = "/linko/configuracoes/unidadesMedida";

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UnidadesDeMedidasFormProps>({
    mode: "all",
    resolver: zodResolver(schemaUnidadesDeMedidas),
    defaultValues: preloadedValues,
  });

  const onSubmit: SubmitHandler<UnidadesDeMedidasFormProps> = async (data) => {
    const idNumber = idItemSelected.id;
    const id = ID.cadastro.unidadeMedida + ":" + idNumber;

    await create({
      currentPage,
      nameMenuCurrent: "unidademedida",
      url: endpoint.cadastro,
      data: editItemVerification({ currentPage, data, id }),
      setIsLoading,
      idItem: idNumber,
    });
    navigate(pageReturn);
  };

  const valuesEdit = (itemValue: UnidadesDeMedidasFormProps) => {
    setValue(
      "unidRecStatus",
      recStatusModify.form(itemValue.unidRecStatus)
    );
    setValue("unidTipo", itemValue.unidTipo);
    setValue("unidUnidade", itemValue.unidUnidade);
    setValue("unidDescricao", itemValue.unidDescricao);
  }

  useEffect(() => {
    getValuesItem({
      currentPage,
      idSelected: idItemSelected.id,
      nameMenuCurrent: "unidademedida",
      setIsLoading,
      url: endpoint.cadastro,
      valuesEdit,
    });
  }, []);
  return (
    <>
     {isLoading ? (
        <Loading />
      ) : (

    <div className={style["sectionContent"]}>
      <TitleForm title={"Unidade de medida"} />

      <div className={style["sectionContent-form"]}>
        <form>
          <ControllerCheckBox
            name="unidRecStatus"
            control={control}
            label="Desativado"
            recStatus={true}
          />

          <ControllerComboBox
            control={control}
            name="unidTipo"
            options={LUnidadeMedidaTempo}
            errors={errors.unidTipo?.message}
            className={style["inputFullWidth"]}
            label="Tipo"
          />
          <ControllerTextField
            name="unidUnidade"
            errors={errors.unidUnidade?.message}
            className={style["inputFullWidth"]}
            label="Unidade de medida"
            control={control}
          />
          <ControllerTextField
            name="unidDescricao"
            errors={errors.unidDescricao?.message}
            className={style["inputFullWidth"]}
            label="Descrição"
            control={control}
          />

          <FooterFormButtons
            min={false}
            btn_salvar={handleSubmit(onSubmit)}
            btn_cancelar={() => navigate(pageReturn)}
          />
        </form>
      </div>
    </div>
        
  )}</>)
};
