import { z } from "zod";

export const schemaCustoDespesasExtras = z
  .object({
    prPDRecStatus:z.any(),
    prPDDescricao:z.string().min(1,"Insira uma descrição"),
    prPDTipo:z.number(),
    prPDValor:z.string().min(1,"Insira um valor"),

});

export type CustoDespesasExtrasProps = z.infer<typeof schemaCustoDespesasExtras>;
