export const messagesNCMForm = {
    ncm:"Número da NCM Inválida. Deve conter 8 números",
    codigoEx:"Deve conter entre 2 e 3 números",
    descricao:"Descrição inválida",
    modalidadeDaBaseDeCalculo: "Selecione a modalidade da base de cálculo",
}

export const messagesValidationNcm = {
    inputEmpty: 'Complete o campo corretamente',
    onlyNumber: 'Apenas números',
    exInformation:'O valor deve conter entre 2-3 números',
    aliquotaInformation: 'O valor deve estar entre 0% até 100%',
    unidadeValidation: 'A unidade deve ser maior ou igual a zero.'
}