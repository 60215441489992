import { IconModify } from "../../../../../../../utils/icon-table.modify";
import { recStatusModify } from "../../../../../../../utils/rec-status.modify";


export const modifyInformationCFOP = async (dados: any) => {
  await dados.forEach((cfop: any) => {
    cfop.cfopRecStatus = recStatusModify.table(cfop.cfopRecStatus)

    switch (cfop.cfopTipo) {
      case 1:
        cfop.cfopTipo = "1 - Entrada Estadual";
        break;
      case 2:
        cfop.cfopTipo = "2 - Entrada Interestadual";
        break;
      case 3:
        cfop.cfopTipo = "3 - Importação";
        break;
      case 5:
        cfop.cfopTipo = "5 - Saída Estadual";
        break;
      case 6:
        cfop.cfopTipo = "6 - Saída Interestadual";
        break;
      case 7:
        cfop.cfopTipo = "7 - Exportação";
        break;
      default:
        cfop.cfopTipo = "";
    }
     
    cfop.cfopPermiteNFe = IconModify.valueToIcon(cfop.cfopPermiteNFe)
    cfop.cfopPermiteNFCe = IconModify.valueToIcon(cfop.cfopPermiteNFCe)
    cfop.cfopPermiteDevolucao = IconModify.valueToIcon(cfop.cfopPermiteDevolucao)
    cfop.cfopPermiteAjusteEstorno = IconModify.valueToIcon(cfop.cfopPermiteAjusteEstorno)
    cfop.cfopPermiteComunicacao = IconModify.valueToIcon(cfop.cfopPermiteComunicacao)
    cfop.cfopPermiteTransporte = IconModify.valueToIcon(cfop.cfopPermiteTransporte)
    cfop.cfopPermiteCombustivel = IconModify.valueToIcon(cfop.cfopPermiteCombustivel)
  });
  return dados;
};
