import { cnpjCpfFormat } from "../../../../../config/RegEx/regex";
import { getLocation } from "../../../../../config/location/location.search";
import { IconModify } from "../../../../../utils/icon-table.modify";
import { recStatusModify } from "../../../../../utils/rec-status.modify";

export const modifyInformation = async (dados: any) => {
  await dados.forEach(async(pess: any) => {
    pess.pessRecStatus = recStatusModify.table(pess.pessRecStatus);
    let modifyUFName:any = await getLocation.getUFById(pess.psEnUfdeId);
    let modifyCityName:any = await getLocation.getCityById(pess.psEnCidaId);

    switch (pess.pessRegimeTributacao) {
      case 0:
        pess.pessRegimeTributacao = "0 - Pessoa Física";
        break;
      case 1:
        pess.pessRegimeTributacao = "1 - Pessoa Física";
        break;
      case 2:
        pess.pessRegimeTributacao = "2 - MEI";
        break;
      case 3:
        pess.pessRegimeTributacao = "3 - Simples Nacional";
        break;
      case 4:
        pess.pessRegimeTributacao = "4 - Simples Nacional";
        break;
      case 5:
        pess.pessRegimeTributacao = "5 - Lucro Real";
        break;
      case 6:
        pess.pessRegimeTributacao = "6 - Lucro Presumido";
        break;
      case 7:
        pess.pessRegimeTributacao = "7 - Lucro Arbitrado";
        break;
      default:
        pess.pessRegimeTributacao = "";
        break;
    }
    pess.psEnCidaId = modifyCityName.cidaNome;
    pess.psEnUfdeId = modifyUFName.ufdeSigla
    pess.pessDocumento = cnpjCpfFormat(pess.pessDocumento)
  });
  return dados;
};

export const modifyInformationEnderecos = (dados: any) => {
  dados.forEach(async(pess: any) => {
    pess.psEnRecStatus = recStatusModify.table(pess.psEnRecStatus);

    let modifyCityName:any = getLocation.getCityById(pess.psEnCidaId);
    let modifyUFName:any = getLocation.getUFById(pess.psEnUfdeId);
    let modifyCountry:any = getLocation.getCountryById(pess.psEnPaisId);
    pess.psEnCidaId = modifyCityName.cidaNome;
    pess.psEnUfdeId = modifyUFName.ufdeSigla;
    pess.psEnPaisId = modifyCountry.paisNome;

    pess.psEnEnderecoCobranca =  IconModify.toTable(pess.psEnEnderecoCobranca);
    pess.psEnEnderecoEntrega =  IconModify.toTable(pess.psEnEnderecoEntrega);
    pess.psEnEnderecoPadrao =  IconModify.toTable(pess.psEnEnderecoPadrao);

  });
  return dados;
};

export const modifyInformationContatos = async (dados: any) => {
  await dados.forEach((pess: any) => {
    pess.psCtRecStatus = recStatusModify.table(pess.psCtRecStatus);
    pess.psCtPrincipal = IconModify.toTable(pess.psCtPrincipal);
    pess.psCtXMLNFe = IconModify.toTable(pess.psCtXMLNFe);
    pess.psCtXMLNFCe = IconModify.toTable(pess.psCtXMLNFCe);
    pess.psCtXMLAutorizado = IconModify.toTable(pess.psCtXMLAutorizado);
  });
  return dados;
};

export const modifyInformationEventos = async (dados: any) => {
  await dados.forEach((pess: any) => {
    pess.psEvRecStatus = recStatusModify.table(pess.psEvRecStatus);
  });
  return dados;
};
