import { z } from "zod";
import { schemaPrecoVenda } from "./preco-venda-ajustado/form.config";
import { schemaCustoDespesasExtras } from "./despesas/form.config";


export const schemaCustoVenda = z
  .object({
    prPBCustoInicial:z.string(),
    prPBCustoPIPI:z.string(),
    prPBCustoVIPI:z.string(),
    prPBCustoPFrete:z.string(),
    prPBCustoVFrete:z.string(),
    prPBCustoPFreteCTe:z.string(),
    prPBCustoVFreteCTe:z.string(),
    prPBCustoVICMSFreteCTe:z.string(),
    prPBCustoPST:z.string(),
    prPBCustoVST:z.string(),
    prPBCustoVICMS:z.string(),
    prPBCustoVPIS:z.string(),
    prPBCustoVCOFINS:z.string(),
    prPBCustoVDespesasTotais:z.string(),
    prPBCustoFinal:z.string(),
    prPBFormacaoPComissao:z.string(),
    prPBFormacaoPMargem:z.string(),
    prLPMkPVMinimo:z.string(),
    prLPMkPVFinal:z.string(),
    prLPPVMinimo:z.string(),

});

const schemaForms = z.object({
  precoVenda:schemaPrecoVenda,
  despesasExtras: z.array(schemaCustoDespesasExtras)
})

export const schemaCustoPrecoVenda = z.intersection(schemaCustoVenda,schemaForms)

export type CadastroPrecoVendaProps = z.infer<typeof schemaCustoPrecoVenda>;
