import { Calendar } from "../../../../../../../../components/input/calendar/calendar";
import style from "../../ncm.module.scss";
interface INCMForm {
  validoAPartir: string;
  expiraEm: string;
}
export const NCMInformation = ({ validoAPartir, expiraEm }: INCMForm) => {
  return (
    <>
      <Calendar
        label={"Válido a partir"}
        disabled={true}
        value={validoAPartir}
      />

      <Calendar label={"Expira em"} disabled={true} value={expiraEm} />
    </>
  );
};
