export const comprasItems = [
  { category: "Compras", key: "ordensDeCompra", name: "Ordens de compra" },
  {
    category: "Compras",
    key: "manifestacaoDestinatario",
    name: "Manifestação do destinatário",
  },
  { category: "Compras", key: "importarXML", name: "Importar XML" },
  {
    category: "Compras",
    key: "comprasServicosOutrasEntradas",
    name: "Compras,serviços e outras entradas",
  },
  {
    category: "Compras",
    key: "auditoriaCompras",
    name: "Auditoria de compras",
  },
];
