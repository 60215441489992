import style from "./style.module.scss";

import addIcon from "../../../assets/icons/geraisCadastros/add.svg";
import copyIcon from "../../../assets/icons/geraisCadastros/copy.svg";
import editIcon from "../../../assets/icons/geraisCadastros/edit.svg";
import removeIcon from "../../../assets/icons/geraisCadastros/remove.svg";

interface ButtonActionProps {
  type: "add" | "remove" | "edit" | "copy";
  onClick: () => void;
}
export const ButtonActionTable = ({ onClick, type }: ButtonActionProps) => {
  let icon = "";
  let styleName = "";

  switch (type) {
    case "add":
      icon = addIcon;
      styleName = "iconAdd";
      break;
    case "edit":
      icon = editIcon;
      styleName = "iconEdit";
      break;
    case "copy":
      icon = copyIcon;
      styleName = "iconCopy";
      break;
    case "remove":
      icon = removeIcon;
      styleName = "iconRemove";
      break;
    default:
      break;
  }

  return (
    <button className={style["icon"]} onClick={onClick}>
      <img className={style[styleName]} src={icon} alt="" />
    </button>
  );
};
