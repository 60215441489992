import { getLocation } from "../../../../../../config/location/location.search";
import { recStatusModify } from "../../../../../../utils/rec-status.modify";
import { AliquotaInterestadualFormProps } from "./form/form.config";

export const modifyInformation = async (dados: any) => {
    await dados.forEach(async(value: AliquotaInterestadualFormProps) => { 
        value.alleRecStatus = recStatusModify.table(value.alleRecStatus);
        let modifyUFOrigem:any = getLocation.getUFById(value.alIeOrigemUfdeId);
        let modifyUFDestino:any = getLocation.getUFById(value.alIeDestinoUfdeId);

        value.alIeOrigemUfdeId = modifyUFOrigem.ufdeNome +" - " +modifyUFOrigem.ufdeSigla
        value.alIeDestinoUfdeId = modifyUFDestino.ufdeNome +" - " + modifyUFDestino.ufdeSigla
        value.alIeICMSAliquotaImportados = value.alIeICMSAliquotaImportados + "%"
        value.alIeICMSAliquota = value.alIeICMSAliquota + "%"

    })}