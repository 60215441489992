import { Route, Routes } from "react-router-dom";
import { Content } from "../pages/content/content";
import { ContentApp } from "../pages/content/contentApp/contentApp";
import { Login } from "../pages/login/login";
import { CadastroPessoas } from "../pages/content/templates/cadastro/pessoas/cadastro.pessoas";
import { FormCadastroPessoas } from "../pages/content/templates/cadastro/pessoas/forms/principal/principal";
import { CadastroProdutos } from "../pages/content/templates/cadastro/produtos-servicos/cadastro.produtos";
import { FormCadastroProduto } from "../pages/content/templates/cadastro/produtos-servicos/form/principal/form.cadastro-produto";
import { CFOP } from "../pages/content/templates/fiscal/config/cfop/cfop";
import { CFOPForm } from "../pages/content/templates/fiscal/config/cfop/form/cfop.form";
import { NCMForm } from "../pages/content/templates/fiscal/config/ncm/form/ncm.form";
import { NCM } from "../pages/content/templates/fiscal/config/ncm/ncm";
import { OperacoesFiscaisForm } from "../pages/content/templates/fiscal/config/operacoes-fiscais/form/principal/principal.form";
import { OperacoesFiscais } from "../pages/content/templates/fiscal/config/operacoes-fiscais/operacoes-fiscais";
import { Configuracao } from "../pages/content/templates/configuracoes-resultados/configuracoes/configuracoes";
import { PDV } from "../pages/content/templates/pdv/pdv";
import { Resultados } from "../pages/content/templates/configuracoes-resultados/resultados/resultados";
import { FormasPagamento } from "../pages/content/templates/financeiro/config/formas-pagamento/formas-pagamento";
import { FormasPagamentoForm } from "../pages/content/templates/financeiro/config/formas-pagamento/formas-pagamento.form";
import { CentroDeCustoForm } from "../pages/content/templates/financeiro/config/centro-de-custo/centro-de-custo-form";
import { CentroDeCustoTable } from "../pages/content/templates/financeiro/config/centro-de-custo/centro-de-custo.table";
import { Bancos } from "../pages/content/templates/financeiro/config/bancos-contas/bancos";
import { BancosForm } from "../pages/content/templates/financeiro/config/bancos-contas/bancos.form";
import { CondicoesPagamento } from "../pages/content/templates/financeiro/config/condicoes-pagamento/condicoes-pagamento";
import { CondicoesDePagamentoForm } from "../pages/content/templates/financeiro/config/condicoes-pagamento/condicoes-pagamento.form";
import { Feriado } from "../pages/content/templates/financeiro/config/feriados/feriados";
import { FeriadosForm } from "../pages/content/templates/financeiro/config/feriados/feriados.form";
import { PlanoDeContasTable } from "../pages/content/templates/financeiro/config/plano-de-contas/plano-de-contas.table";
import { PlanoDeContasForm } from "../pages/content/templates/financeiro/config/plano-de-contas/plano-de-contas.form";
import { UnidadeMedida } from "../pages/content/templates/estoque/config/unidades-medida/unidade-medida";
import { UnidadeMedidaForm } from "../pages/content/templates/estoque/config/unidades-medida/form/unidade-medida.form";
import { NotFound } from "../pages/notFound/notFound";
import { ListaDePrecosForm } from "../pages/content/templates/faturamento/config/lista-precos/form/lista-precos.form";
import { ListaDePrecos } from "../pages/content/templates/faturamento/config/lista-precos/lista-precos";
import { AliquotaInterestadualForm } from "../pages/content/templates/fiscal/config/aliquotas-interestaduais-icms/form/aliquotas-interestaduais.form";
import { AliquotaInterestadual } from "../pages/content/templates/fiscal/config/aliquotas-interestaduais-icms/aliquotas-interestaduais-table";

export const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/linko/" element={<Content><ContentApp /></Content>} />
      
        <Route path="/linko/cadastros/pessoas" element={<Content><CadastroPessoas /></Content>} />
        <Route path="/linko/cadastros/pessoas/add" element={<Content>< FormCadastroPessoas /> </Content>} />
        <Route path="/linko/cadastros/pessoas/edit" element={<Content>< FormCadastroPessoas /> </Content>} />
        <Route path="/linko/cadastros/pessoas/copy" element={<Content>< FormCadastroPessoas /> </Content>} />

        <Route path="/linko/cadastros/produtosServicos" element={<Content><CadastroProdutos /></Content>} />
        <Route path="/linko/cadastros/produtosServicos/add" element={<Content>< FormCadastroProduto /> </Content>} />
        <Route path="/linko/cadastros/produtosServicos/edit" element={<Content>< FormCadastroProduto /> </Content>} />
        <Route path="/linko/cadastros/produtosServicos/copy" element={<Content>< FormCadastroProduto /> </Content>} />

        <Route path="/linko/pdv/pdv" element={<Content><PDV/></Content>}/>

        <Route path="/linko/configuracoes/cfop" element={<Content><CFOP/></Content>}/>
        <Route path="/linko/configuracoes/cfop/add" element={<Content><CFOPForm/></Content>}/>
        <Route path="/linko/configuracoes/cfop/edit" element={<Content><CFOPForm/></Content>}/>
        <Route path="/linko/configuracoes/cfop/copy" element={<Content><CFOPForm/></Content>}/>

        <Route path="/linko/configuracoes/NCMNVE" element={<Content><NCM/></Content>}/>
        <Route path="/linko/configuracoes/NCMNVE/add" element={<Content><NCMForm/></Content>}/>
        <Route path="/linko/configuracoes/NCMNVE/edit" element={<Content><NCMForm/></Content>}/>
        <Route path="/linko/configuracoes/NCMNVE/copy" element={<Content><NCMForm/></Content>}/>

        <Route path="/linko/configuracoes/operacoesFiscais" element={<Content><OperacoesFiscais/></Content>}/>
        <Route path="/linko/configuracoes/operacoesFiscais/add" element={<Content><OperacoesFiscaisForm/></Content>}/>
        <Route path="/linko/configuracoes/operacoesFiscais/edit" element={<Content><OperacoesFiscaisForm/></Content>}/>
        <Route path="/linko/configuracoes/operacoesFiscais/copy" element={<Content><OperacoesFiscaisForm/></Content>}/>

        <Route path="/linko/configuracoes/bancosContas" element={<Content><Bancos/></Content>}/>
        <Route path="/linko/configuracoes/bancosContas/add/" element={<Content><BancosForm/></Content>}/>
        <Route path="/linko/configuracoes/bancosContas/edit/" element={<Content><BancosForm/></Content>}/>
        <Route path="/linko/configuracoes/bancosContas/copy/" element={<Content><BancosForm/></Content>}/>

        <Route path="/linko/configuracoes/centroCustos" element={<Content><CentroDeCustoTable/></Content>}/>
        <Route path="/linko/configuracoes/centroCustos/add" element={<Content><CentroDeCustoForm/></Content>}/>
        <Route path="/linko/configuracoes/centroCustos/edit" element={<Content><CentroDeCustoForm/></Content>}/>
        <Route path="/linko/configuracoes/centroCustos/copy" element={<Content><CentroDeCustoForm/></Content>}/>

        <Route path="/linko/configuracoes/condicoesPagamento" element={<Content><CondicoesPagamento/></Content>}/>
        <Route path="/linko/configuracoes/condicoesPagamento/add" element={<Content><CondicoesDePagamentoForm/></Content>}/>
        <Route path="/linko/configuracoes/condicoesPagamento/edit" element={<Content><CondicoesDePagamentoForm/></Content>}/>
        <Route path="/linko/configuracoes/condicoesPagamento/copy" element={<Content><CondicoesDePagamentoForm/></Content>}/>

        <Route path="/linko/configuracoes/feriados" element={<Content><Feriado/></Content>}/>
        <Route path="/linko/configuracoes/feriados/add" element={<Content><FeriadosForm/></Content>}/>
        <Route path="/linko/configuracoes/feriados/edit" element={<Content><FeriadosForm/></Content>}/>
        <Route path="/linko/configuracoes/feriados/copy" element={<Content><FeriadosForm/></Content>}/>

        <Route path="/linko/configuracoes/formasPagamento" element={<Content><FormasPagamento/></Content>}/>
        <Route path="/linko/configuracoes/formasPagamento/add" element={<Content><FormasPagamentoForm/></Content>}/>
        <Route path="/linko/configuracoes/formasPagamento/edit" element={<Content><FormasPagamentoForm/></Content>}/>
        <Route path="/linko/configuracoes/formasPagamento/copy" element={<Content><FormasPagamentoForm/></Content>}/>
        
        <Route path="/linko/configuracoes/planoContas" element={<Content><PlanoDeContasTable/></Content>}/>
        <Route path="/linko/configuracoes/planoContas/add" element={<Content><PlanoDeContasForm/></Content>}/>
        <Route path="/linko/configuracoes/planoContas/edit" element={<Content><PlanoDeContasForm/></Content>}/>
        <Route path="/linko/configuracoes/planoContas/copy" element={<Content><PlanoDeContasForm/></Content>}/>

        <Route path="/linko/configuracoes/unidadesMedida" element={<Content><UnidadeMedida/></Content>}/>
        <Route path="/linko/configuracoes/unidadesMedida/add" element={<Content><UnidadeMedidaForm/></Content>}/>
        <Route path="/linko/configuracoes/unidadesMedida/edit" element={<Content><UnidadeMedidaForm/></Content>}/>
        <Route path="/linko/configuracoes/unidadesMedida/copy" element={<Content><UnidadeMedidaForm/></Content>}/>

        <Route path="/linko/configuracoes/listaPrecos" element={<Content><ListaDePrecos/></Content>}/>
        <Route path="/linko/configuracoes/listaPrecos/add" element={<Content><ListaDePrecosForm/></Content>}/>
        <Route path="/linko/configuracoes/listaPrecos/edit" element={<Content><ListaDePrecosForm/></Content>}/>
        <Route path="/linko/configuracoes/listaPrecos/copy" element={<Content><ListaDePrecosForm/></Content>}/>

        <Route path="/linko/configuracoes/aliquotasInterestaduaisICMS" element={<Content><AliquotaInterestadual/></Content>}/>
        <Route path="/linko/configuracoes/aliquotasInterestaduaisICMS/add" element={<Content><AliquotaInterestadualForm/></Content>}/>
        <Route path="/linko/configuracoes/aliquotasInterestaduaisICMS/edit" element={<Content><AliquotaInterestadualForm/></Content>}/>
        <Route path="/linko/configuracoes/aliquotasInterestaduaisICMS/copy" element={<Content><AliquotaInterestadualForm/></Content>}/>

        <Route path="/linko/configuracoes/configuracoes" element={<Content><Configuracao/></Content>}/>
        <Route path="/linko/resultados/resultados" element={<Content><Resultados/></Content>}/>
        <Route path='*' element={<NotFound/>}/>
      </Routes>

    </>
  );
};

