import { z } from "zod";
import { validationCNPJ, validationCPF } from "../../../../../../../utils/validation-document";

export const schemaCadastroPessoaContato = z
  .object({
    psCtRecStatus: z.any(),
    psCtPrincipal: z.boolean(),
    psCtXMLNFCe: z.boolean(),
    psCtXMLNFe: z.boolean(),
    psCtXMLAutorizado: z.boolean(),
    psCtNome: z.string().min(1,{message:"Complete o campo"}),
    psCtCPF: z.string(),
    psCtCNPJ: z.string(),
    psCtEmail: z.string().min(1,{message:"Complete o campo"}).email({message:"e-mail incorreto"}).transform((val) => val.split("@")[1]),
    psCtCelular: z.string(),
    psCtTelefone: z.string(),
  }).superRefine((val,ctx) => {
    if(val.psCtXMLAutorizado){
      if(!val.psCtCPF && !val.psCtCNPJ){
      ctx.addIssue({
        code:z.ZodIssueCode.custom,
        message:"Informe o CPF ou CNPJ",
        path:["psCtCPF"]
      })

      ctx.addIssue({
        code:z.ZodIssueCode.custom,
        message:"Informe o CPF ou CNPJ",
        path:["psCtCNPJ"]
      })}
      if(val.psCtCPF.length > 1){
        if(!validationCPF(val.psCtCPF)){
          ctx.addIssue({
            code:z.ZodIssueCode.custom,
            message:"CPF Incorreto",
            path:["psCtCPF"]
          })}
        }
      }
      if(val.psCtCNPJ.length > 1 ){
        if(!validationCNPJ(val.psCtCNPJ)){
        ctx.addIssue({
          code:z.ZodIssueCode.custom,
          message:"CNPJ Incorreto",
          path:["psCtCNPJ"]
        })}
  }})
  
  

  export type CadastroPessoaContatoFormProps = z.infer<typeof schemaCadastroPessoaContato>;
