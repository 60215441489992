import { useForm } from "react-hook-form";
import { ControllerCheckBox } from "../../../../../../../components/controllers/checkbox-controller";
import { ControllerComboBox } from "../../../../../../../components/controllers/combobox-controller";
import { FooterFormButtons } from "../../../../../../../components/footer-form/buttons-form";
import { FormaCondicaoFormProps, schemaFormaCondicao } from "./form.config";
import { zodResolver } from "@hookform/resolvers/zod";
import { preloadedValues } from "./default-values-form";
import { useContext, useEffect, useState } from "react";
import { ActionButtonTable } from "../../../../../../../context/actionButtonTable";
import { idItemSelectedContext } from "../../../../../../../context/idItemSelected";
import { uuid } from "../../../../cadastro/pessoas/forms/create.informations";
import { Loading } from "../../../../../../../components/loading/loading";
import style from "./style.module.scss";
type PropsForm = {
  setShowForm: (value: boolean) => void;
  arrayWithValuesForm: any;
  setArrayWithValuesForm: any;
  options: any;
};
export const FormaCondicaoForm = ({
  arrayWithValuesForm,
  setArrayWithValuesForm,
  options,
  setShowForm,
}: PropsForm) => {
  const { actionButton } = useContext(ActionButtonTable);
  const { idItemSelected } = useContext(idItemSelectedContext);
  const [loading, setIsLoading] = useState(false);
  const actionForm = actionButton === "edit" || actionButton === "copy";

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormaCondicaoFormProps>({
    mode: "all",
    resolver: zodResolver(schemaFormaCondicao),
    defaultValues: preloadedValues,
  });

  let searchId: FormaCondicaoFormProps = arrayWithValuesForm.find(
    (value: any) => value.id === idItemSelected.id
  );

  const editValues = (value: FormaCondicaoFormProps) => {
    setValue("cnFmRecStatus", value.cnFmRecStatus);
    setValue("cnFmCnPgId", value.cnFmCnPgId);

    setIsLoading(false);
  };

  const submit = async (data: FormaCondicaoFormProps) => {
    setIsLoading(true);
    if (actionForm) {
      searchId.cnFmCnPgId = getValues("cnFmCnPgId");
      searchId.cnFmRecStatus = getValues("cnFmRecStatus");

      setShowForm(false);
      setIsLoading(false);

      return;
    }

    await setArrayWithValuesForm([
      { id: uuid(), ...data },
      ...arrayWithValuesForm,
    ]);
    setShowForm(false);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (actionForm) {
      editValues(searchId);
      return;
    }
    setIsLoading(false);
  }, []);
  return (
    <>
      {loading && <Loading />}
      <section className={style["content"]}>
        <form>
          <ControllerCheckBox
            label="Desativado"
            name="cnFmRecStatus"
            control={control}
            recStatus={true}
          />
          <ControllerComboBox
            label="Forma de pagamento"
            name="cnFmCnPgId"
            options={options}
            control={control}
            errors={errors.cnFmCnPgId?.message}
          />
          <FooterFormButtons
            btn_cancelar={() => setShowForm(false)}
            btn_salvar={handleSubmit(submit)}
            min={true}
          />
        </form>
      </section>
    </>
  );
};
