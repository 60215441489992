import { RegEx, moneyFormatValue, percentFormatValue } from "../../../../../../../../config/RegEx/regex";

export const calcCustoFinal = (getValues: any, setValue: any, setColorCalc: any) => {
  const convertForNumber = (name: string) => {
    let result = Number(getValues(name).replace(RegEx.onlyNumber, ""))
    return result
  }
  const custoInicial: number = convertForNumber("prPBCustoInicial")
  let x: number =
    convertForNumber("prPBCustoInicial") +
    convertForNumber("prPBCustoVIPI") +
    convertForNumber("prPBCustoVFrete") +
    convertForNumber("prPBCustoVFreteCTe")
  let y: number =
    convertForNumber("prPBCustoVDespesasTotais") -
    convertForNumber("prPBCustoVST") -
    convertForNumber("prPBCustoVICMSFreteCTe") -
    (convertForNumber("prPBCustoVICMS") / 100) * custoInicial -
    (convertForNumber("prPBCustoVPIS") / 100) * custoInicial -
    (convertForNumber("prPBCustoVCOFINS") / 100) * custoInicial
  setColorCalc(x < Math.abs(y))
  setValue("prPBCustoFinal", moneyFormatValue(String(x + y)));
  return;
};

export const calcPercent = (custoInicial: string, valor: string) => {
  let inicial = custoInicial.replace(RegEx.onlyNumber, "")
  let value = valor.replace(RegEx.onlyNumber, "")
  let result = (Number(value) * 100) / Number(inicial)
  return percentFormatValue(String(result))
};

export const calcValue = (custoInicial: string, valor: string) => {
  let inicial = custoInicial.replace(RegEx.onlyNumber, "")
  let value = valor.replace(RegEx.onlyNumber, "")
  let result = value.length > 2 ? Number(inicial) * Number(value) / 1000 : Number(inicial) * Number(value) / 100
  return moneyFormatValue(String(result));
};
