type PropsRegimeTributacao = {
    watchRegimeTributacao: any;
    setMaskDocumento: any;
}

type PropsIETipo = {
    watchIETipo: any;
    setValue: any;
    setReadOnlyPessIE:any
}
export const regimeDeTributacaoRule = ({ setMaskDocumento, watchRegimeTributacao }: PropsRegimeTributacao) => {

    if (!watchRegimeTributacao) {
        setMaskDocumento("***.***.***-**")
        return
    };
    if (watchRegimeTributacao === 1) {
        setMaskDocumento("")
        return
    }
    if (watchRegimeTributacao > 1) {
        setMaskDocumento("**.***.***/****-**");
        return
    }
}

export const IETipo = ({setValue,watchIETipo,setReadOnlyPessIE}:PropsIETipo)=> {
    if (!watchIETipo) {
        setReadOnlyPessIE(false)
        setValue("pessIE","")
        return
    };
    if (watchIETipo === 1) {
        setValue("pessIE","N/C")
        setReadOnlyPessIE(true)
        return
    }
    if (watchIETipo > 1) {
        setValue("pessIE","Isento")
        setReadOnlyPessIE(true)
        return
    }
}