import { createTheme } from "@fluentui/react";

export const MyTheme = createTheme({
    defaultFontStyle:{
      fontFamily:"Roboto, sans-serif"
    },
    palette: {
      themePrimary: '#edd300',
      themeLighterAlt: '#fefdf4',
      themeLighter: '#fcf7d4',
      themeLight: '#faf1af',
      themeTertiary: '#f4e362',
      themeSecondary: '#efd71d',
      themeDarkAlt: '#d5bd00',
      themeDark: '#b49f00',
      themeDarker: '#857500',
      neutralLighterAlt: '#f8f8f8',
      neutralLighter: '#f4f4f4',
      neutralLight: '#eaeaea',
      neutralQuaternaryAlt: '#dadada',
      neutralQuaternary: '#d0d0d0',
      neutralTertiaryAlt: '#c8c8c8',
      neutralTertiary: '#141414',
      neutralSecondary: '#282828',
      neutralSecondaryAlt: '#aaaccc',
      neutralPrimaryAlt: '#3a3a3a',
      neutralPrimary: '#424242',
      neutralDark: '#707070',
      black: '#959595',
      white: '#ffffff',
   
    }});