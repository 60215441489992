
import { getLocation } from "../../../../../../../config/location/location.search";
import { IconModify } from "../../../../../../../utils/icon-table.modify";
import { recStatusModify } from "../../../../../../../utils/rec-status.modify";
import { LProdIndicador } from "../options/lista-precos.options";

export const modifyListaPrecosTable = async (dados: any) => {
  await dados.map((value: any) => {
    let modifyUFName:any = getLocation.getUFById(value.lsPrUfdeId);
    let indicador:any = LProdIndicador.find(item => item.key === value.lsPrIndComissao)
    value.lsPrRecStatus = recStatusModify.table(value.lsPrRecStatus)
    value.lsPrUfdeId = modifyUFName?.ufdeSigla
    value.lsPrIndComissao = indicador.text
    value.lsPrPDescontoMaximo = `${value.lsPrPDescontoMaximo} %`
    value.lsPrAtualizacaoAutomatica = IconModify.valueToIcon(value.lsPrAtualizacaoAutomatica)
    value.lsPrPadrao = IconModify.valueToIcon(value.lsPrPadrao)
    value.lsPrPFatorComissao = `${value.lsPrPFatorComissao} %`
    value.lsPrPCustoFixo = `${value.lsPrPCustoFixo} %`

});
return dados;
};

export const modifyListaPrecosApi = async (dados: any) => {
  await dados.map((value: any) => {
   // let modifyUF = getLocation.getUFByName(value);
    let indicador:any = LProdIndicador.find(item => item.text === value.lsPrIndComissao)

    value.unidRecStatus = recStatusModify.api(value.unidRecStatus);
    
  });
  return dados;
};
