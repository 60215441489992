import { useState } from "react";
import { IConstructTable, ConstructTable } from "../../../../../../components/construct/construct-table/construct-table";
import { endpoint } from "../../../../../../config/environment/endpoint.config";
import { ID } from "../../../../../../config/environment/id.config";
import { columns } from "./columns/aliquota-interestadual.column";
import { modifyInformation } from "./modify-information";

export const AliquotaInterestadual = (data: any) => {
  const [isLoading,setIsLoading] = useState(false);
  const Informations: IConstructTable = {
    endpoint: endpoint.fiscal,
    idNameTable: ID.fiscal.aliquotaInterestadual,
    nameTable: "Alíquotas interestaduais de ICMS",
    columnsTable: columns,
    nameMenu: "fiscal",
    nameSubmenu: "aliquotaInterestadual",
    modifyData: modifyInformation,
    isLoading,setIsLoading
  };
  return <ConstructTable {...Informations} />;
}
