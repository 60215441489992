export const resultFinanceiro = [
  {
    category: "resultados",
    key: "relatorioContasReceber",
    name: "Relatório de contas a receber",
  },
  {
    category: "resultados",
    key: "relatorioContasPagar",
    name: "Relatorio de contas a pagar",
  },
  {
    category: "resultados",
    key: "fluxoCaixa",
    name: "Fluxo de caixa",
  },
  {
    category: "resultados",
    key: "dre",
    name: "DRE",
  },
];
