import iconCadastro from "../../../assets/icons/menu/cadastro.svg";
import iconCompras from "../../../assets/icons/menu/compras.svg";
import iconConfiguracoes from "../../../assets/icons/menu/configuracoes.svg";
import iconEstoque from "../../../assets/icons/menu/estoque.svg";
import iconFaturamento from "../../../assets/icons/menu/faturamento.svg";
import iconFinanceiro from "../../../assets/icons/menu/financeiro.svg";
import iconResultados from "../../../assets/icons/menu/resultados.svg";
import iconPreVendas from "../../../assets/icons/menu/preVendas.svg";
import iconPdv from "../../../assets/icons/menu/pdv.svg";
import iconFiscal from "../../../assets/icons/menu/fiscal.svg";
import style from "./style.module.scss";
import { cadastroItems } from "./list/cadastros";
import { comprasItems } from "./list/compras";
import { estoqueItems } from "./list/estoque";
import { faturamentoItems } from "./list/faturamento";
import { financeiroItems } from "./list/financeiro.nav";
import { fiscalItems } from "./list/fiscal";
import { pdvItems } from "./list/pdv.nav";
import { preVendasItems } from "./list/pre-vendas.nav";
import { configuracaoItems } from "./list/configuracoes/configuracoes";
import { resultadoItems } from "./list/resultados/resultados";
import { ButtonNotOptions } from "../../buttons/buttons-create-tabs/button-nav-not-options";
import { ButtonNavOptions } from "../../buttons/buttons-create-tabs/button-nav-options";
type Props = {
  isClosed: boolean;
};

export const ButtonsNav = ({ isClosed }: Props) => {
  return (
    <nav className={style["nav"]}>
      <ButtonNavOptions
        icon={iconCompras}
        menu={comprasItems}
        isClose={isClosed}
      />
      <ButtonNotOptions
        icon={iconPreVendas}
        isClose={isClosed}
        props={preVendasItems}
      />
      <ButtonNavOptions
        icon={iconFaturamento}
        menu={faturamentoItems}
        isClose={isClosed}
      />
      <ButtonNotOptions icon={iconPdv} isClose={isClosed} props={pdvItems} />
      <ButtonNavOptions
        icon={iconFinanceiro}
        menu={financeiroItems}
        isClose={isClosed}
      />
      <ButtonNavOptions
        icon={iconEstoque}
        menu={estoqueItems}
        isClose={isClosed}
      />
      <ButtonNavOptions
        icon={iconFiscal}
        menu={fiscalItems}
        isClose={isClosed}
      />
      <ButtonNavOptions
        icon={iconCadastro}
        menu={cadastroItems}
        isClose={isClosed}
      />

      <ButtonNotOptions
        icon={iconConfiguracoes}
        isClose={isClosed}
        props={configuracaoItems}
      />
      <ButtonNotOptions
        icon={iconResultados}
        isClose={isClosed}
        props={resultadoItems}
      />
    </nav>
  );
};
