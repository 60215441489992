import { z } from "zod";

export const schemaICMS = z.object({
  operICMSCST: z.string({required_error:"Informe a CST para cálculo do ICMS"}).trim().min(1),
  operICMSCSOSN: z.string({required_error:"Informe a CSOSN do ICMS para contribuintes do Simples Nacional"}).trim().min(1),
  operICMSModalidadeBC: z.string({required_error:"Informe a modalidade da Base de Cálculo do ICMS"}).trim().min(1),
  operICMSDesoneracaoMotivo: z.string({required_error:"Informe o motivo de desoneração do ICMS"}).trim().min(1),
  operICMSDesoneracaoFormulaBC: z.string({required_error:"Informe como será a fórmula de cálculo da desoneração"}).trim().min(1),
  operICMSReducaoBC: z.string({required_error:  "Informe um percentual entre 0% e 100%"}).trim().min(1),
  operICMSDiferimentoBC:z.string({required_error:  "Informe um percentual entre 0% e 100%"}).trim().min(1),
  operUfdeId: z.string().trim().min(1),
  operICMSAliquotaInternaUF: z.string({required_error:"Informe a alíquota interna do ICMS na UF destino"}).trim().min(1),
  operICMSCodigoBeneficioFiscal: z.string().optional(),
  operICMSModalidadeBCST: z.string({required_error:"Informe a modalidade da Base de Cálculo do ICMS ST"}).trim().min(1),
  operICMSSTMVA:z.string({required_error:"Informe a MVApara cálculo do ICMS ST"}).trim().min(1)
}).superRefine((value, ctx) => {
  if (Number(value.operICMSReducaoBC) > 0 && !value.operICMSDiferimentoBC) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["operICMSDiferimentoBC"],
      message: "Informe um percentual entre 0% e 100%",
    })
  }
})

  .superRefine((value, ctx) => {
    let result = Number(value.operICMSReducaoBC + value.operICMSDiferimentoBC)
    const rule = result == 0 || result == 100
    if (!rule) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["operICMSReducaoBC"],
        message: "A soma da redução + diferimento do ICMS deve ser 0% ou 100%. Verifiquei as informações digitadas."
      })
    }
  })

export type ICMSFormProps = z.infer<
  typeof schemaICMS
>;
