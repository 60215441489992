import { z } from "zod";

export const schemaOperacoesFiscais = z.object({
  operRecStatus: z.any(),
  operDescricao: z.string(),
  operCfopId: z.string(),
  operAjusteCfopId: z.string(),
  operDevolucaoCfopId: z.string(),
  operCnCtId: z.string(),
  operPlCtId: z.string(),
  operUfdeId: z.string(),
 
  operIPICST: z.string(),
  operIPIEnquandramento: z.string(),
  operIPIAcumulaBCICMSProprio: z.boolean(),
  operIPIAcumulaBCICMSDestino: z.boolean(),

  operICMSCST: z.string(),
  operICMSCSOSN: z.string(),
  operICMSModalidadeBC: z.string(),
  operICMSDesoneracaoMotivo: z.string(),
  operICMSDesoneracaoFormulaBC: z.string(),
  operICMSReducaoBC: z.string(),
  operICMSDiferimentoBC: z.string(),
 // operUfdeId: z.string(),
  operICMSAliquotaInternaUF: z.string(),
  operICMSCodigoBeneficioFiscal: z.string(),
  operICMSModalidadeBCST: z.string(),
  operICMSSTMVA: z.string(),

  operPISSCST: z.string(),
  operPISDeduzICMS: z.boolean(),
  operCOFINSCST: z.string(),
  operCOFINSDeduzICMS: z.boolean(),

  operIndicadorPresenca: z.string(),
  operConsumidorFinal: z.string(),
  operIntermediador: z.string(),
  operIntermediadorCNPJ: z.string(),
  operIntermediadorID: z.string(),
  
  opIA: z.array(z.object({
    opIARecStatus: z.any(),
    opIADescricao: z.string(),
    opIAAliquota: z.string(),
    opIADeduzTotalDFe: z.boolean(),
    opIADadosAdicionaisGerar: z.boolean(),
    opIADadosAdicionaisTexto: z.string(),
  })),
});

export type OperacoesFiscaisProps = z.infer<typeof schemaOperacoesFiscais>;

