
import { modifyInformationNCM } from "../ncm/modifyInformation/modifyInformation";
import { IConstructTable, ConstructTable } from "../../../../../../components/construct/construct-table/construct-table";
import { endpoint } from "../../../../../../config/environment/endpoint.config";
import { ID } from "../../../../../../config/environment/id.config";
import { useState } from "react";
import { columnsOperacoesFiscais } from "./columns/operacoes-fiscais.column";

export const OperacoesFiscais = () => {
  const [isLoading,setIsLoading] = useState(false);
  
  const Informations: IConstructTable = {
    endpoint: endpoint.fiscal,
    idNameTable: ID.fiscal.operacoesFiscais,
    nameTable: "Operações Fiscais",
    columnsTable: columnsOperacoesFiscais,
    nameMenu: "fiscal",
    nameSubmenu: "operacoesFiscais",
    modifyData: modifyInformationNCM,
    isLoading,setIsLoading
  };
  return <ConstructTable {...Informations} />;
};
