export const ID = {
  fiscal: {
    ncm: process.env.REACT_APP_NCM_ID as string,
    cfop: process.env.REACT_APP_CFOP_ID as string,
    operacoesFiscais: process.env.REACT_APP_OPF_ID as string,
    operacaoFiscalImpostoAdicional: process.env.REACT_APP_OPFIA_ID as string,
    aliquotaInterestadual: process.env.REACT_APP_AIE_ID as string,
  },

  cadastro: {
    produto: process.env.REACT_APP_PROD_ID as string,
    pessoa: process.env.REACT_APP_PESS_ID as string,
    pessoaContato: process.env.REACT_APP_PESS_CONT_ID as string,
    pessoaEndereco: process.env.REACT_APP_PESS_END_ID as string,
    pessoaEvento: process.env.REACT_APP_PESS_EN_ID as string,
    unidadeMedida: process.env.REACT_APP_UM_ID as string,
    centroDeCusto: process.env.REACT_APP_CT_ID as string,
    planoContas: process.env.REACT_APP_PLCT_ID as string,
    operacoesFiscas: process.env.REACT_APP_OF_ID as string,
    country:"paisId",
    uf: "ufdeId",
    city:"cidaId",
    despesasExtras: process.env.REACT_APP_PD_ID as string,
  },
  
  financeiro: {
    feriado: process.env.REACT_APP_HDAY_ID as string,
    banco: process.env.REACT_APP_BCOS_ID as string,
    bancoConta: process.env.REACT_APP_BCCT_ID as string,
    condicaoPagamento: process.env.REACT_APP_CNPG_ID as string,
    formaCondicao: process.env.REACT_APP_CNFM_ID as string,
    formasPagamento: process.env.REACT_APP_FMPG_ID as string,
  },

  faturamento: {
    listaPreco: process.env.REACT_APP_LP_ID as string
  }
};
