import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ControllerCheckBox } from "../../../../../../components/controllers/checkbox-controller";
import { preloadedValues } from "./default-values-form";
import {
  FormasDePagamentoFormProps,
  schemaFormasDePagamento,
} from "./form.config";
import { ControllerComboBox } from "../../../../../../components/controllers/combobox-controller";
import { SubtitleForm } from "../../../../../../components/text/form/subtitle/subtitle";
import { Expander } from "../../../../../../components/expander/expander";
import { FooterFormButtons } from "../../../../../../components/footer-form/buttons-form";
import { EDfFincTBand, EDfFincTPag, EDfFincTpIntegra } from "./options";
import { TitleForm } from "../../../../../../components/text/form/title/title";
import { useContext, useEffect, useState } from "react";
import { FormaCondicao } from "./condicoes-de-pagamento/forma-condicao";
import { useNavigate, useLocation } from "react-router-dom";
import { idItemSelectedContext } from "../../../../../../context/idItemSelected";
import { currentURlName } from "../../../../../../utils/url.name";
import { endpoint } from "../../../../../../config/environment/endpoint.config";
import { ID } from "../../../../../../config/environment/id.config";
import { editItemVerification } from "../../../../../../utils/actions/editItemPost";
import {
  getValuesItem,
  create,
} from "../../../../../../utils/actions/form.actions";
import style from "./style.module.scss";
import { apiApp } from "../../../../../../services/api/app.api";
import { Loading } from "../../../../../../components/loading/loading";
import { masksApp } from "../../../../../../config/masks/masks";
import { recStatusModify } from "../../../../../../utils/rec-status.modify";
import { ControllerTextField } from "../../../../../../components/controllers/ControllerTextField";

export const FormasPagamentoForm = () => {
  const [arrayWithValuesForm, setArrayWithValuesForm] = useState<any>([]);
  const [showForm, setShowForm] = useState(false);
  let navigate = useNavigate();
  const [statusForm, setStatusForm] = useState(false);
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { idItemSelected } = useContext(idItemSelectedContext);
  const [optionsBancos, setOptionsBancos] = useState<any>([]);
  const [optionsCondicoesPagamentos, setOptionsCondicoesPagamentos] =
    useState<any>([]);
  const currentPage = currentURlName(pathname);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormasDePagamentoFormProps>({
    mode: "all",
    resolver: zodResolver(schemaFormasDePagamento),
    defaultValues: preloadedValues,
  });
  const pageReturn = "/linko/configuracoes/formasPagamento";

  const watchBandeira = watch("fmPgTBand");
  const watchTipoPagamento = watch("fmPgTPag");
  const tipoPagamento =
    watchTipoPagamento === "03" ||
    watchTipoPagamento === "04" ||
    watchTipoPagamento === "10" ||
    watchTipoPagamento === "11" ||
    watchTipoPagamento === "13";

  useEffect(() => {
    tipoPagamento ? setValue("fmPgTBand", "00") : setValue("fmPgTBand", "");
  }, [watchTipoPagamento]);

  let bandeiraIcon: any = EDfFincTBand.find(
    (value: any) => value.key === watchBandeira
  );

  let tipoDePagamentoIcon: any = EDfFincTPag.find(
    (value: any) => value.key === watchTipoPagamento
  );

  const valuesEdit = (itemValue: FormasDePagamentoFormProps) => {
    setArrayWithValuesForm(itemValue.fmFc);
    setValue(
      "fmPgRecStatus",
      recStatusModify.form(itemValue.fmPgRecStatus)
    );
    setValue("fmPgAutorizadorTEF", itemValue.fmPgAutorizadorTEF);
    setValue("fmPgBcCtId", itemValue.fmPgBcCtId);
    setValue("fmPgCNPJCredenciadora", itemValue.fmPgCNPJCredenciadora);
    setValue("fmPgCodCredenciadora", itemValue.fmPgCodCredenciadora);

    setValue("fmPgDescricao", itemValue.fmPgDescricao);
    setValue("fmPgTBand", itemValue.fmPgTBand);
    setValue("fmPgTPag", itemValue.fmPgTPag);
    setValue("fmPgTpIntegra", itemValue.fmPgTpIntegra);
    setValue("fmPgTpIntegraCNPJ", itemValue.fmPgTpIntegraCNPJ);

    setIsLoading(false);
    return;
  };

  const optionsImport = async () => {
    const itemsBancos: any = await apiApp.getAll(endpoint.financeiro, "banco");
    const itemsPagamentos: any = await apiApp.getAll(
      endpoint.financeiro,
      "condicaoPagamento"
    );
    await itemsPagamentos.data.map((value: any) => {
      const data: any = {
        key: value.id,
        text: value.cnPgDescricao,
      };
      setOptionsCondicoesPagamentos([...optionsCondicoesPagamentos, data]);
    });
    await itemsBancos.data.map((value: any) => {
      let nameBanco = value.bcosNome;
      let options: any = [];
      value.bcCtBanco.map((result: any) => {
        let data: any = {
          key: value.id,
          cnPgDescricao: value.cnPgDescricao,

          text: `${nameBanco} - ${result.bcCtAgencia} - ${result.bcCtConta}`,
        };
        options.push(data);
      });
      setOptionsBancos([...optionsBancos, ...options]);
      options = [];
    });

    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getValuesItem({
      currentPage,
      idSelected: idItemSelected.id,
      nameMenuCurrent: "formasPagamento",
      setIsLoading,
      url: endpoint.financeiro,
      valuesEdit,
    });

    optionsImport();
  }, []);

  const submit = async (data: FormasDePagamentoFormProps) => {
    const idNumber = idItemSelected.id;
    const id = ID.financeiro.banco + ":" + idNumber;
    await create({
      currentPage,
      nameMenuCurrent: "formasPagamento",
      url: endpoint.financeiro,
      data: editItemVerification({ currentPage, data, id }),
      setIsLoading,
      idItem: idNumber,
    });
    navigate(pageReturn);
  };
  useEffect(() => {
    setValue("fmFc", [...arrayWithValuesForm]);
    const watchFmFc = watch("fmFc");
    watchFmFc.length > 0 && setStatusForm(true)
  }, [arrayWithValuesForm.length]);
  const bandeira = bandeiraIcon?.icon ? bandeiraIcon.icon : EDfFincTBand[0].key;
  return (
    <section className={style["content"]}>
      {isLoading && <Loading/>}
      <TitleForm title="Formas de pagamento" />
      <form>
        <header className={style["content_header"]}>
          <ControllerCheckBox
            label={"Desativado"}
            name={"fmPgRecStatus"}
            control={control}
            recStatus={true}
          />
          <div className={style["content_header-img"]}>
            <img
              src={tipoPagamento ? bandeira : tipoDePagamentoIcon.icon}
              alt=""
            />
          </div>
        </header>
        <ControllerTextField
          label={"Descrição"}
          name={"fmPgDescricao"}
          control={control}
          placeholder="Descrição da forma de pagamento"
          errors={errors.fmPgDescricao?.message}
        />
        <ControllerComboBox
          label={"Conta bancária associada"}
          options={optionsBancos}
          name={"fmPgBcCtId"}
          control={control}
          placeholder="Conta bancária associada"
          errors={errors.fmPgBcCtId?.message}
        />
        <ControllerComboBox
          label={"Tipo de pagamento"}
          options={EDfFincTPag}
          name={"fmPgTPag"}
          control={control}
          placeholder="Tipo de pagamento"
          errors={errors.fmPgTPag?.message}
        />
        <ControllerComboBox
          label={"Tipo de integração"}
          options={EDfFincTpIntegra}
          name={"fmPgTpIntegra"}
          control={control}
          placeholder="Tipo de integração"
          errors={errors.fmPgTpIntegra?.message}
        />
        <SubtitleForm subtitle="Dados de credenciadora" />
        <div className={style["content_row-form"]}>
          <ControllerTextField
            label={"CNPJ"}
            name={"fmPgCNPJCredenciadora"}
            control={control}
            placeholder="CNPJ da credenciadora do cartão do débito ou crédito"
            errors={errors.fmPgCNPJCredenciadora?.message}
            mask={masksApp.CNPJ}
          />
          <ControllerTextField
            label={"Código"}
            name={"fmPgCodCredenciadora"}
            control={control}
            placeholder="Código de credenciadora do cartão de débito ou crédito"
            errors={errors.fmPgCodCredenciadora?.message}
          />
          <ControllerTextField
            label={"Autorizador TEF"}
            name={"fmPgAutorizadorTEF"}
            control={control}
            placeholder="Autorizador TEF"
            errors={errors.fmPgAutorizadorTEF?.message}
          />
        </div>
        <ControllerComboBox
          label={"Bandeira"}
          options={EDfFincTBand}
          name={"fmPgTBand"}
          control={control}
          disabled={!tipoPagamento}
          placeholder="Bandeira do cartão de débito/crédito"
          errors={errors.fmPgTBand?.message}
        />
        <Expander
          content={
            <FormaCondicao
              arrayWithValuesForm={arrayWithValuesForm}
              setArrayWithValuesForm={setArrayWithValuesForm}
              options={optionsCondicoesPagamentos}
            />
          }
          name="Condições de pagamento"
          setValue={setShowForm}
          statusForm={statusForm}
          value={showForm}
          withIcon={true}
        />
        <FooterFormButtons
          btn_cancelar={() => navigate(pageReturn)}
          btn_salvar={handleSubmit(submit)}
          min={false}
        />
      </form>
    </section>
  );
};
