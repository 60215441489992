import style from "./style.module.scss";

import { HeaderContentApp } from "./contentApp/header/headerContent";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "../../components/menu/menu";
import { Loading } from "../../components/loading/loading";
import { CenterValuesContext } from "../../context/center-values";
import { endpoint } from "../../config/environment/endpoint.config";
import { GetAllLocalStart, Store } from "./local";

type contentProps = {
  children: any;
};

export const Content = ({ children }: contentProps) => {
  const navigate = useNavigate();
  const [isCloseMenu, setIsCloseMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setCenterValues } = useContext(CenterValuesContext)

  const getValues = async () => {
    await Store(endpoint.fiscal, "cfop")
    await Store(endpoint.cadastro, "centroDeCustos")
    await Store(endpoint.financeiro, "planoContas")
    await Store(endpoint.cadastro, "pais")
    await Store(endpoint.cadastro, "uf")
    await Store(endpoint.cadastro, "cidade")
    await Store(endpoint.cadastro, "unidademedida")
    localStorage.setItem("empresa", JSON.stringify({name:"CR Sistemas e Web",regimeTributacao: 5}))
    GetAllLocalStart(setCenterValues)
  }

  useEffect(() => {
    const token = localStorage.getItem("linko_token");

    if (!token) {
      setIsLoading(true);

      navigate("/");
      setIsLoading(false);
      return;
    }
    setIsLoading(false);  
    getValues()
    return;
  }, []);

  return (
    <div
      className={style[isCloseMenu ? "principal_page-min" : "principal_page"]}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className={style["principal_page-menu"]}>
            <Menu isCloseMenu={isCloseMenu} setIsCloseMenu={setIsCloseMenu} />
          </div>

          <section className={style["principal_page-section"]}>
            <HeaderContentApp />
            {children}
          </section>
        </>
      )}
    </div>
  );
};
