import style from './style.module.scss';

type props = {
  subtitle:string
}
export const SubtitleForm = ({subtitle}:props) => {
  return (
    <div className={style["title_box"]} data-testid="test-subtitle-form">
      <span className={style["title_box-title"] }>{subtitle}</span>
    </div>
  );
};

