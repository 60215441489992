import olhoLinko from "../../../assets/OLHO_LINKO.png";
import fechar from "../../../assets/icons/menu/arrowBack.svg";
import abrir from "../../../assets/icons/menu/arrowForward.svg";

import style from "./style.module.scss"
type IHeaderNav = {
  isClosed: boolean;
  setIsClosed: (value:boolean) => void;
};

export const HeaderNav = ({ isClosed,setIsClosed }: IHeaderNav) => {
  return (
    <header className={style["header"]}>
      <div className={style["header-icon"]}>
        <img src={isClosed ? abrir : fechar} alt="" onClick={() => setIsClosed(!isClosed)} />
      </div>
      <img
        src={olhoLinko}
        alt=""
        className={style[isClosed ? "header_img-close" : "header_img-open"]}
      />

      {isClosed ? (
        <></>
      ) : (
        <div className={style["header_content"]}>
          <span className={style["header_content-name"]}>CR SISTEMAS E WEB </span>
          <span className={style["header_content-title"]}>Simples Nacional </span>
        </div>
      )}
    </header>
  );
};
