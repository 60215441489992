import { z } from "zod";

//TODO:Validar CNPJ
export const schemaXML = z.object({
  operConsumidorFinal: z.string({ required_error: "Indique se a operação é com o consumidor final" }).trim().min(1,"Complete o campo corretamente"),
  operIndicadorPresenca: z.string({ required_error: "Informe o indicador de presença do comprador no estabelecimento comercial no momento da operação" }).trim().min(1,"Complete o campo corretamente"),
  operIntermediador: z.string().optional(),
  operIntermediadorCNPJ: z.string().optional().transform(value => value?.trim().replace(/[^0-9]/g, '')),
  operIntermediadorID: z.string().optional(),
})

export type XMLFormProps = z.infer<
  typeof schemaXML
>;
