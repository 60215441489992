export const preloadedValues = {
  prodRecStatus: 0,
  prodGTIN: "",
  prodCodigo: 1,
  prodDescricao: "",
  prodOrigem: "",
  prodTipoItem: "",
  prodIndMovFisica: false,
  prodUnidId: "",
  prodUnidMultiplaPermitida: "",
  prodPesoL: 0,
  prodPesoB: 0,

  ProdPrTr: [{
    PrTrNcmsId: "",
    prTrCOFINSCSTCredito: "",
    prTrOperId: "",
    prTrCEST: "",
    prTrPISCSTCredito: "",
    prTrIndEscala: true,
    prTrIndEscalaCNPJFabricante: "",
  }],

  prodPrPB:[ {
    prPBCustoInicial: 0,
    prPBCustoPIPI: 0,
    prPBCustoVIPI: 0,
    prPBCustoPFrete: 0,
    prPBCustoVFrete: 0,
    prPBCustoPFreteCTe: 0,
    prPBCustoVFreteCTe: 0,
    prPBCustoVICMSFreteCTe: 0,
    prPBCustoPST: 0,
    prPBCustoVST: 0,
    prPBCustoFinal: 0,
    prLPPComissao:0,
    prPBCustoVICMS: 0,
    prPBCustoVPIS: 0,
    
    prPBCustoVDespesasTotais: 0,
    prPBFormacaoPComissao: 0,
    prPBFormacaoPMargem: 0,
    prLPMkPVMinimo:0,
    prLPMkPVFinal:0,
    prLPPV:0,
    prLPPVMinimo:0,
    prPBCustoVCOFINS: 0,

    prLPPVFinal:0,
    prLPComissao:0,
    prLPPCustoFixo:0,
    prLPPCOFINS:0,
    prLPPFaixaSN:0,
    prLPPICMS:0,
    prLPPPIS:0,
    prLPPDescontoMaximo: 0,
    prLPPrPD: [
      {
        prPDDescricao: "",
        prPDTipo: 0,
        prPDValor: 0,
      },
    ],
   
  }],

  prodOperacaoFiscal: [{
    prOFRecStatus: 0,
    prOFMod: "",
    prOFOperId: "",
    prOFProposito: "",
  }],
  prodCodigosAlternativos: [{
    prCARecStatus: 0,
    prCATipo: "",
    prCACodigo: "",
    prCAXML: false,
  }],
  ProdPrIE: [{
    prIEmedANVISARegistro: "",
    prIEmedANVISAMotivoIsencao: "",
    prIEmedPrecoMaximoConsumidor: 0,
    prIEcombANPCodigo: "",
    prIEcombANPDescricao: 0,
    prIEcombPercGLPPetroleo: 0,
    prIEcombPercGasNaturalNacional: 0,
    prIEcombPercGasNaturalImportado: 0,
    prIERECOPI: "",
  }],
  ProdPrPF:[ {
    PrPFUfdeId: "",
    prPFPrecoPauta: 0,
    prPFPrecoTabeladoMaximo: 0,
    prPFPrecoListaNegativa: 0,
    prPFPrecoListaPositiva: 0,
    prPFPrecoListaNeutral: 0,
  }]
};
