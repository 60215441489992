import style from "./pagination.module.scss";
import { useState } from "react";

export type Props = {
  pageIndex: number
  pageCount: number
  onSetPage: (pageIndex: number) => void
}

export const Pagination = ({pageIndex, pageCount, onSetPage}: Props) => {
  const [numberPage,setNumberPage] = useState(pageIndex)
 
  const nextPage = () => {
    if (numberPage < pageCount -1) {
      const np = numberPage + 1
      setNumberPage(np);
      onSetPage(np);
    }
  }

  const backPage = () => {
    if (numberPage > 0) {
      const np = numberPage - 1
      setNumberPage(np);
      onSetPage(np);
    }
  }

  const directPageClick = (pageNumber:number) => {
    if (numberPage !== pageNumber) {
      setNumberPage(pageNumber);
      onSetPage(pageNumber);
    }
  }

  if (pageIndex !== numberPage)
    setNumberPage(pageIndex)

  const LIMIT = 11
  const HALF_LIMIT = Math.ceil(LIMIT / 2)
  let start, end
  if (numberPage >= HALF_LIMIT) {
    end = numberPage + HALF_LIMIT

    if (end > pageCount)
      end = pageCount

    start = end - LIMIT
    if (start < 0)
      start = 0
  } else {
    start = 0
    end = LIMIT

    if (end > pageCount)
      end = pageCount
  }

  const range = (start: number, end: number) => Array.from({length: (end - start)}, (v, k) => k + start)

  return (
    <ul className={style.pagination_list}>
      <li key={'back_page'}  onClick={() => {backPage()}}> {"<"} </li>
      {range(start, end).map((value, index) => (
        <li key={value} className={numberPage === value? style.selected : ""}
        onClick={() => directPageClick(value)}
        >{value + 1}</li>
      ))}
      <li key={'next_page'} onClick={() => {nextPage()}}> {">"} </li>
    </ul>
  );
};
