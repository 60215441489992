import { createContext, ReactNode, useState } from "react";

type NameItemContextProps = {
  children: ReactNode;
};

type NameItemContextType = {
  nameItem: string;
  setNameItem: (text:string) => void;
};

const initialValue = {
  nameItem: '',
  setNameItem: (text:string) => {},
};

export const NameItemContext = createContext<NameItemContextType>(initialValue);

export const NameItemContextProvider = ({ children }: NameItemContextProps) => {
  const [nameItem, setNameItem] = useState(initialValue.nameItem);

  return (
    <NameItemContext.Provider value={{ nameItem, setNameItem }}>
      {children}
    </NameItemContext.Provider>
  );
};
