import { createContext, ReactNode, useState } from "react";

type FilterItemsContextProps = {
  children: ReactNode;
};

type FilterItemsContextType = {
  filterItems: any[];
  setFilterItems: (data: any) => void;
};

const initialValue = {
  filterItems: [],
  setFilterItems: () => {},
};

export const FilterItemsContext =
  createContext<FilterItemsContextType>(initialValue);

export const FilterItemsContextProvider = ({
  children,
}: FilterItemsContextProps) => {
  const [filterItems, setFilterItems] = useState(initialValue.filterItems);

  return (
    <FilterItemsContext.Provider value={{ filterItems, setFilterItems }}>
      {children}
    </FilterItemsContext.Provider>
  );
};
