import { apiApp } from "../../services/api/app.api";
import { XPagination, propsDeleteItem, propsDetectKeyDown, propsGetAll } from "./types/actions.types";

export type ItensData = {
  data: any
  xPagination: XPagination
}

export const getAllItems = async ({ ...props }: propsGetAll) => {
  let xPagination: XPagination
  if (props.xPagination === undefined || props.xPagination === null) {
    xPagination = {
      PageSize: 20,
      PageIndex: 1,
      PageTotalItens: -1
    }
  }
  else {
    xPagination = props.xPagination
  }
  let xpagination: string = JSON.stringify(xPagination)

  const result: any = await apiApp.getAll(props.url, props.nameMenuCurrent, props.search, xpagination, props.desativados);
  
  if (!result.status)
    throw Error(result)

  if (!result.data) {
    let itensData: ItensData = {
      data: [],
      xPagination: {}
    }
    return itensData
  }

  xpagination = result.headers["x-pagination"]
  if (xpagination !== undefined) {
    xPagination = JSON.parse(xpagination)
  }

  if (props.modifyInformation != null) props.modifyInformation(result.data)
  let itensData: ItensData = {
    data: result.data,
    xPagination: xPagination
  }
  return itensData
};

export const deleteItem:any = async({...props}:propsDeleteItem) => {
    await apiApp.remove(props.url, props.nameMenuCurrent, props.idSelected);
    props.setIsOpenModal(false)
};

export const detectKeyDown = async (e:KeyboardEvent,{...props}: propsDetectKeyDown) => {
    if (e.key === "Enter") {
      if (props.nameItem.length > 0) {
        props.setIsLoading(true);
        const result: any = await apiApp.getItem(
          props.url,
          props.nameMenuCurrent,
          props.nameItem
        );
        if (props.modifyInformation != null) props.modifyInformation(result.data)
        props.setItems(result);
        props.setIsLoading(false);
        return;
      }
    }
  };