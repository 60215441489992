import { useForm } from "react-hook-form";
import { ControllerComboBox } from "../../../../../../../../components/controllers/combobox-controller";
import { FooterFormButtons } from "../../../../../../../../components/footer-form/buttons-form";
import { IOperacoesFiscais } from "../../interface/form.interface";
import {
  LIcmsCsosn,
  LIcmsCst,
  LIcmsDesoneracaoFormulaBc,
  LIcmsDesoneracaoMotivo,
  LIcmsModalidadeBc,
  LIcmsModalidadeBcSt,
} from "../../options/options.form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ICMSFormProps, schemaICMS } from "./form.config";
import { useContext, useEffect, useState } from "react";
import { Loading } from "../../../../../../../../components/loading/loading";
import { preloadedValues } from "./default-values-form";
import { CenterValuesContext } from "../../../../../../../../context/center-values";
import { convertForComboBox } from "../../../../../../../../utils/convert-for-combobox";
import { ID } from "../../../../../../../../config/environment/id.config";
import style from "./style.module.scss"
import { ControllerTextField } from "../../../../../../../../components/controllers/ControllerTextField";

interface IICMS extends IOperacoesFiscais {
  states: any[]
}
export const ICMS = ({ getValuesForm, setValuesForm, closeExpander,setCloseExpander}: IICMS) => {
  const [loading, setLoading] = useState(false);
  const { centerValues } = useContext(CenterValuesContext)
  const [ufOptions, setUfOptions] = useState([])
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ICMSFormProps>({
    mode: "onChange",
    resolver: zodResolver(schemaICMS),
    defaultValues: preloadedValues
  });

  const setFormValues = () => {
    setLoading(true)
    setValue("operICMSCST", getValuesForm("operICMSCST"))
    setValue("operICMSCSOSN", getValuesForm("operICMSCSOSN"))
    setValue("operICMSModalidadeBC", getValuesForm("operICMSModalidadeBC"))
    setValue("operICMSDesoneracaoMotivo", getValuesForm("operICMSDesoneracaoMotivo"))
    setValue("operICMSDesoneracaoFormulaBC", getValuesForm("operICMSDesoneracaoFormulaBC"))
    setValue("operICMSReducaoBC", getValuesForm("operICMSReducaoBC"))
    setValue("operICMSDiferimentoBC", getValuesForm("operICMSDiferimentoBC"))
    setValue("operUfdeId", getValuesForm("operUfdeId"))
    setValue("operICMSAliquotaInternaUF", getValuesForm("operICMSAliquotaInternaUF"))
    setValue("operICMSCodigoBeneficioFiscal", getValuesForm("operICMSCodigoBeneficioFiscal"))
    setValue("operICMSModalidadeBCST", getValuesForm("operICMSModalidadeBCST"))
    setValue("operICMSSTMVA", getValuesForm("operICMSSTMVA"))
   
    setLoading(false)
  }

  const submit = async (data: ICMSFormProps) => {
    setValuesForm("operICMSCST", data.operICMSCST)
    setValuesForm("operICMSCSOSN", data.operICMSCSOSN)
    setValuesForm("operICMSModalidadeBC", data.operICMSModalidadeBC)
    setValuesForm("operICMSDesoneracaoMotivo", data.operICMSDesoneracaoMotivo)
    setValuesForm("operICMSDesoneracaoFormulaBC", data.operICMSDesoneracaoFormulaBC)
    setValuesForm("operICMSReducaoBC", data.operICMSReducaoBC)
    setValuesForm("operICMSDiferimentoBC", data.operICMSDiferimentoBC)
    setValuesForm("operUfdeId", data.operUfdeId)
    setValuesForm("operICMSAliquotaInternaUF", data.operICMSAliquotaInternaUF)
    setValuesForm("operICMSCodigoBeneficioFiscal", data.operICMSCodigoBeneficioFiscal)
    setValuesForm("operICMSModalidadeBCST", data.operICMSModalidadeBCST)
    setValuesForm("operICMSSTMVA", data.operICMSSTMVA)
    
    
    closeExpander({ state: true, open: false })
  }
  useEffect(() => {
    setLoading(true);
if (closeExpander.state) setFormValues()
    convertForComboBox({ id: ID.cadastro.uf, list: centerValues.uf, prop: 'ufdeNome', items: ufOptions, setItems: setUfOptions, isUF: true });
    setLoading(false);
  }, []);
  return (
    <>
      {loading && <Loading />}
      <form>

        <ControllerComboBox
          options={LIcmsCst}
          name={"operICMSCST"}
          control={control}
          label="CST"
          placeholder="CST do ICMS"
          errors={errors.operICMSCST?.message}
        />
        <ControllerComboBox
          options={LIcmsCsosn}
          name={"operICMSCSOSN"}
          control={control}
          label="CSOSN"
          placeholder="CSOSN do ICMS"
          errors={errors.operICMSCSOSN?.message}

        />
        <div className={style["form-row"]}>

          <ControllerComboBox
            options={LIcmsModalidadeBc}
            name={"operICMSModalidadeBC"}
            control={control}
            label="Modalidade da BC"
            placeholder="Modalidade da BC"
            errors={errors.operICMSModalidadeBC?.message}

          />
          <ControllerComboBox
            options={LIcmsDesoneracaoMotivo}
            name={"operICMSDesoneracaoMotivo"}
            control={control}
            label="Motivo de desoneração"
            placeholder="Motivo de desoneração"
            errors={errors.operICMSDesoneracaoMotivo?.message}

          />
        </div>
        <div className={style["form-row"]}>

          <ControllerComboBox
            options={LIcmsDesoneracaoFormulaBc}
            name={"operICMSDesoneracaoFormulaBC"}
            control={control}
            label="Fórmula de cálculo de desoneração"
            placeholder="Fórmula de cálculo da desoneração"
            errors={errors.operICMSDesoneracaoFormulaBC?.message}

          />
          <ControllerTextField
            name={"operICMSReducaoBC"}
            control={control}
            label="Percentual de redução de BC"
            placeholder="Percentual de redução da BC"
            errors={errors.operICMSReducaoBC?.message}
            suffix="%"
          />
          <ControllerTextField
            name={"operICMSDiferimentoBC"}
            control={control}
            label="Percentual de diferimento da BC"
            placeholder="Percentual de redução da BC"
            errors={errors.operICMSDiferimentoBC?.message}
            suffix="%"
          />
        </div>
        <div className={style["form-row"]}>


          <ControllerComboBox
            options={ufOptions}
            name={"operUfdeId"}
            control={control}
            label="UF de destino"
            placeholder="UF de destino"
            errors={errors.operUfdeId?.message}


          />
          <ControllerTextField
            name={"operICMSAliquotaInternaUF"}
            control={control}
            label="Alíquota interna na UF de destino"
            placeholder="Alíquota interna na UF de destino"
            errors={errors.operICMSAliquotaInternaUF?.message}

          />
        </div>
        <div className={style["form-row"]}>
          <ControllerTextField
            name={"operICMSCodigoBeneficioFiscal"}
            control={control}
            label="Código de Benefício Fiscal"
            placeholder="Código de Benefício Fiscal"
            errors={errors.operICMSCodigoBeneficioFiscal?.message}

          />

          <ControllerComboBox
            options={LIcmsModalidadeBcSt}
            name={"operICMSModalidadeBCST"}
            control={control}
            label="Modalidade da BC ST"
            placeholder="Modalidade da BC ST"
            errors={errors.operICMSModalidadeBCST?.message}

          />
          <ControllerTextField
            name={"operICMSSTMVA"}
            control={control}
            label="MVA"
            placeholder="MVA"
            errors={errors.operICMSSTMVA?.message}

          />
        </div>
        <FooterFormButtons
          min={true}
          btn_salvar={handleSubmit(submit)}
          btn_cancelar={() => closeExpander({ state: false, open: false })}
        />
      </form>
    </>
  );
};
