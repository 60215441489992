import { BancosFormProps, schemaBancos } from "./form.config";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useContext, useEffect, useState } from "react";
import { ContaBancaria } from "./conta-bancaria/conta-bancaria";
import style from "./style.module.scss";

import { useLocation, useNavigate } from "react-router-dom";

import { preloadedValues } from "./default-values-form";
import { ControllerCheckBox } from "../../../../../../components/controllers/checkbox-controller";
import { Expander } from "../../../../../../components/expander/expander";
import { FooterFormButtons } from "../../../../../../components/footer-form/buttons-form";
import { SubtitleForm } from "../../../../../../components/text/form/subtitle/subtitle";
import { TitleForm } from "../../../../../../components/text/form/title/title";
import { endpoint } from "../../../../../../config/environment/endpoint.config";
import { idItemSelectedContext } from "../../../../../../context/idItemSelected";
import {
  create,
  getValuesItem,
} from "../../../../../../utils/actions/form.actions";
import { currentURlName } from "../../../../../../utils/url.name";
import { editItemVerification } from "../../../../../../utils/actions/editItemPost";
import { ID } from "../../../../../../config/environment/id.config";
import { recStatusModify } from "../../../../../../utils/rec-status.modify";
import { ControllerTextField } from "../../../../../../components/controllers/ControllerTextField";
export const BancosForm = () => {
  const [arrayWithValuesForm, setArrayWithValuesForm] = useState<any>([]);
  const [isOpenContasBancos, setIsOpenContasBancos] = useState(false);
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { idItemSelected } = useContext(idItemSelectedContext);
  const currentPage = currentURlName(pathname);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<BancosFormProps>({
    mode: "all",
    resolver: zodResolver(schemaBancos),
    defaultValues: preloadedValues,
  });
  const pageReturn = "/linko/configuracoes/bancosContas";

  const valuesEdit = (itemValue: BancosFormProps) => {
    setArrayWithValuesForm(itemValue.bcosContas);
    setValue(
      "bcosRecStatus",recStatusModify.form(itemValue.bcosRecStatus));
    setValue("bcosCodigoFebraban", itemValue.bcosCodigoFebraban);
    setValue("bcosCodigoFebrabanDV", itemValue.bcosCodigoFebrabanDV);
    setValue("bcosNomeFebraban", itemValue.bcosNomeFebraban);
    setValue("bcosNome", itemValue.bcosNome);

    setIsLoading(false);
    return;
  };
  useEffect(() => {
    getValuesItem({
      currentPage,
      idSelected: idItemSelected.id,
      nameMenuCurrent: "banco",
      setIsLoading,
      url: endpoint.financeiro,
      valuesEdit,
    });
  }, []);

  const createItem = async (data: BancosFormProps) => {
    const idNumber = idItemSelected.id;
    const id = ID.financeiro.banco + ":" + idNumber;
    await create({
      currentPage,
      nameMenuCurrent: "banco",
      url: endpoint.financeiro,
      data: editItemVerification({ currentPage, data, id }),
      setIsLoading,
      idItem: idNumber,
    });
    navigate(pageReturn);
  };

  useEffect(() => {
    setValue("bcosContas", [...arrayWithValuesForm]);
  }, [arrayWithValuesForm.length]);

  return (
    <section className={style["content"]}>
      <TitleForm title="Banco" />
      <form className={style["content_form"]}>
        <ControllerCheckBox
          name={"bcosRecStatus"}
          label={"Desativado"}
          control={control}
          recStatus={true}
        />
        <SubtitleForm subtitle="Dados da Febraban" />
        <div className={style["content_form-row"]}>
          <ControllerTextField
            className={style["input-sm"]}
            name={"bcosCodigoFebraban"}
            placeholder="Código Febraban"
            label={"Código"}
            control={control}
            errors={errors.bcosCodigoFebraban?.message}
          />
          <ControllerTextField
            className={style["input-sm"]}
            name={"bcosCodigoFebrabanDV"}
            placeholder="DV Febraban"
            label={"DV"}
            control={control}
            errors={errors.bcosCodigoFebrabanDV?.message}
          />
          <ControllerTextField
            name={"bcosNomeFebraban"}
            placeholder="Nome na Febraban"
            label={"Nome"}
            control={control}
            errors={errors.bcosNomeFebraban?.message}
          />
        </div>

        <ControllerTextField
          name={"bcosNome"}
          label="Nome interno"
          placeholder="Nome interno"
          control={control}
          className={style["input-full"]}
          errors={errors.bcosNome?.message}
        />

        <Expander
          content={
            <ContaBancaria
              setArrayWithValuesForm={setArrayWithValuesForm}
              arrayWithValuesForm={arrayWithValuesForm}
            />
          }
          name="Contas"
          value={isOpenContasBancos}
          setValue={setIsOpenContasBancos}
          statusForm={false}
        />
        <FooterFormButtons
          min={false}
          btn_cancelar={() => {
            navigate(pageReturn);
          }}
          btn_salvar={handleSubmit(createItem)}
        />
      </form>
    </section>
  );
};
