import style from "./form.module.scss";
import { zodResolver } from "@hookform/resolvers/zod";
import {  Checkbox } from "@fluentui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { ECfopLocal } from "../optionsInput/options.input";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  CFOPFormProps,
  checkedRule,
  disabledOptionsRule,
  schemaCFOP,
} from "./form.config";
import { preloadedValues } from "./default-values-form";
import { ControllerCheckBox } from "../../../../../../../components/controllers/checkbox-controller";
import { ControllerComboBox } from "../../../../../../../components/controllers/combobox-controller";
import { FooterFormButtons } from "../../../../../../../components/footer-form/buttons-form";
import { Loading } from "../../../../../../../components/loading/loading";
import { TitleForm } from "../../../../../../../components/text/form/title/title";
import { endpoint } from "../../../../../../../config/environment/endpoint.config";
import { ID } from "../../../../../../../config/environment/id.config";
import { idItemSelectedContext } from "../../../../../../../context/idItemSelected";
import { editItemVerification } from "../../../../../../../utils/actions/editItemPost";
import { getValuesItem, create } from "../../../../../../../utils/actions/form.actions";
import { currentURlName } from "../../../../../../../utils/url.name";
import { Calendar } from "../../../../../../../components/input/calendar/calendar";
import { recStatusModify } from "../../../../../../../utils/rec-status.modify";
import { ControllerTextField } from "../../../../../../../components/controllers/ControllerTextField";

export const CFOPForm = () => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const { idItemSelected } = useContext(idItemSelectedContext);
  const currentPage = currentURlName(pathname);
  const pageReturn = "/linko/configuracoes/cfop";
  const [isLoading, setIsLoading] = useState(true);
  const [validationNFCeNFe, setValidationNFCeNFe] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<CFOPFormProps>({
    mode: "all",
    resolver: zodResolver(schemaCFOP),
    defaultValues: preloadedValues,
  });

  const watchNFCe = watch("cfopPermiteNFCe");
  const watchNFe = watch("cfopPermiteNFe");

  useEffect(() => {
    errors.cfopPermiteNFe?.message
      ? setValidationNFCeNFe(!watchNFe && !watchNFCe)
      : setValidationNFCeNFe(false);
  }, [watchNFe, watchNFCe, errors.cfopPermiteNFe?.message]);

  const valuesEdit = (itemValue: CFOPFormProps) => {
    setValue(
      "cfopRecStatus",
      recStatusModify.form(itemValue.cfopRecStatus));
    setValue("cfopPermiteNFe", itemValue.cfopPermiteNFe);
    setValue("cfopPermiteNFCe", itemValue.cfopPermiteNFCe);
    setValue("cfopPermiteDevolucao", itemValue.cfopPermiteDevolucao);
    setValue("cfopPermiteAjusteEstorno", itemValue.cfopPermiteAjusteEstorno);
    setValue("cfopPermiteRetorno", itemValue.cfopPermiteRetorno);
    setValue("cfopPermiteRemessa", itemValue.cfopPermiteRemessa);
    setValue("cfopPermiteComunicacao", itemValue.cfopPermiteComunicacao);
    setValue("cfopPermiteTransporte", itemValue.cfopPermiteTransporte);
    setValue("cfopPermiteCombustivel", itemValue.cfopPermiteCombustivel);
    setValue("cfopTipo", itemValue.cfopTipo);
    setValue("cfopCodigo", itemValue.cfopCodigo);
    setValue("cfopNaturezaOperacao", itemValue.cfopNaturezaOperacao);
    setValue("cfopAplicacao", itemValue.cfopAplicacao);
  };

  useEffect(() => {
    getValuesItem({
      currentPage,
      idSelected: idItemSelected.id,
      nameMenuCurrent: "cfop",
      setIsLoading,
      url: endpoint.fiscal,
      valuesEdit,
    });
  }, []);

  const createItem = async (data: any) => {
    const idNumber = idItemSelected.id;
    const id = ID.fiscal.cfop + ":" + idNumber;

    await create({
      currentPage,
      nameMenuCurrent: "cfop",
      url: endpoint.fiscal,
      data: editItemVerification({ currentPage, data, id }),
      setIsLoading,
      idItem: idNumber,
    });
    navigate(pageReturn);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={style["content"]}>
          <TitleForm title={"CFOP: Códigos fiscais de operação"} />
          {false && <></>}
          <form className={style["content_form"]}>
            <header className={style["content_form-header"]}>
              <ControllerCheckBox
                name={"cfopRecStatus"}
                control={control}
                label="Desativado"
                recStatus={true}
              />

              <ul className={style["content_form-list-checkbox"]}>
                <li className={style["content_form-group-checkbox"]}>
                  <ControllerCheckBox
                    name={"cfopPermiteNFe"}
                    control={control}
                    className={
                      validationNFCeNFe
                        ? style["checkbox-error"]
                        : style["checkbox"]
                    }
                    label="NF-e"
                  />

                  <ControllerCheckBox
                    name={"cfopPermiteDevolucao"}
                    control={control}
                    label="Devolução"
                    disabled={getValues("cfopPermiteNFCe")}
                  />

                  <Controller
                    name="cfopPermiteComunicacao"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        label="Comunicação"
                        checked={value}
                        onChange={(_, value) => {
                          onChange(value);
                          checkedRule({
                            getValues,
                            setValue,
                            nameCheckbox: "comunicacao",
                          });
                        }}
                      />
                    )}
                  />
                </li>
                <li className={style["content_form-group-checkbox"]}>
                  <Controller
                    name="cfopPermiteNFCe"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        className={
                          validationNFCeNFe
                            ? style["checkbox-error"]
                            : style["checkbox"]
                        }
                        label="NFC-e"
                        defaultChecked={value}
                        onChange={(_, value) => {
                          onChange(value);
                          disabledOptionsRule({ getValues, setValue });
                        }}
                      />
                    )}
                  />
                  <ControllerCheckBox
                    name="cfopPermiteAjusteEstorno"
                    control={control}
                    label="Ajuste/Estorno"
                    disabled={getValues("cfopPermiteNFCe")}
                  />

                  <Controller
                    name="cfopPermiteTransporte"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        label="Transporte"
                        checked={value}
                        onChange={(_, value) => {
                          onChange(value);
                          checkedRule({
                            getValues,
                            setValue,
                            nameCheckbox: "transporte",
                          });
                        }}
                      />
                    )}
                  />
                </li>
                <li className={style["content_form-group-checkbox"]}>
                  <span className={style["content_form-error"]}>
                    {validationNFCeNFe ? errors.cfopPermiteNFe?.message : <></>}
                  </span>
                  <ControllerCheckBox
                    control={control}
                    name="cfopPermiteRetorno"
                    label="Retorno"
                    disabled={getValues("cfopPermiteNFCe")}
                  />

                  <Controller
                    name="cfopPermiteCombustivel"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        label="Combustivel"
                        defaultChecked={value}
                        onChange={(_, value) => {
                          onChange(value);
                          checkedRule({
                            getValues,
                            setValue,
                            nameCheckbox: "combustivel",
                          });
                        }}
                      />
                    )}
                  />
                </li>
                <li className={style["content_form-group-checkbox-sm"]}>
                  <ControllerCheckBox
                    control={control}
                    name="cfopPermiteRemessa"
                    label="Remessa"
                    disabled={getValues("cfopPermiteNFCe")}
                  />
                </li>
              </ul>
              <Calendar
                label={"Inicio de vigência"}
                value={undefined}
                disabled
              />
              <Calendar
                label={"Final de vigência"}
                value={undefined}
                disabled
              />
            </header>
            <section className={style["content_form-section"]}>
              <ControllerComboBox
                options={ECfopLocal}
                name="cfopTipo"
                control={control}
                errors={errors.cfopTipo?.message}
                label="Tipo"
                placeholder="Tipo do CFOP"
              />

              <div className={style["content_form-section-inputs"]}>
                <ControllerTextField
                  control={control}
                  name="cfopCodigo"
                  label="CFOP"
                  errors={errors.cfopCodigo?.message}
                />
                <ControllerTextField
                  control={control}
                  name="cfopNaturezaOperacao"
                  label="Natureza da operação"
                  errors={errors.cfopNaturezaOperacao?.message}
                  placeholder="Digite a natureza da operação"
                />
                <ControllerTextField
                  control={control}
                  name="cfopAplicacao"
                  className={style["textarea"]}
                  label="Aplicação"
                  errors={errors.cfopAplicacao?.message}
                  placeholder="Digite a aplicação"
                  multiline={true}
                />
              </div>
            </section>

            <FooterFormButtons
              btn_cancelar={() => navigate("/linko/configuracoes/cfop")}
              btn_salvar={handleSubmit(createItem)}
              min={false}
            />
          </form>
        </div>
      )}
    </>
  );
};
