import style from "./style.module.scss";
import Logo from "../../assets/LINKO_TRANSPARENTE.png";
import { PasswordInput } from "../../components/input/password/password-input";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {  Spinner } from "@fluentui/react";
import { endpoint } from "../../config/environment/endpoint.config";
import { apiApp } from "../../services/api/app.api";
import { useForm } from "react-hook-form";
import { LoginFormProps, schemaLogin } from "./form.config";
import { zodResolver } from "@hookform/resolvers/zod";
import { Notification, RetNotification } from "../../components/notification/notification";

export const Login = () => {
  let navigate = useNavigate();
  const notification = useRef<RetNotification>(null);
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormProps>({
    mode: "onSubmit",
    resolver: zodResolver(schemaLogin),
  });

  document.addEventListener("keypress", (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      (handleSubmit(submit))
      return
    }
    return
  })

  useEffect(() => {
    if(!!errors.usroEmail?.message || !!errors.usroSenha?.message){
      setIsLoading(false)
      notification.current?.setMessage({ message: "Complete os campos corretamente", type: "warning" })
      return
    }
  }, [errors])

  const submit = async (data: LoginFormProps) => {
    setIsLoading(true)
    const result: any = await apiApp.post(endpoint.auth, 'login', data)

    if (!result) {
      setIsLoading(false)
      console.log('submit') 
      if (notification.current)
        notification.current?.setMessage({ message: "Login Incorreto", type: "error" })
      
      return
    }

    localStorage.setItem("linko_token", result.data)
    setIsLoading(false)
    navigate("/linko")
    return
  }

  return (
    <main className={style["contain"]}>
      <Notification ref={notification} />
      <section className={style["section"]}>
        <header className={style["section_header"]}>
          <img
            className={style["section_header-img"]}
            src={Logo}
            alt="Logo Linko"
          />
        </header>
        <form className={style["section_form"]}>
          <div className={style["section_form-content"]}>
            <div className={style["section_form-input"]}>
              <label>Usuário</label>
              <input autoFocus type="text" placeholder="Digite seu usuário" {...register("usroEmail")} />
            </div>
            <PasswordInput inline={true} label="Senha" register={register} />
          </div>

          <button className={style["section_form-button"]} type="submit" onClick={handleSubmit(submit)}>
            {isLoading ? <Spinner /> : <>Entrar</>}
          </button>
        </form>
        <footer className={style["section_footer"]}>
          Esqueceu sua senha ?
        </footer>
      </section>
    </main>
  );
};
