
export const preloadedValues = {
  fmPgRecStatus: false,
  fmPgDescricao: "",
  fmPgBcCtId: "",
  fmPgTPag: "03",
  fmPgTpIntegra: "",
  fmPgCNPJCredenciadora: "",
  fmPgCodCredenciadora: "",
  fmPgAutorizadorTEF: "",
  fmPgTBand: "03",
  fmPgTpIntegraCNPJ: "",
};
