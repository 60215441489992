export const financeiroItems = [
    { category: "Financeiro", key: "constasAReceber", name: "Contas a receber" },
    { category: "Financeiro", key: "constasAPagar", name: "Contas a pagar" },
    {
      category: "Financeiro",
      key: "fechamentoDeCaixa",
      name: "Fechamento de caixa",
    },
    {
      category: "Financeiro",
      key: "conciliacaoBancaria",
      name: "Conciliação bancária",
    },
    { category: "Financeiro", key: "comissoes", name: "Comissões" },
  ];