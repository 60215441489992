import { HeaderTable } from "../../header-table/header-table";
import { ModalDeleteItem } from "../../modal-delete/modalDelete";
import { Table } from "../component/table";

import style from "./style.module.scss";

interface ITablePrincipal {
  nameForm: string;
  loading: boolean;
  isOpenModal: boolean;
  idName: string;
  columns: any[];
  items: any[];
  deleteItem: () => void;
  setIsOpenModal: (value:boolean) => void;
  onSearch: (text: string) => void;
  onFilterStatus: (value: boolean) => void
  pageIndex: number
  pageCount: number
  totalItens: number
  onSetPage: (pageIndex: number) => void
}

export const TablePrincipal = ({
  nameForm,
  isOpenModal,
  loading,
  deleteItem,
  setIsOpenModal,
  columns,
  items,
  idName,
  onSearch,
  onFilterStatus,
  pageIndex,
  pageCount,
  totalItens,
  onSetPage
}: ITablePrincipal) => {
  return (
    <>
      <section className={style.section}>
        <>
          {isOpenModal ? (
            <ModalDeleteItem
              functionDelete={deleteItem}
              setShowModal={setIsOpenModal}
            />
          ) : (
            <>
              <div className={style.sectionContent}>
                <HeaderTable namePage={nameForm} onSearch={onSearch} onFilterStatus={onFilterStatus}/>
            
                <Table
                  columns={columns}
                  listItems={items}
                  FilterTablesValue={<></>}
                  propIdName={idName}
                  pageIndex={pageIndex}
                  pageCount={pageCount}
                  onSetPage={onSetPage}
                  totalItens={totalItens}
                />
              </div>
            </>
          )}
        </>
      </section>
    </>
  );
};
