import style from "./contatos.module.scss";
import { useContext, useEffect, useState } from "react";
import { masksApp } from "../../../../../../../config/masks/masks";
import { ActionButtonTable } from "../../../../../../../context/actionButtonTable";
import { idItemSelectedContext } from "../../../../../../../context/idItemSelected";
import { uuid } from "../create.informations";
import { FooterFormButtons } from "../../../../../../../components/footer-form/buttons-form";
import { useForm } from "react-hook-form";
import { CadastroPessoaContatoFormProps, schemaCadastroPessoaContato } from "./form.config";
import { zodResolver } from "@hookform/resolvers/zod";
import { preloadedValuesContato } from "./default-values-form";
import { ControllerCheckBox } from "../../../../../../../components/controllers/checkbox-controller";
import { ControllerMaskTextField } from "../../../../../../../components/controllers/mask-textfield-controller";
import { ID } from "../../../../../../../config/environment/id.config";
import { Loading } from "../../../../../../../components/loading/loading";
import { ValuesPessoaForm } from "../types/types-cadastro-pessoas";
import { IconModify } from "../../../../../../../utils/icon-table.modify";
import { recStatusModify } from "../../../../../../../utils/rec-status.modify";
import { ControllerTextField } from "../../../../../../../components/controllers/ControllerTextField";

export const ContatosForm = ({ arrayForm, setArrayForm, setShowForm, getValuesForm, setValuesForm }: ValuesPessoaForm) => {
  const { actionButton } = useContext(ActionButtonTable);
  const { idItemSelected } = useContext(idItemSelectedContext);
  const [loading, setLoading] = useState(false)
  const actionForm = actionButton === "edit" || actionButton === "copy"

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CadastroPessoaContatoFormProps>({
    mode: "all",
    resolver: zodResolver(schemaCadastroPessoaContato),
    defaultValues: preloadedValuesContato,
  });

  let searchId: CadastroPessoaContatoFormProps = arrayForm.find(
    (value: any) => value[ID.cadastro.pessoaContato] === idItemSelected.id
  );

  const editValues = () => {
    const XMLAutorizado_modify: any = IconModify.toForm(searchId.psCtXMLAutorizado);
    const Principal_modify: any = IconModify.toForm(searchId.psCtPrincipal);
    const XMLNFCe_modify: any = IconModify.toForm(searchId.psCtXMLNFCe);
    const XMLNFe_modify: any = IconModify.toForm(searchId.psCtXMLNFe);
    setValue("psCtPrincipal", Principal_modify);
    setValue("psCtXMLNFCe", XMLNFCe_modify);
    setValue("psCtXMLNFe", XMLNFe_modify);
    setValue("psCtXMLAutorizado", XMLAutorizado_modify);

    setValue("psCtRecStatus", searchId.psCtRecStatus);
    setValue("psCtCNPJ", searchId.psCtCNPJ)
    setValue("psCtCPF", searchId.psCtCPF)
    setValue("psCtCelular", searchId.psCtCelular)
    setValue("psCtEmail", searchId.psCtEmail)
    setValue("psCtNome", searchId.psCtNome)
    setValue("psCtPrincipal", searchId.psCtPrincipal)
    setValue("psCtRecStatus", searchId.psCtRecStatus)
    setValue("psCtTelefone", searchId.psCtTelefone)
    setValue("psCtXMLAutorizado", searchId.psCtXMLAutorizado)
    setValue("psCtXMLNFCe", searchId.psCtXMLNFCe)
    setValue("psCtXMLNFe", searchId.psCtXMLNFe)
    setLoading(false)
  }
  const changeValuesArray = async (data: CadastroPessoaContatoFormProps) => {
    searchId.psCtCNPJ = data.psCtCNPJ;
    searchId.psCtCPF = data.psCtCPF;
    searchId.psCtCelular = data.psCtCelular;
    searchId.psCtEmail = data.psCtEmail;
    searchId.psCtNome = data.psCtNome;
    searchId.psCtPrincipal = data.psCtPrincipal;
    searchId.psCtRecStatus = recStatusModify.form(data.psCtRecStatus);
    searchId.psCtTelefone = data.psCtTelefone;
    searchId.psCtXMLAutorizado = data.psCtXMLAutorizado;
    searchId.psCtXMLNFCe = data.psCtXMLNFCe;
    searchId.psCtXMLNFe = data.psCtXMLNFe;
  }

  const submit = (data: CadastroPessoaContatoFormProps) => {
    if (actionButton === "edit") {
      changeValuesArray(data)
      setValuesForm("pessContatos", arrayForm)
      setArrayForm([...arrayForm])
      setShowForm(false)
      return
    }
    setValuesForm("pessContatos", arrayForm)
    setArrayForm([...arrayForm, { id: uuid(), ...data }])
    setShowForm(false)
  };

  useEffect(() => {
    setLoading(true)
    if (actionForm) {
      editValues()
      return
    }
    setLoading(false)
  }, [])

  return (
    <>
      {loading && <Loading />}
      <main className={style["content"]}>
        <form>
          <ControllerCheckBox
            control={control}
            name="psCtRecStatus"
            label="Desativado"
            recStatus={true}
          />
          <div className={style["form-row"]}>
            <ControllerTextField
              control={control}
              name="psCtNome"
              errors={errors.psCtNome?.message}
              label={"Nome"}
              placeholder="Nome do contato"
            />
            <ControllerMaskTextField
              control={control}
              name="psCtCPF"
              errors={errors.psCtCPF?.message}
              label={"CPF"}
              placeholder="CPF do autorizado para download do XML"
              mask={masksApp.CPF}

            />
            <ControllerMaskTextField
              control={control}
              name="psCtCNPJ"
              errors={errors.psCtCNPJ?.message}
              label={"CNPJ"}
              placeholder="CNPJ do autorizado para download do XML"
              mask={masksApp.CNPJ}
            />
          </div>

          <div className={style["form-row"]}>

            <ControllerTextField
              control={control}
              name="psCtTelefone"
              errors={errors.psCtTelefone?.message}
              label={"Telefone"}
              placeholder="Telefone do contato"
            />
            <ControllerTextField
              control={control}
              name="psCtCelular"
              errors={errors.psCtCelular?.message}
              label={"Celular"}
              placeholder="Celular do contato"
            />
            <ControllerTextField
              control={control}
              name="psCtEmail"
              errors={errors.psCtEmail?.message}
              label={"E-mail"}
              placeholder="Informe o nome fantasia da empresa"
            />
          </div>

          <div className={style["form-row-checkbox"]}>

            <ControllerCheckBox
              name="psCtPrincipal"
              control={control}
              label="Principal"
            />
            <ControllerCheckBox
              name="psCtXMLNFCe"
              control={control}
              label="Enviar NFC-e" />
            <ControllerCheckBox
              name="psCtXMLNFe"
              control={control}
              label="Enviar NF-e"
            />
            <ControllerCheckBox
              name="psCtXMLAutorizado"
              control={control}
              label="Download XML" />
          </div>


          <FooterFormButtons min={true} btn_cancelar={() => {
            setShowForm(false);
          }} btn_salvar={handleSubmit(submit)} />
        </form>
      </main>
    </>
  )
}

