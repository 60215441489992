import { z } from "zod";

export const schemaCodigoAlternativo = z
  .object({
    prCARecStatus:z.any(),
    prCATipo: z.string().min(1,"Tipo de código alternativo inválido"),
    prCACodigo: z.string().min(1,"O código alternativo não pode ficar em branco"),
    prCAXML: z.boolean(),
  });

export type CodigoAlternativoFormProps = z.infer<typeof schemaCodigoAlternativo>;
