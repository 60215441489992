import { recStatusModify } from "../../../../../../utils/rec-status.modify";

export const modifyInformationCondicoesPagamento = async (dados: any) => {
  await dados.forEach((value: any) => {
    value.cnPgRecStatus = recStatusModify.table(value.bcosRecStatus)
 });
  return dados;
};


