import style from "./eventos.module.scss";

import { useContext, useEffect, useState } from "react";
import { ActionButtonTable } from "../../../../../../../context/actionButtonTable";
import { idItemSelectedContext } from "../../../../../../../context/idItemSelected";
import { uuid } from "../create.informations";
import { FooterFormButtons } from "../../../../../../../components/footer-form/buttons-form";
import { ControllerCheckBox } from "../../../../../../../components/controllers/checkbox-controller";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { preloadedValuesEvento } from "./default-values-form";
import { CadastroPessoaEventoFormProps, schemaCadastroPessoaEvento } from "./form.config";
import { ID } from "../../../../../../../config/environment/id.config";
import { Loading } from "../../../../../../../components/loading/loading";
import { ValuesPessoaForm } from "../types/types-cadastro-pessoas";
import { recStatusModify } from "../../../../../../../utils/rec-status.modify";
import { modifyInformationEventos } from "../../modify-information";
import { ControllerTextField } from "../../../../../../../components/controllers/ControllerTextField";

export const EventosForm = ({
  arrayForm, setArrayForm, setShowForm, getValuesForm, setValuesForm
}: ValuesPessoaForm) => {
  const { actionButton } = useContext(ActionButtonTable);
  const actionForm = actionButton === "edit" || actionButton === "copy"
  const { idItemSelected } = useContext(idItemSelectedContext);
  const [loading, setLoading] = useState(false)

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CadastroPessoaEventoFormProps>({
    mode: "all",
    resolver: zodResolver(schemaCadastroPessoaEvento),
    defaultValues: preloadedValuesEvento,
  });

  let searchId: CadastroPessoaEventoFormProps = arrayForm.find(
    (value: any) => value[ID.cadastro.pessoaEvento] === idItemSelected.id
  );

  const editValues = () => {
    setValue("psEvRecStatus", recStatusModify.form(searchId.psEvRecStatus))
    setValue("psEvObservacao", searchId.psEvObservacao)
    setLoading(false)
  }
  const submit = (data: CadastroPessoaEventoFormProps) => {
    if (actionButton === "edit") {
      searchId.psEvRecStatus = recStatusModify.form(searchId.psEvRecStatus)
      searchId.psEvObservacao = data.psEvObservacao
      setValuesForm("pessEventos",arrayForm)
      setArrayForm([...arrayForm])
      setShowForm(false)
      return

    }
    setValuesForm("pessEventos",arrayForm)
    setArrayForm([...arrayForm, { id: uuid(), ...data }])
    setShowForm(false)
    return
  };

  useEffect(() => {
    setLoading(true);
    if (actionForm) {
      editValues()
      return
    }
    setLoading(false)
  }, []);

  return (
    <>
      {loading && <Loading />}
      <main className={style["content"]}>
        <form>

          <ControllerCheckBox
            control={control}
            name="psEvRecStatus"
            label="Desativado"
            recStatus={true}
          />

          <ControllerTextField
            control={control}
            multiline={true}
            name="psEvObservacao"
            errors={errors.psEvObservacao?.message}
            label="Observações do evento"
            placeholder="Observações do evento"
            className={style["content_input"]}
          />

          <FooterFormButtons min={true} btn_cancelar={() => setShowForm(false)} btn_salvar={handleSubmit(submit)} />
        </form>

      </main>
    </>
  );
};
