import { z } from "zod";

export const schemaIPI = z.object({
  operIPICST: z.string({required_error:"Informe a CST para cálculo do IPI"}).trim().min(1),
  operIPIEnquandramento: z.string({required_error:"Informe a CST para cálculo do IPI"}).trim().min(1,"Complete o campo corretamente"),
  operIPIAcumulaBCICMSProprio: z.boolean(),
  operIPIAcumulaBCICMSDestino: z.boolean(),
});

export type IPIFormProps = z.infer<
  typeof schemaIPI
>;
