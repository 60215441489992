import style from "./style.module.scss";
import close from "../../../assets/icons/gerais/close.svg"
import done from "../../../assets/icons/gerais/done.svg"
interface ButtonProps {
  name: string;
  styleButton: "save" | "save-sm" | "cancel" | "cancel-sm";
  onClick: () => void;
  type?: "button" | "submit" 
}

export const ButtonForm = ({ name, type, styleButton, onClick }: ButtonProps) => {
    let nameStyle = ""
    let icon = ""
  switch (styleButton) {
    case "save":
        nameStyle = "button-save"
        icon = ""
      break;
    case "save-sm":
        nameStyle = "button-save-sm"
        icon = done
      break;
    case "cancel":
        nameStyle = "button-cancel"
        icon = ""
      break;
    case "cancel-sm":
        nameStyle = "button-cancel-sm"
        icon = close
      break;
    default:
      break;
  }
  return <button className={style[nameStyle]} onClick={onClick} type={type}>
    {name} {!!icon && <img src={icon} className={style["button_icon"]} />}
    </button>;
};

ButtonForm.defaultProps = {
    name:"Button",
    type:"button",
    styleButton:'save',
    onClick:() => {}
}