import { z } from "zod";

export const schemaUnidadesDeMedidas = z.object({
  unidRecStatus: z.any(),
  unidTipo: z.number(),
  unidUnidade: z.string().min(1,"Unidade de medida inválida."),
  unidDescricao: z
    .string()
    .min(1,"Descrição da unidade de medida inválida."),
});

export type UnidadesDeMedidasFormProps = z.infer<
  typeof schemaUnidadesDeMedidas
>;
