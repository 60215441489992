import { ComboBox } from "@fluentui/react";
import style from "../ncm.module.scss";
import { optionsModalidadeDaBaseDeCálculo } from "../../optionsInput/options.input";
import { Controller } from "react-hook-form";
import { SubtitleForm } from "../../../../../../../../components/text/form/subtitle/subtitle";
import { maskTypingAliquota, maskTypingUnidade } from "../../../../../../../../config/masks/masks";
import { ControllerNumberTextField } from "../../../../../../../../components/controllers/number-textfield-controller";

type propsForm = {
  control: any;
  name:string,
  register:any;
  watchIPIModalidadeBC:any;
  watchIPIAliquota:any
  errorMessage:any;
  trigger: any;
};

export const IPIForm = ({control,watchIPIModalidadeBC,watchIPIAliquota,errorMessage, trigger}: propsForm) => {

 const modalidadeType = watchIPIModalidadeBC === "0" 

  return (
    <>
      <SubtitleForm subtitle=" IPI" />
      <div className={style["section_content-box"]}>
        <Controller
          name="ncmsIPIModalidadeBC"
          control={control}
          render={({ field: { onChange, value } }) => (
            <ComboBox
              selectedKey={value}
              className={style["middle"]}
              label="Modalidade da Base de Cálculo"
              placeholder="Informe se IPI por alíquota ou unidade"
              options={optionsModalidadeDaBaseDeCálculo}
              errorMessage={errorMessage.ncmsIPIModalidadeBC?.message} 
              required
              onChange={(_, value: any) => {
                onChange(value.key);
                trigger("ncmsIPIAliquota");
              }}
            />
          )}
        />
        <ControllerNumberTextField
          name="ncmsIPIAliquota"
          control={control}
          label={"Aliquota/Fração por unidade"}
          errorMessage={errorMessage.ncmsIPIAliquota?.message}
          style={style}
          suffix={modalidadeType? "%" : "$"}
          maxLength={modalidadeType? 6 : 20}
          placeholder={
            watchIPIModalidadeBC === 0
              ? "Aliquota de IPI"
              : "Unidade de cálculo"
          }
          updateMask={(e: any) => {
            return modalidadeType?maskTypingAliquota(e) : maskTypingUnidade(e)
          }}
        />
      </div>
    </>
  );
};
