
export const preloadedValues = {
    cfopRecStatus: false,
    cfopPermiteNFe: false,
    cfopPermiteNFCe: false,
    cfopPermiteDevolucao: false,
    cfopPermiteAjusteEstorno: false,
    cfopPermiteRetorno: false,
    cfopPermiteRemessa: false,
    cfopPermiteComunicacao: false,
    cfopPermiteTransporte: false,
    cfopPermiteCombustivel: false,
    cfopTipo: -1,
    cfopCodigo: "",
    cfopNaturezaOperacao: "",
    cfopAplicacao: "",
  };