export const columnsCentroDeCusto = [
    {
      key: "column1",
      name: "Descrição",
      fieldName: "cnCtDescricao",
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
    },
  
   
    {
      key: "column2",
      name: "Status",
      fieldName: "cnCtRecStatus",
      minWidth: 120,
      maxWidth: 160,
      isResizable: true,
    },

  ];
  
