export const uuid = () => {
  let group1 = Math.random() * (1000 - 0) + 5;
  let group2 = Math.random() * (1000 - 0) + 5;
  let group3 = Math.random() * (1000 - 0) + 5;
  let group4 = Math.random() * (1000 - 0) + 5;
  return `${Math.round(group1)}-${Math.round(group2)}-${Math.round(
    group3
  )}-${Math.round(group4)}`;
};

let date = new Date();
let current_date = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
let current_time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}`;

export let date_time = `${current_date}T${current_time}`;
