export const columns = [
  {
    key: "column1",
    name: "Tipo",
    fieldName: "cfopTipo",
    isResizable: true,
  },
  {
    key: "column2",
    name: "CFOP",
    fieldName: "cfopCodigo",
    minWidth: 80,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "column3",
    name: "Natureza da Operação",
    fieldName: "cfopNaturezaOperacao",
    minWidth: 200,
    maxWidth: 220,
    isResizable: true,
  },
  {
    key: "column4",
    name: "NF-e",
    fieldName: "cfopPermiteNFe",
    minWidth: 50,
    maxWidth: 70,
    isResizable: true,
  },
  {
    key: "column5",
    name: "NFC-e",
    fieldName: "cfopPermiteNFCe",
    minWidth: 80,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "column6",
    name: "Devolução",
    fieldName: "cfopPermiteDevolucao",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "column7",
    name: "Ajuste/Estorno",
    fieldName: "cfopPermiteAjusteEstorno",
    minWidth: 140,
    maxWidth: 160,
    isResizable: true,
  },   {
    key: "column11",
    name: "Status",
    fieldName: "cfopRecStatus",
    minWidth: 70,
    maxWidth: 90,
    isResizable: true,
  }, 
];
