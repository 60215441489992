import { api } from "./api";

class Api {
  async post(url: string, local: string, data: any) {
    return await api
      .post(`${url}/${local}`, data)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  }

  async getAll(urlBase: string, local: string, search: string = '', xpagination: any = undefined, desativados: boolean = false) {
    let url: string = `${urlBase}/${local}`;
    let filtros: string = ""
    if (search !== '')
      filtros += `${filtros.length > 0 ? '&' : '?'}filtro=${search}`
    if (desativados !== false)
      filtros += `${filtros.length > 0 ? '&' : '?'}desativados=true`
    url += filtros

    return await api
      .get(url, {headers: {'X-Pagination': xpagination} })
      .then((resp) => {
        const result = resp;
        return result;
      })
      .catch((err: any) => {
        console.error(err);
        return err;
      });
  }

  async getItem(url: string, local: string, id: string) {
    return await api
      .get(`${url}/${local}/${id}`)
      .then((resp) => {
        return resp;
      })
      .catch((err: any) => {
        console.error(err);
        return false;
      });
  }

  //TODO Jeff: eliminar por estar sem uso?
  async search(url: string, local: string, itemName: string) {
    return await api
      .get(`${url}/${local}/busca/${itemName}`)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  }

  async remove(url: string, local: string, id: string) {
    return await api
      .delete(`${url}/${local}/${id}`)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  }
}

export const apiApp = new Api();
