import { createContext, ReactNode, useState } from "react";

type NamePageLoginContextProps = {
  children: ReactNode;
};

type NamePageLoginContextType = {
  namePageLogin: string;
  setNamePageLogin: (text:string) => void;
};

const initialValue = {
  namePageLogin: 'login',
  setNamePageLogin: (text:string) => {},
};

export const NamePageLogin = createContext<NamePageLoginContextType>(initialValue);

export const NamePageLoginContextProvider = ({ children }: NamePageLoginContextProps) => {
  const [namePageLogin, setNamePageLogin] = useState(initialValue.namePageLogin);

  return (
    <NamePageLogin.Provider value={{ namePageLogin, setNamePageLogin }}>
      {children}
    </NamePageLogin.Provider>
  );
};
