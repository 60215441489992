import { useEffect } from "react";
import { FooterFormButtons } from "../../../../../../../components/footer-form/buttons-form";
import { SubtitleForm } from "../../../../../../../components/text/form/subtitle/subtitle";
import style from "./style.module.scss";
import { InformacoesEspecificasFormProps, schemaInformacoesEspecificas } from "./form.config";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { IPropsFormProduto } from "../../interface/props.interface-form";
import { ControllerTextField } from "../../../../../../../components/controllers/ControllerTextField";

export const InformacoesEspecificaProduto = ({getValuesForm,setValuesForm,setCloseExpander,closeExpander}:IPropsFormProduto) => {

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<InformacoesEspecificasFormProps>({
    mode: "all",
    resolver: zodResolver(schemaInformacoesEspecificas),
  });

  const ANPCodigo = "210203001"
  const submit = () => {
    setCloseExpander({ state: true, open: false })
  }


  const watchANP = watch("prIEcombANPCodigo");
  let ANPCodIsTrue = getValues("prIEcombANPCodigo") === ANPCodigo;

  useEffect(() => {
    if (ANPCodIsTrue) {
      setValue("prIEcombPercGLPPetroleo", 0);
      setValue("prIEcombPercGasNaturalNacional", 0);
      setValue("prIEcombPercGasNaturalImportado", 0);
    }
  }, [watchANP]);

  return (
    <main className={style["main"]}>
      <SubtitleForm subtitle={"Medicamentos"} />

      <div className={style["form-row"]}>
        <ControllerTextField
          name="prIEmedAnvisaRegistro"
          label="Registro na Anvisa"
          placeholder="Número de registro na Anvisa"
          className={style.inputMiddle}
          control={control}
        />
        <ControllerTextField
          name="prIEmedANVISAMotivoIsencao"
          label="Motivo de isenção"
          placeholder="Motivo de isenção na Anvisa"
          className={style.inputLarge}
          control={control}
        />
        <ControllerTextField
          name="prIEmedPrecoMaximoConsumidor"
          label="Preço máximo ao consumidor"
          placeholder="Preço máximo ao consumidor"
          className={style.inputMiddle}
          control={control}
        />
      </div>

      <SubtitleForm subtitle={"Combustíveis"} />

      <div>
        <div className={style["form-row"]}>
          <ControllerTextField
            name="prIEcombANPCodigo"
            label="Código na ANP"
            placeholder="Código do produto na ANP"
            className={style.inputLarge}
            control={control}
          />
          <ControllerTextField
            name="prIEcombANPDescricao"
            label="Descrição na ANP"
            placeholder="Descrição do produto na ANP"
            className={style.inputLarge}
            control={control}
          />
        </div>
        <div className={style["form-row"]}>
          <ControllerTextField
            name="prIEcombPercGLPPetroleo"
            label="Percentual GLP/Petróleo"
            placeholder="Percentual do GLP"
            className={style.inputLarge}
            control={control}
            readOnly={ANPCodIsTrue}
            prefix="%"

          />
          <ControllerTextField
            name="prIEcombPercGasNaturalNacional"
            label="Percentual de gás natural nacional"
            placeholder="Percentual de gás natural nacional"
            className={style.inputLarge}
            control={control}
            readOnly={ANPCodIsTrue}
            prefix="%"

          />
          <ControllerTextField
            name="prIEcombPercGasNaturalImportado"
            label="Percentual de gás natural Importado"
            placeholder="Percentual de gás natural Importado"
            className={style.inputLarge}
            control={control}
            readOnly={ANPCodIsTrue}
            prefix="%"

          />

          <ControllerTextField
            name="prIEcombValorPartida"
            label="Valor de partida"
            placeholder="Digite o valor de partida"
            className={style.inputLarge}
            control={control}
            prefix="%"
          />
        </div>
      </div>

      <SubtitleForm subtitle={"Recopi"} />
      <ControllerTextField
        name="prlERECOPI"
        label="Número do RECOPI"
        placeholder="Digite o valor"
        className={style.inputLarge}
        control={control}
      />

<FooterFormButtons
          min={true}
          btn_salvar={handleSubmit(submit)}
          btn_cancelar={() => setCloseExpander({ state: false, open: false })}
        />
    </main>
  );
};
