export const columnsDespesas = [
    {
      key: "column1",
      name: "Despesa",
      fieldName: "prPDDescricao",
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column2",
      name: "Valor",
      fieldName: "prPDValor",
      minWidth: 160,
      maxWidth: 240,
      isResizable: true,
    },

    {
      key: "column3",
      name: "Status",
      fieldName: "prPDRecStatus",
      minWidth: 120,
      maxWidth: 160,
      isResizable: true,
    },

  ];
  
