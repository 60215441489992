import { ID } from "../../../../../../../config/environment/id.config";
import { getLocation } from "../../../../../../../config/location/location.search";


type FormPropsAction = {
    cepNumber: any;
    setValue: any;
}


export const getCep = async ({ cepNumber, setValue }: FormPropsAction) => {
    const cep = cepNumber.replace(/\D/g, "");
    if (cep.length !== 8) return false;

    await fetch(`http://viacep.com.br/ws/${cep}/json/`).then((res) =>
        res.json().then((res) => {
            setValue("psEnLogradouro", res.logradouro);
            setValue("psEnComplemento", res.complemento);
            setValue("psEnBairro", res.bairro);
            let ufFind: any = getLocation.getUFBySigla(res.uf);
            setValue("psEnUfdeId", ufFind[ID.cadastro.uf]);
            let cidadeFind: any = getLocation.getCityByName(res.localidade);
            setValue("psEnCidaId", cidadeFind[ID.cadastro.city]);
            let paisFind: any = getLocation.getCountryByName("Brasil");
            setValue("psEnPaisId", paisFind[ID.cadastro.country]);
        }).catch((err) => {
            console.error(err);
            return;
        })

    )
}

