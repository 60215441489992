import { Checkbox } from "@fluentui/react";
import { Controller } from "react-hook-form";
import { IControllerCheckbox } from "./types/types";
import style from "./style.module.scss"
export const ControllerCheckBox = ({
  name,
  label,
  className="",
  control,
  recStatus = false,
  disabled = false,
}: IControllerCheckbox) => {
  return (
    <div className={recStatus ? style["checkbox-recstatus"] : style["checkbox-controller"]}>
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Checkbox
          className={className}
          checked={value}
          label={label}
          disabled={disabled}
          onChange={(_, value) => {
            onChange(value);
          }}
        />
      )}
    />
    </div>
  );
};
