import { columnsProdutos } from "./columns/cadastro-produto.column";
import { ID } from "../../../../../config/environment/id.config";
import { endpoint } from "../../../../../config/environment/endpoint.config";
import { ConstructTable, IConstructTable } from "../../../../../components/construct/construct-table/construct-table";
import { useState } from "react";

export const CadastroProdutos = () => {
  const [isLoading,setIsLoading] = useState(false);

  const Informations: IConstructTable = {
    endpoint: endpoint.cadastro,
    idNameTable: ID.cadastro.produto,
    nameTable: "Cadastro: Produtos",
    columnsTable: columnsProdutos,
    nameMenu: "cadastros",
    nameSubmenu: "produto",
    modifyData: (data: any) => {},
    isLoading,setIsLoading
  };
  return <ConstructTable {...Informations} />;
};

