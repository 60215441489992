import { useState } from "react";
import { IConstructTable, ConstructTable } from "../../../../../../components/construct/construct-table/construct-table";
import { endpoint } from "../../../../../../config/environment/endpoint.config";
import { ID } from "../../../../../../config/environment/id.config";
import { columns } from "./columns/cfop.column";
import { modifyInformationCFOP } from "./modifyInformation/modifyInformation";

export const CFOP = () => {
  const [isLoading,setIsLoading] = useState(false);

  const Informations: IConstructTable = {
    endpoint: endpoint.fiscal,
    idNameTable: ID.fiscal.cfop,
    nameTable: "CFOP: Códigos fiscais de operação",
    columnsTable: columns,
    nameMenu: "fiscal",
    nameSubmenu: "cfop",
    modifyData: modifyInformationCFOP,
    isLoading,setIsLoading
  };
  return <ConstructTable {...Informations} />;
};
