import { useState } from "react";
import {
  IConstructTable,
  ConstructTable,
} from "../../../../../../components/construct/construct-table/construct-table";
import { endpoint } from "../../../../../../config/environment/endpoint.config";
import { ID } from "../../../../../../config/environment/id.config";
import { columnsCentroDeCusto } from "../centro-de-custo/columns/columns";

export const PlanoDeContasTable = () => {
  const [isLoading,setIsLoading] = useState(false);

  const Informations: IConstructTable = {
    endpoint: endpoint.cadastro,
    idNameTable: ID.cadastro.planoContas,
    nameTable: "Planos de Contas",
    columnsTable: columnsCentroDeCusto,
    nameMenu: "cadastros",
    nameSubmenu: "planoDeContas",
    modifyData: (data: any) => {},
    isLoading,setIsLoading
  };
  return <ConstructTable {...Informations} />;
};
