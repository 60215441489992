
type props = {
  id: any;
  data: any;
  currentPage: string;
};
export const editItemVerification = ({ id, data, currentPage }: props) => {
  const a = String(id).split(":")
  let withId = { ...data };
  withId[a[0]] = a[1]

  const noId = data;
  if (currentPage === "edit") {    
    return process.env.REACT_APP_LOCAL === "true" ? noId : withId;
  }
  return noId;
};
