export const columnsContaBancaria = [
  {
    key: "column1",
    name: "Conciliado até",
    fieldName: "bcCtDataConciliacao",
    minWidth: 160,
    maxWidth: 180,
    isResizable: true,
  },
  {
    key: "column2",
    name: "Agência",
    fieldName: "bcCtAgencia",
    minWidth: 160,
    maxWidth: 180,
    isResizable: true,
  },
  {
    key: "column3",
    name: "Conta",
    fieldName: "bcCtConta",
    isResizable: true,
  },
  {
    key: "column4",
    name: "Status",
    fieldName: "bcCtRecStatus",
    minWidth: 120,
    maxWidth: 140,
    isResizable: true,
  },
 
]