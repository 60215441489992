import {
  DefaultButton,
  DirectionalHint,
  IContextualMenuProps,
} from "@fluentui/react";
import style from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { TabsContext } from "../../../context/allTabs";
import { TabSelectedContext } from "../../../context/tabSelected";

export type ITab = {
  key: string;
  name: string;
  category: string;
};

interface IStyleMenu {
  icon: any;
  menu: ITab[];
  isClose: boolean;
}

export const ButtonNavOptions = ({ icon, isClose, menu }: IStyleMenu) => {
  const navigate = useNavigate();
  const [isBeakVisible] = useBoolean(false);
  const [useDirectionalHintForRTL] = useBoolean(false);
  const [directionalHint] = React.useState<DirectionalHint>(
    DirectionalHint.rightTopEdge
  );
  const [directionalHintForRTL] = React.useState<DirectionalHint>(
    DirectionalHint.rightTopEdge
  );

  const { newTab, setNewTab } = React.useContext(TabsContext);
  const { setTabSelected } = React.useContext(TabSelectedContext);

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const openPage = (props: ITab) => {
    if (newTab.length === 0) {
      navigate(`/linko/${props.category}/${props.key}`);
      setTabSelected(props.key);
      return setNewTab([...newTab, props]);
    }
    let tab = newTab.filter((value) => value.key === props.key);

    if (tab.length === 0) {
      navigate(`/linko/${props.category}/${props.key}`);
      setTabSelected(props.key);

      return setNewTab([...newTab, props]);
    }
    setTabSelected(props.key);

    navigate(`/linko/${props.category}/${props.key}`);
  };

  const menuProps: IContextualMenuProps | any = React.useMemo(
    () => ({
      isBeakVisible: isBeakVisible,
      directionalHint: directionalHint,
      directionalHintForRTL: useDirectionalHintForRTL
        ? directionalHintForRTL
        : undefined,
      gapSpace: 0,
      onItemClick: (e: any, value: any) => {
        openPage(value);
      },
      beakWidth: 20,
      directionalHintFixed: false,
      items: menu,
    }),
    [
      isBeakVisible,
      directionalHint,
      useDirectionalHintForRTL,
      directionalHintForRTL,
      menu,
      openPage,
    ]
  );
  return (
    <div
      className={
        style[isClose ? "button_content-close" : "button_content-open"]
      }
      aria-label={menu[0].category}
    >
      <>
        <DefaultButton
          className={style[isClose ? "button_nav-close" : "button_nav "]}
          menuProps={menuProps}
        >
          <div
            className={
              style[isClose ? "button_options-close" : "button_options"]
            }
          >
            <img className={style["button_options-icon"]} src={icon} alt="" />
            {isClose ? (
              <></>
            ) : (
              <span className={style["button_options-name"]}>
                {menu[0].category}
              </span>
            )}
          </div>
        </DefaultButton>
      </>
    </div>
  );
};
