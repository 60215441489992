export const columns = [
  {
    key: "column1",
    name: "NCM",
    fieldName: "ncmsNCM",
    minWidth: 60 ,
    maxWidth: 80,
    isResizable: true,
  },
  {
    key: "column2",
    name: "EX",
    fieldName: "ncmsCodigoEX",
    minWidth: 60 ,
    maxWidth: 80,
    isResizable: true,
  },
  {
    key: "column3",
    name: "Descrição",
    fieldName: "ncmsDescricao",
    minWidth: "",
    maxWidth: "",
    isResizable: true,
  },
  {
    key: "column4",
    name: "Status",
    fieldName: "ncmsRecStatus",
    minWidth: 140,
    maxWidth: 160,
    isResizable: true,
   
  },
];
