
export const preloadedValues = {
  operICMSCST: "",
  operICMSCSOSN: "",
  operICMSModalidadeBC: "",
  operICMSDesoneracaoMotivo: "",
  operICMSDesoneracaoFormulaBC: "",
  operICMSReducaoBC: "",
  operICMSDiferimentoBC: "",
  operUfdeId: "",
  operICMSAliquotaInternaUF: "",
  operICMSCodigoBeneficioFiscal: "",
  operICMSModalidadeBCST: "",
  operICMSSTMVA: "",

};
