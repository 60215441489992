import "./style.scss";

export const UserInformations = () => {
  return (
    <div data-testid="informations_user" className="userInformation">
      <span className="userInformation__name">Nome: Cristiano</span>
      <span className="userInformation__version">Versão: v XX.XX.X</span>
      <button className="userInformation__button">Editar Perfil</button>
    </div>
  );
};
