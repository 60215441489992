export const columnsFormaCondicao = [
    {
      key: "column1",
      name: "Descrição",
      fieldName: "cnFmCnPgId",
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
    },

    {
      key: "column1",
      name: "Status",
      fieldName: "cnFmRecStatus",
      minWidth: 140,
      maxWidth: 190,
      isResizable: true,
    },
  ]