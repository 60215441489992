import { useEffect, useState } from "react";
import { columnsEnderecos } from "../../columns/endereco.column";
import { ConstructTableForm, IConstructTableForm } from "../../../../../../../components/construct/construct-table-form/construct-table-form";
import { modifyInformationEnderecos } from "../../modify-information";
import { ID } from "../../../../../../../config/environment/id.config";
import { EnderecosForm } from "./endereco.form";
import { ValuesPessoaTable } from "../types/types-cadastro-pessoas";

export const EnderecosPessoa = ({ arrayForm, setArrayForm, closeExpander, setCloseExpander, getValuesForm, setValuesForm }: ValuesPessoaTable) => {
  const [showForm, setShowForm] = useState(false);

 
  const Informations: IConstructTableForm = {
    columns: columnsEnderecos,
    propIdName: ID.cadastro.pessoaEndereco,
    modifyValuesApi: modifyInformationEnderecos,
    arrayWithValuesForm: arrayForm,
    setArrayWithValuesForm: setArrayForm,
    categoryId: "cadastro",
    showForm: showForm,
    setShowForm: setShowForm,
    navigatePage: "",
    setCloseExpander,closeExpander,
    form: <EnderecosForm arrayForm={arrayForm} setArrayForm={setArrayForm} setShowForm={setShowForm} getValuesForm={getValuesForm} setValuesForm={setValuesForm} />,
  };
  return <ConstructTableForm {...Informations} />;
};