import { z } from "zod";

export const schemaOperacoesFiscais = z
  .object({
   
        prOFRecStatus: z.any(),
        prOFMod: z.string().min(1,"Modelo do documento fiscal inválido."),
        prOFOperId: z.string().min(1,"Especifique o propósito da operação fiscal."),
        prOFProposito: z.string().min(1,"Informe a operação fiscal a ser utilizada para o modelo e proósito"
          ),
      });

export type OperacoesFiscaisFormProps = z.infer<typeof schemaOperacoesFiscais>;
