import "../app/styles/reset.scss";
import { ContextsApp } from "./contextsApp";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { MyTheme } from "./styles/theme";
import { ThemeProvider } from "@fluentui/react";
import { AllRoutes } from "./routes/routes";
import { TokenUpdate } from "./services/api/api";

export const App = () => {
  initializeIcons();
  return (
    <>
    {TokenUpdate()}
      <ThemeProvider theme={MyTheme}>
        <ContextsApp>
          <AllRoutes />
        </ContextsApp>
      </ThemeProvider>
    </>
  );
};

