import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { ControllerCheckBox } from "../../../../../../../../components/controllers/checkbox-controller"
import { ControllerComboBox } from "../../../../../../../../components/controllers/combobox-controller"
import { FooterFormButtons } from "../../../../../../../../components/footer-form/buttons-form"
import { SubtitleForm } from "../../../../../../../../components/text/form/subtitle/subtitle"
import { PISCofinsFormProps, schemaPISCofins } from "./form.config"
import { IOperacoesFiscais } from "../../interface/form.interface"
import { LContribuicaoCst } from "../../options/options.form"
import { preloadedValues } from "./default-values-form"
import { useState, useEffect } from "react"
import { Loading } from "../../../../../../../../components/loading/loading"
import style from "./style.module.scss"
export const PISCOFINS = ({ getValuesForm, setValuesForm, closeExpander, setCloseExpander }: IOperacoesFiscais) => {

  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PISCofinsFormProps>({
    mode: "onChange",
    resolver: zodResolver(schemaPISCofins),
    defaultValues: preloadedValues
  });

  const setFormValues = () => {
    setLoading(true)
    setValue("operPISDeduzICMS", getValuesForm("operPISDeduzICMS"))
    setValue("operPISSCST", getValuesForm("operPISSCST"))
    setValue("operCOFINSDeduzICMS", getValuesForm("operCOFINSDeduzICMS"))
    setValue("operCOFINSCST", getValuesForm("operCOFINSCST"))
    setLoading(false)
  }

  const submit = async (data: PISCofinsFormProps) => {
    setValuesForm("operPISDeduzICMS", data.operPISDeduzICMS)
    setValuesForm("operPISSCST", data.operPISSCST)
    setValuesForm("operCOFINSDeduzICMS", data.operCOFINSDeduzICMS)
    setValuesForm("operCOFINSCST", data.operCOFINSCST)
    closeExpander({ state: true, open: false })
  }

  useEffect(() => {
    if (closeExpander.state) setFormValues()
  }, [])

  return (
    <>
      {loading && <Loading />}
      <form>
        <SubtitleForm subtitle={"PIS"} />
        <ControllerCheckBox name={"operPISDeduzICMS"} control={control} label="Deduzir ICMS da BC" />
        <ControllerComboBox options={LContribuicaoCst} name={"operPISSCST"} control={control} label="CST" placeholder="CST do PIS" errors={errors.operPISSCST?.message} />
        <SubtitleForm subtitle={"COFINS"} />
        <ControllerCheckBox name={"operCOFINSDeduzICMS"} control={control} label="Deduzir ICMS da BC" />
        <ControllerComboBox options={LContribuicaoCst} name={"operCOFINSCST"} control={control} label="CST" placeholder="CST do COFINS" errors={errors.operCOFINSCST?.message} />
        <FooterFormButtons min={true} btn_salvar={handleSubmit(submit)}
          btn_cancelar={() => closeExpander({ state: false, open: false })} />
      </form>
    </>
  )
}