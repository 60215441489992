import { TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import { IControllerTextField } from "./types/types";
import style from "./style.module.scss";


export const ControllerTextField = ({
  name, label, placeholder, className = style["fields"], control, errors, prefix, suffix, readOnly = false, 
  disabled = false, multiline = false, maxLength = "",format = false,formatFunction = () => {}

}: IControllerTextField) => {
  

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          value={value}
          label={label}
          className={multiline ? style["textarea"] : className}
          disabled={disabled}
          prefix={prefix}
          suffix={suffix}
          placeholder={placeholder}
          multiline={multiline}
          errorMessage={errors}
          maxLength={maxLength}
          readOnly={readOnly}
          
          onChange={format ? (e) => onChange(formatFunction(e)) : onChange} />
      )} />
  );
};

