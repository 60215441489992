import { formatDate } from "../../../../../../../components/controllers/calendar-controller/format-date.config";

export const preloadedValues = {
  bcCtRecStatus: false,
  bcCtDataConciliacao: formatDate(new Date()),
  bcCtAgencia: "",
  bcCtAgenciaDV: "",
  bcCtConta: "",
  bcCtContaDV: "",
  bcCtSaldo: 0,
};
