export const formatDate = (props: any) => {
    if (typeof(props) === "string") return props;
    
    const day = props.getDate() || null;
    const month = props.getMonth() + 1;
    const year = props.getFullYear();
    return `${day}/${month}/${year}`;
  };

  export const convertDateInString = (value: string) => {
    const date = formatDate(value);
    return convertStringInDate(date);
  };

 export const convertStringInDate = (value: string) => {
    const convertDate = value.split("/");
    return new Date(
      Number(convertDate[2]),
      Number(convertDate[1]) - 1,
      Number(convertDate[0])
    );
  };