type convertProps = {
  list: any;
  id: string;
  prop: string;
  items: any;
  setItems: any;
  isUF?: boolean
  propSigla?: string
};

type listFilter = {
  list: any[];
  prop: string;
  valueSearch: any;
};

let options: any = []
export const convertForComboBox = async ({ id, list, prop, setItems, isUF = false, propSigla = "ufdeSigla" }: convertProps) => {
  list.map((value: any, index: number) => {
    let data: any = {
      key: `${value[id]}`,
      text: !isUF ? value[prop] : value[prop] + ' - ' + value[propSigla],
    };
    options.push(data)
    setItems(options)
    if (index === list.length - 1) options = []
  }
  );
};

export const filterItems = ({ list, prop, valueSearch }: listFilter) => {
  let search: any = list.find(
    (value: any) => value[prop] === valueSearch
  );
  return search;
};
