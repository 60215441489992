import { TextField } from "@fluentui/react";
import { columnsDespesas } from "./columns/custos-despesas.column";
import style from "../style.module.scss";
import { DespesasExtras } from "./despesas-extras.form";
import { useEffect, useState } from "react";
import { FooterFormButtons } from "../../../../../../../../components/footer-form/buttons-form";
import { ConstructTableForm, IConstructTableForm } from "../../../../../../../../components/construct/construct-table-form/construct-table-form";
import { moneyFormatValue } from "../../../../../../../../config/RegEx/regex";

type Despesas = {
  getValuesForm:any, 
  setValuesForm:any, 
  closeExpander:any,
  setCloseExpander:any,
  showFormOutrasDespesas:any,
};

export const OutrasDespesas = ({
  closeExpander,getValuesForm,setValuesForm,showFormOutrasDespesas,setCloseExpander
}: Despesas) => {
  const [showForm, setShowForm] = useState(false);
  const [arrayForm,setArrayForm] = useState([])
  const [getItemSelectedTable,setGetItemSelectedTable] = useState<any>("")

  const Informations: IConstructTableForm = {
    columns: columnsDespesas,
    propIdName: "id",
    modifyValuesApi: () => {},
    arrayWithValuesForm: arrayForm,
    setArrayWithValuesForm: setArrayForm,
    categoryId: "cadastro",
    showForm: showForm,
    setShowForm: setShowForm,
    navigatePage: "",
    setGetItemSelectedTable,
    closeExpander,setCloseExpander,
    form: <DespesasExtras arrayForm={arrayForm} setArrayForm={setArrayForm} setShowForm={setShowForm} />,
  };

  useEffect(() => {
    setValuesForm('prPBCustoVDespesasTotais', getItemSelectedTable)
  },[getItemSelectedTable])
  return (
    <>
      <section className={style["table"]}>
        <div className={style[!showForm ? "table_header" : "table_header-off"]}>
          <span className={style["span-label"]}>Outras despesas:</span>
          <span className={style["money-show"]}> {!getItemSelectedTable ? "" : "R$ " +getItemSelectedTable} </span>
        </div>

        <ConstructTableForm {...Informations} />
      </section>
     {!showForm && <FooterFormButtons
        min={true}
        btn_cancelar={()=> showFormOutrasDespesas(false)}
        btn_salvar={()=>showFormOutrasDespesas(false)}
      />}
    </>
  );
};
