export const columnsPrincipal = [
    {
      key: "column1",
      name: "Nome/Razão Social/Nome Fantasia",
      fieldName: "pessFantasia",
      minWidth: 300,
      maxWidth: 320,
      isResizable: true,
    },
    {
      key: "column2",
      name: "Tributação",
      fieldName: "pessRegimeTributacao",
      minWidth: 200,
      maxWidth: 210,
      isResizable: true,
    },
    {
      key: "column3",
      name: "CPF/CNPJ/Documento",
      fieldName: "pessDocumento",
      minWidth: 200,
      maxWidth: 210,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Cidade",
      fieldName: "cidaNome",
      isResizable: true,
    },
    {
      key: "column5",
      name: "UF",
      fieldName: "ufdeSigla",
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column6",
      name: "Status",
      fieldName: "pessRecStatus",
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
    },
  ];
  