
export const endpoint = {
    auth: process.env.REACT_APP_AUTH_API as string,
    cadastro: process.env.REACT_APP_CADASTRO_API as string,
    fiscal: process.env.REACT_APP_FISCAL_API as string,
    financeiro: process.env.REACT_APP_FINANCEIRO_API as string,
    uf: process.env.REACT_APP_CADASTRO_API as string,
    city: process.env.REACT_APP_FISCAL_API as string,
    country: process.env.REACT_APP_FINANCEIRO_API as string,
}
