export const columnsListaPrecos = [
  {
    key: "column1",
    name: "Descrição",
    fieldName: "lsPrDescricao",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "column2",
    name: "Padrão",
    fieldName: "lsPrPadrao",
    minWidth: 80,
    maxWidth: 100,
    isResizable: true,
  },

  {
    key: "column3",
    name: "Auto-atualizar",
    fieldName: "lsPrAtualizacaoAutomatica",
    minWidth: 120,
    maxWidth: 140,
    isResizable: true,
  },
  {
    key: "column4",
    name: "Destino",
    fieldName: "lsPrUfdeId",
    minWidth: 120,
    maxWidth: 140,
    isResizable: true,
  },
  {
    key: "column5",
    name: "Custo fixo",
    fieldName: "lsPrPCustoFixo",
    minWidth: 120,
    maxWidth: 140,
    isResizable: true,
  },

  {
    key: "column6",
    name: "Indicador de comissão",
    fieldName: "lsPrIndComissao",
    minWidth: 280,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "column7",
    name: "Comissão",
    fieldName: "lsPrPFatorComissao",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "column8",
    name: "Desconto máximo",
    fieldName: "lsPrPDescontoMaximo",
    minWidth: 180,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "column9",
    name: "Status",
    fieldName: "lsPrRecStatus",
    minWidth: 80,
    maxWidth: 100,
    isResizable: true,
  },
];
