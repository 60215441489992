
import style from "./style.module.scss";
interface IPropsModal {
  
  title: string;
  content: any;
}
export const Modal = ({content,title}: IPropsModal) => {
  return (
    <section className={style["section"]}>
      <div className={style["section_modal"]}>
        <span className={style["section_modal-title"]}>{title}</span>
        {content}
 
      </div>
    </section>
  );
};
