import { z } from "zod";
import { messagesCFOPForm } from "../messages/messages";
import { UseFormSetValue } from "react-hook-form";

type RuleProps = {
  setValue: UseFormSetValue<any>;
  getValues: (value: any) => void;
  nameCheckbox?: string;
};
export const schemaCFOP = z
  .object({
    cfopRecStatus: z.any(),
    cfopPermiteNFe: z.boolean(),
    cfopPermiteNFCe: z.boolean(),
    cfopPermiteDevolucao: z.boolean(),
    cfopPermiteAjusteEstorno: z.boolean(),
    cfopPermiteRetorno: z.boolean(),
    cfopPermiteRemessa: z.boolean(),
    cfopPermiteComunicacao: z.boolean(),
    cfopPermiteTransporte: z.boolean(),
    cfopPermiteCombustivel: z.boolean(),
    cfopTipo: z.number().nonnegative(messagesCFOPForm.tipo),
    cfopCodigo: z
      .string()
      .nonempty(messagesCFOPForm.inputEmpty)
      .refine((value) => !Number(value.length < 4), {
        message: messagesCFOPForm.codigo,
      }),

    cfopNaturezaOperacao: z
      .string()
      .nonempty(messagesCFOPForm.naturezaDaOperacao),
    cfopAplicacao: z.string().nonempty(messagesCFOPForm.inputEmpty),
  })
  .superRefine((val, ctx) => {
    if (!val.cfopPermiteNFCe && !val.cfopPermiteNFe) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path:["cfopPermiteNFe"],
        message: messagesCFOPForm.NFeAndNFCeNull,
      });
    }
  })
  .superRefine((val, ctx) => {
    let firstValue = val.cfopCodigo.split("")[0];

    if (Number(val.cfopTipo) !== Number(firstValue)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path:["cfopCodigo"],
        message: `O CFOP deve iniciar com ${val.cfopTipo}`,
      });
    }
  });

export const checkedRule = ({ nameCheckbox, setValue }: RuleProps) => {
  switch (nameCheckbox) {
    case "comunicacao":
      setValue("cfopPermiteCombustivel", false);
      setValue("cfopPermiteTransporte", false);
      break;
    case "transporte":
      setValue("cfopPermiteCombustivel", false);
      setValue("cfopPermiteComunicacao", false);
      break;
    case "combustivel":
      setValue("cfopPermiteTransporte", false);
      setValue("cfopPermiteComunicacao", false);
      break;
    default:
      break;
  }
};

export const disabledOptionsRule = ({ getValues, setValue }: RuleProps) => {
  let valueNFCe: any = getValues("cfopPermiteNFCe");
  if (valueNFCe) {
    setValue("cfopPermiteDevolucao", false);
    setValue("cfopPermiteAjusteEstorno", false);
    setValue("cfopPermiteRetorno", false);
    setValue("cfopPermiteRemessa", false);
  }
};

export type CFOPFormProps = z.infer<typeof schemaCFOP>;
