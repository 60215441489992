import { TextField } from "@fluentui/react";
import { useState } from "react";
import { Control, Controller } from "react-hook-form";

const numberToTextField = (value: Number) => {
    if (Number.isNaN(value) || value == null)
        return ""
    else
        return value.toFixed(2).replace('.', ',');
}

const textFieldToNumber = (value: string) => {
    if (value == "")
        return null
    else
        return Number(value.replaceAll('.', '').replace(',', '.'))
}

export interface IControllerTextField{
  name?: string;
  control?: Control<any>;
  style: any;
  label?: string,
  errorMessage?: string,
  suffix?: string,
  maxLength?: number,
  placeholder?: string,
  updateMask: any
}

export const ControllerNumberTextField = ({
    name,
    control,
    style,
    label,
    errorMessage,
    suffix,
    maxLength,
    placeholder,
    updateMask
}: any) => {
    const [text, setText] = useState("---")

    const load=(value: Number) => {
        if (text == "---")
            setText(numberToTextField(value))
    }

    return (
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => (
            <>
              {load(value)}
              <TextField
                value={text}
                className={style["middle"]}
                label={label}
                errorMessage={errorMessage}
                suffix={suffix}
                maxLength={maxLength}
                placeholder={placeholder}
                onChange={(e) => {
                  const t = updateMask(e)
                  setText(t)
                  onChange(textFieldToNumber(t))
                }}
              />
            </>
          )}
        />
    );
};
