export const columnsContatos = [
    {
      key: "column1",
      name: "Principal",
      fieldName: "psCtPrincipal",
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column2",
      name: "NFC-e",
      fieldName: "psCtXMLNFCe",
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column3",
      name: "NF-e",
      fieldName: "psCtXMLNFe",
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column4",
      name: "XML",
      fieldName: "psCtXMLAutorizado",
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column5",
      name: "Nome",
      fieldName: "psCtNome",
      minWidth: 120,
      maxWidth: 160,
      isResizable: true,
    },
    {
      key: "column6",
      name: "Telefone",
      fieldName: "psCtTelefone",
      minWidth: 120,
      maxWidth: 160,
      isResizable: true,
    },
    {
      key: "column7",
      name: "Celular",
      fieldName: "psCtCelular",
      minWidth: 120,
      maxWidth: 160,
      isResizable: true,
    },
    {
      key: "column8",
      name: "E-mail",
      fieldName: "psCtEmail",
      minWidth: 120,
      maxWidth: 160,
      isResizable: true,
    },
    {
      key: "column9",
      name: "Status",
      fieldName: "psCtRecStatus",
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
    },
  ];
  