
export const preloadedValuesEndereco = {
  psEnRecStatus: false,
  psEnEnderecoPadrao: false,
  psEnEnderecoCobranca: false,
  psEnEnderecoEntrega: false,
  psEnCEP:"",
  psEnPaisId:"7f8dc367-d5d6-400c-baa4-8eebca5c9f56",
  psEnUfdeId:"",
  psEnCidaId:"",
  psEnBairro:"",
  psEnLogradouro:"",
  psEnLogradouroNumero:"",
  psEnComplemento:"",
};