import style from "../../style.module.scss";
import { LContribuicaoCst } from "./options/LContribuicaoCst";

import { FooterFormButtons } from "../../../../../../../components/footer-form/buttons-form";
import { SubtitleForm } from "../../../../../../../components/text/form/subtitle/subtitle";
import { ControllerComboBox } from "../../../../../../../components/controllers/combobox-controller";
import { ControllerCheckBox } from "../../../../../../../components/controllers/checkbox-controller";
import { TributacaoFormProps, schemaTributacao } from "./form.config";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { preloadedValues } from "./default-values-form";
import { useEffect, useState } from "react";
import { apiApp } from "../../../../../../../services/api/app.api";
import { endpoint } from "../../../../../../../config/environment/endpoint.config";
import { ID } from "../../../../../../../config/environment/id.config";
import {  IPropsFormProduto } from "../../interface/props.interface-form";
import { Loading } from "../../../../../../../components/loading/loading";
import { getInformations } from "./get-options-form";
import { ControllerMaskTextField } from "../../../../../../../components/controllers/mask-textfield-controller";
import { masksApp } from "../../../../../../../config/masks/masks";
import { ControllerTextField } from "../../../../../../../components/controllers/ControllerTextField";

export const TributacaoProduto = ({closeExpander,getValuesForm,setValuesForm,setCloseExpander}:IPropsFormProduto) => {
  const [loading, setLoading] = useState(false);
  
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<TributacaoFormProps>({
    mode: "all",
    resolver: zodResolver(schemaTributacao),
    defaultValues: preloadedValues,
  });

  const [optionsNCM,setOptionsNCM] = useState<any>([])
  const [optionsOperacoesFiscais,setOptionsOperacoesFiscais] = useState<any>([])


  useEffect(() => {
    getInformations({optionsNCM,setOptionsNCM,optionsOperacoesFiscais,setOptionsOperacoesFiscais})
  },[])

  useEffect(() => {
    setValue("prTrIndEscalaCNPJFabricante","")
  },[watch("prTrIndEscala")])

  const setFormValues = () => {
    setLoading(true)
    setValue("PrTrNcmsId", getValuesForm("PrTrNcmsId"))
    setValue("prTrCOFINSCSTCredito", getValuesForm("prTrCOFINSCSTCredito"))
    setValue("prTrOperId", getValuesForm("prTrOperId"))
    setValue("prTrCEST", getValuesForm("prTrCEST"))
    setValue("prTrPISCSTCredito", getValuesForm("prTrPISCSTCredito"))
    setValue("prTrIndEscala", getValuesForm("prTrIndEscala"))
    setValue("prTrIndEscalaCNPJFabricante", getValuesForm("prTrIndEscalaCNPJFabricante"))
   
    setLoading(false)
  }

  const submit = (data:TributacaoFormProps) => {
    setValuesForm("PrTrNcmsId", data.PrTrNcmsId)
    setValuesForm("prTrCOFINSCSTCredito", data.prTrCOFINSCSTCredito)
    setValuesForm("prTrOperId", data.prTrOperId)
    setValuesForm("prTrCEST", data.prTrCEST)
    setValuesForm("prTrPISCSTCredito", data.prTrPISCSTCredito)
    setValuesForm("prTrIndEscala", data.prTrIndEscala)
    setValuesForm("prTrIndEscalaCNPJFabricante", data.prTrIndEscalaCNPJFabricante)
   
    setCloseExpander({ state: true, open: false })

  }
  useEffect(() => {
    if (closeExpander.state) setFormValues()
  }, [])
  return (
    <>
    {loading && <Loading />}
    <form>
    <section className={style["form-content"]}>
      <div className={style["form-row"]}>
        <ControllerComboBox
          name="prTrNcmsId"
          control={control}
          label="NCM"
          options={optionsNCM}
        
        />
        <ControllerTextField
          name="prTrCEST"
          control={control}
          label="CEST"
        />
      </div>

      <SubtitleForm subtitle="PIS/COFINS para fins de crédito do imposto" />
      <div className={style["form-row"]}>
        <ControllerComboBox
          name="prTrPISCSTCredito"
          control={control}
          label="CST do PIS"
          options={LContribuicaoCst}
      
        />
        <ControllerComboBox
          name="prTrCOFINSCSTCredito"
          control={control}
          label="CST da COFINS"
          options={LContribuicaoCst}
       
        />
      </div>

      <SubtitleForm subtitle="Operação fiscal para fins de débito do imposto" />
      <ControllerComboBox
        name="prTrOperId"
        control={control}
        label="Operação fiscal padrão que será usada para débito de imposto"
        options={optionsOperacoesFiscais}
     
      />

      <SubtitleForm subtitle="Escala relevante" />

      <div className={style["form-row"]}>
        <ControllerCheckBox
          name="prTrIndEscala"
          label="Indicador de escala relevante"
          control={control}
        />
        
         <ControllerMaskTextField
            control={control}
            name="prTrIndEscalaCNPJFabricante"
            errors={errors.prTrIndEscalaCNPJFabricante?.message}
            label="CNPJ do Fabricante"
            placeholder=""
            mask={masksApp.CNPJ}
            readOnly={watch("prTrIndEscala")}
/>
      </div>
      <FooterFormButtons
          min={true}
          btn_salvar={handleSubmit(submit)}
          btn_cancelar={() => setCloseExpander({ state: false, open: false })}
        />
    </section>
    </form>
    </>
  );
};
