import { z } from "zod";

export const schemaPISCofins = z.object({
  operPISSCST: z.string({required_error:"Informe a CST para cálculo do PIS"}).min(1),
  operPISDeduzICMS: z.boolean(),
  operCOFINSCST: z.string({required_error:"Informe a CST para cálculo da COFINS"}).min(1),
  operCOFINSDeduzICMS: z.boolean(),
});

export type PISCofinsFormProps = z.infer<
  typeof schemaPISCofins
>;
