export const columnsFormasPagamento = [
    {
      key: "column1",
      name: "Descrição",
      fieldName: "fmPgDescricao",
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
    },
    {
      key: "column2",
      name: "Tipo",
      fieldName: "fmPgTPag",
      minWidth: 200,
      maxWidth: 210,
      isResizable: true,
    },
  
    {
      key: "column3",
      name: "Bandeira",
      fieldName: "fmPgTBand",
      minWidth: 120,
      maxWidth: 140,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Status",
      fieldName: "fmPgRecStatus",
      minWidth: 140,
      maxWidth: 190,
      isResizable: true,
    },
  ]