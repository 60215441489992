import { useNavigate } from "react-router-dom";
import React from "react";
import { TabsContext } from "../../../context/allTabs";
import { TabSelectedContext } from "../../../context/tabSelected";
import { ITab } from "./button-nav-options";

interface IButtonNavList {
  title: string;
  props: ITab;
  key: number;
  className: string;
}

export const ButtonNavList = ({ title, props, className }: IButtonNavList) => {
  const navigate = useNavigate();

  const { newTab, setNewTab } = React.useContext(TabsContext);
  const { setTabSelected } = React.useContext(TabSelectedContext);

  const openPage = (props: ITab) => {
    if (newTab.length === 0) {
      navigate(`/linko/${props.category}/${props.key}`);
      setTabSelected(props.key);
      return setNewTab([...newTab, props]);
    }
    let tab = newTab.filter((value) => value.key === props.key);

    if (tab.length === 0) {
      navigate(`/linko/${props.category}/${props.key}`);
      setTabSelected(props.key);

      return setNewTab([...newTab, props]);
    }
    setTabSelected(props.key);

    navigate(`/linko/${props.category}/${props.key}`);
  };

  return (
    <li onClick={() => openPage(props)} key={props.key} className={className}>
      {title}
    </li>
  );
};
