import { useState, useEffect } from "react";
import { IConstructTableForm, ConstructTableForm } from "../../../../../../../components/construct/construct-table-form/construct-table-form";
import { ID } from "../../../../../../../config/environment/id.config";
import { columnsEventos } from "../../columns/evento.column";
import { modifyInformationEventos } from "../../modify-information";
import { EventosForm } from "./evento.form";
import {  ValuesPessoaTable } from "../types/types-cadastro-pessoas";

export const EventosPessoa = ({ arrayForm,setArrayForm, closeExpander,setCloseExpander,getValuesForm,setValuesForm }: ValuesPessoaTable) => {
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (arrayForm.length > 0) setCloseExpander({ state: true, open: true });
    
  }, [arrayForm])
  
  const Informations: IConstructTableForm = {
    columns: columnsEventos,
    propIdName: ID.cadastro.pessoaEvento,
    modifyValuesApi: modifyInformationEventos,
    arrayWithValuesForm: arrayForm,
    setArrayWithValuesForm: setArrayForm,
    categoryId: "cadastro",
    showForm: showForm,
    setShowForm: setShowForm,
    navigatePage: "",
    setCloseExpander,closeExpander,
    form: <EventosForm arrayForm={arrayForm} setArrayForm={setArrayForm} setShowForm={setShowForm} getValuesForm={getValuesForm} setValuesForm={setValuesForm}/>,
  };
  return <ConstructTableForm {...Informations} />;
};
