import { useForm } from "react-hook-form";
import { CentroDeCustoFormProps, schemaCentroDeCusto } from "./form.config";
import { zodResolver } from "@hookform/resolvers/zod";
import { preloadedValues } from "./default-values-form";
import style from "./style.module.scss"
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ControllerCheckBox } from "../../../../../../components/controllers/checkbox-controller";
import { FooterFormButtons } from "../../../../../../components/footer-form/buttons-form";
import { TitleForm } from "../../../../../../components/text/form/title/title";
import { endpoint } from "../../../../../../config/environment/endpoint.config";
import { ID } from "../../../../../../config/environment/id.config";
import { idItemSelectedContext } from "../../../../../../context/idItemSelected";
import { editItemVerification } from "../../../../../../utils/actions/editItemPost";
import { getValuesItem, create } from "../../../../../../utils/actions/form.actions";
import { currentURlName } from "../../../../../../utils/url.name";
import { recStatusModify } from "../../../../../../utils/rec-status.modify";
import { ControllerTextField } from "../../../../../../components/controllers/ControllerTextField";

export const CentroDeCustoForm = () => {
  let navigate = useNavigate();
  const pageReturn = "/linko/configuracoes/centroCustos";

  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { idItemSelected } = useContext(idItemSelectedContext);
  const currentPage = currentURlName(pathname);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CentroDeCustoFormProps>({
    mode: "all",
    resolver: zodResolver(schemaCentroDeCusto),
    defaultValues:preloadedValues
  });

  const valuesEdit = (value: CentroDeCustoFormProps) => {
    setValue("cnCtRecStatus", recStatusModify.form(value.cnCtRecStatus))
    setValue("cnCtDescricao",value.cnCtDescricao);

    setIsLoading(false);
    return;
  };
  useEffect(() => {
    getValuesItem({
      currentPage,
      idSelected: idItemSelected.id,
      nameMenuCurrent: "centroDeCusto",
      setIsLoading,
      url: endpoint.cadastro,
      valuesEdit,
    });
  }, []);

  const createItem = async (data: any) => {
    const idNumber = idItemSelected.id;
    const id = ID.cadastro.centroDeCusto + ":" + idNumber;

    await create({
      currentPage,
      nameMenuCurrent: "centroDeCusto",
      url: endpoint.cadastro,
      data: editItemVerification({ currentPage, data, id }),
      setIsLoading,
      idItem: idNumber,
    });
    navigate(pageReturn);
  };
  return (
    <div className={style["form"]}>
      <TitleForm title={"Centros de Custos"} />
      <form className={style["form_content"]}>
        <ControllerCheckBox name={"cnCtRecStatus"} control={control} label="Desativado" recStatus={true} />
        <ControllerTextField
          name={"cnCtDescricao"}
          control={control}
          label="Descrição"
          placeholder="Descrição ou nome do centro de custo"
          errors={errors.cnCtDescricao?.message}
        />
        <FooterFormButtons
          min={false}
          btn_salvar={handleSubmit(createItem)}
          btn_cancelar={() => {
            navigate(pageReturn);
          }}
        />
      </form>
    </div>
  );
};
